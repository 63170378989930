import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { MenuItem } from './menu-native-item';

@Component({
  selector: 'bpc-menu-native',
  templateUrl: './menu-native.component.html'
})
export class MenuNativeComponent {
  @ViewChild('content') content!: ElementRef;
  @ViewChild('menuItems') menuItems!: ElementRef;

  @Input() items: MenuItem[] = [];
  @Output() hideTooltip = new EventEmitter();

  open = false;
  style: any = {};

  get contentElement(): HTMLElement {
    return this.content.nativeElement;
  }

  get menuItemsElement(): HTMLElement {
    return this.menuItems.nativeElement;
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClickListener(target: HTMLElement) {
    const clickedInside = this.menuItemsElement.contains(target);
    const clickedInsideContentEl = this.contentElement.contains(target);

    if (!clickedInside && !clickedInsideContentEl && this.open) {
      this.open = false;
    }
  }

  toggle() {
    this.open = !this.open;
    this.calculateOffset();
    this.hideTooltip.emit();
  }

  trackBy(index: number, item: MenuItem): string {
    return item.id ?? index.toString();
  }

  itemClick(event: MouseEvent, item: MenuItem) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    item.command?.();
    this.open = false;
  }

  private calculateOffset() {
    const { height } = this.contentElement.getBoundingClientRect();
    this.style.top = height + 10 + 'px';
    this.style.left = 'auto';
    this.style.right = 0;
    this.style.bottom = 'auto';
    this.style['z-index'] = 2000;
  }
}
