<!-- <div class="flex flex-col items-center my-8">
  <span class="items-center flex flex-col" *ngIf="!isExtension">
    <img src="assets/404.svg" alt="Not found" />
    <h1 class="text-3xl mt-12 mb-4 text-center">Page not found</h1>
    <h2 class="mt-4 text-center">We're sorry, the page you requested could not be found.</h2>
    <h2 class="text-center">Please go back to homepage.</h2>
    <span class="my-4">
      <a class="bg-daintree hover:bg-opacity-80 text-white px-4 py-2" routerLink="/">Go Home</a>
    </span>
  </span>
  <span *ngIf="isExtension" class="items-center flex flex-col">
    <h1 class="text-3xl mt-12 mb-4 text-center">Company not found</h1>
    <h2 class="mt-4 text-center">We're sorry, the company or web address you requested could not be found.</h2>
    <h2 class="text-center">Please click the button below if you would like us to investigate.</h2>
    <button class="px-4 my-4 bg-daintree hover:bg-opacity-80 text-white self-center">
      <a class="inline-block px-4 py-2">
        <span class="pi pi-question-circle text-teal-300"></span>
        <span class="ml-2">Support</span>
      </a>
    </button>
  </span>
</div> -->

<section class="auth-wrap" [ngClass]="{ 'not-found-extension': isExtension }">
  <div class="section-auth">
    <div class="auth-left">
      <div class="grid grid-nogutter">
        <div class="col-12">
          <a href="/" title="BRIGHTON PARK CAPITAL">
            <img src="assets/svg/auth-logo.svg" alt="BRIGHTON PARK CAPITAL" />
          </a>
        </div>
        <div class="col-12">
          <h2>Company not found</h2>
        </div>
        <div class="col-12 mb-20">
          <p class="paragraph">
            We're sorry, the company or web address you requested could not be found. <br />
            Please click the button below if you would like us to investigate.
          </p>
        </div>

        <div class="col-12">
          <a href="{{ supportService.supportEmail(true) }}">
            <button pButton type="button" label="Support" class="btn-secondary"></button>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
