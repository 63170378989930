<div class="grid">
  <div class="col">
    <div class="grid align-items-center">
      <div *ngIf="companyDetails.fullTimeEquivalent" class="col flex-w-auto">
        <span class="fte-data">
          FTE: <br />
          <strong>{{ companyDetails.fullTimeEquivalent | bpcNumber : "1.0-0" : "" }}</strong>
        </span>
      </div>
      <div *ngIf="companyDetails.fullTimeEquivalentGrowth" class="col flex-w-auto">
        <bpc-revenue-change [fteGrowth]="companyDetails.fullTimeEquivalentGrowth!"></bpc-revenue-change>
      </div>
      <div class="col flex-w-auto" *ngIf="companyDetails.paidInCapital">
        <span class="fte-data">
          Total PIC <br />
          <strong>{{ companyDetails.paidInCapital | bpcNumber : "1.0-1" : "$" }}</strong>
        </span>
      </div>
    </div>
  </div>
  <div class="col">
    <p-dropdown [options]="activityCalendar" [(ngModel)]="selectedActivity" (onChange)="onPeriodChange()"
      optionLabel="label" styleClass="w-full">
    </p-dropdown>
  </div>
  <div class="col-12">
    <div *ngIf="validChartData">
      <p-chart type="line" width="100%" height="280" [data]="basicData" [options]="basicOptions"></p-chart>
    </div>
    <p class="overview" *ngIf="!validChartData">No data available</p>
  </div>
</div>