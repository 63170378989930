import {
  from,
  mergeMap,
  Observable,
  ObservableInput,
  ObservedValueOf,
  OperatorFunction,
  shareReplay,
  ShareReplayConfig
} from 'rxjs';

export function mergeMapWithShareReplayableProjections<T, O extends ObservableInput<any>>(
  project: (value: T, index: number) => O,
  config: ShareReplayConfig = { bufferSize: 1, refCount: false }
): OperatorFunction<T, ObservedValueOf<O>> {
  const map = new Map<T, Observable<any>>();
  return source => source.pipe(mergeMap((value, index) => {
    let obs = map.get(value);

    if (!obs) {
      obs = from(project(value, index)).pipe(shareReplay(config));
      map.set(value, obs);
    }

    return obs;
  }));
}