import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { Tag, TagType } from '@models/tag';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AuthService } from '@core/auth/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'bpc-custom-tag[tag]', // [tag] makes tag @Input property as required
  templateUrl: './custom-tag.component.html'
})
export class CustomTagComponent {
  @Input() tag!: Tag;
  @Output() tagClick = new EventEmitter<void>();
  @Output() editTagClick = new EventEmitter<void>();
  @Output() removeTagClick = new EventEmitter<void>();
  @ViewChild('tooltip') tooltip!: OverlayPanel;

  userId = inject(AuthService).userId;

  get canEditTag(): boolean {
    return this.tag.createBy === this.userId && this.tag.type !== TagType.Source;
  }

  get isTablet(): boolean {
    return this.deviceService.isTablet();
  }

  constructor(
    private deviceService: DeviceDetectorService
  ) { }

  emitTagClick() {
      this.tagClick.emit();
  }

  onEditTagClick() {
    this.editTagClick.emit();
    this.tooltip.hide();
  }

  onRemoveTagClick($event: Event) {
    $event.stopPropagation();
    this.removeTagClick.emit();
    this.tooltip.hide();
  }
}