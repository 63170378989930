<div class="relative" [id]="id">
  <div class="search-input-container">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-search"></i>
      </span>
      <input
        #searchInput
        #searchQueryNgModel="ngModel"
        appCompanyName
        class="search-field"
        placeholder="Search here..."
        type="text"
        (focusin)="op.show($event)"
        (focusout)="onFocusOut($event)"
        (keydown.arrowDown)="$event.preventDefault()"
        (keydown.arrowUp)="$event.preventDefault()"
        (keydown.enter)="onEnter(); searchInput.blur();"
        (keydown.escape)="searchInput.blur()"
        (ngModelChange)="onChange($event)"
        [ngModel]="searchQuery"
      />
      <span class="p-inputgroup-addon">
        <span *ngIf="loading" class="pi pi-spinner pi-spin mr-2"></span>
        <span
          class="pi pi-times"
          (click)="onClearButtonClick($event)"
          *ngIf="!loading && searchQuery != null && searchQuery.length"
          id="clearButton"
        ></span>
        <!-- <span class="pi pi-chevron-down"></span> -->
      </span>
    </div>
  </div>

  <p-overlayPanel #op [appendTo]="overlayContainer" styleClass="search-results-wrapper">
    <div *ngIf="!loading && showNoResults" class="cursor-default px-3 py-2 text-gray-900">
      <div class="p-table-no-data search">
        <img src="assets/svg/search.gif" alt="No Result Found" />
        <h3>No Result Found</h3>
      </div>
    </div>
    <p-tabView *ngIf="!showNoResults">
      <p-tabPanel header="All">
        <div *ngIf="!searchQuery && recentCompanies.length > 0">
          <p class="mb-2">Recent searches</p>
          <div class="grid">
            <div
              class="col-6"
              (click)="openCompanyDetails(item); op.hide()"
              *ngFor="let item of recentCompanies; let i = index"
              [ngClass]="i === selectedIndex ? highlightClasses : ''"
            >
              <bpc-search-pannel-item [item]="item"></bpc-search-pannel-item>
            </div>
          </div>
        </div>

        <div class="grid" *ngIf="showRealResults && combinedResults.length">
          <div
            class="col-6"
            (click)="openDetails(item); op.hide()"
            *ngFor="let item of combinedResults; let i = index"
            [ngClass]="i === selectedIndex ? highlightClasses : ''"
          >
            <bpc-search-pannel-item [item]="item.item"></bpc-search-pannel-item>
          </div>
          <div class="col-12 flex align-items-center justify-content-center">
            <a (click)="onEnter(); searchInput.blur(); op.hide()" class="view-all" title="View All Results"
              >View All Results</a
            >
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Company">
        <div class="grid" *ngIf="showRealResults && filteredCompanies.length">
          <div
            class="col-6"
            (click)="openCompanyDetails(item); op.hide()"
            *ngFor="let item of filteredCompanies; let i = index"
            [ngClass]="i === selectedIndex ? highlightClasses : ''"
          >
            <bpc-search-pannel-item [item]="item"></bpc-search-pannel-item>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Tags">
        <div class="grid" *ngIf="showRealResults && filteredTags.length">
          <div
            class="col-6"
            (click)="searchBySource(item); op.hide()"
            *ngFor="let item of filteredTags; let i = index; trackBy: identify"
            [ngClass]="i + filteredCompanies.length === selectedIndex ? highlightClasses : ''"
          >
            <bpc-search-pannel-item [item]="item"></bpc-search-pannel-item>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
    <div class="grid">
      <div class="col-12" *ngIf="showNoResults && combinedResults.length === 0 && recentCompanies.length === 0">
        <div class="p-table-no-data">
          <img src="assets/svg/search.gif" alt="No Result Found" />
          <h3>No Result Found</h3>
        </div>
      </div>
    </div>
  </p-overlayPanel>
</div>
