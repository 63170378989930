<div class="max-w-[200px]">
  <div *ngIf="!editMode"
       class="select-none cursor-pointer whitespace-pre-line"
       (dblclick)="enterEditMode()">
                <span *ngIf="!value" class="text-gray-400">
                  Add IC Ask
                </span>
    {{ value }}
  </div>

  <textarea *ngIf="editMode"
            appAutoFocus
            [(ngModel)]="value"
            (focusout)="onFocusOut()"></textarea>
</div>