<div>
  <div class="cd-sub-title">
    <h2>History</h2>
    <span
      (click)="toggleHistory()"
      class="text-gray-700 text-xs flex items-center cursor-pointer">
    </span>
    <span (click)="toggleHistory()" class="text-gray-700 text-xs cursor-pointer">
      <span *ngIf="showHistory" class="flex items-center">
        Collapse
        <i class="pi pi-minus ml-1"></i>
      </span>
      <span *ngIf="!showHistory" class="flex items-center">
        Expand
        <i class="pi pi-plus ml-1"></i>
      </span>
    </span>
  </div>

  <div *ngIf="showHistory"
       class="max-h-60 overflow-auto scrollbar scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">

    <ng-container *ngIf="icVotingWorkflows.length">
      <div class="font-bold">Voting history</div>
      <div *ngFor="let workflow of icVotingWorkflows" class="w-full mb-4">
        <div class="mb-1 text-sm mt-1 flex items-center flex justify-between">
          <span>Company was added to IC on {{ workflow?.createDate | date}}.</span>
          <span (click)="showVoteStatsModal(workflow)" *ngIf="authService.isVoterCommittee"
                class="font-bold mr-4 cursor-pointer text-sm">see results</span>
        </div>
      </div>
      <hr class="mb-4">
    </ng-container>

    <ng-container *ngFor="let record of readableRecords">
      <div *ngIf="record.value" class="flex flex-col mb-4">
        <span class="text-xs text-gray-500">{{ record.date | date: 'medium' }}</span>
        <span class="text-sm mt-1">{{ record.value }}</span>
      </div>
    </ng-container>
  </div>
</div>