import { Injectable } from '@angular/core';
import { ApiResponse, BaseApiService } from '../../api/base';
import { Observable } from 'rxjs';
import { CompanyContact, DeleteConnection, EditConnection, NewConnection } from '@models/forms';
import { OperationResult } from '@models/operation-result';
import { AuthService } from '@core/auth/auth.service';
import { Staff } from '@models/user';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ConnectionsService extends BaseApiService {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/connections', authService, router, messageService);
  }

  createNewConnection(data: NewConnection): Observable<ApiResponse<OperationResult>> {
    return this.post('', undefined, data);
  }

  updateConnection(data: EditConnection): Observable<ApiResponse<OperationResult>> {
    return this.put('', undefined, data);
  }

  upsertCompanyPerson(data: CompanyContact): Observable<ApiResponse<OperationResult>> {
    return this.post('/companies/people', undefined, data);
  }

  deleteConnection(data: DeleteConnection): Observable<ApiResponse<OperationResult>> {
    return this.delete('', data);
  }

  getUserByWorkEmail(email: string): Observable<ApiResponse<Staff>> {
    return this.get('/companies/people/byworkemail', { workEmail: email });
  }

  // Linkedin
  getUserByExternalUrl(url: string): Observable<ApiResponse<Staff>> {
    return this.get('/companies/people/byexternalurl', { externalUrl: encodeURIComponent(url) });
  }
}
