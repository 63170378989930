import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlow } from '@models/auxiliary-company-models';
import { Nullable } from '@models/nullable';
import { getEnrichmentWorkflow } from '../../../utils/company-utils';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'enrichmentCreateDatePipe'
})
export class EnrichmentCreateDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(workflows: WorkFlow[]): Nullable<string> {
    return this.datePipe.transform(getEnrichmentWorkflow(workflows)?.createDate);
  }
}
