import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlow } from '@models/auxiliary-company-models';
import { getActiveOrLastICVotingWorkflow } from '../../../utils/company-utils';

@Pipe({
  name: 'votingSummaryEnable'
})
export class VotingSummaryEnablePipe implements PipeTransform {

  transform(workflows: WorkFlow[]): boolean {
    return !!getActiveOrLastICVotingWorkflow(workflows)?.details?.averageVote;
  }
}
