<div *ngIf="badgeType === 'simple'" class="flex items-center text-sm">
  <bpc-avatar [size]="'sm'" [user]="user"></bpc-avatar>
  <span class="truncate max-h-[20px] max-w-[{{ width }}px] ml-1">{{ user?.name }}</span>
</div>

<div *ngIf="badgeType === 'extended'" class="contact-list" [ngClass]="{ 'linked-contact': !isNonAnalystUser && isConnected }">
  <div class="grid grid-no-tb-gutter flex justify-content-between align-items-center">
    <div class="col flex-w-auto">
      <a [ngClass]="{ disabled: !extendedUser.workEmail }" href="mailto:{{ extendedUser.workEmail }}" title="Mail"
        class="mr-2" target="_blank">
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.7031 0.928711H4.92188C3.61699 0.930227 2.366 1.43355 1.44331 2.32828C0.520618 3.22302 0.00156305 4.4361 0 5.70144L0 17.156C0.00156305 18.4213 0.520618 19.6344 1.44331 20.5291C2.366 21.4239 3.61699 21.9272 4.92188 21.9287H18.7031C20.008 21.9272 21.259 21.4239 22.1817 20.5291C23.1044 19.6344 23.6234 18.4213 23.625 17.156V5.70144C23.6234 4.4361 23.1044 3.22302 22.1817 2.32828C21.259 1.43355 20.008 0.930227 18.7031 0.928711ZM4.92188 2.8378H18.7031C19.2926 2.83893 19.8682 3.01107 20.3558 3.33207C20.8435 3.65307 21.221 4.10825 21.4397 4.63903L13.9013 11.9499C13.3466 12.4857 12.5955 12.7865 11.8125 12.7865C11.0295 12.7865 10.2784 12.4857 9.72366 11.9499L2.18531 4.63903C2.40399 4.10825 2.78146 3.65307 3.26916 3.33207C3.75685 3.01107 4.33245 2.83893 4.92188 2.8378ZM18.7031 20.0196H4.92188C4.13866 20.0196 3.38752 19.7179 2.8337 19.1809C2.27988 18.6438 1.96875 17.9155 1.96875 17.156V7.13326L8.33175 13.2996C9.25571 14.1933 10.5075 14.6952 11.8125 14.6952C13.1175 14.6952 14.3693 14.1933 15.2933 13.2996L21.6563 7.13326V17.156C21.6563 17.9155 21.3451 18.6438 20.7913 19.1809C20.2375 19.7179 19.4863 20.0196 18.7031 20.0196Z"
            fill="#002438" />
          <path
            d="M18.7031 0.928711H4.92188C3.61699 0.930227 2.366 1.43355 1.44331 2.32828C0.520618 3.22302 0.00156305 4.4361 0 5.70144L0 17.156C0.00156305 18.4213 0.520618 19.6344 1.44331 20.5291C2.366 21.4239 3.61699 21.9272 4.92188 21.9287H18.7031C20.008 21.9272 21.259 21.4239 22.1817 20.5291C23.1044 19.6344 23.6234 18.4213 23.625 17.156V5.70144C23.6234 4.4361 23.1044 3.22302 22.1817 2.32828C21.259 1.43355 20.008 0.930227 18.7031 0.928711ZM4.92188 2.8378H18.7031C19.2926 2.83893 19.8682 3.01107 20.3558 3.33207C20.8435 3.65307 21.221 4.10825 21.4397 4.63903L13.9013 11.9499C13.3466 12.4857 12.5955 12.7865 11.8125 12.7865C11.0295 12.7865 10.2784 12.4857 9.72366 11.9499L2.18531 4.63903C2.40399 4.10825 2.78146 3.65307 3.26916 3.33207C3.75685 3.01107 4.33245 2.83893 4.92188 2.8378ZM18.7031 20.0196H4.92188C4.13866 20.0196 3.38752 19.7179 2.8337 19.1809C2.27988 18.6438 1.96875 17.9155 1.96875 17.156V7.13326L8.33175 13.2996C9.25571 14.1933 10.5075 14.6952 11.8125 14.6952C13.1175 14.6952 14.3693 14.1933 15.2933 13.2996L21.6563 7.13326V17.156C21.6563 17.9155 21.3451 18.6438 20.7913 19.1809C20.2375 19.7179 19.4863 20.0196 18.7031 20.0196Z"
            fill="#002438" fill-opacity="0.5" />
        </svg>
      </a>
      <a [ngClass]="{ disabled: !extendedUser.externalUrl }" [href]="extendedUser.externalUrl | urlTransform"
        title="LinkedIn" target="_blank">
        <img src="assets/svg/linkedIn.svg" alt="linkedIn" />
      </a>
    </div>
    <div class="col">
      <a class="contact-name" [overlayPanelTooltip]="linkedInURLOverlayPanel" [target]="'_blank'"
        [href]="extendedUser.externalUrl | urlTransform"
        *ngIf="extendedUser.firstName || extendedUser.lastName; else notAvailableButton">
        {{ extendedUser.firstName }} {{ extendedUser.lastName }}
      </a>
      <span *ngIf="extendedUser.position" [overlayPanelTooltip]="nameTooltip" class="contact-position">{{
        extendedUser.position
        }}</span>

      <p-overlayPanel #nameTooltip>
        {{ extendedUser.position }}
      </p-overlayPanel>
      <p-overlayPanel #linkedInURLOverlayPanel>
        <ng-template pTemplate="content">
          <span *ngIf="extendedUser.externalUrl" class="cursor-pointer" (click)="copyLinkedInProfileURL()">{{
            extendedUser.externalUrl
            }}</span>
          <span *ngIf="!extendedUser.externalUrl">No details available</span>
        </ng-template>
      </p-overlayPanel>

      <p-overlayPanel #emailOverlayPanel>
        <ng-template pTemplate="content">
          <span *ngIf="extendedUser.workEmail" class="cursor-pointer" (click)="copyEmail()">{{ extendedUser.workEmail }}
          </span>
          <span *ngIf="!extendedUser.workEmail">No details available</span>
        </ng-template>
      </p-overlayPanel>
    </div>
    <!-- <div class="col flex-w-auto">
      <div class="grid grid-no-tb-gutter">
        <div class="col flex-w-auto" *ngIf="sortedInternal">
          <ng-container *ngFor="let profile of sortedInternal; let i = index">
            <p-avatar [styleClass]="isSecondLevelConnection(profile) ? 'orange mr-1' : 'cyan mr-1'" *ngIf="i < 3"
              size="large" shape="circle">
              <ng-container *ngIf="(isMobile && i < 3) || (!isMobile && i < 10)">
                <span *ngFor="let connect of profile.connections">
                  <ng-container *ngIf="user$ | async as user">
                    <a class="relative" [overlayPanelTooltip]="connectionPanel" target="_blank" href="mailto:{{ profile.email }}?subject=MUST MEET REMINDER: {{ companyName }} - Introduce {{
                          user?.name
                        }} to {{ extendedUser.firstName }} {{ extendedUser.lastName }}{{
                          extendedUser.position ? ', ' + extendedUser.position : ''
                        }}.">
                      <p-overlayPanel styleClass="tool-tip-class" #connectionPanel>
                        <ng-container *ngFor="let connection of connect.secondLevelConnections">
                          <div>
                            <a class="company-link" href="{{ connection.externalUrl | urlTransform }}"
                              target="_blank">{{ connection.firstName + " " + connection.lastName || "" }}</a>
                          </div>
                        </ng-container>
                      </p-overlayPanel>
                      <bpc-avatar size="xs" [user]="profile" [disableTooltip]="true"> </bpc-avatar>
                    </a>
                  </ng-container>
                </span>
              </ng-container>
            </p-avatar>
          </ng-container>
          <p-avatar styleClass="grey" *ngIf="sortedInternal.length > 3" size="large" shape="circle"
            (click)="displayConnectionsModal = true;">+{{(sortedInternal.length) - 3}}
          </p-avatar>
        </div>
        <div class="col flex-w-auto flex align-items-center"> -->
          <!-- bpc-menu-native-primeng change this // it will hurt the performance for few companies -->
          <bpc-menu-native *ngIf="!isMobile" [items]="menuItems" (click)="onMenuClick(extendedUser)">
            <span class="pi pi-ellipsis-v cursor-pointer ellipse-icon"> </span>
          </bpc-menu-native>
        <!-- </div>
      </div>
    </div> -->
    <ng-template #notAvailableButton>
      <span class="contact-name">N/A</span>
    </ng-template>
  </div>
</div>

<p-dialog [(visible)]="displayModal" [modal]="true" [draggable]="false" [resizable]="false" [showHeader]="false">
  <div class="p-dialog-header">
    <span class="p-dialog-title">Remove connection</span>
  </div>

  <div class="p-dialog-content">
    <div class="col-12">
      <p>This key individual will be removed from the company. Are you sure?</p>
    </div>
    <div class="col-12">
      <p-checkbox [(ngModel)]="deleteFromSystem" label="Delete all data for this individual from the system">
      </p-checkbox>
      <p *ngIf="deleteFromSystem" class="col-12 text-sm">
        This key individual data will be removed from all the related companies in Jarvis. This action is not
        revertable.
      </p>
    </div>
  </div>

  <div class="p-dialog-footer">
    <button pButton (click)="toggleDeleteModal()">Cancel</button>
    <button pButton (click)="removeUser()" class="button-dark">Delete</button>
  </div>
</p-dialog>

<bpc-custom-modal (onHide)="displayConnectionsModal = false;" [visible]="displayConnectionsModal"
  [contentStyleClass]="'scrollable'">
  <ng-container modal-header>
    <span class="text-xl font-bold">All Connections</span>
    <i class="pi pi-times cursor-pointer" (click)="displayConnectionsModal = false"></i>
  </ng-container>
  <ng-container modal-content>
    <div class="grid">
      <ng-container *ngFor="let profile of sortedInternal; let i = index">
        <div class="col-12">
          <p-avatar [styleClass]="isSecondLevelConnection(profile) ? 'orange' : 'cyan'" size="large" shape="circle">
            <ng-container *ngIf="(isMobile && i < 3) || (!isMobile && i < 10)">
              <span *ngFor="let connect of profile.connections">
                <ng-container *ngIf="user$ | async as user">
                  <a class="relative" [overlayPanelTooltip]="connectionPanel" target="_blank" href="mailto:{{ profile.email }}?subject=MUST MEET REMINDER: {{ companyName }} - Introduce {{
                    user?.name
                  }} to {{ extendedUser.firstName }} {{ extendedUser.lastName }}{{
                    extendedUser.position ? ', ' + extendedUser.position : ''
                  }}.">
                    <p-overlayPanel #connectionPanel>
                      <ng-container *ngFor="let connection of connect.secondLevelConnections">
                        <div>
                          <a class="company-link" href="{{ connection.externalUrl | urlTransform }}" target="_blank">{{
                            connection.firstName + " " + connection.lastName || "" }}</a>
                        </div>
                      </ng-container>
                    </p-overlayPanel>
                    <bpc-avatar size="xs" [user]="profile" [disableTooltip]="true"> </bpc-avatar>
                  </a>
                </ng-container>
              </span>
            </ng-container>
          </p-avatar>
          <strong class="ml-2">
            {{profile?.name}}
          </strong>
        </div>
      </ng-container>
    </div>
  </ng-container>
</bpc-custom-modal>