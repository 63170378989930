import { Directive } from '@angular/core';

import { combineLatest, map, takeUntil } from 'rxjs';
import { AutoComplete } from 'primeng/autocomplete';

import { LocationService } from '../services/location.service';
import { DestroyObservable } from '../../rxjs/DestroyObservable';
import { Zone } from '@models/zone';
import { BaseMultipleAutoCompleteDirective } from './base-multiple-auto-complete.directive';

@Directive({
  selector: 'p-autoComplete[zones]',
  providers: [DestroyObservable]
})
export class ZonesDirective extends BaseMultipleAutoCompleteDirective<Zone> {

  loadData = combineLatest([this.locationService.countries$, this.locationService.cities$, this.locationService.regions$])
    .pipe(
      map(([countries, cities, regions]) => ({
        statusCode: 200,
        data: countries.data?.concat(cities.data ?? []).concat(regions.data ?? []) ?? []
      })),
      takeUntil(this.destroyObservable)
    );

  constructor(autoComplete: AutoComplete, private locationService: LocationService, private destroyObservable: DestroyObservable) {
    super(autoComplete);
  }
}
