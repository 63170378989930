<!-- Start  Company Details -->
<div class="overlay"></div>
<section class="company-details-wrapper" [ngClass]="{ 'company-details-extension-wrapper': (isExtension || isMobile)}">
  <div class="wrapper" *ngIf="companyDetails; else loading">
    <div *ngIf="!isMobile" class="details-header">
      <div class="grid flex justify-content-between flex-wrap grid-nogutter">
        <div class="col align-items-center">
          <ul class="navigation">
            <li>
              <a (click)="onCopyUrl()" tooltipPosition="bottom" placeholder="Bottom" pTooltip="Copy Url">
                <!-- <img src="assets/svg/copy-icon.svg" alt="Copy Url" /> -->
                <i class="pi pi-copy"></i>
              </a>
            </li>
            <ng-container *ngIf="authService.isInvestor || authService.isAdmin">
              <!-- Hiding -->
              <!-- <li *ngIf="companyDetails.priority === 0 || companyDetails.priority === 2">
                <a
                  (click)="onTopBarClick('addToPriority')"
                  [ngClass]="{ 'opacity-50': !isPartOfCoverageUsers }"
                  tooltipPosition="bottom"
                  placeholder="Bottom"
                  pTooltip="Add to Priority"
                >
                  <span class="icon-add-priority"></span>
                </a>
              </li>

              <li *ngIf="companyDetails.priority === 3">
                <a
                  (click)="onTopBarClick('removeFromPriority')"
                  [ngClass]="{ 'opacity-50': !isPartOfCoverageUsers || !isCompanyActive }"
                  tooltipPosition="bottom"
                  placeholder="Bottom"
                  pTooltip="Remove from priority"
                >
                  <span class="icon-remove-priority"></span>
                </a>
              </li> -->

              <li *ngIf="!isPartOfCoverageUsers">
                <a
                  (click)="onTopBarClick('joinCoverage')"
                  [pTooltip]="coverageBtnLabel"
                  [ngClass]="{ 'opacity-50': !isCompanyActive }"
                  tooltipPosition="bottom"
                  placeholder="Bottom"
                  pTooltip="Join Coverage"
                >
                  <span class="icon-add-coverage"></span>
                </a>
              </li>

              <li>
                <a
                  (click)="onTopBarClick('changeStatus')"
                  [ngClass]="{ 'opacity-50': !isCompanyActive }"
                  tooltipPosition="bottom"
                  placeholder="Bottom"
                  pTooltip="Change Status"
                >
                  <span class="icon-status"></span>
                </a>
              </li>
              <li>
                <a
                  (click)="onTopBarClick('leaveCoverage')"
                  [ngClass]="{ 'opacity-50': !isPartOfCoverageUsers || !isCompanyActive }"
                  tooltipPosition="bottom"
                  placeholder="Bottom"
                  pTooltip="Leave Coverage"
                >
                  <span class="icon-remove-coverage"></span>
                </a>
              </li>
              <li>
                <a
                  (click)="onTopBarClick('editCoverage')"
                  [ngClass]="{ 'opacity-50': !isCompanyActive }"
                  tooltipPosition="bottom"
                  placeholder="Bottom"
                  pTooltip="Edit Coverage"
                >
                  <span class="icon-edit"></span>
                </a>
              </li>
              <li>
                <!--  -->
                <a class="opacity-50" tooltipPosition="bottom" placeholder="Bottom" pTooltip="Create/Edit Alert">
                  <span class="icon-notification" (click)="onTopBarClick('createOrEditNotificationTrigger')"></span>
                </a>
              </li>
              <li>
                <a
                  (click)="onTopBarClick('flagForICVoting')"
                  [ngClass]="{ 'opacity-50': !enableICVoting }"
                  tooltipPosition="bottom"
                  placeholder="Bottom"
                  pTooltip="Flag for IC Voting"
                >
                  <span class="icon-thumbs-up"></span>
                </a>
              </li>
              <!-- enrich data icon is intentionally hidden for experimentation -->
              <li style="display: none;">
                <a
                  (click)="onTopBarClick('enrichData')"
                  [ngClass]="{ 'opacity-50': !enableEnrichment }"
                  tooltipPosition="bottom"
                  placeholder="Bottom"
                  pTooltip="Enrich Data"
                >
                  <span class="icon-enrich"></span>
                </a>
              </li>
              
              <!-- Button hidden due to AI Prep being hidden -->
              <!-- <li>
                <a
                  (click)="onAISummaryClick()"
                  tooltipPosition="bottom"
                  placeholder="Bottom"
                  pTooltip="AI Generated Summary"
                >
                <i class="fa-regular fa-file-lines"></i>
                </a>
              </li> -->

            </ng-container>
            <li>
              <a (click)="supportIconClick($event)" href="{{ supportService.supportEmail() }}" tooltipPosition="bottom" placeholder="Bottom"
                pTooltip="Support" target="_blank">
                <span class="icon-mail"></span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col flex-w-auto align-items-center">
          <ul class="navigation gap-16">
            <ng-container *ngIf="config.navigateThruCompanies && !isExtension">
              <li>
                <a (click)="goToPrevious()" [ngClass]="{ 'opacity-50': !companiesNavigationEnabled }">
                  <span class="icon-circle-left"></span>
                </a>
              </li>
              <li>
                <a (click)="goToNext()" [ngClass]="{ 'opacity-50': !companiesNavigationEnabled }">
                  <span class="icon-circle-right"></span>
                </a>
              </li>
            </ng-container>
            <li *ngIf="!isExtension">
              <a (click)="close()">
                <i class="pi pi-times"></i>
              </a>
            </li>
            <li *ngIf="isExtension">
              <a (click)="openInJarvis()" tooltipPosition="bottom" placeholder="Bottom" pTooltip="Open in Jarvis">
                <span class="icon-exit"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="isMobile" class="details-header">
      <div class="grid flex justify-content-between flex-wrap grid-nogutter">
        <div class="col align-items-center">
          <h2>
            <span class="icon-enrich mr-2" *ngIf="!enableEnrichment"></span>
            <p-overlayPanel #enrichmentStatusPanel>
              {{ companyDetails.workflows | enrichmentStatus }}
            </p-overlayPanel>
            {{ companyDetails.name }}
          </h2>
        </div>
        <!-- <p-menu class="mobile-p-menu" #menu [model]="items" [popup]="true"></p-menu> -->
        <!-- <span
          class="mobile-menu"
          pButton
          (click)="menu.toggle($event)"
          icon="pi pi-ellipsis-v"
        ></span> -->
        <a (click)="close()" class="mobile-cross">
          <i class="pi pi-times"></i>
        </a>
      </div>
    </div>
    <div class="details-content" [ngClass]="{ 'details-content-mobile': isMobile}"  >
      <div class="grid grid-nogutter info-wrapper" style="margin-bottom: 2px">
        <div class="col extention-full-w">
          <div class="grid grid-no-tb-gutter">
            <div class="col flex-w-auto" *ngIf="!isMobile">
              <!-- <p-avatarGroup>
                <p-avatar
                  [overlayPanelTooltip]="scoreTooltip"
                  label="{{ companyDetails.rank | number : '1.0-0' }}"
                  styleClass="cyan w-38"
                  shape="circle"
                ></p-avatar>
              </p-avatarGroup> -->
              <!-- Company Score -->
              <!-- <p-overlayPanel  styleClass="tool-tip-class" #scoreTooltip>
                <bpc-company-score></bpc-company-score>
              </p-overlayPanel> -->
            </div>
            <div class="col">
              <h2 *ngIf="!isMobile">
                <span class="icon-enrich mr-2" *ngIf="!enableEnrichment"></span>
                <p-overlayPanel #enrichmentStatusPanel>
                  {{ companyDetails.workflows | enrichmentStatus }}
                </p-overlayPanel>
                {{ companyDetails.name }}
              </h2>
              <!-- <p class="type" *ngIf="companyType">{{ companyType }}</p> -->
              <div style="display: flex; align-items: center;">
                <!-- Social Links -->
                <bpc-social-icon
                  *ngFor="let link of companyDetails | socialLinks"
                  class="social-links"
                  [socialLink]="link"
                  (urlClick)="socialUrlClick($event)"
                >
                </bpc-social-icon>
                <bpc-notifications-icon
                  (onClick)="emitCompanyAction('createOrEditNotificationTrigger')"
                  [company]="companyDetails"
                >
                </bpc-notifications-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="col flex-w-auto d-center extention-full-w">
          <div class="grid grid-no-tb-gutter flex justify-content-end">
            <!-- <div *ngIf="isExtension" class="col flex-w-auto">            
              <p-button (click)="openInJarvis()" styleClass="btn-accept mg-b-16" label="Open in Jarvis"></p-button>
            </div> -->
            <div *ngIf="canAcceptChanges" class="flex">
              <div class="col flex-w-auto">
                <p-button (click)="acceptChanges()" styleClass="btn-accept mg-b-16" label="Accept Changes"></p-button>
              </div>
              <div class="col flex-w-auto">
                <p-button
                  (click)="declineChanges()"
                  styleClass="btn-decline mg-b-16"
                  label="Decline Changes"
                ></p-button>
              </div>
            </div>
            <div class="col flex-w-auto" *ngIf="canMarkAsDone">
              <p-button (click)="markAsDone()" styleClass="btn-accept mg-b-16" label="Mark as Done"></p-button>
            </div>
          </div>

          <div class="grid" [ngClass]="{'info-header-container':isExtension || isMobile}">
            
            <!-- Source Scrub Exclusive -->
            <!-- <div *ngIf="companyDetails.foundDate" class="col flex-w-auto data-labels btm-space">
              <strong>Founded:</strong>{{ (companyDetails.foundDate | date : "yyyy") ?? "-" }}
            </div> -->
            
            <div *ngIf="companyDetails.address" class="col flex-w-auto data-labels btm-space">
              <strong>Location:</strong>{{ companyDetails.address | companyLocation }}
            </div>
          </div>
          <div class="grid">
            <!-- <div *ngIf="companyDetails.paidInCapital" class="col flex-w-auto data-labels btm-space">
              <strong>Total Raised:</strong>{{ companyDetails.paidInCapital | bpcNumber : "1.0-1" : "$" }}
            </div> -->
            <!-- <div *ngIf="companyDetails.latestRound?.amount" class="col flex-w-auto data-labels btm-space">
              <strong>Last Round:</strong> {{ companyDetails.latestRound?.amount | bpcNumber : "1.0-1" : "$" }}
            </div> -->
          </div>
          <!-- <div *ngIf="companyDetails.parentCompanyName?.name" class="grid">
            <div class="col data-labels" style="line-height: 150%;">
              <strong>Parent Company: </strong>{{ companyDetails.parentCompanyName?.name }}
            </div>
          </div> -->
          <div *ngIf="authService.isInvestor || authService.isAdmin" class="grid" [ngClass]="{'info-header-container':isExtension || isMobile}">
            <div class="col flex-w-auto data-labels btm-space flex align-items-center gap-3" >
              <strong>Daily News Alerts:</strong>
              <p-inputSwitch name="weeklyNewsToggle" onLabel="On" offLabel="Off" (onChange)="handleSubscriptionChange($event)" [(ngModel)]="weeklySubscription">
              </p-inputSwitch>
            </div>
          </div>
        </div>
      </div>
      <section class="columns-wrap">
        <!-- First Column -->
        <div class="first">
          <div class="grid grid-no-tb-gutter">
            <div class="col-12 label-tag" *ngIf="companyDetails.statusId">
              <strong>Status</strong>
              <p>{{ companyDetails.statusId | status }}</p>
              <!-- <div class="tags-wrap">
                <span class="tags">{{ companyDetails.statusId | status }}</span>
              </div> -->
            </div>
            <div class="col-12 label-tag" *ngIf="isExtension || isMobile">
              <div class="grid">
                <div class="col flex-w-auto flex align-items-center justify-content-center">
                  <strong>Tags</strong>
                </div>
                <div class="col flex justify-content-end" *ngIf="!isMobile">
                  <p-button
                    styleClass="btn-sec"
                    title="Add new tag"
                    label=" Add New Tag"
                    (click)="openCreateNewTagModal()"
                  >
                    <i class="pi pi-plus mr-2"></i>
                  </p-button>
                </div>
                <div class="col-12">
                  <div class="tags-wrap" *ngIf="tags.length > 0; else noTags">
                    <bpc-custom-tag
                      *ngFor="let tag of tags | slice : 0 : 5; trackBy: trackByTag"
                      (tagClick)="onTagClick(tag)"
                      (editTagClick)="onEditTagClick(tag)"
                      (removeTagClick)="onRemoveTagClick(tag)"
                      [tag]="tag"
                    >
                    </bpc-custom-tag>
                    <button
                      pButton
                      id="{{ 'show-more-tags' }}"
                      title="Show More"
                      (click)="showMoreTags()"
                      *ngIf="tags.length > 5"
                      class="show-more"
                    >
                      Show more
                    </button>
                  </div>
                  <ng-template #noTags>
                    <p>No Tags found</p>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="col-12 label-tag">
              <!-- FINANCIALS  -->
              <strong>Financials</strong>
              <p class="mgr-0" *ngIf="companyDetails.targetFinancials; else noTargetFinancials">
                <!-- SourceScrub exclusive -->
                {{ companyDetails.targetFinancials }}
                <!-- {{ companyType ? companyType + " / " : "" }}{{ companyDetails.targetFinancials }} -->
              </p>
              <ng-template #noTargetFinancials>
                <!-- SourceScrub exclusive -->
                <p>No data available</p>
                <!-- <p>{{ companyType ? companyType + " / " : "" }}No data available</p> -->
              </ng-template>
            </div>

            <ng-container *ngIf="companyDetails.statusId === 13">
              <div class="col-12 label-tag">
                <strong>Next Step</strong>
                <p *ngIf="companyDetails?.nextStep; else noNextStep">
                  {{ companyDetails.nextStep }}
                </p>
                <ng-template #noNextStep>
                  <p>No data available</p>
                </ng-template>
              </div>
            </ng-container>

            <div class="col-12 label-tag extention-full-w-50">
              <strong>Coverage</strong>
              <ng-template [ngIf]="coverageUsers">
                <p-avatarGroup>
                  <p-avatar
                    *ngFor="let user of coverageUsers | slice : 0 : 3"
                    [overlayPanelTooltip]="userNameTooltip"
                    label="{{ user?.name ?? '' | initials }}"
                    size="large"
                    styleClass="cyan cursor-pointer"
                    shape="circle"
                  >
                    <p-overlayPanel styleClass="tool-tip-class" #userNameTooltip>
                      <p>{{ user?.name ?? "" }}</p>
                    </p-overlayPanel>
                  </p-avatar>
                  <p-avatar
                    styleClass="grey"
                    *ngIf="coverageUsers.length > 3"
                    size="large"
                    shape="circle"
                    [overlayPanelTooltip]="allUserNameTooltip"
                    >+{{ coverageUsers.length - 3 }}
                  </p-avatar>
                </p-avatarGroup>

                <p-overlayPanel styleClass="tool-tip-class" #allUserNameTooltip>
                  <p *ngFor="let user of coverageUsers">{{ user?.name ?? "" }}</p>
                </p-overlayPanel>

                <!-- <p *ngFor="let user of coverageUsers">{{ user?.name ?? "" }}</p> -->
              </ng-template>
              <ng-template [ngIf]="!coverageUsers">
                <p>None</p>
              </ng-template>
              <ng-container *ngIf="(authService.isInvestor || authService.isAdmin) && !isMobile">
                <div class="padd-tb-12" *ngIf="!isPartOfCoverageUsers">
                  <p-button styleClass="btn-sec" (click)="onCoverageButtonClick('joinCoverage')">
                    <i class="pi pi-plus mr-2"></i> {{ coverageBtnLabel }}
                  </p-button>
                </div>
              </ng-container>
            </div>

            <!-- Pod leader -->
            <div class="col-12 label-tag extention-full-w-50">
              <strong>Pod leader</strong>
              <p *ngIf="podLeader">
                {{ podLeader.name }}
              </p>
              <!-- <div *ngIf="podLeader" class="tags-wrap">
                <span class="tags">{{ podLeader.name }}</span>
              </div> -->
              <ng-template [ngIf]="!podLeader">
                <span>None</span>
              </ng-template>
            </div>

            <!-- Exclusive to sourcescrub -->
            <!-- INDUSTRY -->
            <!-- <div class="col-12 label-tag" *ngIf="companyDetails.industries.length > 0">
              <strong>Industry</strong>
              <p *ngFor="let sector of companyDetails.industries | companySectors">{{ sector }}</p>
            </div> -->

            <!-- Exclusive to sourcescrub -->
            <!-- INVESTORS  -->
            <!-- <div class="col-12 label-tag" *ngIf="companyDetails.investors.length > 0">
              <strong>Investor</strong>
              <p
                class="investor-label"
                *ngFor="let investor of companyDetails.investors; let i = index"
                (click)="(isExtension || isMobile) ? null : onInvestorClick(investor)"
              >
                {{ investor.name }}
              </p>
            </div> -->
          </div>
        </div>

        <!-- Second Column -->
        <div class="second">
          <!-- "activeIndex===0" are added for making performance good otherwise the app may crash -->
          <p-tabView styleClass="details-tabs" [(activeIndex)]="activeIndex" (onChange)="onTabViewClick()">
            <p-tabPanel header="Overview">
              <div class="grid">
                <div class="col-12">
                  <p class="overview">
                    {{ companyDetails.description }}
                  </p>
                </div>
                <!-- <div class="col-12">
                  <bpc-activity-data id="activity-data" [_companyDetails]="companyDetails" *ngIf="activeIndex === 0">
                  </bpc-activity-data>
                </div> -->
                <div class="col-12 mg-t-20">
                  <strong>Last Interaction</strong>
                  <bpc-interaction-history
                    id="activity-data"
                    [companyDetails]="companyDetails"
                    [interactions]="companyDetails.latestInteraction ? [companyDetails.latestInteraction] : []"
                  >
                  </bpc-interaction-history>
                </div>
              </div>
            </p-tabPanel>
            <!-- Exclusive to sourcescrub -->
             <!-- Updated active indexes as well -->
            <!-- <p-tabPanel header="Funding">
              <bpc-company-pic-data
                id="activity-data"
                [companyDetails]="companyDetails"
                *ngIf="activeIndex === 1"
              ></bpc-company-pic-data>
              <ng-template [ngIf]="editModeConnections">
                <bpc-relationship-data
                  (editConnections)="onEditConnections($event)"
                  (markChangesAsDone)="markAsDone()"
                  [companyDetails]="companyDetails"
                  [isExtension]="isExtension || isMobile"
                >
                </bpc-relationship-data>
              </ng-template>
              <p></p>
            </p-tabPanel> -->

            <p-tabPanel header="Contacts">
              <div class="grid">
                <div class="col-12">
                  <bpc-relationship-data
                    *ngIf="activeIndex === 1"
                    id="relationship"
                    (editConnections)="onEditConnections($event)"
                    (toggleCompanyNavigation)="onToggleCompanyNavigation($event)"
                    [companyDetails]="companyDetails"
                    [isExtension]="isExtension  || isMobile"
                  >
                  </bpc-relationship-data>
                </div>
              </div>

              <bpc-notifications-icon
                (onClick)="emitCompanyAction('createOrEditNotificationTrigger')"
                [company]="companyDetails"
              >
              </bpc-notifications-icon>
            </p-tabPanel>
            <p-tabPanel header="Interaction History">
              <div #historyData>
                <bpc-interaction-history
                  id="activity-data"
                  [companyDetails]="companyDetails"
                  *ngIf="activeIndex === 2"
                  [interactions]="companyDetails.interactions ?? []"
                >
                </bpc-interaction-history>
              </div>
            </p-tabPanel>
          </p-tabView>

          <!-- Product Score -->

          <!-- <div *ngIf="g2ProductScores" class="mb-2">
            <bpc-product-score [productScore]="g2ProductScores"> </bpc-product-score>
          </div> -->

          <!-- 
          <div class="cd-st">
            <span class="cd-label">INVESTORS</span>
            <div class="cd-s-slot" style="max-height: 200px; overflow-y: scroll">
              <span *ngFor="let investor of companyDetails.investors" class="font-semibold md:font-normal">
                {{ investor.name }}
              </span>
            </div>
          </div>
          <div class="cd-st">
            <div class="cd-s-slot">
              <span class="cd-label">TOTAL RAISED</span>
              <span class="font-semibold md:font-normal">{{
                companyDetails.paidInCapital | bpcNumber : "1.0-1" : "$"
              }}</span>
              <span class="cd-label">LATEST ROUND</span>
              <span
                >{{ companyDetails.latestRound?.amount | bpcNumber : "1.0-1" : "$" }}
                {{ companyDetails.latestRound?.raiseDate | date : "MM/'yy" }}</span
              >
            </div>
          </div> -->
        </div>

        <!-- Third Column -->
        <div class="third" *ngIf="!(isExtension || isMobile)">
          <div class="grid grid-no-tb-gutter">
            <div class="col flex-w-auto flex align-items-center justify-content-center">
              <strong>Tags</strong>
            </div>
            <div class="col flex justify-content-end mb-10">
              <p-button styleClass="btn-sec" title="Add new tag" label=" Add New Tag" (click)="openCreateNewTagModal()">
                <i class="pi pi-plus mr-2"></i>
              </p-button>
            </div>
            <div class="col-12" *ngIf="tags.length > 0; else noTags">
              <div class="tags-wrap">
                <bpc-custom-tag
                  *ngFor="let tag of tags | slice : 0 : 10; trackBy: trackByTag"
                  (tagClick)="onTagClick(tag)"
                  (editTagClick)="onEditTagClick(tag)"
                  (removeTagClick)="onRemoveTagClick(tag)"
                  [tag]="tag"
                >
                </bpc-custom-tag>
                <button
                  pButton
                  id="{{ 'show-more-tags' }}"
                  title="Show More"
                  (click)="showMoreTags()"
                  *ngIf="tags.length > 10"
                >
                  Show more
                </button>
              </div>
            </div>
            <ng-template #noTags>
              <div class="col-12 mg-t-20">
                <p>No Tags found</p>
              </div>
            </ng-template>
          </div>
        </div>
      </section>
    </div>
  </div>
  <bpc-extension-preview
    *ngIf="!isExtension && companyDetails"
    #extensionPreview
    [companyDomain]="companyDetails?.domain"
  ></bpc-extension-preview>
</section>
<!-- End Company Details -->

<ng-template #loading>
  <div class="wrapper">
    <div class="details-header" *ngIf="!(isExtension || isMobile)"></div>
    <div class="details-content loader-text-content" [ngClass]="{ 'loader-text-content-extension': (isExtension || isMobile)}">
      <div class="extension-loader">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        <p>Loading company details....</p>
      </div>
    </div>
  </div>
</ng-template>

<p-toast key="cd" position="bottom-center"></p-toast>

<bpc-custom-modal (onHide)="resetTags()" [visible]="displayEditCustomTag">
  <ng-container modal-header>
    <span class="p-dialog-title" [innerHtml]="editTagHeader"><i class="fa-solid fa-tag mr-2"></i></span>
  </ng-container>
  <ng-container modal-content>
    <bpc-edit-custom-tag
      *ngIf="displayEditCustomTag && companyDetails"
      [mode]="editCustomTagMode"
      [company]="companyDetails"
      [isBulk]="false"
      [(selectedCompanies)]="selectedTagCompanies"
      [(tag)]="tag"
    >
    </bpc-edit-custom-tag>
  </ng-container>
  <ng-container modal-footer>
    <button
      *ngIf="editCustomTagMode === 'edit' && tag.createBy === user?.id"
      pButton
      class="mr-2"
      (click)="deleteTag()"
    >
      Delete Tag from Jarvis
    </button>
    <button pButton class="mr-2" (click)="resetTags()">Cancel</button>
    <button
      pButton
      class="button-dark"
      type="submit"
      (click)="saveTag()"
      [disabled]="savingTags || !tag.name || !tag.name.length"
    >
      Save
    </button>
  </ng-container>
</bpc-custom-modal>

<bpc-custom-modal (onHide)="displayTagsModal = false" [visible]="displayTagsModal" [contentStyleClass]="'scrollable'">
  <ng-container modal-header>
    <span class="text-xl font-bold">Tags</span>
    <i class="pi pi-times cursor-pointer" (click)="displayTagsModal = false"></i>
  </ng-container>
  <ng-container modal-content>
    <div class="tags-wrap" *ngIf="tags.length > 0; else noTags">
      <bpc-custom-tag
        *ngFor="let tag of tags; trackBy: trackByTag"
        (tagClick)="onTagClick(tag)"
        (editTagClick)="onEditTagClick(tag)"
        (removeTagClick)="onRemoveTagClick(tag)"
        [tag]="tag"
      >
      </bpc-custom-tag>
    </div>
    <ng-template #noTags>
      <p>No Tags found</p>
    </ng-template>
  </ng-container>
</bpc-custom-modal>
