import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlow } from '@models/auxiliary-company-models';
import { Nullable } from '@models/nullable';
import { getEnrichmentWorkflow } from '../../../utils/company-utils';

@Pipe({
  name: 'enrichmentCreator'
})
export class EnrichmentCreatorPipe implements PipeTransform {

  transform(workflows: WorkFlow[]): Nullable<string> {
    return getEnrichmentWorkflow(workflows)?.owner.name ?? null;
  }
}
