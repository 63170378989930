import { AfterViewInit, Component, ElementRef, inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { EMPTY, filter, from, map, mergeAll, mergeMap, Observable, scan, Subject, switchMap, takeUntil } from 'rxjs';
import { NotificationsService } from '@core/services/notifications.service';
import { DestroyObservable } from '../../../rxjs/DestroyObservable';
import { fromIntersectionObserver } from '../../../rxjs/fromIntersectionObserver';
import { Notification } from '@models/notifications';

@Component({
  selector: 'bpc-notifications-bell',
  templateUrl: './notifications-bell.component.html',
  providers: [DestroyObservable]
})
export class NotificationsBellComponent implements OnInit, AfterViewInit {

  private destroy$ = inject(DestroyObservable);
  private markAsReadSubject = new Subject<number>();

  unread = 0;

  notifications: Notification[] = [];
  notifications$: Observable<Notification[]> = this.notificationsService.notifications$.pipe(
    scan((acc, { page, notifications }) => page === 1 ? notifications : acc.concat(notifications), <Notification[]>[])
  );

  notificationsCount$ = this.notificationsService.notificationsCount$;

  @ViewChildren('n') notificationElements!: QueryList<ElementRef<HTMLDivElement>>;

  constructor(private notificationsService: NotificationsService) {
  }

  ngOnInit() {
    this.notificationsCount$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => this.unread = res.data?.unreadCount ?? 0);

    this.notifications$
      .pipe(takeUntil(this.destroy$))
      .subscribe(notifications => this.notifications = notifications);

    this.markAsReadSubject
      .pipe(mergeMap(id => this.findNotificationById(id)?.isRead ? EMPTY : this.notificationsService.markAsRead(id)))
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res.data!.result && this.unread > 0) {
          const notification = this.findNotificationById(res.data!.data);
          if (notification) {
            notification.isRead = true;
          }
          this.unread--;
        }
      });
  }

  ngAfterViewInit(): void {
    (this.notificationElements.changes as Observable<QueryList<ElementRef<HTMLDivElement>>>).pipe(
      map(queryList => queryList.toArray().splice(-3).map(({ nativeElement }) => nativeElement)), // take the last 3 <div #n> tags
      switchMap(elements => from(elements.map(el => fromIntersectionObserver(el))).pipe(mergeAll())),
      filter(({ isIntersecting }) => isIntersecting)
    ).subscribe(this.notificationsService.loadMore$);
  }

  trackByNotificationId(index: number, { id }: Notification) {
    return id;
  }

  markAsRead({ id }: Notification) {
    this.markAsReadSubject.next(id);
  }

  private findNotificationById(notificationId: number): Notification | undefined {
    return this.notifications.find(({ id }) => id === notificationId);
  }
}