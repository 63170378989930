import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bpc-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {

  @Input() visible!: boolean;
  @Input() showCloseButton = true;
  @Input() closeOnEscape = true;
  @Input() contentStyleClass: string = "";
  @Input() showHeader = true;
  @Input() showFooter = true;
  @Input() dismissableMask = true;
  @Input() styleClass: string = "";
  
  @Output() onHide = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
