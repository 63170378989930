import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { User, UserGroup, UserHierarchy, UserRole } from '@models/user';
import { Nullable } from '@models/nullable';
import { userDataKey } from '@core/localStorageKeys.ts';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MixpanelService } from '@core/services/mixpanel.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<Nullable<User>>(this.getUserFromLocalStorage());
  loading = false;

  get userRole(): UserRole {
    return this.user.getValue()!.role;
  }

  get userRoles(): UserRole[] {
    return this.user.getValue()!.roles;
  }

  get userGroups(): UserGroup[] {
    return this.user.getValue()!.groups;
  }

  get isInvestor(): boolean {
    return this.userRole === 'investor';
  }

  get isAnalyst(): boolean {
    return this.userRole === 'analyst';
  }
  
  get isPartner():boolean{
    return this.userGroups.some((group) => group.name.toLowerCase() === UserHierarchy.Partners.toLowerCase()) ?? false;
  }

  get isInvestorRelation(): boolean {
    return this.user.getValue()?.roles.includes('investorrelation') ?? false;
  }

  get isNewsletterModerator(): boolean {
    return this.user.getValue()?.roles.includes('newslettermoderator') ?? false;
  }

  get investmentcommitteeadmin(): boolean {
    return this.user.getValue()?.roles.includes('investmentcommitteeadmin') ?? false;
  }



  get isAdmin(): boolean {
    return this.user.getValue()?.roles.includes('admin') ?? false;
  }

  get isVoterCommittee(): boolean {
    return this.user.getValue()?.roles.includes('votercommittee') ?? false;
  }

  get userId(): Nullable<number> {
    return this.user.getValue()?.id ?? null;
  }

  get userName(): Nullable<string> {
    return this.user.getValue()?.name ?? null;
  }

  get isAD(): boolean {
    return !!this.user.getValue()?.isAD;
  }

  constructor(public oidcSecurityService: OidcSecurityService,
    private mixpanelService: MixpanelService,
    private router: Router) {
  }

  logout(isExtension: boolean = false) {
    this.mixpanelService.onLogout();
    this.oidcSecurityService.logoffLocal();
    this.user.next(null);
    this.removeUserData();
    if (!isExtension) {
      window.location.href = "/login"; // did this for reloading the cached data
    } else {
      this.router.createUrlTree(['/login']);
    }
  }

  getUserFromLocalStorage(): Nullable<User> {
    let userData = this.getUserData();

    if (!userData) {
      return null;
    }

    let user: unknown;

    try {
      user = JSON.parse(userData);
    } catch {
      this.removeUserData();

      return null;
    }

    if (!this.isUserDataValid(user)) {
      this.removeUserData();

      return null;
    }

    this.setUserRole(user);

    return user;
  }

  setUserRole(user: User) {
    const role = user.roles[0]; // So far, exactly one role is possible, thus using "role" property
    user.role = role === 'analyst' || role === 'admin' ? role : 'investor';
    this.setUserData(user);
  }

  isUserDataValid(user: any): user is User {
    return typeof user.token === 'string' &&
      typeof user.name === 'string' &&
      typeof user.email === 'string' &&
      (Array.isArray(user.roles) && user.roles.every((role: any) => typeof role === 'string'));
  }


  getUserData() {
    let userData = localStorage.getItem(userDataKey);
    if (!userData) {
      return null;
    }
    return userData;
  }

  setUserData(user: Nullable<User>) {
    localStorage.setItem(userDataKey, JSON.stringify(user));
  }

  removeUserData() {
    localStorage.removeItem(userDataKey);
  }
}