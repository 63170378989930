import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FilterLabel, MainFilterRaw } from "@models/main-filter-view";
import { FilterHelperService } from "@core/services/filter-helper.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { TagsService } from "@core/services/tags.service";

@Component({
  selector: "bpc-filter-labels",
  templateUrl: "./filter-labels.component.html",
  styleUrls: ["./filter-labels.component.scss"],
})
export class FilterLabelsComponent implements OnInit {
  @Input() mainFilterRaw!: MainFilterRaw;
  @Input() hasFilters?: boolean;
  @Input() tabIndex!: number;
  @Output() mainFilterRawChange = new EventEmitter<MainFilterRaw>();
  @Output() clearAllFiltersEvent = new EventEmitter<null>();
  maxTagsCount: number = 4;
  displayFilterLabelModal: boolean = false;

  constructor(
    private filterHelper: FilterHelperService,
    private deviceDetectorService: DeviceDetectorService,
    private tagsService: TagsService
  ) { }

  ngOnInit() {
    this.maxTagsCount = this.isTablet ? 3 : 5;
  }

  get isTablet(): boolean {
    return this.deviceDetectorService.isTablet();
  }

  openFilterLabelModal() {
    this.displayFilterLabelModal = true;
  }

  resetModalItems() {
    this.displayFilterLabelModal = false;
  }

  deleteLabel(label: FilterLabel) {
    this.mainFilterRawChange.emit(this.filterHelper.removeItemFromFilter(this.mainFilterRaw, label));
  }

  trackBy(index: number, label: FilterLabel): string {
    return label.key + "_" + index;
  }

  clearAllFilters() {
    this.clearAllFiltersEvent.emit();
  }

  get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  showMore() {
    this.displayFilterLabelModal = true;
    // alert("open modal to show all")
  }
}
