import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { BasicCompany, Company } from '@models/company';
import { Tag, TagType } from '@models/tag';
import { cloneDeep } from '../../../utils/utils';
import { AuthService } from '@core/auth/auth.service';
import { CompaniesSearchService } from 'src/app/services/companies-search/companies-search.service';
import { FilterDropdown } from '@core/models/auxiliary-company-models';

export type EditCustomTagMode = 'create' | 'edit';
export type CustomTagType = TagType.System | TagType.User;

@Component({
  selector: 'bpc-edit-custom-tag[company][tag][mode]',
  templateUrl: './edit-custom-tag.component.html'
})
export class EditCustomTagComponent implements OnInit {
  private originalTagName!: string;

  @Input() company!: Company | undefined;
  @Input() selectedCompanies: BasicCompany[] = [];
  @Input() tag!: Tag;
  @Input() mode!: EditCustomTagMode;
  @Input() isBulk = false;

  @Output() selectedCompaniesChange = new EventEmitter<BasicCompany[]>();
  @Output() tagChange = new EventEmitter<Tag>();

  uploadedFileName!: string;
  companiesFromFile: BasicCompany[] = [];
  missingCompanies: BasicCompany[] = [];
  constructor(private companiesSearchService: CompaniesSearchService) {
  }

  userId = inject(AuthService).userId;

  type: CustomTagType = TagType.System;

  get title(): string {
    return this.mode === 'create'
      ? 'Create new or assign existing tag'
      : `Edit tag <strong>${this.originalTagName}</strong>`;
  }

  get isNew(): boolean {
    return !this.tag.id;
  }

  get isGlobalSwitchReadonly(): boolean {
    return !this.isNew && this.mode === 'create' && !this.createdByLoggedInUser;
  }

  get createdByLoggedInUser(): boolean {
    return this.tag.createBy === this.userId;
  }

  ngOnInit() {
    const { tag } = this;
    this.tag = cloneDeep(tag);
    this.originalTagName = tag.name;
    this.type = <CustomTagType>tag?.type ?? this.type;
  }

  onTagSelect(tag: Tag | string) {
    if (typeof tag === 'object') {
      this.tagChange.emit(tag);
      this.type = <CustomTagType>tag.type;
    }
  }
  onKeyDown(event:any){
    event.stopPropagation();
  }
  
  onKeyUp(event: KeyboardEvent) {
    event.stopPropagation();
    const name = (<HTMLInputElement>event.target).value;
    const { type } = this;

    this.tagChange.emit({ name, type } as Tag);
  }

  onTypeChange(type: CustomTagType) {
    this.tagChange.emit({ ...this.tag, type });
  }

  onTagNameChange(name: string) {
    this.tagChange.emit({ ...this.tag, name });
  }

  onCompanyChange(companies: BasicCompany[]) {
    this.selectedCompaniesChange.emit(companies)
  }

  onRemoveFileClick() {
    this.uploadedFileName = "";
    this.missingCompanies = [];
    const filteredCompanies = this.selectedCompanies?.filter((company) => {
      return !this.companiesFromFile?.some((fileCompany) => {
        return fileCompany.id === company.id;
      });
    });
    this.selectedCompanies = filteredCompanies || [];
  }

  onManyCompaniesFileUpload(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement?.files) {
      this.uploadedFileName = inputElement.files[0].name;
      this.companiesSearchService.getBulkCompaniesFromFile(inputElement.files[0])
        .subscribe(response => {
          let companiesResponse = response?.data || [];
          this.companiesFromFile = companiesResponse.filter((com) => com.id !== 0);
          this.missingCompanies = companiesResponse.filter((com) => com.id === 0);
          const allCompanies = [...this.selectedCompanies, ...this.companiesFromFile]
          this.selectedCompanies = [...new Set(allCompanies.map(item => JSON.stringify(item)))].map(item => JSON.parse(item))
          this.selectedCompaniesChange.emit(this.selectedCompanies);
        })
    }
  }

  downloadBulkTagTemplate() {
    const data = ["URLs\n'https://www.website.com/?source=SC'"]
    const csvArray = data.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `Bulk_Companies_Template.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  setFormControlValue(value: BasicCompany, type: FilterDropdown) {
    this.selectedCompanies.push(value);
    this.onCompanyChange(this.selectedCompanies)
  }

  get dropdownTypes() {
    return FilterDropdown;
  }

  removeDropdownValue(id: number, type: FilterDropdown) {

    const index = this.selectedCompanies.findIndex((item: BasicCompany) => item.id === id);
    if (index !== -1) {
      this.selectedCompanies.splice(index, 1);
      this.onCompanyChange(this.selectedCompanies);
    }
  }
}