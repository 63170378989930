import { Component, OnInit } from '@angular/core';
import { DestroyObservable } from '../../../rxjs/DestroyObservable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ChromeExtensionService } from 'src/app/services/chrome-extension/chrome-extension.service';
import { environment } from 'src/environments/environment';
import { APP_VERSION, DEV_APP_VERSION } from '../company-details/package-constants';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'bpc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DestroyObservable]
})
export class HomeComponent implements OnInit {

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    public chromeExtensionService: ChromeExtensionService,
    public authService: AuthService,
  ) {
  }
  ngOnInit() {
    this.checkVersion();
  }

  checkVersion() {
    let key = null;
    let envVersion = null;
    let localVersion = null;
    let isProd = environment.production;
    if (isProd) {
      envVersion = APP_VERSION;
      key = "ANGULAR_APP_VERSION";
      localVersion = localStorage.getItem(key);
    }
    else {
      envVersion = DEV_APP_VERSION;
      key = "ANGULAR_APP_VERSION_DEV";
      localVersion = localStorage.getItem(key);
    }
    if (!localVersion) {
      localStorage.setItem(key, envVersion);
      this.authService.logout(this.isExtension);
    }
    else {
      localVersion = localVersion.split(".").join("");
      const currentVersion = envVersion.split(".").join("");
      if (Number(localVersion) < Number(currentVersion)) {
        localStorage.setItem(key, envVersion);
        this.authService.logout(this.isExtension);
      }
    }
  }

  get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }
  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

}
