import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CanvasHelperService } from '@core/services/canvas-helper.service';
import { Company } from '@models/company';
import { ConnectionsService } from '@core/services/connections.service';
import {
  AddConnection,
  CompanyContact,
  ConnectionForm,
  DeleteConnection,
  DeleteUser,
  EditConnection,
  NewConnection
} from '@models/forms';
import { MessageService } from 'primeng/api';
import { CompanyHelperService } from '@core/services/company-helper.service';
import { DestroyObservable } from '../../../rxjs/DestroyObservable';
import { delay, filter, finalize, MonoTypeOperatorFunction, pipe, takeUntil, tap } from 'rxjs';
import { UsersService } from '../../../services/users/users.service';
import { CompaniesService } from '../../../services/companies/companies.service';
import { AuthService } from '@core/auth/auth.service';
import { ConnectedUser, Staff, UserRole } from '@models/user';
import { ApiResponse } from '../../../api/base';
import { OperationResult } from '@models/operation-result';
import { Nullable } from '@models/nullable';
import { getEnrichmentStatusId } from '../../../utils/company-utils';
import { WorkFlowStatusId } from '@models/auxiliary-company-models';
import { RelationshipGraph } from '@models/relationship-graph';
import { DeviceDetectorService } from 'ngx-device-detector';
import { cloneDeep } from '../../../utils/utils';

@Component({
  selector: 'bpc-relationship-data',
  templateUrl: './relationship-data.component.html',
  styleUrls: ['./relationship-data.component.scss'],
  viewProviders: [DestroyObservable]
})
export class RelationshipDataComponent implements OnInit, OnDestroy {
  private _showAddNewPanel = false;
  public isNonAnalystUser: boolean = false;
  public selectedInternalId!: number;
  allInternalUsers: ConnectedUser[] = [];
  @Input() isExtension?: boolean;
  @Input() companyDetails!: Company;
  currentPage: number = 0;
  pageTotal: number = 10;
  isLastPage: boolean = false;
  @Output() editConnections = new EventEmitter<boolean>();
  @Output() markChangesAsDone = new EventEmitter();
  @Output() toggleCompanyNavigation = new EventEmitter<boolean>();

  selectedInternal!: ConnectedUser;

  get showAddNewPanel(): boolean {
    return this._showAddNewPanel;
  }

  set showAddNewPanel(value: boolean) {
    this._showAddNewPanel = value;
  }

  get companyId(): number {
    return this.companyDetails.id;
  }

  get companyName(): string {
    return this.companyDetails.name;
  }



  get lastUpdate(): Nullable<string> {
    return this.companyDetails.modifyDate;
  }

  get enrichStatusId(): WorkFlowStatusId {
    return getEnrichmentStatusId(this.companyDetails.workflows);
  }


  get relationshipDetails(): RelationshipGraph {
    return { internal: this.companyDetails.connectedUsers, external: this.companyDetails.staff };
  }

  get internal(): ConnectedUser[] {
    return this.relationshipDetails.internal;
  }

  get external(): Staff[] {
    // Filter contacts from sourcescrub here after after unique identifier is placed.
    return this.relationshipDetails.external;
  }


  get userRole(): UserRole {
    return this.authService.user.getValue()!.role;
  }


  constructor(private canvasHelperService: CanvasHelperService,
    private connectionsService: ConnectionsService,
    private companiesService: CompaniesService,
    private companyHelperService: CompanyHelperService,
    private readonly destroy$: DestroyObservable,
    private usersService: UsersService,
    private authService: AuthService,
    private messageService: MessageService,
    private deviceService: DeviceDetectorService
  ) {
  }

  ngOnInit() {
    this.authService.user.subscribe((userData) => {
      this.isNonAnalystUser = (userData?.roles ? userData.roles.indexOf('analyst') < 0 : false) || this.userRole !== 'analyst';
    });
    this.subscribeToCompanyHelperService();
    this.getInternalUsers();
  }

  ngOnDestroy() {
    this.companyHelperService.emitCompanyDetailsDismissible(true);
  }

  scrollToTop() {
    scrollTo(0, 0);
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get totalPages(): number {
    return Math.ceil(this.external.length / this.pageTotal);
  }

  navigateToNextPage() {
    this.currentPage = this.currentPage + 1;
    this.isLastPage = this.currentPage + 1 >= this.totalPages;
  }
  closeConnectionPanel() {
    this.showAddNewPanel = false;
    this.toggleCompanyNavigation.emit(true);
    this.editProfile = null;
    this.companyHelperService.changeMessage('refresh-details');
  }


  editProfile: any;

  resetProfile() {
    this.editProfile = null
    this.showAddNewPanel = false
    this.toggleCompanyNavigation.emit(true);
  }


  closeEditPanel() {
    this.editConnections.emit(false);
  }

  toggleAddNew() {
    this.showAddNewPanel = !this.showAddNewPanel;
    if (this.showAddNewPanel) {
      this.toggleCompanyNavigation.emit(false);
    } else {
      this.toggleCompanyNavigation.emit(true);
    }
  }


  isConnected(id?: number): boolean {
    const selectedInternal = this.allInternalUsers.find((x) => x.id === this.selectedInternalId);
    const connected = !!selectedInternal?.connections?.find(x => x.companyPersonId === id);
    return connected;
  }

  onRemoveConnection(data: Staff) {
    this.deleteConnection(false, data);
  }

  onMakeConnection(data: Staff) {
    this.editProfile = data;
    this.showAddNewPanel = true;
    this.toggleCompanyNavigation.emit(false);
  }

  onSubmit(formData: ConnectionForm, type: 'new' | 'edit') {
    this.scrollToTop();
    // Handling non analyst user
    if (this.isNonAnalystUser) {
      this.upsertCompanyPerson(formData, this.editProfile?.id)
    } else {

      if (type === 'new') {
        this.createNewConnection(formData);
      } else if (type === 'edit') {
        if (formData.connectionId) {
          this.editConnection(formData, formData.connectionId!);
        } else {
          this.addConnection(formData, this.editProfile?.id);
        }
      }
    }
  }



  private subscribeToCompanyHelperService() {
    this.companyHelperService.currentMessage
      .pipe(takeUntil(this.destroy$))
      .pipe(delay(10))
      .subscribe(message => {
        switch (message) {
          case 'refresh-finished':
            this.getInternalUsers();
            break;
          default:
            break;
        }
      });
  }


  onDeleteUser({ id, deleteFromSystem }: { id: number, deleteFromSystem: boolean }) {
    this.deleteUser(id, deleteFromSystem);
  }

  markAsDone() {
    this.markChangesAsDone.emit();
  }

  identify(index: number, item: Staff) {
    return item.id;
  }

  onEditing(profile: Staff, editing: boolean) {
    profile.editing = editing;
  }

  private handleResponse(successMessage: string, errorMessage: string): MonoTypeOperatorFunction<ApiResponse<OperationResult>> {
    return pipe(
      tap(response => {
        if (response.error || !response.data?.result) {
          this.messageService.add({
            key: 'rd',
            severity: 'error',
            detail: response.error?.response ?? response.data?.message ?? errorMessage
          });
        }
      }),
      filter(value => !!value.data?.result),
      tap(response => {
        this.messageService.add({
          key: 'rd',
          severity: 'success',
          detail: response.data?.message ?? successMessage
        });
        this.companyHelperService.changeMessage('refresh-details');
      })
    );
  }

  private createNewConnection(formData: ConnectionForm) {

    let data: NewConnection = {
      companyId: this.companyId,
      companyRole: 0,
      connectedUserId: formData.selectedInternal ? formData.selectedInternal.id : this.selectedInternalId,
      isNonAnalystUser: this.isNonAnalystUser ? true : false,
      affinity: 2,
      ...Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != ''))
    };
    this.cleanFormData(data);

    this.connectionsService.createNewConnection(data)
      .pipe(
        this.handleResponse('Connection created', 'Error creating new connection'),
        finalize(() => {
          this.closeConnectionPanel();
        }))
      .subscribe(() => {
        this.closeConnectionPanel();
      });
  }

  private editConnection(formData: ConnectionForm, connectionId: number) {
    const data: EditConnection = {
      id: connectionId,
      companyId: this.companyId,
      companyRole: 0,
      connectedUserId: formData.selectedInternal ? formData.selectedInternal.id : this.selectedInternalId,
      isNonAnalystUser: this.isNonAnalystUser ? true : false,
      affinity: 2,
      ...Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != ''))
    };
    this.cleanFormData(data);
    // let defaultConnectionLevel = this.getConnectionLevel(companyPersonId)?.toString();; // TODO
    // if (this.isNonAnalystUser && defaultConnectionLevel) {
    //   data.level = defaultConnectionLevel;
    // }

    this.connectionsService.updateConnection(data).pipe(this.handleResponse('Connection updated', 'Error creating new connection'))
      .subscribe(() => {
        this.closeConnectionPanel();
      });
  }

  onSelectedInternalChange() {
    // Force re-render of external users
    this.relationshipDetails.external = cloneDeep(this.relationshipDetails.external);
    this.setSelectedInternal();
    // this.cloneDeepAllUsers();
    // todo set Selected Internal
  }

  setSelectedInternal() {
    const selectedInternal = this.allInternalUsers.find((x) => x.id === this.selectedInternalId);
    if (selectedInternal) {
      this.selectedInternal = selectedInternal;
    }
  }


  onEditContact(data: Staff) {
    this.editProfile = data;
    this.showAddNewPanel = true;
    this.toggleCompanyNavigation.emit(false);
  }

  onRemoveContact(data: DeleteUser) {
    this.deleteUser(data.id, data.deleteFromSystem);
  }

  onToggleCompanyNavigation(event: boolean) {
    this.toggleCompanyNavigation.emit(event);
  }

  private getInternalUsers() {
    this.usersService.getActiveUsers()
      .pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response.data) {

          this.allInternalUsers = response.data.map(user => {
            return { ...user, connections: [] };
          }).sort((a: ConnectedUser, b: ConnectedUser) => {
            return ('' + a.name).localeCompare(b.name);
          });

          this.mergeInternalUsers();
          this.setSelectedInternal();

        } else {
          this.allInternalUsers = this.internal;
          if (this.internal.length && !this.selectedInternalId) {
            this.selectedInternalId = this.internal[0].id;
          }
          this.setSelectedInternal();

        }
      });
  }



  private mergeInternalUsers() {
    const ids = new Set(this.internal.map(x => x.id));
    this.allInternalUsers = [...this.internal, ...(this.allInternalUsers.filter(x => !ids.has(x.id)))];
    if (this.allInternalUsers.length && !this.selectedInternalId) {
      this.selectedInternalId = this.allInternalUsers[0].id;
    }
  }

  private upsertCompanyPerson(formData: ConnectionForm, companyPersonId?: number) {
    const data: CompanyContact = {
      id: !!companyPersonId ? companyPersonId : 0,
      companyId: this.companyId,
      companyRole: 0,
      affinity: 2,
      ...Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != ''))
    };
    // let defaultConnectionLevel = this.getConnectionLevel(companyPersonId)?.toString();; TODO
    // if (this.isNonAnalystUser && defaultConnectionLevel) { // TODO
    //   data.level = defaultConnectionLevel;
    // }

    this.cleanFormData(data);

    this.connectionsService.upsertCompanyPerson(data)
      .pipe(this.handleResponse(companyPersonId ? 'Contact updated' : 'Contact created',
        companyPersonId ? 'Error updating contact' : 'Error creating new contact'))
      .subscribe(() => {
        this.closeConnectionPanel();
      });
  }

  cleanFormData(data: AddConnection | CompanyContact | EditConnection | NewConnection) {
    delete data.selectedInternal;
    delete data.connectionId;
  }


  private addConnection(formData: ConnectionForm, companyPersonId: number) {
    const data: AddConnection = {
      companyPersonId: companyPersonId,
      companyId: this.companyId,
      companyRole: 0,
      connectedUserId: formData.selectedInternal ? formData.selectedInternal.id : this.selectedInternalId,
      isNonAnalystUser: this.isNonAnalystUser ? true : false,
      affinity: 2,
      ...formData
    };
    this.cleanFormData(data);
    this.connectionsService.createNewConnection(data).pipe(this.handleResponse('Connection updated', 'Error creating new connection'),
      finalize(() => {
        this.closeConnectionPanel();
      }))
      .subscribe(() => {
        this.closeConnectionPanel();
      });
  }

  private getConnectionId(companyPersonId: number): number {
    const selectedInternal = this.allInternalUsers.find((x) => x.id === this.selectedInternalId);
    const connection = selectedInternal?.connections.find(x => x.companyPersonId === companyPersonId);
    return connection ? connection?.id : 0;
  }

  private deleteConnection(state: boolean, staffData: Staff) {
    const data: DeleteConnection = {
      connectionId: this.getConnectionId(staffData.id)// TODO
    };
    if (!state && data.connectionId) {
      this.connectionsService.deleteConnection(data).pipe(this.handleResponse('Connection updated', 'Error adding connection'))
        .subscribe(() => {
          this.closeConnectionPanel();
        });
    } else {
      this.editProfile = data;
      this.showAddNewPanel = true;
      this.toggleCompanyNavigation.emit(false);
    }
  }

  private deleteUser(id: number, deleteFromSystem: boolean) {
    const data: DeleteUser = {
      id, deleteFromSystem
    };
    if (data.id) {
      this.companiesService.deleteUser(data).pipe(this.handleResponse('User deleted', 'Error deleting user'))
        .subscribe(() => {
          this.closeConnectionPanel();
        });
    }
  }
}