import { Component, OnInit } from "@angular/core";
import { AdAuthService } from "@core/auth/ad-auth.service";
import { ChromeExtensionService } from "./services/chrome-extension/chrome-extension.service";
import { AuthService } from "@core/auth/auth.service";
import { APP_VERSION, DEV_APP_VERSION } from "@core/components/company-details/package-constants";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(private adAuthService: AdAuthService, public chromeExtensionService: ChromeExtensionService, public authService: AuthService,) { }

  ngOnInit() {
    this.adAuthService.checkADAuth();
  }
  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }
}
