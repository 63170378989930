import { Pipe, PipeTransform } from '@angular/core';

import { Nullable } from '@models/nullable';

interface DateDiff {
  diff: number;
  ms: number;
  s: number;
  m: number;
  h: number;
  d: number;
}

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string): string {
    if (value === null) {
      return ''
    }
    const diff = dateDiff(value, new Date().toISOString());

    if (diff?.d !== 0) {

      return diff?.d && diff.d > 1 ? `${diff.d} Days ago` : `${diff?.d} Day${diff?.d === 1 ? '' : 's'} ago`;
    }

    if (diff?.h !== 0) {
      return diff?.h && diff.h > 1 ? `${diff.h} Hours ago` : `${diff?.h} Hour${diff?.h === 1 ? '' : 's'} ago`;
    }

    if (diff?.m !== 0) {
      return diff?.m && diff.m > 1 ? `${diff.m} Minutes ago` : `${diff?.m} Minute${diff?.m === 1 ? '' : 's'} ago`;
    }

    return 'Moments ago';
  }
}

function dateDiff(str1: string, str2: string): Nullable<DateDiff> {
  const diff = Date.parse(str2) - Date.parse(str1);
  return isNaN(diff) ? null : {
    diff: diff,
    ms: Math.floor(diff % 1000),
    s: Math.floor(diff / 1000 % 60),
    m: Math.floor(diff / 60000 % 60),
    h: Math.floor(diff / 3600000 % 24),
    d: Math.floor(diff / 86400000)
  };
}
