import { Pipe, PipeTransform } from '@angular/core';
import { getEnrichmentClasses } from '../../../utils/company-utils';
import { WorkFlow } from '@models/auxiliary-company-models';
import { AuthService } from '@core/auth/auth.service';

@Pipe({
  name: 'enrichmentClasses'
})
export class EnrichmentClassesPipe implements PipeTransform {

  constructor(private authService: AuthService) {
  }

  transform(workflows: WorkFlow[]): string[] {
    return getEnrichmentClasses(this.authService.user.getValue()!, workflows);
  }
}
