import { Directive } from '@angular/core';
import { Checkbox } from 'primeng/checkbox';

@Directive({
  selector: 'p-checkbox'
})
export class CheckboxDirective {

  constructor(checkbox: Checkbox) {
    checkbox.binary = true;
  }
}
