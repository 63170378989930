<form>
  <div class="grid grid-no-tb-gutter">
    <!-- <div class="col-12 mg-b-16">
      <label class="w-100">Tag Type</label>
      <p-dropdown placeholder="Select Type" styleClass="w-100" optionLabel="name"></p-dropdown>
    </div> -->

    <div *ngIf="mode === 'create' && company" class="col-12 mg-b-16">
      <label class="w-100">Tag Name</label>
      <p-autoComplete
        tagsAutocomplete
        name="tag"
        styleClass="w-100"
        inputStyleClass="w-100"
        (ngModelChange)="onTagSelect($event)"
        (onKeyUp)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
        [companyId]="company.id"
        [ngModel]="tag"
      >
      </p-autoComplete>
    </div>
    <div class="col-12 mg-b-16" *ngIf="mode === 'create' && !company">
      <label class="w-100">Tag</label>
      <p-autoComplete
        tagsAutocomplete
        name="tag"
        styleClass="w-100"
        inputStyleClass="w-100"
        (ngModelChange)="onTagSelect($event)"
        (onKeyUp)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
        [companyId]="0"
        [ngModel]="tag"
      >
      </p-autoComplete>
    </div>
    <div class="col-12 mg-b-16" *ngIf="mode === 'edit'">
      <label class="w-100">Edit</label>
      <input name="tag" class="w-100" (keydown)="onKeyDown($event)" pInputText (ngModelChange)="onTagNameChange($event)" [(ngModel)]="tag.name" />
    </div>
    <div class="col-12 mg-b-16">
      <ng-container *ngIf="isBulk">
        <!-- <label class="w-100">Companies</label>
          <p-autoComplete
            companies
            name="company"
            class="auto-search-company-search"
            styleClass="w-100"
            inputStyleClass="w-100"
            (ngModelChange)="onCompanyChange($event)"
            [(ngModel)]="selectedCompanies"
          >
          </p-autoComplete> -->
        <bpc-custom-dropdown-search
          class="w-full"
          [values]="selectedCompanies"
          [type]="dropdownTypes.companyQuickSearch"
          [label]="'Companies'"
          [runtimeSearch]="true"
          (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.companyQuickSearch)"
          (setFormControl)="setFormControlValue($event, dropdownTypes.companyQuickSearch)"
        >
        </bpc-custom-dropdown-search>

        <ng-container *ngIf="uploadedFileName; then uploadedFile; else uploadFile"></ng-container>
        <ng-template #uploadFile>
          <input
            #fileInput
            type="file"
            name="file"
            title="Upload Companies"
            class="hidden"
            accept=".csv"
            (change)="onManyCompaniesFileUpload($event)"
          />
          <p-button id="upload-file" styleClass="btn-sec mg-b-16" (click)="fileInput.click()">
            <i class="pi pi-upload mr-2"></i>
            Upload Companies
          </p-button>
          <div class="flex items-center">
            <p class="text-p-a">
              To download bulk companies template <br />
              <a class="text-blue-a" (click)="downloadBulkTagTemplate()">Click here</a>.
            </p>
          </div>
        </ng-template>

        <div *ngIf="missingCompanies && missingCompanies.length > 0" style="max-height: 100px; overflow-y: auto">
          <p class="text-p-a">We were unable to find the following companies:</p>
          <p class="text-p-a" *ngFor="let company of missingCompanies">
            <a class="text-blue-a" href="{{ company.domain }}" target="_blank"> {{ company.domain }}</a>
          </p>
        </div>

        <ng-template #uploadedFile>
          <div class="flex items-center">
            <p>{{ uploadedFileName }}</p>
            <i class="pi pi-times-circle ml-2 text-sm cursor-pointer" (click)="onRemoveFileClick()"></i>
          </div>
        </ng-template>
      </ng-container>
    </div>

    <div
      class="col flex align-items-center"
      pTooltip="Available to all users"
      [tooltipOptions]="{ tooltipPosition: 'bottom' }"
    >
      <label class="mgr-0">Global</label>
      <p-inputSwitch
        name="type"
        styleClass="ml-2"
        (ngModelChange)="onTypeChange($event)"
        [readonly]="isGlobalSwitchReadonly"
        [trueValue]="1"
        [falseValue]="2"
        [(ngModel)]="type"
      >
      </p-inputSwitch>
    </div>
    <div class="col flex-w-auto flex align-items-center">
      <p class="tag-label" *ngIf="mode === 'create'">
        {{ isNew ? "NEW" : "EXISTING" }}
      </p>
    </div>
  </div>
</form>
