import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { Company } from '@core/models/company';
import { OperationResult } from '@core/models/operation-result';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ApiResponse, BaseApiService } from 'src/app/api/base';

@Injectable({
  providedIn: 'root'
})
export class CompanyNewsletterService extends BaseApiService<Company> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/newsletter', authService, router, messageService);
  }

  getSubscriptionStatus(companyId: number): Observable<ApiResponse<OperationResult>> {
    return this.get(`/company/${companyId}/subscription-status`);
  }

  toggleSubscription(companyId: number, subscription: boolean): Observable<ApiResponse<OperationResult>> {
    return this.post(`/company/${companyId}/toggle-subscription`, null, { subscribe: subscription });
  }
}
