import { Directive, inject, OnInit } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { Observable, takeUntil } from 'rxjs';
import { ApiResponse } from '../../api/base';
import { DestroyObservable } from '../../rxjs/DestroyObservable';

const MAX_RESULTS = 10;

@Directive({
  providers: [DestroyObservable]
})
export abstract class BaseMultipleAutoCompleteDirective<T extends { [property: string]: string | any }> implements OnInit {

  private destroy$ = inject(DestroyObservable);
  private items: T[] = [];

  abstract loadData: Observable<ApiResponse<T[]>>;

  constructor(private autoComplete: AutoComplete, public field = 'name') {
    this.autoComplete.field = this.field;
    this.autoComplete.multiple = true;
    this.autoComplete.forceSelection = true;
    this.autoComplete.showEmptyMessage = true;
    this.autoComplete.emptyMessage = 'No results';
    this.autoComplete.appendTo = 'body';
    this.autoComplete.completeMethod
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => this.filter(event));
  }

  ngOnInit(): void {
    this.loadData
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.items = value?.data ?? [];
      });
  }

  private filter(event: { query: string }) {
    const suggestions: T[] = [];
    const { query } = event;
    const selectedValues: T[] = this.autoComplete.value;
    const field = this.field;
    const notSelectedItems = this.items.filter(x => {
      if (Array.isArray(selectedValues)) {
        return !selectedValues.find(y => x[field] === y[field])
      } else {
        return !this.items.find(y => y[field] === selectedValues)
      }
    });

    for (const item of notSelectedItems) {
      if (item[field].toLowerCase().indexOf(query.toLowerCase()) > -1) {
        suggestions.push(item);
      }

      if (suggestions.length === MAX_RESULTS) {
        break;
      }
    }

    this.autoComplete.suggestions = suggestions;
    this.autoComplete.cd.markForCheck();
  }
}