import { Component, Input } from '@angular/core';
import { Nullable } from '@models/nullable';

@Component({
  selector: 'bpc-deal-cloud-company',
  templateUrl: './deal-cloud-company.component.html'
})
export class DealCloudCompanyComponent {

  @Input() externalUrl: Nullable<string> = null;
  @Input() displayIcon = true;
}
