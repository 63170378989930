import { Directive } from '@angular/core';

import { AutoComplete } from 'primeng/autocomplete';
import { takeUntil } from 'rxjs';

import { DestroyObservable } from '../../rxjs/DestroyObservable';
import { BaseMultipleAutoCompleteDirective } from './base-multiple-auto-complete.directive';
import { InvestorService } from '@core/services/investor.service';
import { Investor } from '@models/auxiliary-company-models';

@Directive({
  selector: 'p-autoComplete[investors]',
  providers: [DestroyObservable]
})
export class InvestorsDirective extends BaseMultipleAutoCompleteDirective<Investor> {

  loadData = this.investorService.investors$.pipe(takeUntil(this.destroyObservable));

  constructor(autoComplete: AutoComplete, private investorService: InvestorService, private destroyObservable: DestroyObservable) {
    super(autoComplete);
  }
}
