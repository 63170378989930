<section class="portfolio-container">

  <div class="grid grid-no-tb-gutter flex justify-content-end align-items-center">
    <div class="col flex-w-auto">
      <p-dropdown [options]="publishedDates" [(ngModel)]="selectedPublishedDate" optionLabel="label"
        (onChange)="onPublishDateChange($event)" placeholder="Select a publish date" styleClass="w-100"
        optionValue="value">
      </p-dropdown>
    </div>
    <div class="col flex-w-auto">
      <button *ngIf="isPublishButtonVisible" pButton class="mr-2" class="button-dark" [disabled]="isPublishDisabled"
        (click)="openModal()">Pubish
        <i *ngIf="isPublishLoading" class="pi pi-spin pi-spinner ml-2" style="font-size: 1rem"></i>
      </button>
    </div>
  </div>

  <section class="report-sections" *ngIf="!(loading || loadingOpenSearches); else showLoader">
    <div class="grid-no-tb-gutter company-container" *ngFor="let openSearch of companyOpenSearches; let i=index">
      <div class="col-12">
        <div class="grid grid-no-tb-gutter flex justify-content-end align-items-center">
          <div class="col">
            <div class="grid grid-no-tb-gutter">
              <div class="col flex-w-auto" style="margin-left: 14px;">
                <h2>
                  {{ openSearch.genericUpdateType.description }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        <!-- <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="portfolio.updates" class="w-full"></textarea> -->
        <p-editor [(ngModel)]="openSearch.updates" [style]="{ height: '180px' }" [readonly]="isHistory" (onTextChange)="limitTextOpenSearches($event, i)">
          <p-header *ngIf="isHistory" style="display: none;"></p-header>
          <p-header *ngIf="!isHistory" >

            <span class="ql-formats">
                    <select class="ql-header" tabindex="-1">
                      <!-- <option value="2" tabindex="-1">Heading</option> -->
                      <option value="3" tabindex="-1">Heading</option>
                      <option selected tabindex="-1">Normal</option>
                    </select>
                    <select class="ql-font" tabindex="-1">
                      <option selected tabindex="-1">Sans Serif</option>
                      <option value="serif" tabindex="-1">Serif</option>
                      <option value="monospace" tabindex="-1">Monospace</option>
                    </select>
                  </span>
            <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold" tabindex="-1" tooltipPosition="bottom" placeholder="Bottom" pTooltip="Bold"></button>
                    <button class="ql-italic" aria-label="Italic" tabindex="-1" tooltipPosition="bottom" placeholder="Bottom" pTooltip="Italic"></button>
                    <button class="ql-underline" aria-label="Underline" tabindex="-1" tooltipPosition="bottom" placeholder="Bottom" pTooltip="Underline"></button>
                  </span>
            <span class="ql-formats">
                    <select class="ql-color" tabindex="-1"></select>
                    <select class="ql-background" tabindex="-1"></select>
                  </span>
            <span class="ql-formats">
                    <button class="ql-list" value="ordered" aria-label="Ordered List" tabindex="-1"></button>
                    <button class="ql-list" value="bullet" aria-label="Unordered List" tabindex="-1"></button>
                    <select class="ql-align" tabindex="-1">
                      <option selected tabindex="-1"></option>
                      <option value="center" tabindex="-1"></option>
                      <option value="right" tabindex="-1"></option>
                      <option value="justify" tabindex="-1"></option>
                    </select>
                  </span>
            <span class="ql-formats">
                    <button class="ql-link" aria-label="Insert Link" tabindex="-1"></button>
                  </span>
            <span class="ql-formats">
                    <button class="ql-clean" aria-label="Remove Styles" tabindex="-1"></button>
                  </span>
          </p-header>
        </p-editor>

      </div>
    </div>
    <div class="grid-no-tb-gutter company-container" *ngFor="let portfolio of companyPortfolios; let i=index">
      <div class="col-12">
        <div class="grid grid-no-tb-gutter flex justify-content-end align-items-center">

          <div class="col">
            <div class="grid grid-no-tb-gutter">
              <div class="col flex-w-auto">
                <!-- <p-avatarGroup>
                  <p-avatar [overlayPanelTooltip]="scoreTooltip" label="{{ portfolio.company.rank | number : '1.0-0' }}"
                    styleClass="cyan w-38" shape="circle"></p-avatar>
                </p-avatarGroup> -->
                <!-- Company Score -->
                <!-- <p-overlayPanel styleClass="tool-tip-class" #scoreTooltip>
                  <bpc-company-score></bpc-company-score>
                </p-overlayPanel> -->
              </div>
              <div class="col flex-w-auto">
                <a class="company-link" [routerLink]="portfolio.company | companyDetailsUrl">
                  <h2>
                    {{ portfolio.company.name }}
                  </h2>
                </a>
                <!-- <p class="type mb-2" *ngIf="getCompanyType(i)">{{ getCompanyType(i) }}</p> -->
                <div style="display: flex; align-items: center; gap:0.3rem">
                  <!-- Social Links -->
                  <bpc-social-icon *ngFor="let link of portfolio.company | socialLinks" class="social-links"
                    [socialLink]="link">
                  </bpc-social-icon>
                  <a *ngIf="portfolio.portfolioCompany.iLevelUrl" [href]="portfolio.portfolioCompany.iLevelUrl" target="_blank">
                    <img src="assets/svg/ilevel.svg" alt="social-icon" />
                  </a>
                  <img *ngIf="!portfolio.portfolioCompany.iLevelUrl" class="opacity-30" pTooltip="Unavailable" tooltipPosition="top" src="assets/svg/ilevel.svg" alt="social-icon" />
                </div>
              </div>
            </div>
          </div>
          <div class="col flex-w-auto">
            <p *ngIf="portfolio.isSubmitLoading"> Saving... 
              <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
            </p>
            <!-- <button pButton class="mr-2 button-dark"(click)="updatePortfolio(i, updates)"
              [disabled]="getIsSubmitDisabled(i, updates)">Save
              <i *ngIf="portfolio.isSubmitLoading" class="pi pi-spin pi-spinner ml-2" style="font-size: 1rem"></i>
            </button> -->
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        <!-- <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="portfolio.updates" class="w-full"></textarea> -->
        <p-editor [(ngModel)]="portfolio.updates" [style]="{ height: '180px' }" [readonly]="isHistory" (onTextChange)="limitText($event, i)">
          <p-header *ngIf="isHistory" style="display: none;"></p-header>
          <p-header *ngIf="!isHistory" >

            <span class="ql-formats">
                    <select class="ql-header" tabindex="-1">
                      <!-- <option value="2" tabindex="-1">Heading</option> -->
                      <option value="3" tabindex="-1">Heading</option>
                      <option selected tabindex="-1">Normal</option>
                    </select>
                    <select class="ql-font" tabindex="-1">
                      <option selected tabindex="-1">Sans Serif</option>
                      <option value="serif" tabindex="-1">Serif</option>
                      <option value="monospace" tabindex="-1">Monospace</option>
                    </select>
                  </span>
            <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold" tabindex="-1" tooltipPosition="bottom" placeholder="Bottom" pTooltip="Bold"></button>
                    <button class="ql-italic" aria-label="Italic" tabindex="-1" tooltipPosition="bottom" placeholder="Bottom" pTooltip="Italic"></button>
                    <button class="ql-underline" aria-label="Underline" tabindex="-1" tooltipPosition="bottom" placeholder="Bottom" pTooltip="Underline"></button>
                  </span>
            <span class="ql-formats">
                    <select class="ql-color" tabindex="-1"></select>
                    <select class="ql-background" tabindex="-1"></select>
                  </span>
            <span class="ql-formats">
                    <button class="ql-list" value="ordered" aria-label="Ordered List" tabindex="-1"></button>
                    <button class="ql-list" value="bullet" aria-label="Unordered List" tabindex="-1"></button>
                    <select class="ql-align" tabindex="-1">
                      <option selected tabindex="-1"></option>
                      <option value="center" tabindex="-1"></option>
                      <option value="right" tabindex="-1"></option>
                      <option value="justify" tabindex="-1"></option>
                    </select>
                  </span>
            <span class="ql-formats">
                    <!-- <input type="file" #fileInput (change)="fileChangeEvent($event, portfolio.id)" style="display: none">
                    <button class="ql-link" aria-label="Insert Link" tabindex="-1" (click)="fileInput.click()">
                    </button> -->
                    <!-- <button class="ql-image" aria-label="Insert Image" tabindex="-1" style="display: block !important;"></button> -->
                    <!-- <button class="ql-code-block" aria-label="Insert Code Block" tabindex="-1"></button> -->
                  </span>
            <span class="ql-formats">
                    <button class="ql-clean" aria-label="Remove Styles" tabindex="-1"></button>
                  </span>
          </p-header>
        </p-editor>

      </div>

    </div>
  </section>
  <ng-template #showLoader>
    <section>
      <div *ngFor="let _ of [].constructor(3)" style="display: flex; flex-direction: column;">
        <div style="margin-top: 15px; margin-bottom: 15px;">
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton width="50rem" styleClass="mb-2"></p-skeleton>
        </div>
      </div>

    </section>
  </ng-template>
</section>



<bpc-custom-modal *ngIf="visible" [visible]="visible" [styleClass]="'portfolio-wrapper'" [contentStyleClass]="'portfolio-preview-container'" (onHide)="onHide()">
  <ng-container modal-header>
    <h1 class="f-18">Mail Preview</h1>
  </ng-container>
  <ng-container modal-content>
    <div class="modal-content-container">
      <div *ngIf="modalLoading">
        <div *ngFor="let _ of [].constructor(3)" style="display: flex; flex-direction: column;">
          <div style="margin-top: 15px; margin-bottom: 15px;">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="45%" styleClass="mb-2"></p-skeleton>
          </div>
        </div>
      </div>
      <!-- <span *ngIf="!modalLoading" [innerHTML]="previewObj.data"></span> -->
      <iframe style="width: 100%; height: 100%;" id="myIframe"></iframe>
      <!-- <iframe  id="myIframe" srcdoc="<html><head><title>My Title</title></head><body><section id='myIframe'></section></body></html>" width="700" height="500"></iframe> -->
      
    </div>
  </ng-container>
  
  <ng-container modal-footer>
    <button pButton class="button-light" (click)="onHide()">Cancel</button>
    <button pButton class="button-dark" [disabled]="isPublishDisabled" (click)="publishPortfolio()">Publish
      <i *ngIf="isPublishLoading" class="pi pi-spin pi-spinner ml-2" style="font-size: 1rem"></i>
    </button>
  </ng-container>
</bpc-custom-modal>

<!-- <p-dialog contentStyleClass="modal-size" (onHide)="onHide()" [(visible)]="visible">
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Mail Preview</span>
  </ng-template>
  <div class="modal-content-container">
    <div *ngIf="modalLoading">
      <div *ngFor="let _ of [].constructor(3)" style="display: flex; flex-direction: column;">
        <div style="margin-top: 15px; margin-bottom: 15px;">
          <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="25rem" styleClass="mb-2"></p-skeleton>
        </div>
      </div>
    </div>
    <iframe *ngIf="!modalLoading" style="width: 100%; height: 100%;" id="myIframe"></iframe>
    
  </div>

  <div style="display: flex;flex-direction: row; justify-content: flex-end;">
    <button pButton class="button-light mr-2" (click)="onHide()">Cancel</button>
    <button pButton class="button-dark" [disabled]="isPublishDisabled" (click)="publishPortfolio()">Publish
      <i *ngIf="isPublishLoading" class="pi pi-spin pi-spinner ml-2" style="font-size: 1rem"></i>
    </button>
  </div>
</p-dialog> -->