<div>
  <p-dialog
    [modal]="true"    
    [resizable]="false"
    [closeOnEscape]="true"
    header="Are you sure you want to remove this vote?"
    [visible]="visible"
    >
    <section id="vote-stats-container" class="voting-modal-wrapper">
      <div class="grid grid-no-tb-gutter align-items-center list-view">
        <div class="col">
          <div class="grid grid-no-tb-gutter align-items-center">
            <div class="col flex-w-auto">
              <span
                class="cursor-pointer voting-members {{ vote?.borderClass }} "
              >
                <img
                  class="cursor-pointer"
                  [src]="vote?.profilePictureUrl ? vote?.profilePictureUrl : 'assets/no-image.png'"
                  style="width: 50px; height: 50px;"
                  alt="{{ vote?.name }}"
                />
              </span>
            </div>
            <div class="col flex-w-auto list-info">
              <h2>{{ vote?.name }}</h2>
              <p *ngIf="vote!=null">
                {{vote | voterDesignation }} |
                <span>{{ (vote.modifyDate ? vote.modifyDate : vote.createDate) | date : "dd MMM y" }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col flex-w-auto">
          <div class="star-tag {{ vote?.borderClass }}">
            <span class="star-circle">
              <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.51965 1.99998L4.09265 4.36498L5.47865 1.99998L7.61265 1.99998L5.13765 5.92698L7.66765 9.72198H5.47865L3.92765 7.38998L2.56365 9.72198H0.429649L2.88265 5.82798L0.33065 1.99998L2.51965 1.99998Z"
                  fill="white"
                />
                <path
                  d="M7.93165 0.899976L0.0666504 0.899975L0.0666504 0.349976L7.93165 0.349976V0.899976Z"
                  fill="white"
                />
              </svg>
            </span>
            <span class="star-value">
              {{ vote?.score }}
            </span>
          </div>
        </div>
        <div class="col-12">
          <p class="comment">{{ vote?.comment }}</p>
        </div>
      </div>
    </section>
    <ng-template pTemplate="footer">
      <div>
        <button
          pButton
          label="Cancel"
          (click)="onNegativeClick()"
        ></button>
        <button
          pButton
          class="button-dark"
          type="submit"
          label="Yes"
          [loading]="modalLoading"
          [disabled]="positiveButtonDisabled"
          (click)="onPositiveClick()"
        ></button>
      </div>
    </ng-template>
  </p-dialog>
</div>