import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appCompanyName]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CompanyNameValidatorDirective,
    multi: true
  }]
})
export class CompanyNameValidatorDirective implements Validator {
  validate(control: AbstractControl) : ValidationErrors | null {
    const regixExp = /^([*]?([\s]*[a-zA-Z0-9&]+[._!"`'#%&,:<>=@{}~\$\s*\(\)\s*\\+\/\\\?\[\]\^\|]*[\s]*)+[*]?[\s]*){0,1}$/;
    
    if (Array.isArray(control.value)) {
      const isInValid = control.value.filter(value => !regixExp.test(value));
      return isInValid.length ? { companySearchInvalid: true } : null;
    }
  
    return !regixExp.test(control.value ?? '') ? { companySearchInvalid: true } : null;
  }
}
