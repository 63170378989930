import { Component } from '@angular/core';

@Component({
  selector: 'bpc-admin-alert',
  templateUrl: './admin-alert.component.html',
  styleUrls: ['./admin-alert.component.scss']
})
export class AdminAlertComponent {

}
