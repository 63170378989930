import { Directive } from '@angular/core';
import { DestroyObservable } from '../../rxjs/DestroyObservable';
import { takeUntil } from 'rxjs';
import { AutoComplete } from 'primeng/autocomplete';
import { BaseMultipleAutoCompleteDirective } from '@core/directives/base-multiple-auto-complete.directive';
import { TagsService } from '@core/services/tags.service';
import { Tag } from '@models/tag';

@Directive({
  selector: 'p-autoComplete[tags]',
  providers: [DestroyObservable]
})
export class TagsDirective extends BaseMultipleAutoCompleteDirective<Tag> {

  loadData = this.tagsService.tags$.pipe(takeUntil(this.destroyObservable));

  constructor(autoComplete: AutoComplete, private tagsService: TagsService, private destroyObservable: DestroyObservable) {
    super(autoComplete);
  }
}