import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '@core/models/company';
import { User } from '@core/models/user';

@Pipe({
  name: 'podLeader'
})
export class PodLeaderPipe implements PipeTransform {

  transform(company: Company): User[] {
    const { assignee } = company;

    if (assignee) {
      const podLeaderInCoverageUsers = company.coverageUsers.find(({ id }) => id === assignee.id);

      if (podLeaderInCoverageUsers) {
        podLeaderInCoverageUsers.isPodLeader = true;
        return [podLeaderInCoverageUsers];
      }

      return [{ ...assignee, isPodLeader: true }];

    }

    return [];
  }

}
