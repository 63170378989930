<div class="inline-block relative">
  <div class="inline-block" #content (click)="toggle()">
    <ng-content></ng-content>
  </div>
  <div #menuItems>
    <div *ngIf="open" class="p-menu p-component p-menu-overlay absolute" [ngStyle]="style">
      <ul class="p-menu-list">
        <div *ngFor="let item of items; trackBy: trackBy">
          <li *ngIf="item.separator" class="p-menu-separator"></li>
          <li *ngIf="!item.separator" class="p-element p-menuitem" [class.p-disabled]="item?.disabled"
            (click)="itemClick($event, item)">
            <a class="p-menuitem-link">
              <i *ngIf="item.icon" class="p-menuitem-icon {{ item.icon }}"></i>
              <span class="p-menuitem-text">
                {{ item.label }}
              </span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  </div>
</div>