import { Injectable } from '@angular/core';
import { Tag } from '@core/models/tag';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchHelperService {

  private messageSource = new BehaviorSubject<SearchMessage>({ type: 'default', payload: null });
  currentMessage: Observable<SearchMessage> = this.messageSource.asObservable();

  get messageSourceBehaviorSubject(){
    return this.messageSource;
  }
  constructor() {
  }

  changeMessage(message: SearchMessage) {
    this.messageSource.next(message);
  }
}

interface SearchMessage {
  type: string,
  payload: Tag | string | null
}
