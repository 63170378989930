import { Directive } from '@angular/core';

import { Dialog } from 'primeng/dialog';

@Directive({
  selector: 'p-dialog'
})
export class DialogDirective {

  constructor(dialog: Dialog) {
    dialog.styleClass = 'w-[700px] min-h-[260px]';
    dialog.appendTo = 'body';
    dialog.closable = true;
    dialog.draggable = false;
    dialog.modal = true;
    dialog.dismissableMask = true;
  }
}