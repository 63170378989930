import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { slugify } from '../../utils/slugify';
import { BasicCompany, Company } from '@models/company';
import { CSVFile } from '@core/models/csv-file';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CompanyHelperService {

  private messageSource = new BehaviorSubject('default message');
  currentMessage: Observable<string> = this.messageSource.asObservable();

  private _companyDetailsDismissible = new Subject<boolean>();
  companyDetailsDismissible: Observable<boolean> = this._companyDetailsDismissible.asObservable();

  generateCompanyDetailsUrl({ id, name }: Company | BasicCompany): string[] {
    return ['company', id + '-' + slugify(name)];
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  emitCompanyDetailsDismissible(dismissible: boolean) {
    this._companyDetailsDismissible.next(dismissible);
  }

  convertJSONToCSV(csvTemplate: CSVFile<Company>, currentTabTitle: string) {
    const header: string[] = [];
    const replacer = (key: any, value: any) => value == null ? '' : value;
    let csv: any = [];

    if (csvTemplate.data.length) {
      csv = csvTemplate.data.map((row, i) =>
        csvTemplate.headers.map((item) => {
          let value: any;
          let terminate = false;
  
          for (let i = 0; i < item.keys.length && !terminate; i++) {
            const key = item.keys[i] as (keyof typeof row);
            const newValue = i === 0 ? row[key] : value[key];
  
            // value = newValue ?? value;
            value = newValue
            terminate = newValue == null;
          }
  
          if (i === 0) {
            header.push(item.label);
          }
          if (item.pipe) {
            value = item.pipe(value, ...(item.arguments ?? []));
          }
  
          return JSON.stringify(value, replacer);
        }).join(',')
      );
    } else {
      csvTemplate.headers.map((item) => {
        header.push(item.label);
      });
    }

    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `${currentTabTitle}_${moment(new Date()).format('YYYY-MM-DD_HH-mm-ss')}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
