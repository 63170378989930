import { DropdownOption } from '@models/forms';
import { StatusId } from '@models/auxiliary-company-models';

export const companyStatusOptions: DropdownOption[] = [
  { id: StatusId.None, label: 'Undefined', inactive: true },
  { id: StatusId.Unlikely, label: 'Unlikely' },
  { id: StatusId.Early, label: 'Early' },
  { id: StatusId.Monitor, label: 'Monitor' },
  { id: StatusId.Contender, label: 'Contender' },
  { id: StatusId.MustMeet, label: 'Must Meet' },
  { id: StatusId.Pearl, label: 'Pearl' },
  { id: StatusId.CantCrack, label: 'Can\'t Crack' },
  { id: StatusId.firmWideElevation, label: 'Firmwide Elevation' },
  { id: StatusId.PipelineDeal, label: 'Pipeline Deal' },
  { id: StatusId.PriorityPipeline, label: 'Priority Pipeline' },
  { id: StatusId.ActiveDeal, label: 'Active Deal' },
  { id: StatusId.IC, label: 'IC' },
  { id: StatusId.HeartsMinds, label: 'Hearts & Minds' },
  { id: StatusId.PortfolioMA, label: 'Portfolio M&A' },
  { id: StatusId.ProspectMA, label: 'Prospect M&A' },
];

export const nonAssignableCompanyStatusOptions: DropdownOption[] = [
  { id: StatusId.NoOutreach, label: 'No Outreach' }
];
