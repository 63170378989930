import { Nullable } from '@models/nullable';

export enum TagType {
  System = 1, // global
  User, // private
  Specialty, // currently unused
  Source // source scrub
}

export interface Tag {
  id: number;
  type: TagType;
  createBy: number;
  name: string;
  category?: number;
  startDate?: string;
  endDate?: string;
  state?: Nullable<string>;
  city?: Nullable<string>;
  companyIds?: number[];
}