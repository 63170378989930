import { ComponentRef, Directive, ElementRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { distinctUntilChanged, fromEvent, map, takeUntil } from 'rxjs';
import { MaxLengthComponent } from '@shared/directives/max-length/max-length.component';
import { DestroyObservable } from '../../../rxjs/DestroyObservable';

@Directive({
  selector: 'textarea[maxlength]',
  providers: [DestroyObservable]
})
export class TextareaMaxLengthDirective implements OnInit, OnDestroy {

  maxLengthComponentRef!: ComponentRef<MaxLengthComponent>;

  get textAreaElement(): HTMLTextAreaElement {
    return this.el.nativeElement;
  }

  get maxLengthComponent(): MaxLengthComponent {
    return this.maxLengthComponentRef.instance;
  }

  constructor(private el: ElementRef, private viewContainerRef: ViewContainerRef, private destroy$: DestroyObservable, private ngModel: NgModel) {
  }

  ngOnInit() {
    this.maxLengthComponentRef = this.viewContainerRef.createComponent(MaxLengthComponent);
    this.maxLengthComponent.maxLength = this.textAreaElement.maxLength;
    this.maxLengthComponent.currentLength = this.ngModel.model?.length ?? 0;

    fromEvent(this.textAreaElement, 'input')
      .pipe(takeUntil(this.destroy$))
      .pipe(map(element => (<HTMLTextAreaElement>element.target).value.length))
      .pipe(distinctUntilChanged())
      .subscribe(currentLength => {
        this.maxLengthComponent.currentLength = currentLength;
      });
  }

  ngOnDestroy(): void {
    this.maxLengthComponentRef.destroy();
  }
}
