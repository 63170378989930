
  <div *ngIf="!loading && companies.length === 0" class="bg-white p-4">
    There are currently no companies available for voting.
  </div>

  <div [hidden]="companies.length === 0" class="mt-2 md:mt-0 bpc-mobile-vote">
    <div *ngFor="let company of companies; trackBy: trackBy" class="mb-8 bg-white px-4 py-6">
      <div class="flex align">
        <div class="inline-flex flex-col items-center mr-2 mt-1">
          <bpc-deal-cloud-company [externalUrl]="company.externalUrl"></bpc-deal-cloud-company>
          <span class="text-md mt-0.5">{{ company.rank | number: '1.1' }}</span>
        </div>
        <div class="flex flex-col">
          <div class="company-link font-bold">
            {{ company.name }}
          </div>
          <div class="text-black opacity-60">
            {{ company.address?.city }}, {{ company.address?.country?.name }}
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-8 border-gray-300 border-y-2 py-4">
        <!-- <div class="flex items-center flex-col border-r border-gray-300 w-1/3">
          <div class="text-gray-800 font-bold">PIC</div>
          <div>{{ company.paidInCapital | bpcNumber: '1.0-1': '$' }}</div>
        </div> -->

        <div class="flex items-center flex-col border-r border-gray-300 w-full">
          <div class="text-gray-800 font-bold">Check Size</div>
          <div>{{ (company.workflows | votingCheckSize)|| 'N/A' }} </div>
        </div>
        <!-- <div class="flex items-center flex-col border-r border-gray-300 w-1/3">
          <div class="text-gray-800 font-bold">Revenue</div>
          <div>{{ company.revenue | bpcNumber: '1.0-1': '$' }}</div>
          <div>
            <bpc-revenue-change *ngIf="company.revenueGrowth" [value]="company.revenueGrowth"></bpc-revenue-change>
          </div>
        </div> -->
        <!-- <div class="flex items-center flex-col w-1/3">
          <div class="text-gray-800 font-bold">FTE</div>
          <div>{{ company.fullTimeEquivalent | bpcNumber: '1.0-0' : '' }}</div>
          <div>
            <bpc-revenue-change *ngIf="company.fullTimeEquivalentGrowth"
                                [value]="company.fullTimeEquivalentGrowth"></bpc-revenue-change>
          </div>
        </div> -->
      </div>
      <div class="pt-4 border-b-2 border-b-gray-300">
        <div class="flex justify-between">
          <div class="font-medium text-gray-800">IC Date</div>
          <div class="font-bold opacity-70">{{ company.workflows | votingDate }}</div>
        </div>
        <div class="py-4 min-h-[60px] text-gray-600">
          {{ company.workflows | votingDescription }}
        </div>
      </div>
      <div class="pt-4">
        <div class="text-black font-bold">CONVICTION LEVEL</div>
        <div class="pt-4">
          <div class="flex justify-between text-teal-300">
            <span>Low</span>
            <span class="text-black">Your vote: <span class="text-teal-300 font-bold">{{ company.vote }}</span></span>
            <span>High</span>
          </div>
          <div class="pt-6 pb-4 px-2">
            <p-slider [(ngModel)]="company.vote" [min]="1" [max]="10" [step]="1"></p-slider>
          </div>
        </div>
        <div class="py-4">
        <textarea
          pInputTextarea
          class="w-full min-h-[3rem] p-1"
          placeholder="Please write a comment"
          rows="2"
          [autoResize]="true"
          [(ngModel)]="company.comment">
        </textarea>
        </div>
        <div>
          <button (click)="voteForCompany(company)"
                  [ngClass]="{ '!text-purple-700 bg-gray-300': company.workflows | votingDisabled }"
                  class="w-full h-9 btn-primary bg-teal-300 square !text-white !border-0">
            Vote
          </button>
        </div>
      </div>
    </div>
  </div>
