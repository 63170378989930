<p-table
    #dataTable
    [styleClass]="tableClass + ' ' + 'company-table' || ''"
    [value]="companies"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [scrollable]="true"
  >
    <ng-template pTemplate="header">
        <tr>
            <th>
                <span class="whitespace-nowrap">
                    <span >Company</span>
                </span>
                <i
                class="cross-icon"
                (click)="onCloseGrid()"
                aria-hidden="true"
                [style.opacity]="1"
                >&times;</i>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-company>
        <tr >
            <td pFrozenColumn>
                <div class="name-wrap">
                    <div class="company-info">
                        <a
                        class="company-link"
                        [routerLink]="company | companyDetailsUrl"
                        >
                        {{ company.name }}
                        </a>
                        <span *ngIf="company.address" class="company-address">
                            {{company.address.city}}, {{company.address.state}}, {{ company.address.countryCode}}
                        </span>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>