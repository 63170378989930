import { Injectable, OnDestroy } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';

/**
 * Idea taken from: https://dev.to/this-is-angular/dry-way-to-manage-subscriptions-in-angular-components-256j
 */
@Injectable()
export class DestroyObservable extends Observable<void> implements OnDestroy {
  private readonly _destroy = new ReplaySubject<void>(1);

  constructor() {
    super(subscriber => this._destroy.subscribe(subscriber));
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
}
