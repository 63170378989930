import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bpc-revenue-change',
  templateUrl: './revenue-change.component.html',
  styleUrls: ['./revenue-change.component.scss']
})
export class RevenueChangeComponent implements OnInit {

  // @Input() value!: number;
  
  @Input()
  set fteGrowth(value: number) {
    if(value) {
      this.value = value;
      if( value < 0) {
        this.type = 'down'
      } else {
        this.type = 'up'
      }
    }
  }

  public value: number = 0;
  public type: 'up' | 'down' = 'up';
  public upIcon: string = 'assets/graph-icons/trending-arrow-up.svg';
  public downIcon: string = 'assets/graph-icons/trending-arrow-down.svg';

  constructor() { }

  ngOnInit(): void {
    // if(this.value < 0) {
    //   this.type = 'down'
    // }
  }
}
