import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CompaniesService } from '../../../services/companies/companies.service';
import { DestroyObservable } from '../../../rxjs/DestroyObservable';
import { takeUntil } from 'rxjs';
import { HistoryRecord } from '@models/company-history';
import { ActionTypeMap } from '@models/action-type';
import { WorkFlow } from '@models/auxiliary-company-models';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'bpc-history-data',
  templateUrl: './history-data.component.html',
  styleUrls: ['./history-data.component.scss'],
  viewProviders: [DestroyObservable]
})
export class HistoryDataComponent implements OnInit, OnChanges {

  @Input() companyId!: number;
  @Input() icVotingWorkflows!: WorkFlow[];
  @Input() showHistory = false;

  @Output() showVoteStats = new EventEmitter<WorkFlow>();
  @Output() togglePanel = new EventEmitter<boolean>();

  historyRecords: HistoryRecord[] = [];
  readableRecords: ReadableRecord[] = [];

  constructor(public authService: AuthService,
    private companiesService: CompaniesService,
    private readonly destroy$: DestroyObservable) {
  }

  ngOnInit(): void {
    this.loadCompanyHistoryData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showHistory'] && changes['showHistory'].currentValue === true && changes['showHistory'].previousValue === false) {
      this.readableRecords = [];
      this.loadCompanyHistoryData();
    }
  }

  toggleHistory() {
    this.togglePanel.emit(!this.showHistory);
  }

  showVoteStatsModal(workflow: WorkFlow) {
    this.showVoteStats.emit(workflow);
  }

  private loadCompanyHistoryData() {
    this.companiesService.getCompanyHistory(this.companyId).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response && response.data?.length) {
        this.historyRecords = [...response.data.reverse()]; // We need reverse() to show by date in descending order
        this.generateReadableRecords();
      }
    });
  }

  private generateReadableRecords() {
    this.historyRecords.forEach(r => {
      let value = '';
      switch (r.actionType) {
        case ActionTypeMap.CREATE:
          value = `Created by ${r.createUser.name}.`;
          break;

        case ActionTypeMap.MODIFY:
          value = `Modified by ${r.createUser.name}.`;
          break;

        case ActionTypeMap.TAKEOVER:
          if (r.createUser.id === r.targetUser?.id) {
            value = `Take ownership by ${r.createUser.name}.`;
          } else if (r.targetUser) {
            value = `${r.targetUser.name} added to coverage by ${r.createUser.name}.`;
          }
          break;

        case ActionTypeMap.RELEASE:
          if (r.targetUser?.id && r.targetUser.id !== r.createUser.id) {
            value = `${r.targetUser.name} removed from coverage by ${r.createUser.name}.`;
          } else {
            value = `Release company by ${r.createUser.name}.`;
          }
          break;

        case ActionTypeMap.TRANSFER:
          break;

        case ActionTypeMap.PRIORITIZE:
          value = `Move to priority by ${r.createUser.name}.`;
          break;

        case ActionTypeMap.DEPRIORITIZE:
          value = `Remove from priority by ${r.createUser.name}.`;
          break;

        case ActionTypeMap.SETSTATUS:
          switch (r.actionValue) {
            case 'PortfolioMAndA':
              value = `Change status to Portfolio M&A by ${r.createUser.name}.`;
              break;
            case 'CantCrack':
              value = `Change status to Can't Crack by ${r.createUser.name}.`;
              break;
            case 'MustMeet':
              value = `Change status to Must Meet by ${r.createUser.name}.`;
              break;
            case 'ActiveDeal':
              value = `Change status to Active Deal by ${r.createUser.name}.`;
              break;
            case 'HeartsAndMinds':
              value = `Change status to Hearts And Minds by ${r.createUser.name}.`;
              break;

            default:
              value = `Change status to ${r.actionValue} by ${r.createUser.name}.`;
          }
          break;

        case ActionTypeMap.SETUNLIKELY:
        case ActionTypeMap.SETLIKELY:
        case ActionTypeMap.SETALERT:
        case ActionTypeMap.INTERACT:
          break;

        case ActionTypeMap.TAG:
          value = `Added tag '${r.actionValue}' by ${r.createUser.name}.`;
          break;

        case ActionTypeMap.TAGEDITED:
          value = `Modified tag ${r.actionValue} by ${r.createUser.name}.`;
          break;

        case ActionTypeMap.TAGREMOVED:
          value = `Removed tag '${r.actionValue}' by ${r.createUser.name}.`;
          break

        case ActionTypeMap.CREATEWORKFLOW:
          switch (r.actionValue) {
            case 'DataQuality':
              value = `Marked for enrichment by ${r.createUser.name}.`;
              break;
            case 'InvestmentCommittee':
              value = `Flag for IC Voting by ${r.createUser.name}.`;
              break;
          }
          break;

        case ActionTypeMap.MODIFYWORKFLOW:
        case ActionTypeMap.ASSIGNWORKFLOW:
        case ActionTypeMap.RELEASEWORKFLOW:
          break;

        case ActionTypeMap.SETWORKFLOWSTATUS:
          switch (r.actionValue) {
            case 'DataQuality.DQ_Done':
              value = `Enrichment status marked as Done by ${r.createUser.name}.`;
              break;
            case 'DataQuality.DQ_Revise':
              value = `Enrichment changes declined by ${r.createUser.name}.`;
              break;
            case 'DataQuality.DQ_Complete':
              value = `Enrichment changes accepted by ${r.createUser.name}.`;
              break;
            case 'InvestmentCommittee.IC_Complete':
              value = `Moved to voting history by ${r.createUser.name}.`;
              break;
          }
          break;

        case ActionTypeMap.REMOVEWORKFLOW:
          value = `Removed from IC Voting by ${r.createUser.name}.`;
          break;

        case ActionTypeMap.TAKEOVERLEAD:
        case ActionTypeMap.RELEASELEAD:
        case ActionTypeMap.TRANSFERLEAD:
        case ActionTypeMap.PRIORITIZELEAD:
        case ActionTypeMap.DEPRIORITIZELEAD:
          break;
      }

      this.readableRecords.push({
        date: r.createDate, value: value
      });
    });
  }
}

interface ReadableRecord {
  date: string;
  value: string;
}
