import { Component, ViewEncapsulation } from '@angular/core';
import { TabsBaseComponent } from '@core/models/tabs-base-component';


@Component({
  selector: 'bpc-mobile-tabs',
  templateUrl: './mobile-tabs.component.html',
  styleUrls: ['./mobile-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileTabsComponent extends TabsBaseComponent {
}