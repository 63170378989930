import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { MessageService } from 'primeng/api';
import { BaseApiService } from 'src/app/api/base';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService extends BaseApiService {
    userGroups: any[] = [];
    constructor(private httpClient: HttpClient, router: Router, authService: AuthService, messageService: MessageService) {
        super("/groups", authService, router, messageService);
        this.getAllGroups();
    }

    getUserGroups() {
        return this.get('');
    }

    getAllGroups() {
        this.getUserGroups().subscribe((res) => {
            this.userGroups = res.data;
        })
    }
}
