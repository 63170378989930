<span
  *ngIf="activeTriggers.length > 0"
  class="mr-2 cursor-pointer opacity-50"
  (click)="onIconClick()"
  [overlayPanelTooltip]="tooltip">
  <i
    class="pi pi-bell rounded-full p-1"
    [class.text-white]="!company.hasUnreadNotifications"
    [class.bg-gray-300]="!company.hasUnreadNotifications"
    [class.bg-amber-200]="company.hasUnreadNotifications"
    [class.text-orange-500]="company.hasUnreadNotifications">
  </i>
</span>
<p-overlayPanel #tooltip>
  <div *ngFor="let trigger of activeTriggers">
    {{ getTriggerDescription(trigger) }}
  </div>
</p-overlayPanel>