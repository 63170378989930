<span #button [overlayPanelTooltip]="alertOverlay" class="relative" class="opacity-50">
  <!-- (click)="alertOverlay.hide(); panel.toggle($event, button)" -->
  <span class="icon-notification"></span>
  <!-- <i class="pi pi-bell"></i> -->
  <span *ngIf="unread > 0" class="absolute text-white-400 bg-red-600 text-sm top-0 notification-count">
    {{ unread }}
  </span>
</span>
<p-overlayPanel styleClass="alert-dropdown" #panel>
  <ng-template pTemplate="content">
    <div class="max-w-[500px] max-h-[75vh] w-[82vw] md:w-[92vw] overflow-auto">
      <p *ngIf="notifications.length === 0" class="text-center select-none">You're all good...</p>
      <div *ngFor="let notification of notifications; trackBy: trackByNotificationId" #n class="mb-1 hover:bg-gray-100">
        <a
          class="block p-2 rounded-sm"
          (click)="markAsRead(notification)"
          [routerLink]="notification.company | companyDetailsUrl"
        >
          <span [class.text-blue-600]="!notification.isRead" [class.font-bold]="!notification.isRead">
            {{ notification.company.name }}
          </span>
          <p class="text-xxs">
            {{ notification | notificationDescription }}
          </p>
        </a>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel #alertOverlay> Alerts </p-overlayPanel>
