import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { BaseApiService } from '../../api/base';
import { Industry } from '@models/auxiliary-company-models';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class IndustryService extends BaseApiService<Industry[]> {

  industries$ = this.get().pipe(shareReplay(1));

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/meta/industries', authService, router, messageService);
  }

  getIndustries() {
    return this.get();
  }
}
