import { Component, ElementRef, Input, SimpleChanges, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

type QueryParams = { tags?: [string]; tagsExclude?: string };

@Component({
  selector: "bpc-extension-preview",
  templateUrl: "./extension-preview.component.html",
  styleUrls: ["./extension-preview.component.scss"],
})
export class ExtensionPreviewComponent {
  @ViewChild("content") content!: ElementRef;
  isVisible: boolean = false;
  @Input() companyDomain?: string;
  trustedCompanyDomain!: SafeResourceUrl;


  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyDomain'] && (changes['companyDomain'].currentValue !== changes['companyDomain'].previousValue)) {
      const urlCompany = `https://qa.jarvis.bpc.com/extension?domain=${this.companyDomain}`;
      this.trustedCompanyDomain = this.sanitizer.bypassSecurityTrustResourceUrl(urlCompany);
    }
  }

}
