import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlow } from '@models/auxiliary-company-models';
import { Nullable } from '@models/nullable';
import * as moment from 'moment';
import { getActiveOrLastICVotingWorkflow } from '../../../utils/company-utils';

@Pipe({
  name: 'votingDate'
})
export class VotingDatePipe implements PipeTransform {

  transform(workflows: WorkFlow[]): Nullable<string> {
    const workflow = getActiveOrLastICVotingWorkflow(workflows);

    if (workflow) {
      return moment(workflow.votingDate).format('MMM DD,YYYY');
    }

    return null;
  }
}
