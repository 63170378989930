<div class="grid search-item" *ngIf="item.name">
  <!-- <div class="col-fixed flex flex-wrap" style="width: 56px">
    <span class="count flex align-items-center justify-content-center">{{(item.metaData?.rank !== undefined &&
      item.metaData?.rank !== null) ? (item.metaData?.rank | number : '1.0-0') :'-'}}</span>
  </div> -->
  <div class="col flex-column flex flex-wrap">
    <h4>{{ item.name }}</h4>
    <p>
      <span>{{item.metaData?.location}}</span>
      <span *ngIf="item.city && item.state">, </span>
      <span>{{ item.state }}</span>
    </p>
    <p *ngIf="item.startDate">{{ item.startDate | date : "d.M.yy" }}</p>
  </div>
</div>