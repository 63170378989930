import { Directive } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';

export const ORDINARY_DAYS_IN_MONTH = [
  '1st', '2nd', '3rd', '4th', '5th', '6th', '7th',
  '8th', '9th', '10th', '11th', '12th', '13th', '14th',
  '15th', '16th', '17th', '18th', '19th', '20th', '21st',
  '22nd', '23rd', '24th', '25th', '26th', '27th', '28th'
];

@Directive({ selector: '[recurringMonthly]' })
export class RecurringMonthlyDirective {

  constructor(dropdown: Dropdown) {
    dropdown.options = ORDINARY_DAYS_IN_MONTH.map((day, index) => ({ name: day, id: index + 1 }));
    dropdown.optionValue = 'id';
    dropdown.optionLabel = 'name';
  }
}