import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { getGridBackgroundClasses } from '../../../utils/company-utils';
import { Company } from '@models/company';
import { Tab } from '../../models/types';

@Pipe({
  name: 'gridRowBackground'
})
export class GridRowBackgroundPipePipe implements PipeTransform {

  constructor(private authService: AuthService) {
  }

  transform(company: Company, tabIndex: Tab): string[] {
    return getGridBackgroundClasses(this.authService.user.value!, company, tabIndex);
  }
}
