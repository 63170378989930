import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagsService } from '@core/services/tags.service';
import { combineLatest, from, map, takeUntil, shareReplay, Subject, debounceTime, distinctUntilChanged, switchMap, filter, tap } from 'rxjs';
import { LocationService } from '@core/services/location.service';
import { DestroyObservable } from 'src/app/rxjs/DestroyObservable';
import { FilterDropdown } from '@core/models/auxiliary-company-models';
import { InvestorService } from '@core/services/investor.service';
import { companyStatusOptions } from '@shared/forms/dropdown-options';
import { UsersService } from 'src/app/services/users/users.service';
import { CompaniesSearchService } from 'src/app/services/companies-search/companies-search.service';
import { IndustryService } from '@core/services/industry.service';
import { UserRolesService } from 'src/app/services/user-roles/user-roles.service';
import { UserGroupsService } from 'src/app/services/user-groups/user-groups.service';

@Component({
  selector: 'bpc-custom-dropdown-search',
  templateUrl: './custom-dropdown-search.component.html',
  styleUrls: ['./custom-dropdown-search.component.scss']
})
export class DropdownSearchComponent implements OnInit {

  @Input() body: string = "";
  @Input() options: any;
  @Input() values!: any;
  @Input() type!: FilterDropdown;
  @Input() label!: string;
  @Input() placeholder?: string;
  @Input() runtimeSearch!: boolean;
  @Input() required: boolean = false;
  @Input()
  set excludeOptions(excludeValue: any) {
    this.excludeOptionItemsIds = excludeValue.map((value: any) => value.id);
    let selectedValuesIds = this.values.map((value: any) => value.id);
    if (excludeValue.length !== undefined && excludeValue.length > 0) {
      if (this.options !== undefined) {
        this.options = [...this.initialOptions];
        this.options = [...this.options.filter((option: any) => !this.excludeOptionItemsIds.includes(option.id))];
        this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
        // this.options = [...this.options.filter((option: any) => !this.values.includes(option))];
      }
    } else {
      if (this.options !== undefined) {
        this.options = [...this.initialOptions];
        this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
      }
    }
  }

  pageNumber: number = 1;
  inputValue: string = "";
  excludeOptionItemsIds: any;
  initialOptions: any;
  isLoading: boolean = false;
  selectedValue: any = null;
  input$ = new Subject<string>();
  @Output() setFormControl = new EventEmitter<any>();
  @Output() removeDropdownItem = new EventEmitter<any>();


  constructor(
    private locationService: LocationService,
    private destroyObservable: DestroyObservable,
    private investorService: InvestorService,
    private tagsService: TagsService,
    private usersService: UsersService,
    private companiesSearchService: CompaniesSearchService,
    private industryService: IndustryService,
    private userRolesService: UserRolesService,
    private userGroupsService: UserGroupsService,
  ) {

  }

  ngOnInit() {
    if (!this.options || this.options?.length === 0) {
      if (this.runtimeSearch) {
        this.subscribeToInputChanges();
      } else {
        let selectedValuesIds: any =[];
        if(this.values){
          selectedValuesIds = this.values.map((value: any) => value.id);
        }
        this.isLoading = true;
        switch (this.type) {
          case FilterDropdown.companyLocation:
            const regionsData$ = combineLatest([this.locationService.countries$, this.locationService.cities$, this.locationService.regions$])
              .pipe(
                map(([countries, cities, regions]) => (countries.data?.concat(cities.data ?? []).concat(regions.data ?? []) ?? [])
                ),
                takeUntil(this.destroyObservable)
              );
            regionsData$.subscribe((data) => {
              if (data) {
                this.options = data.map((_d) => ({ ..._d }));
                this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
                this.initialOptions = data.map((_d) => ({ ..._d }));
              }
              this.isLoading = false;
            });
            break;

          case FilterDropdown.coveragePersonExclude:
          case FilterDropdown.coveragePersonInclude:
            const users$ = this.usersService.getActiveUsers().pipe(takeUntil(this.destroyObservable));
            users$.subscribe((res) => {
              if (res.data) {
                this.options = res.data;
                this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
                if (this.excludeOptionItemsIds) {
                  this.options = [...this.options.filter((option: any) => !this.excludeOptionItemsIds.includes(option.id))];
                }
                this.initialOptions = res.data;
              }
              this.isLoading = false;

            });
            break;
          case FilterDropdown.companyStatusInclude:
          case FilterDropdown.companyStatusExclude:
            this.options = companyStatusOptions?.map((_d) => ({ ..._d, name: _d.label }));
            this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
            if (this.excludeOptionItemsIds) {
              this.options = [...this.options.filter((option: any) => !this.excludeOptionItemsIds.includes(option.id))];
            }
            this.initialOptions = companyStatusOptions?.map((_d) => ({ ..._d, name: _d.label }));
            this.isLoading = false;
            break;

          case FilterDropdown.industryInclude:
          case FilterDropdown.industryExclude:
            const industries$ = this.industryService.industries$.pipe(takeUntil(this.destroyObservable));
            industries$.subscribe((res) => {
              if (res.data) {
                this.options = res.data;
                this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
                if (this.excludeOptionItemsIds) {
                  this.options = [...this.options.filter((option: any) => !this.excludeOptionItemsIds.includes(option.id))];
                }
                this.initialOptions = res.data;
              }
              this.isLoading = false;
            })
            break;
          
          case FilterDropdown.userRoles:
            const roles$ = this.userRolesService.getUserRoles();
            roles$.subscribe((res) => {
              if (res.data) {
                this.options = res.data;
                this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
                if (this.excludeOptionItemsIds) {
                  this.options = [...this.options.filter((option: any) => !this.excludeOptionItemsIds.includes(option.id))];
                }
                this.initialOptions = res.data;
              }
              this.isLoading = false;

            });
          break;

          case FilterDropdown.userGroups:
            const groups$ = this.userGroupsService.getUserGroups();
            groups$.subscribe((res) => {
              if (res.data) {
                this.options = res.data.filter((group: any) => group.groupType === 2 );
                this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
                if (this.excludeOptionItemsIds) {
                  this.options = [...this.options.filter((option: any) => !this.excludeOptionItemsIds.includes(option.id))];
                }
                this.initialOptions = res.data;
              }
              this.isLoading = false;

            });
          break;

          case FilterDropdown.userPods:
            const pods$ = this.userGroupsService.getUserGroups();
            pods$.subscribe((res) => {
              if (res.data) {
                let pods = res.data.filter((pod: any) => pod.groupType === 1 );
                pods = pods.map((pod: any) => {
                  const sortedPodMembers = pod.members.sort((a: any, b: any) => {
                    return b.isAdmin - a.isAdmin;
                  });
                  pod.name = "Pod: " + sortedPodMembers.map((member:any) => member.initials).join(", ");
                  return pod;
                });
                this.options = pods;
                this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
                if (this.excludeOptionItemsIds) {
                  this.options = [...this.options.filter((option: any) => !this.excludeOptionItemsIds.includes(option.id))];
                }
                this.initialOptions = res.data;
              }
              this.isLoading = false;

            });
          break;
        }
      }
    }
  }

  subscribeToInputChanges() {
    this.input$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(res => {
        return res !== null && res.length >= 3;
      }),
      tap(() => (this.isLoading = true)),
      switchMap((term) => {
        this.inputValue = term;
        this.pageNumber = 1;
        switch (this.type) {
          case FilterDropdown.companyQuickSearch:
            return this.companiesSearchService.quickCompaniesOnlySearch(term).pipe(shareReplay(1));
          case FilterDropdown.investorInclude:
          case FilterDropdown.investorExclude:
            return this.investorService.getInvestorsByName(term).pipe(shareReplay(1));
          case FilterDropdown.tagInclude:
          case FilterDropdown.tagExclude:
            return this.tagsService.getTagsByName(term).pipe(shareReplay(1));
          default:
            return this.companiesSearchService.quickCompaniesOnlySearch(term).pipe(shareReplay(1));
        }
      })
    )
      .subscribe(res => {
        if (res.data) {
          const selectedValuesIds = this.values.map((value: any) => value.id);
          this.options = res.data;
          if(selectedValuesIds.length>0) {
            this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
          }
          if (this.excludeOptionItemsIds) {
            this.options = [...this.options.filter((option: any) => !this.excludeOptionItemsIds.includes(option.id))];
          }
          this.initialOptions = res.data;
        }
        this.isLoading = false;
      });
  }

  // onTyping(term: any) {
  //   console.log("term", term);
  //   this.input$.next(term.term);
  // }

  // fetchMore() {
  //   this.pageNumber ++;
  //   return from([this.inputValue]).pipe(
  //     filter(res => {
  //       return res !== null && res.length >= 3;
  //     }),
  //     tap(() => (this.isLoading = true)),
  //     switchMap((term) => {
  //       console.log("parameterss",this.pageNumber,term);
  //       switch (this.type) {
  //         case FilterDropdown.companyQuickSearch:
  //           return this.companiesSearchService.quickCompaniesOnlySearch(term).pipe(shareReplay(1));
  //         case FilterDropdown.investorInclude:
  //         case FilterDropdown.investorExclude:
  //           return this.investorService.getInvestorsByName(term).pipe(shareReplay(1));
  //         case FilterDropdown.tagInclude:
  //         case FilterDropdown.tagExclude:
  //           return this.tagsService.getTagsByName(term).pipe(shareReplay(1));
  //         default:
  //           return this.companiesSearchService.quickCompaniesOnlySearch(term).pipe(shareReplay(1));
  //       }
  //     })
  //   )
  //     .subscribe(res => {
  //       if (res.data) {
  //         const selectedValuesIds = this.values.map((value: any) => value.id);
  //         this.options = [...this.options, ...res.data];
  //         if(selectedValuesIds) {
  //           this.options = [...this.options.filter((option: any) => !selectedValuesIds.includes(option.id))];
  //         }
  //         if (this.excludeOptionItemsIds) {
  //           this.options = [...this.options.filter((option: any) => !this.excludeOptionItemsIds.includes(option.id))];
  //         }
  //         this.initialOptions = [...this.options, ...res.data];
  //       }
  //       this.isLoading = false;
  //     });
  // }

  onSelectChange(event: any) {
    if (event) {
      const exists = this.values?.find((_val: any) => {
        if (event.hasOwnProperty('id')) {
          return _val.id === event.id;
        } else if (event.hasOwnProperty('code')) {
          return _val.code === event.code;
        }
        return false;
      });
      if (!exists) {
        this.setFormControl.emit(event);
        this.options = [...this.options.filter((option: any) => option !== event)]
      }
    }
    if (this.selectedValue) {
      setTimeout(() => { this.selectedValue = null; }, 100)
    }
  }

  removeItem(event: any) {
    this.removeDropdownItem.emit(event?.id);
    this.options = [event, ...this.options]
  }

  onKeyDown(event:any){
    event.stopPropagation();
  }
}
