import { Injectable } from '@angular/core';
import { ApiResponse, BaseApiService } from '../../api/base';
import { BehaviorSubject, exhaustMap, map, NEVER, Observable } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PagerData } from '../../services/companies-search/companies-search.service';
import { OperationResult } from '@models/operation-result';
import { Notification, NotificationsCount, NotificationsPager, NotificationTrigger } from '@models/notifications';

export const MAX_NOTIFICATIONS = 25;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends BaseApiService<Notification> {
  public page = 1;
  public hasNext = true;

  loadMore$ = new BehaviorSubject<any>(null);

  notifications$: Observable<NotificationsPager> = this.loadMore$.pipe(
    exhaustMap(() => this.hasNext
      ? this.get<PagerData<Notification>>(`/page/${ this.page }`).pipe(map(res => {
        if (res.data && this.hasNext) {
          const page = this.page;
          const { totalCount } = res.data;

          if (page * MAX_NOTIFICATIONS < totalCount) {
            ++this.page;
          } else {
            this.hasNext = false;
          }

          return { notifications: res.data.data, page };
        }

        return { notifications: [], page: 0 };
      }))
      : NEVER
    )
  );

  notificationsCount$ = this.get<NotificationsCount>('/count');

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/notifications', authService, router, messageService);
  }

  markAsRead(id: number): Observable<ApiResponse<OperationResult<number>>> {
    return this.put<OperationResult>(`/${ id }/read`)
      .pipe(map(a => ({
        statusCode: 200,
        data: { data: id, result: !!a.data?.result, exception: undefined, message: '' }
      })));
  }

  upsertNotificationTriggers(id: number, notificationTriggers: NotificationTrigger[]): Observable<ApiResponse<OperationResult>> {
    return this.post(`/companies/${ id }`, null, notificationTriggers);
  }
}