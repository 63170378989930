import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ImageCroppedEvent, LoadedImage } from "ngx-image-cropper";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { Nullable } from "primeng/ts-helpers";

@Component({
  selector: "bpc-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.scss"],
})
export class ImageUploaderComponent {
  @Output() toggleEditButton = new EventEmitter<boolean>();
  @Output() toggleSaveButton = new EventEmitter<boolean>();
  @Output() toggleImageLoading = new EventEmitter<boolean>();
  @Output() base64ImageEmitter = new EventEmitter<string>();
  @Output() profileImageFileEmitter = new EventEmitter<File>();
  imageChangedEvent: any = "";
  showSave: boolean = false;
  croppedImage: any = "";
  showDoneButton: boolean = false;
  blob: Blob | null | undefined;
  @Input() base64Image: string = "";
  @Input() showEditButton: boolean = false;
  @Input() showSaveButton: boolean = false;
  @ViewChild('fileInput') 
  fileInput: ElementRef | any;

  constructor(
    private sanitizer: DomSanitizer,
    public httpClient: HttpClient
  ) {}
  
  fileChangeEvent(event: any): void {
    this.showDoneButton = true;
    this.imageChangedEvent = event;
    this.showSaveButton = true;
    this.toggleSaveButton.emit(this.showSaveButton);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.blob = event.blob;

    if (this.blob != null){
      var profileImageFile = this.convertBlobToFile(this.blob, "profile-pic");
      this.profileImageFileEmitter.emit(profileImageFile);
    }
    
    this.showDoneButton = true;
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl ?? "");
    const blobUrl = event.objectUrl;
    if (blobUrl) {
      this.blobUrlToBase64(blobUrl).subscribe((res) => {
        if (res) {
          this.blobToBase64(res).then((base64) => {
            this.base64Image = base64;
            this.base64ImageEmitter.emit(this.base64Image);
          });
        }
      });
    }
  }

  convertBlobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, { type: blob.type });
  }

  imageLoaded(image: LoadedImage) {
    this.toggleImageLoading.emit(true);
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.toggleImageLoading.emit(false);
    // show message
  }
  handleEditButtonDisplay() {
    this.showEditButton = true;
    this.showSaveButton = true;
    this.showDoneButton = false;
    this.toggleEditButton.emit(this.showEditButton);
  }
  saveImage() {
    this.showEditButton = false;
    this.toggleEditButton.emit(this.showEditButton);
    this.showSaveButton = false;
    this.toggleSaveButton.emit(this.showSaveButton);
  }
  hideImage() {
    this.showEditButton = false;
    this.toggleEditButton.emit(this.showEditButton);
    this.showSaveButton = false;
    this.toggleSaveButton.emit(this.showSaveButton);
    this.base64ImageEmitter.emit(this.base64Image);
    this.imageChangedEvent = null; // Reset the imageChangedEvent to clear the cropped image.
    this.toggleImageLoading.emit(false);
  }  

  blobUrlToBase64(blobUrl: string) {
    const blob$ = this.httpClient.get(blobUrl, { responseType: "blob" });
    return blob$;
  }

  blobToBase64(blob: Blob | undefined): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!blob) {
        reject(new Error("Blob is undefined"));
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  toggleDoneButton(event: boolean) {
    this.showDoneButton = event;
  }
}
