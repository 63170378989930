import { Injectable } from '@angular/core';
import { ApiResponse, BaseApiService, serialize } from '../../api/base';
import { EMPTY, Observable, shareReplay } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { Investor } from '@models/auxiliary-company-models';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class InvestorService extends BaseApiService<Investor[]> {

  investors$ = this.get().pipe(shareReplay(1));
  investorsById$: Observable<ApiResponse<Investor[]>> = EMPTY;

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/investors', authService, router, messageService);
  }

  getInvestorsById(investorIds: Array<string>) {
    this.investorsById$ = this.get(`?${serialize({ investorIds })}`).pipe(shareReplay(1));
    return this.investorsById$;
  }

  getInvestorsByName(query: string) {
    return this.get(`/global?pageSize=500&term=${query}`);
  }

}
