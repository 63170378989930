<div class="sticky">
  <form [formGroup]="mainFilterForm" (ngSubmit)="onSubmit()">
    <section class="menu">
      <div class="filter-head">
        <div class="grid grid-nogutter">
          <div class="col-6">
            <h2>Filters</h2>
          </div>
          <div class="col-6">
            <a class="lbl-clear" title="Clear All" (click)="clearFilter()">Clear All</a>
          </div>
        </div>
      </div>
      <div class="grid grid-nogutter">
        <div class="col-12" [ngStyle]="getMenuStyle()">
          <ul class="vertical-nav">
            <li (click)="onMenuClick($event, 'Keywords')" [ngClass]="{ active: activeItem === 'Keywords' }">
              <ng-container>
                <a title=" Keywords">
                  <span class="label-a">
                    Keywords
                    <span *ngIf="filterCounts['keywordsGroup']" class="count">{{ filterCounts["keywordsGroup"] }}</span>
                  </span>
                  <i class="pi pi-chevron-right"></i>
                </a>
              </ng-container>
            </li>
            <li
              (click)="onMenuClick($event, 'CompanyOverview')"
              [ngClass]="{ active: activeItem === 'CompanyOverview' }"
            >
              <a title="Company Overview">
                <span class="label-a">
                  Company Overview
                  <span *ngIf="filterCounts['companyOverviewForm']" class="count">{{
                    filterCounts["companyOverviewForm"]
                  }}</span>
                </span>

                <i class="pi pi-chevron-right"></i>
              </a>
            </li>
            <!-- Exclusive to sourcescrub -->
            <!-- <li (click)="onMenuClick($event, 'Ownership')" [ngClass]="{ active: activeItem === 'Ownership' }">
              <ng-container formGroupName="ownershipForm">
              <a title="Ownership">
                <span class="label-a">
                  Ownership
                  <span *ngIf="filterCounts['ownershipForm']" class="count">{{ filterCounts["ownershipForm"] }}</span>
                </span>
                <i class="pi pi-chevron-right"></i>
              </a>
            </li> -->

            <!-- Exclusive to sourcescrub -->
            <!-- <li (click)="onMenuClick($event, 'Funding')" [ngClass]="{ active: activeItem === 'Funding' }">
              <a title="Funding">
                <span class="label-a">
                  Funding
                  <span *ngIf="filterCounts['fundingForm']" class="count">{{ filterCounts["fundingForm"] }}</span>
                </span>

                <i class="pi pi-chevron-right"></i>
              </a>
            </li> -->

            <!-- Exclusive to sourcescrub -->
            <!-- <li (click)="onMenuClick($event, 'Investors')" [ngClass]="{ active: activeItem === 'Investors' }">
              <a title="Investors">
                <span class="label-a">
                  Investors
                  <span *ngIf="filterCounts['investorsForm']" class="count">{{ filterCounts["investorsForm"] }}</span>
                </span>
                <i class="pi pi-chevron-right"></i>
              </a>
            </li> -->

            <!-- <li (click)="onMenuClick($event, 'EndMarkets')" [ngClass]="{ active: activeItem === 'EndMarkets' }">
              <ng-container formGroupName="endMarketsForm">
                <a title="End Markets">
                  <span class="label-a">
                    End Markets
                    <span *ngIf="filterCounts['endMarketsForm']" class="count">{{filterCounts['endMarketsForm']}}</span>
                  </span>

                  <i class="pi pi-chevron-right"></i>
                </a>
                <ul
                  *ngIf="activeItem === 'EndMarkets'"
                  class="inner-ul sub-menu"
                  [ngStyle]="getInnerUlStyle()"
                  (click)="$event.stopPropagation()"
                >
                  <li>
                    <div class="f-i-wrapper">
                      <div class="grid grid-nogutter">
                        <div class="col-12">
                          <a class="clear-btn" title="Clear All">Clear All</a>
                        </div>
                      </div>
                      <div class="grid">
                        <div class="col-12">
                          <label class="w-100"> Include End Markets </label>
                          
                          <input
                            formControlName="endMarketsInclude"
                            class="w-100"
                            type="text"
                            pInputText
                            value="Label"
                          />
                        </div>

                        <div class="col-12">
                          <label class="w-100"> Exclude End Markets </label>
                          
                          <input
                            formControlName="endMarketsExclude"
                            class="w-100"
                            type="text"
                            pInputText
                            value="Label"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-container>
            </li> -->
            <!-- <li (click)="onMenuClick($event, 'ProductsServices')" [ngClass]="{ active: activeItem === 'ProductsServices' }">
              <ng-container formGroupName="productsServicesForm">
                <a title="Products & Services">
                  <span class="label-a">
                    Products & Services
                    <span *ngIf="filterCounts['productsServicesForm']" class="count">{{filterCounts['productsServicesForm']}}</span>
                  </span>

                  <i class="pi pi-chevron-right"></i>
                </a>
                <ul
                  *ngIf="activeItem === 'ProductsServices'"
                  class="inner-ul sub-menu"
                  [ngStyle]="getInnerUlStyle()"
                  (click)="$event.stopPropagation()"
                >
                  <li>
                    <div class="f-i-wrapper">
                      <div class="grid grid-nogutter">
                        <div class="col-12">
                          <a class="clear-btn" title="Clear All">Clear All</a>
                        </div>
                      </div>
                      <div class="grid">
                        <div class="col-12">
                          <label class="w-100"> Include </label>
                          
                          <p-autoComplete
                            industries
                            formControlName="productsServicesInclude"
                            class="w-100"
                          ></p-autoComplete>
                        </div>
                        <div class="col-12">
                          <div class="flex">
                            <span class="tags">
                              <span>Public Relations and Communications</span>
                              <i class="ml-1.5 pi pi-times"></i>
                            </span>
                            <span class="tags">
                              <span>Public Relations and Communications</span>
                              <i class="ml-1.5 pi pi-times"></i>
                            </span>
                          </div>
                        </div>

                        <div class="col-12">
                          <label class="w-100"> Exclude </label>
                          
                          <p-autoComplete
                            industries
                            formControlName="productsServicesExclude"
                            class="w-100"
                          ></p-autoComplete>
                        </div>
                        <div class="col-12">
                          <div class="flex">
                            <span class="tags">
                              <span>Public Relations and Communications</span>
                              <i class="ml-1.5 pi pi-times"></i>
                            </span>
                            <span class="tags">
                              <span>Public Relations and Communications</span>
                              <i class="ml-1.5 pi pi-times"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-container>
            </li> -->

            <!-- SourceScrub Removal -->
            <!-- <li
              (click)="onMenuClick($event, 'EstimatedRevenue')"
              [ngClass]="{ active: activeItem === 'EstimatedRevenue' }"
            >
              <a title="Products & Services">
                <span class="label-a">
                  Estimated Revenue
                  <span *ngIf="filterCounts['estimatedRevenueForm']" class="count">{{
                    filterCounts["estimatedRevenueForm"]
                  }}</span>
                </span>

                <i class="pi pi-chevron-right"></i>
              </a>
            </li> -->

            <li (click)="onMenuClick($event, 'Tags')" [ngClass]="{ active: activeItem === 'Tags' }">
              <a title="Tags">
                <span class="label-a">
                  Tags
                  <span *ngIf="filterCounts['tagsForm']" class="count">{{ filterCounts["tagsForm"] }}</span>
                </span>

                <i class="pi pi-chevron-right"></i>
              </a>
            </li>
            <li
              class="last"
              (click)="onMenuClick($event, 'BPCCoverage')"
              [ngClass]="{ active: activeItem === 'BPCCoverage' }"
            >
              <a title="BPC Coverage">
                <span class="label-a">
                  BPC Coverage
                  <span *ngIf="filterCounts['bpcCoverageForm']" class="count">{{
                    filterCounts["bpcCoverageForm"]
                  }}</span>
                </span>

                <i class="pi pi-chevron-right"></i>
              </a>
            </li>
            <!-- <button class="btn-save" [disabled]="mainFilterForm.invalid" (click)="onSubmit()">Search</button> -->
            <li class="filter-buttons">
              <ul>
                <li
                  class="btn-save"
                  [ngClass]="{ disabled: isFormInvalid }"
                  (click)="isFormInvalid ? null : onSubmit()"
                >
                  <a title="Search"> Search </a>
                </li>
                <!-- <li
                  class="search-button"
                  [ngClass]="{ disabled: isFormInvalid }"
                  (click)="isFormInvalid ? null : displaySavedSearchModal()"
                >
                  <a title="Save"> Save Filter </a>
                </li> -->
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <ul
      *ngIf="activeItem === 'Keywords'"
      class="inner-ul sub-menu scroll-enable filter-submenu-body"
      [ngStyle]="getInnerUlStyle()"
      (click)="$event.stopPropagation()"
      formArrayName="keywordsGroup"
    >
      <div></div>
      <li>
        <div class="grid grid-nogutter">
          <div class="col-12">
            <a class="clear-btn" title="Clear All" (click)="clearFormFilters('keywords')">Clear All</a>
          </div>
        </div>
        <div class="position-relative" *ngFor="let kgroup of keywordsGroup().controls; let keywordIndex = index">
          <div [formGroupName]="keywordIndex">
            <div class="grid grid-nogutter mg-tb-20" *ngIf="keywordIndex !== 0">
              <div style="width: 30px" class="col-fixed" *ngIf="keywordsGroup().controls.length > 1">
                <i
                  class="pi pi-times secondary-icon action-plus mgr-0 cursor-pointer"
                  (click)="removeKeywordGroup(keywordIndex)"
                ></i>
              </div>
              <div class="col flex align-items-center justify-content-center">
                <span class="p-buttonset black show-lines">
                  <p-selectButton
                    [options]="logicalOperators"
                    optionLabel="name"
                    optionValue="id"
                    formControlName="outerOperator"
                  ></p-selectButton>
                </span>
              </div>
            </div>
            <div class="f-i-wrapper keywords">
              <div class="grid">
                <div class="col flex align-items-center justify-content-center flex-wrap">
                  <input
                    type="radio"
                    formControlName="includeOrExclude"
                    [value]="includeExclude.INCLUDE"
                    inputId="includes1"
                  />
                  <label class="inline-label" for="inclusion" class="ml-2 inline-label">Includes</label>
                </div>
                <div class="col flex align-items-center justify-content-center flex-wrap">
                  <input
                    type="radio"
                    formControlName="includeOrExclude"
                    [value]="includeExclude.EXCLUDE"
                    inputId="includes2"
                  />
                  <label class="inline-label" for="inclusion" class="ml-2 inline-label">Excludes</label>
                </div>
                <div class="col flex justify-content-end flex-wrap align-items-center">
                  <i
                    class="pi pi-info-circle info-icon"
                    (mouseenter)="overlayPanel.show($event)"
                    (mouseleave)="overlayPanel.hide()"
                  ></i>
                  <p-overlayPanel #overlayPanel>
                    <!-- Content to be displayed in the overlay panel -->
                    <p>
                      <strong>Eg:</strong>
                      <span class="clr-blue">name:</span>Canonical&nbsp;
                      <span class="clr-orange f-medium">AND</span> &nbsp; <span class="clr-blue">name:</span>Microsoft
                      <span class="clr-blue">Description:</span>Software&nbsp;
                      <span class="clr-orange f-medium">OR</span>&nbsp;
                      <span class="clr-blue">Description:</span>Critical
                    </p>
                  </p-overlayPanel>
                </div>
              </div>
              <hr class="hr-90" />
              <!-- Remove parent -->

              <div formArrayName="filterLines">
                <div *ngFor="let filter of keywordFilters(keywordIndex).controls; let filterIndex = index">
                  <div [formGroupName]="filterIndex">
                    <div class="grid grid-nogutter" *ngIf="filterIndex !== 0">
                      <div class="col mg-tb-20">
                        <p-selectButton
                          styleClass="p-buttonset black show-lines"
                          optionLabel="name"
                          optionValue="id"
                          [options]="logicalOperators"
                          formControlName="innerOperator"
                        ></p-selectButton>
                      </div>
                    </div>
                    <div class="grid grid-nogutter">
                      <div class="col">
                        <div class="grid grid-nogutter">
                          <div class="col-6">
                            <input
                              formControlName="keyword"
                              type="text"
                              class="w-100 right-cut"
                              appCompanyName
                              pInputText
                              value="Enter Keyword"
                              placeholder="Enter Keyword"
                            />
                            <p *ngIf="filter?.invalid" class="small cursor-default px-3 py-2 p-error block">
                              Invalid input value
                            </p>
                          </div>
                          <div class="col-6">
                            <p-dropdown [options]="options" formControlName="field" class="w-100 left-cut"></p-dropdown>
                          </div>
                        </div>
                      </div>
                      <div style="width: 30px" class="col-fixed flex align-items-center justify-content-center">
                        <i
                          class="pi pi-times cursor-pointer"
                          (click)="removeFilter(keywordIndex, filterIndex)"
                          *ngIf="keywordFilters(keywordIndex).controls.length > 1"
                        >
                        </i>
                      </div>
                      <div
                        class="col-12"
                        *ngIf="
                          filterIndex === keywordFilters(keywordIndex).controls.length - 1 &&
                          keywordFilterCount < keywordFilterLimit
                        "
                      >
                        <i
                          (click)="addFilterRow(keywordIndex)"
                          class="pi pi-plus action-plus left-icon cursor-pointer"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <i
            class="pi pi-plus action-plus cursor-pointer"
            (click)="addKeywordGroup()"
            *ngIf="keywordIndex === keywordsGroup().controls.length - 1 && keywordFilterCount < keywordFilterLimit"
          ></i>
        </div>
      </li>
    </ul>
    <ng-container formGroupName="companyOverviewForm">
      <ul
        *ngIf="activeItem === 'CompanyOverview'"
        class="inner-ul sub-menu scroll-enable filter-submenu-body"
        [ngStyle]="getInnerUlStyle()"
        (click)="$event.stopPropagation()"
      >
        <li>
          <div class="f-i-wrapper">
            <div class="grid grid-nogutter">
              <div class="col-12">
                <a class="clear-btn" title="Clear All" (click)="clearFormFilters('companyOverview')">Clear All</a>
              </div>
            </div>
            <div class="grid grid-no-tb-gutter">

              <!-- SourceScrub Exclusive -->
              <!-- <div class="col-12 mg-tb-12">
                <strong>Industries</strong>
              </div>
              <div class="col-12">
                <bpc-custom-dropdown-search
                  class="w-full"
                  [body]="'body'"
                  [values]="fieldControlValues(dropdownTypes.industryInclude)"
                  [type]="dropdownTypes.industryInclude"
                  [label]="'Include'"
                  [runtimeSearch]="false"
                  [excludeOptions]="fieldControlValues(dropdownTypes.industryExclude)"
                  (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.industryInclude)"
                  (setFormControl)="setFormControlValue($event, dropdownTypes.industryInclude)"
                ></bpc-custom-dropdown-search>
              </div>
              <div class="col-12">
                <bpc-custom-dropdown-search
                  class="w-full"
                  [body]="'body'"
                  [values]="fieldControlValues(dropdownTypes.industryExclude)"
                  [type]="dropdownTypes.industryExclude"
                  [label]="'Exclude'"
                  [runtimeSearch]="false"
                  [excludeOptions]="fieldControlValues(dropdownTypes.industryInclude)"
                  (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.industryExclude)"
                  (setFormControl)="setFormControlValue($event, dropdownTypes.industryExclude)"
                ></bpc-custom-dropdown-search>
              </div> -->

              <div class="col-12">
                <bpc-custom-dropdown-search
                  class="w-full"
                  [body]="'body'"
                  [values]="fieldControlValues(dropdownTypes.companyLocation)"
                  [type]="dropdownTypes.companyLocation"
                  [label]="'Location'"
                  [runtimeSearch]="false"
                  (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.companyLocation)"
                  (setFormControl)="setFormControlValue($event, dropdownTypes.companyLocation)"
                ></bpc-custom-dropdown-search>
              </div>

              <!-- SourceScrub Exclusive -->
              <!-- <div class="col-12 mg-tb-12">
                <strong>Employees</strong>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Min</label>
                <p-inputNumber
                  formControlName="companyOverviewMinEmployee"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  [step]="1"
                  [min]="0"
                  [max]="999999999999"
                  (keyup.enter)="onSubmit()"
                  placeholder="-"
                ></p-inputNumber>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Max</label>
                <p-inputNumber
                  formControlName="companyOverviewMaxEmployee"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  [step]="1"
                  [min]="0"
                  [max]="999999999999"
                  placeholder="-"
                ></p-inputNumber>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Growth Rate Min</label>
                <p-inputNumber
                  formControlName="companyOverviewGrowthRateMin"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  [step]="1"
                  [min]="-999999999999"
                  [max]="999999999999"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  placeholder="-"
                ></p-inputNumber>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Growth Rate Max</label>
                <p-inputNumber
                  formControlName="companyOverviewGrowthRateMax"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  [step]="1"
                  [min]="-999999999999"
                  [max]="999999999999"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  placeholder="-"
                ></p-inputNumber>
              </div> -->

              <!-- <div class="col-12">
            <span class="p-buttonset">
              <button formControlName="companyOverviewButton1" pButton pRipple label="1 Month"></button>
              <button formControlName="companyOverviewButton3" pButton pRipple label="3 Month"></button>
              <button formControlName="companyOverviewButton6" pButton pRipple label="6 Month"></button>
              <button
                formControlName="companyOverviewButton1Year"
                pButton
                pRipple
                label="1 Year"
              ></button>
            </span>
          </div> -->
          
          <!-- SourceScrub Exclusive  -->
              <!-- <div class="col-12 mg-tb-12">
                <strong>Founding</strong>
              </div>
              <div class="col-6 mb-20">
                <label class="w-100">Start Year</label>
                <p-calendar
                  formControlName="companyOverviewStartYear"
                  [showIcon]="true"
                  view="year"
                  dateFormat="yy"
                  styleClass="calender"
                  panelStyleClass="w-year"
                  placeholder="Select Start Year"
                  [maxDate]="
                    mainFilterForm.value.companyOverviewForm?.companyOverviewEndYear
                      ? mainFilterForm.value.companyOverviewForm?.companyOverviewEndYear
                      : maxAllowableDate
                  "
                  appendTo="body"
                ></p-calendar>
              </div>
              <div class="col-6 mb-20">
                <label class="w-100">End Year</label>
                <p-calendar
                  formControlName="companyOverviewEndYear"
                  styleClass="calender"
                  panelStyleClass="w-year"
                  [showIcon]="true"
                  view="year"
                  dateFormat="yy"
                  placeholder="Select End Year"
                  [minDate]="mainFilterForm.value.companyOverviewForm?.companyOverviewStartYear"
                  [maxDate]="maxAllowableDate"
                  appendTo="body"
                ></p-calendar>
              </div> -->
            </div>
          </div>
        </li>
      </ul>
    </ng-container>
    <ul
      *ngIf="activeItem === 'Ownership'"
      class="inner-ul sub-menu scroll-enable filter-submenu-body"
      [ngStyle]="getInnerUlStyle()"
      (click)="$event.stopPropagation()"
    >
      <li>
        <div class="f-i-wrapper">
          <div class="grid grid-nogutter">
            <div class="col-12">
              <a class="clear-btn" title="Clear All" (click)="clearFormFilters('ownership')">Clear All</a>
            </div>
          </div>
          <!-- <div class="parent-checbox">
            <div class="child-checbox"></div>
          </div> -->
          <!-- <div class="grid grid-nogutter">
            <div class="col-12">
              <div class="parent-checbox">
                <p-checkbox
                  formControlName="ownershipPrivate"
                  name="groupname"
                  value="Private"
                  label="Private"
                ></p-checkbox>
                <div class="child-checbox">
                  <p-checkbox
                    formControlName="ownershipHeadQuarter"
                    name="groupname"
                    value="Include all non-headquarter locations"
                    label="Include all non-headquarter locations"
                  ></p-checkbox>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="parent-checbox">
                <p-checkbox
                  formControlName="ownershipInvestorBacked"
                  name="groupname"
                  value="Investor Backed"
                  label="Investor Backed"
                ></p-checkbox>
                <div class="child-checbox">
                  <p-checkbox
                    formControlName="ownershipPrivateEquityBacked"
                    name="groupname"
                    value="Private Equity Backed"
                    label="Private Equity Backed"
                  ></p-checkbox>

                  <p-checkbox
                    formControlName="ownershipEarlyStageFunding"
                    name="groupname"
                    value="Early-Stage Funding"
                    label="Early-Stage Funding"
                  ></p-checkbox>

                  <p-checkbox
                    formControlName="ownershipLateStageFunding"
                    name="groupname"
                    value="Late-Stage Funding"
                    label="Late-Stage Funding"
                  ></p-checkbox>

                  <p-checkbox
                    formControlName="ownershipPreIpoFunding"
                    name="groupname"
                    value="Pre-IPO Funding"
                    label="Pre-IPO Funding"
                  ></p-checkbox>

                  <p-checkbox
                    formControlName="ownershipOtherFunding"
                    name="groupname"
                    value="Other funding "
                    label="Other funding "
                  ></p-checkbox>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="parent-checbox">
                <p-checkbox
                  formControlName="ownershipPrivateSubsidiary"
                  name="groupname"
                  value="Private Subsidiary "
                  label="Private Subsidiary "
                ></p-checkbox>
                <div class="child-checbox">
                  <p-checkbox
                    formControlName="ownershipPrivateSub"
                    name="groupname"
                    value="Private Sub"
                    label="Private Sub"
                  ></p-checkbox>

                  <p-checkbox
                    formControlName="ownershipPrivateEquityAddOn"
                    name="groupname"
                    value="Private Equity Add-On"
                    label="Private Equity Add-On"
                  ></p-checkbox>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="parent-checbox">
                <p-checkbox
                  formControlName="ownershipPublic"
                  name="groupname"
                  value="Public"
                  label="Public"
                ></p-checkbox>
                <div class="child-checbox">
                  <p-checkbox
                    formControlName="ownershipPublicCompany"
                    name="groupname"
                    value="Public Company"
                    label="Public Company"
                  ></p-checkbox>

                  <p-checkbox
                    formControlName="ownershipPublicSub"
                    name="groupname"
                    value="Public Sub "
                    label="Public Sub "
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div> -->
          <div class="grid grid-nogutter">
            <ng-container *ngFor="let group of grouped | keyvalue">
              <div class="col-12">
                <div class="parent-checbox">
                  <p-checkbox
                    name="groupname"
                    value="Private"
                    (onChange)="setAll(group.key, $event)"
                    [label]="group.key"
                    [(ngModel)]="group.value.isChecked"
                    [ngModelOptions]="{ standalone: true }"
                  ></p-checkbox>
                  <div class="child-checbox">
                    <ng-container *ngFor="let g of group.value.companies">
                      <p-checkbox
                        name="groupname"
                        [value]="g.id"
                        [label]="g.name"
                        [(ngModel)]="g.isChecked"
                        [ngModelOptions]="{ standalone: true }"
                        (onChange)="updateIndividual(group.key, $event)"
                      ></p-checkbox>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </li>
    </ul>
    <!-- Exclusive to sourcescrub -->
    <!-- <ng-container formGroupName="fundingForm">
      <ul
        *ngIf="activeItem === 'Funding'"
        class="inner-ul sub-menu filter-submenu-body"
        [ngStyle]="getInnerUlStyle()"
        (click)="$event.stopPropagation()"
      >
        <li>
          <div class="f-i-wrapper">
            <div class="grid grid-nogutter">
              <div class="col-12">
                <a class="clear-btn" title="Clear All" (click)="clearFormFilters('funding')">Clear All</a>
              </div>
            </div>
            <div class="grid grid-no-tb-gutter">
              <div class="col-12 mg-b-16">
                <strong>Total Funding</strong>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Min (Million)</label>
                <p-inputNumber
                  formControlName="fundingTotalMin"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  [step]="1"
                  [min]="0"
                  [max]="999999999999"
                  placeholder="-"
                ></p-inputNumber>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Max (Million)</label>
                <p-inputNumber
                  formControlName="fundingTotalMax"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  [step]="1"
                  [min]="0"
                  [max]="999999999999"
                  placeholder="-"
                ></p-inputNumber>
              </div>

              <div class="col-12 mg-b-16">
                <strong>Last Funding Round</strong>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Min (Million)</label>
                <p-inputNumber
                  formControlName="fundingLastMin"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  [step]="1"
                  [min]="0"
                  [max]="999999999999"
                  placeholder="-"
                ></p-inputNumber>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Max (Million)</label>
                <p-inputNumber
                  formControlName="fundingLastMax"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  [step]="1"
                  [min]="0"
                  [max]="999999999999"
                  placeholder="-"
                ></p-inputNumber>
              </div>
              <div class="col-12 mg-b-16">
                <strong>Last Funding Date</strong>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Start Date</label>
                <p-calendar
                  styleClass="calender"
                  formControlName="fundingLastDateStart"
                  view="month"
                  dateFormat="M yy"
                  [showIcon]="true"
                  placeholder="Select Start Date"
                  [maxDate]="
                    mainFilterForm.value.fundingForm?.fundingLastDateEnd
                      ? mainFilterForm.value.fundingForm?.fundingLastDateEnd
                      : maxAllowableDate
                  "
                  appendTo="body"
                ></p-calendar>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">End Date</label>
                <p-calendar
                  styleClass="calender"
                  formControlName="fundingLastDateEnd"
                  view="month"
                  dateFormat="M yy"
                  [showIcon]="true"
                  placeholder="Select End Date"
                  [minDate]="mainFilterForm.value.fundingForm?.fundingLastDateStart"
                  [maxDate]="maxAllowableDate"
                  appendTo="body"
                ></p-calendar>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </ng-container> -->
    
    <!-- Exclusive to sourcescrub -->
    <!-- <ng-container formGroupName="investorsForm">
      <ul
        *ngIf="activeItem === 'Investors'"
        class="inner-ul sub-menu scroll-enable filter-submenu-body"
        [ngStyle]="getInnerUlStyle()"
        (click)="$event.stopPropagation()"
      >
        <li>
          <div class="f-i-wrapper">
            <div class="grid grid-nogutter">
              <div class="col-12">
                <a class="clear-btn" title="Clear All" (click)="clearFormFilters('investors')">Clear All</a>
              </div>
            </div>
            <div class="grid">
              <bpc-custom-dropdown-search
                class="w-full"
                [body]="'body'"
                [values]="fieldControlValues(dropdownTypes.investorInclude)"
                [type]="dropdownTypes.investorInclude"
                [label]="'Include'"
                [runtimeSearch]="true"
                [excludeOptions]="fieldControlValues(dropdownTypes.investorExclude)"
                (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.investorInclude)"
                (setFormControl)="setFormControlValue($event, dropdownTypes.investorInclude)"
              ></bpc-custom-dropdown-search>

              <bpc-custom-dropdown-search
                class="w-full"
                [body]="'body'"
                [values]="fieldControlValues(dropdownTypes.investorExclude)"
                [type]="dropdownTypes.investorExclude"
                [label]="'Exclude'"
                [runtimeSearch]="true"
                [excludeOptions]="fieldControlValues(dropdownTypes.investorInclude)"
                (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.investorExclude)"
                (setFormControl)="setFormControlValue($event, dropdownTypes.investorExclude)"
              ></bpc-custom-dropdown-search>
            </div>
          </div>
        </li>
      </ul>
    </ng-container> -->
    
    <!-- SourceScrub Exclusive -->
    <!-- <ng-container formGroupName="estimatedRevenueForm">
      <ul
        *ngIf="activeItem === 'EstimatedRevenue'"
        class="inner-ul sub-menu scroll-enable filter-submenu-body"
        [ngStyle]="getInnerUlStyle()"
        (click)="$event.stopPropagation()"
      >
        <li>
          <div class="f-i-wrapper">
            <div class="grid grid-nogutter">
              <div class="col-12">
                <a class="clear-btn" title="Clear All" (click)="clearFormFilters('estimatedRevenue')">Clear All</a>
              </div>
            </div>
            <div class="grid">
              <div class="col-12 mg-tb-12">
                <strong>Total Amount</strong>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Min (Million)</label>
                <p-inputNumber
                  formControlName="estimatedRevenueMin"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  [step]="1"
                  [min]="0"
                  [max]="999999999999"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  placeholder="-"
                ></p-inputNumber>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Max (Million)</label>
                <p-inputNumber
                  formControlName="estimatedRevenueMax"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  [step]="1"
                  [min]="0"
                  [max]="999999999999"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  placeholder="-"
                ></p-inputNumber>
              </div>

              <div class="col-12 mg-tb-12">
                <strong>Growth</strong>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Min (%)</label>
                <p-inputNumber
                  formControlName="estimatedRevenueGrowthMin"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  [step]="1"
                  [min]="0"
                  [max]="999999999999"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  placeholder="-"
                ></p-inputNumber>
              </div>

              <div class="col-6 mb-20">
                <label class="w-100">Max (%)</label>
                <p-inputNumber
                  formControlName="estimatedRevenueGrowthMax"
                  styleClass="btn-input"
                  decrementButtonClass="p-button-decrement"
                  incrementButtonClass="p-button-increment"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  [step]="1"
                  [min]="0"
                  [max]="999999999999"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  placeholder="-"
                ></p-inputNumber>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </ng-container> -->
    <ng-container formGroupName="tagsForm">
      <ul
        *ngIf="activeItem === 'Tags'"
        class="inner-ul sub-menu scroll-enable filter-submenu-body"
        [ngStyle]="getInnerUlStyle()"
        (click)="$event.stopPropagation()"
      >
        <li>
          <div class="f-i-wrapper">
            <div class="grid grid-nogutter">
              <div class="col-12">
                <a class="clear-btn" title="Clear All" (click)="clearFormFilters('tags')">Clear All</a>
              </div>
            </div>
            <div class="grid grid-nogutter mg-tb-12">
              <!-- <div class="col-12">
            <div class="grid grid-no-tb-gutter">
              <div class="col-6 mb-20">
                <label class="w-100">Start Date</label>
                <p-calendar
                  styleClass="calender"
                  formControlName="tagsTaggedSince"
                  [showIcon]="true"
                  placeholder="Select Start Date"
                  [maxDate]="
                    mainFilterForm.value.tagsForm?.tagsTaggedBefore
                      ? mainFilterForm.value.tagsForm?.tagsTaggedBefore
                      : maxAllowableDate
                  "
                ></p-calendar>
              </div>
              <div class="col-6 mb-20">
                <label class="w-100">End Date</label>
                <p-calendar
                  styleClass="calender"
                  formControlName="tagsTaggedBefore"
                  [showIcon]="true"
                  styleClass="calender"
                  placeholder="Select End Date"
                  [minDate]="mainFilterForm.value.tagsForm?.tagsTaggedSince"
                  [maxDate]="maxAllowableDate"
                ></p-calendar>
              </div>
            </div>
          </div> -->

              <bpc-custom-dropdown-search
                class="w-full"
                [body]="'body'"
                [values]="fieldControlValues(dropdownTypes.tagInclude)"
                [type]="dropdownTypes.tagInclude"
                [label]="'Include'"
                [runtimeSearch]="true"
                [excludeOptions]="fieldControlValues(dropdownTypes.tagExclude)"
                [placeholder]="'Tags Include'"
                (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.tagInclude)"
                (setFormControl)="setFormControlValue($event, dropdownTypes.tagInclude)"
              ></bpc-custom-dropdown-search>

              <bpc-custom-dropdown-search
                class="w-full"
                [body]="'body'"
                [values]="fieldControlValues(dropdownTypes.tagExclude)"
                [type]="dropdownTypes.tagExclude"
                [label]="'Exclude'"
                [runtimeSearch]="true"
                [excludeOptions]="fieldControlValues(dropdownTypes.tagInclude)"
                [placeholder]="'Tags Exclude'"
                (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.tagExclude)"
                (setFormControl)="setFormControlValue($event, dropdownTypes.tagExclude)"
              ></bpc-custom-dropdown-search>
            </div>
          </div>
        </li>
      </ul>
    </ng-container>
    <ng-container formGroupName="bpcCoverageForm">
      <ul
        *ngIf="activeItem === 'BPCCoverage'"
        class="inner-ul sub-menu scroll-enable filter-submenu-body"
        [ngStyle]="getInnerUlStyle()"
        (click)="$event.stopPropagation()"
      >
        <li>
          <div class="f-i-wrapper">
            <div class="grid grid-nogutter">
              <div class="col-12">
                <a class="clear-btn" title="Clear All" (click)="clearFormFilters('bpcCoverage')">Clear All</a>
              </div>
            </div>
            <div class="grid grid-nogutter mg-tb-12">
              <div class="col-12 mb-20">
                <p-checkbox
                  formControlName="bpcNoExistingCoverage"
                  name="groupname"
                  value="No Existing BPC Coverage"
                  label="No Existing BPC Coverage"
                  [trueValue]="true"
                  [falseValue]="null"
                ></p-checkbox>
              </div>

              <bpc-custom-dropdown-search
                class="w-full"
                [body]="'body'"
                [values]="fieldControlValues(dropdownTypes.coveragePersonInclude)"
                [type]="dropdownTypes.coveragePersonInclude"
                [label]="'Include Coverage Person'"
                [runtimeSearch]="false"
                [excludeOptions]="fieldControlValues(dropdownTypes.coveragePersonExclude)"
                (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.coveragePersonInclude)"
                (setFormControl)="setFormControlValue($event, dropdownTypes.coveragePersonInclude)"
              ></bpc-custom-dropdown-search>

              <bpc-custom-dropdown-search
                class="w-full"
                [body]="'body'"
                [values]="fieldControlValues(dropdownTypes.coveragePersonExclude)"
                [type]="dropdownTypes.coveragePersonExclude"
                [label]="'Exclude Coverage Person'"
                [runtimeSearch]="false"
                [excludeOptions]="fieldControlValues(dropdownTypes.coveragePersonInclude)"
                (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.coveragePersonExclude)"
                (setFormControl)="setFormControlValue($event, dropdownTypes.coveragePersonExclude)"
              ></bpc-custom-dropdown-search>

              <bpc-custom-dropdown-search
                class="w-full"
                [body]="'body'"
                [values]="fieldControlValues(dropdownTypes.companyStatusInclude)"
                [type]="dropdownTypes.companyStatusInclude"
                [label]="'Dealcloud Company Stage Include'"
                [runtimeSearch]="false"
                [excludeOptions]="fieldControlValues(dropdownTypes.companyStatusExclude)"
                (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.companyStatusInclude)"
                (setFormControl)="setFormControlValue($event, dropdownTypes.companyStatusInclude)"
              ></bpc-custom-dropdown-search>

              <!-- new field -->
              <bpc-custom-dropdown-search
                class="w-full"
                [body]="'body'"
                [values]="fieldControlValues(dropdownTypes.companyStatusExclude)"
                [type]="dropdownTypes.companyStatusExclude"
                [label]="'Dealcloud Company Stage Exclude'"
                [runtimeSearch]="false"
                [excludeOptions]="fieldControlValues(dropdownTypes.companyStatusInclude)"
                (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.companyStatusExclude)"
                (setFormControl)="setFormControlValue($event, dropdownTypes.companyStatusExclude)"
              ></bpc-custom-dropdown-search>

              <!-- <div class="col-6">
            <label>DC Start Date</label> <br />
            <p-calendar formControlName="bpcDCStartDate" styleClass="calender" [showIcon]="true"></p-calendar>
          </div>
          <div class="col-6">
            <label>DC End Date</label>
            <p-calendar formControlName="bpcDCEndDate" styleClass="calender" [showIcon]="true"></p-calendar>
          </div> -->

              <div class="col-6 mb-20">
                <label class="w-100">Last Interaction From</label>
                <p-calendar
                  formControlName="bpcLastInteractionFrom"
                  [showIcon]="true"
                  styleClass="calender mr-1"
                  placeholder="Select Date"
                  dateFormat="dd M yy"
                  [style]="{ overflow: 'visible', 'z-index': '9999', opacity: '1' }"
                  [maxDate]="
                    mainFilterForm.value.bpcCoverageForm?.bpcLastInteractionTo
                      ? mainFilterForm.value.bpcCoverageForm?.bpcLastInteractionTo
                      : maxAllowableDate
                  "
                  appendTo="body"
                ></p-calendar>
              </div>
              <div class="col-6 mb-20">
                <label class="w-100">Last Interaction To</label>
                <p-calendar
                  formControlName="bpcLastInteractionTo"
                  [showIcon]="true"
                  styleClass="calender ml-1"
                  panelStyleClass="right-align"
                  placeholder="Select Date"
                  dateFormat="dd M yy"
                  [minDate]="mainFilterForm.value.bpcCoverageForm?.bpcLastInteractionFrom"
                  [maxDate]="maxAllowableDate"
                  appendTo="body"
                ></p-calendar>
              </div>

              <div class="col-12 mb-20">
                <div class="grid grid-no-tb-gutter">
                  <div class="col-4">
                    <p-radioButton
                      formControlName="bpcInteractionType"
                      name="bpcInteractionType"
                      [value]="interactionType.All"
                      inputId="ingredient3"
                    ></p-radioButton>
                    <label for="ingredient3" class="ml-2 inline-label">All</label>
                  </div>
                  <div class="col-4 mb-20">
                    <p-radioButton
                      formControlName="bpcInteractionType"
                      name="bpcInteractionType"
                      [value]="interactionType.Email"
                      inputId="ingredient1"
                    ></p-radioButton>
                    <label class="" for="ingredient1" class="ml-2 inline-label">By Email</label>
                  </div>
                  <div class="col-4 mb-20">
                    <p-radioButton
                      formControlName="bpcInteractionType"
                      name="bpcInteractionType"
                      [value]="interactionType.Phone"
                      inputId="ingredient2"
                    ></p-radioButton>
                    <label for="ingredient2" class="ml-2 inline-label">By Call</label>
                  </div>
                  <div class="col-4 mb-20">
                    <p-radioButton
                      formControlName="bpcInteractionType"
                      name="bpcInteractionType"
                      [value]="interactionType.VideoCall"
                      inputId="ingredient2"
                    ></p-radioButton>
                    <label for="ingredient2" class="ml-2 inline-label">By Video</label>
                  </div>
                  <div class="col-4">
                    <p-radioButton
                      formControlName="bpcInteractionType"
                      name="bpcInteractionType"
                      [value]="interactionType.Meeting"
                      inputId="ingredient2"
                    ></p-radioButton>
                    <label for="ingredient2" class="ml-2 inline-label">By Meeting</label>
                  </div>

                  <div class="col-12">
                    <p-radioButton
                      formControlName="bpcInteractionType"
                      name="bpcInteractionType"
                      [value]="interactionType.NeverContacted"
                      inputId="ingredient4"
                    ></p-radioButton>
                    <label for="ingredient4" class="ml-2 inline-label">Never Contacted</label>
                  </div>
                </div>
              </div>

              <!-- <div class="col-6">
            <label>Recall Date From</label> <br />
            <p-calendar formControlName="bpcRecallDateFrom" styleClass="calender" [showIcon]="true"></p-calendar>
          </div>
          <div class="col-6">
            <label>Recall Date To</label>
            <p-calendar formControlName="bpcRecallDateTo" styleClass="calender" [showIcon]="true"></p-calendar>
          </div> -->
            </div>
          </div>
        </li>
      </ul>
    </ng-container>
  </form>

  <p-toast key="cd" position="bottom-center"></p-toast>

  <!-- <bpc-custom-modal (onHide)="resetSavedSearch()" [visible]="savedSearchModalVisible">
    <ng-container modal-header>
      <span class="p-dialog-title">{{ mainFilterRaw.savedSearchId ? "Update Search" : "Save New Search" }}</span>
    </ng-container>
    <ng-container modal-content>
      <div class="grid grid-no-tb-gutter">
        <div class="col-12 mg-b-16">
          <label class="w-100">Name</label>
          <input
            type="text"
            name="savedSearchName"
            styleClass="w-100"
            inputStyleClass="w-100"
            class="w-100"
            pInputText
            [(ngModel)]="savedSearchName"
          />
        </div>
      </div>
    </ng-container>
    <ng-container modal-footer>
      <button pButton class="mr-2" (click)="resetSavedSearch()">Cancel</button>
      <button pButton class="button-dark" type="submit" (click)="onSaveSearch()" [disabled]="!savedSearchName">
        Save
      </button>
    </ng-container>
  </bpc-custom-modal> -->
</div>
