<div class="grid">
  <div class="col-6">
    <span class="label-small">
      <strong>Initiator</strong>
      {{ initiator }}
    </span>
  </div>
  <div class="col-6">
    <span class="label-small">
      <strong>Participants</strong>
      {{ participants }}
    </span>
  </div>
  <div class="col-12">
    <p class="label-small" [innerHTML]="interaction.details">
    </p>
  </div>
</div>
