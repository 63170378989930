import { OnChanges, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { FilterLabel, MainFilterRaw } from '@models/main-filter-view';
import { FilterHelperService } from '@core/services/filter-helper.service';
import { UsersService } from '../../services/users/users.service';
import { IndustryService } from '@core/services/industry.service';
import { LocationService } from '@core/services/location.service';
import { InvestorService } from '@core/services/investor.service';
import { TagsService } from '@core/services/tags.service';
import { CompanyTypeService } from '../services/company-type.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, startWith, takeUntil } from 'rxjs';

@Pipe({
  name: 'filterLabels',
  pure: false
})
export class FilterLabelsPipe implements PipeTransform {

  constructor(
    private filterHelper: FilterHelperService,
    usersService: UsersService,
    industryService: IndustryService,
    locationService: LocationService,
    investorService: InvestorService,
    tagsService: TagsService,
    companyTypeService: CompanyTypeService,
    protected router: Router,

  ) {
  }

  transform(value: MainFilterRaw): FilterLabel[] {
    return this.filterHelper.getFilterLabels(value);
  }
}