import { Directive } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { NotificationOperator } from '@models/notifications';

@Directive({ selector: 'p-dropdown[dataBasedTriggerComparisonValues]' })
export class DataBasedTriggerComparisonValuesDirective {
  constructor(dropdown: Dropdown) {
    dropdown.options = [
      { name: '<', id: <NotificationOperator>'<' }, // cast is not needed, it's here to indicate possible values
      { name: '≥', id: <NotificationOperator>'>=' }
    ];
    dropdown.optionValue = 'id';
    dropdown.optionLabel = 'name';
  }
}