import { Pipe, PipeTransform } from '@angular/core';
import { InvestorRole } from '@models/auxiliary-company-models';
import { Nullable } from '@models/nullable';

@Pipe({
  name: 'investorRole'
})
export class InvestorRolePipe implements PipeTransform {
  transform(value: InvestorRole): Nullable<string> {
    switch (value) {
      case InvestorRole.LEAD:
        return 'Lead';
      case InvestorRole.ACQUIRER:
        return 'Acquirer';
      case InvestorRole.BANKER:
        return 'Banker';
      case InvestorRole.STRATEGIC:
        return 'Strategic';
      default:
        return null;
    }
  }
}
