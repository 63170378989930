import { Component, OnInit } from "@angular/core";

import { AuthService } from "./auth.service";
import { Nullable } from "@models/nullable";
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from "../../services/users/users.service";
import { MessageService } from "primeng/api";
import { environment } from "../../../environments/environment";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { ChromeExtensionService } from "src/app/services/chrome-extension/chrome-extension.service";
import { MixpanelService } from "@core/services/mixpanel.service";
import { APP_VERSION, DEV_APP_VERSION } from "@core/components/company-details/package-constants";

@Component({
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  email: Nullable<string> = null;
  password: Nullable<string> = null;
  loginWithEmailPassword = false;
  simpleLogin: boolean = false;
  loginType = environment.config.loginType;
  isNonBPC: boolean = false;

  get returnUrl(): string | undefined {
    return this.route.snapshot.queryParams["returnUrl"];
  }

  set loading(value: boolean) {
    this.authService.loading = value;
  }

  get loading(): boolean {
    return this.authService.loading;
  }

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private router: Router,
    private messageService: MessageService,
    private oidcSecurityService: OidcSecurityService,
    private route: ActivatedRoute,
    public chromeExtensionService: ChromeExtensionService,
    private mixpanelService: MixpanelService
  ) { }

  ngOnInit() {
    this.checkVersion();
    if (this.router.url === "/login/sso") {
      this.loginOIDC();
    }
  }

  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  checkVersion() {
    let key = null;
    let envVersion = null;
    let localVersion = null;
    let isProd = environment.production;
    if (isProd) {
      envVersion = APP_VERSION;
      key = "ANGULAR_APP_VERSION";
      localVersion = localStorage.getItem(key);
    }
    else {
      envVersion = DEV_APP_VERSION;
      key = "ANGULAR_APP_VERSION_DEV";
      localVersion = localStorage.getItem(key);
    }
    if (!localVersion) {
      localStorage.setItem(key, envVersion);
    }
    else {
      localVersion = localVersion.split(".").join("");
      const currentVersion = envVersion.split(".").join("");
      if (Number(localVersion) < Number(currentVersion)) {
        localStorage.setItem(key, envVersion);
      }
    }
  }

  onSubmit() {
    this.simpleLogin = true;
    this.usersService.login(this.email!, this.password!).subscribe((response) => {
      this.simpleLogin = false;
      if (response.data?.result) {
        const user = response.data.data;

        this.authService.setUserRole(user);
        this.mixpanelService.onLogin(user, false);
        this.authService.user.next(user);

        if (this.returnUrl) {
          this.router.navigateByUrl(this.returnUrl);
        } else {
          this.router.navigate(["/"]);
        }
      } else if (response.error || !response.data?.result) {
        this.displayToastMessage(response.data?.message ?? "Wrong email or password.");
      }
    });
  }

  loginOIDC() {
    if (this.returnUrl?.includes("/extension?domain=")) {
      this.displayToastMessage(
        "You are currently not logged into JARVIS. To activate the plugin, please first log into JARVIS in another tab on your browser.  Then, reload this website and try again.",
        "info",
        5000
      );
      setTimeout(() => {
        window.open(window.location.origin, "_blank")?.focus();
      }, 3000);
    } else {
      this.loading = true;
      this.oidcSecurityService.authorize();
    }
  }

  onForgotPasswordClick() {
    this.mixpanelService.trackEvent("Forgot_Password", {
      email: this.email,
    });
    this.displayToastMessage("Please contact the admin.", "info");
  }

  private displayToastMessage(message: string, severity = "error", life = 3000) {
    this.messageService.add({
      key: "login",
      severity,
      detail: message,
      life,
    });
  }
}
