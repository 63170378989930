<div class="grid padd-tb-20">
  <div class="col-12" *ngIf="validInteractionsData">
    <p-accordion class="w-full">
      <p-accordionTab
        (click)="toggleInteraction(index)"
        *ngFor="let interaction of interactionsData; let index = index"
        [selected]="selectedInteraction === index"
      >
        <ng-template pTemplate="header">
          <div class="grid grid-no-tb-gutter">
            <div *ngIf="interaction | interactionIcon" class="col flex-w-auto">
              <img class="v-base" [src]="interaction | interactionIcon" />
              <!-- <i [class]="interaction | interactionIcon"></i> -->
            </div>
            <div class="col">
              <span class="title" [innerHTML]="interaction.summary"></span>
              <span class="date">{{ interaction.interactionDate | date }}</span>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <bpc-interaction-details class="accordian-content" [interaction]="interaction"></bpc-interaction-details>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div *ngIf="!validInteractionsData" class="col-12 no-data">
    <!-- class="overview" -->
    <p>No Interaction Data</p>
   <!-- <img src="assets/svg/no-data.gif" alt="No Data" /> -->
  </div>
</div>
