export interface ActionType {
  id: number;
  name: string;
  description: string;
}

export enum ActionTypeMap {
  CREATE = 1,
  MODIFY = 2,
  TAKEOVER = 3,
  RELEASE = 4,
  TRANSFER = 5,
  PRIORITIZE = 6,
  DEPRIORITIZE = 7,
  SETSTATUS = 8,
  SETUNLIKELY = 9,
  SETLIKELY = 10,
  SETALERT = 11,
  INTERACT = 12,
  TAG = 13,
  CREATEWORKFLOW = 14,
  MODIFYWORKFLOW = 15,
  ASSIGNWORKFLOW = 16,
  RELEASEWORKFLOW = 17,
  SETWORKFLOWSTATUS = 18,
  REMOVEWORKFLOW = 19,
  TAKEOVERLEAD = 20,
  RELEASELEAD = 21,
  TRANSFERLEAD = 22,
  PRIORITIZELEAD = 23,
  DEPRIORITIZELEAD = 24,
  TAGEDITED = 26,
  TAGREMOVED = 25
}