import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bpc-heatmap-grid',
  templateUrl: './heatmap-grid.component.html',
  styleUrls: ['./heatmap-grid.component.scss']
})
export class HeatmapGridComponent {
  @Input() companies: any[] = [];
  @Output() onCloseGridEmitter = new EventEmitter();
  loading: boolean = false;

  get tableClass(): string {
    const classes = ["main-grid-table"];
    this.loading && classes.push("loading");
    return classes.join(" ");
  }

  onCloseGrid() {
    this.onCloseGridEmitter.emit(false);
  }
}
