import { userDataKey } from "@core/localStorageKeys.ts";
import { User } from "@core/models/user";
import moment from "moment";
import { Nullable } from "primeng/ts-helpers";
import { FrequenyType } from "../components/generic-modal/generic-modal.component";

export function cloneDeep<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function getUserData() {
  let userData = localStorage.getItem(userDataKey);
  if (document.cookie) {
    const cookieValue = getUserDataCookieValue()
    if (cookieValue) {
      userData = JSON.parse(cookieValue);
    }
  }
  if (!userData) {
    return null;
  }
  return userData;
}

export function setUserData(user: Nullable<User>) {
  localStorage.setItem(userDataKey, JSON.stringify(user));
  document.cookie = `${userDataKey}=${JSON.stringify(user)}; path=/`;
}

function getUserDataCookieValue() {
  var cookieValue = null
  try {
    cookieValue = document.cookie
      ?.split('; ')
      ?.find(row => row.startsWith(userDataKey))
      ?.split('=')[1];
  }
  catch (ex) {
    console.log("------------>", ex);
  }
  return cookieValue;
}

export function formatDate(date: string){
  return moment(date).format("M/YY");
}

export function convertCronExpressionToTime(company: any) {
  let obj = {
    frequency: null as FrequenyType | null,
    selectedDayOfWeek: null as number | null,
    selectedDayOfMonth: null as number | null,
    selectedTime: null as Date | null
  };
  const splitCron: string[] = company.cronExpression.split(" ");
  const intCron: (number | null)[] = splitCron.map(value => {
    if (value === '*') {
        return null;
    } else {
        return parseInt(value, 10);
    }
  });
  if(intCron[4] !== null) {
    obj.selectedDayOfWeek = intCron[4];
    obj.frequency = FrequenyType.Weekly;
  }
  else if(intCron[2] !== null) {
    obj.selectedDayOfMonth = intCron[2];
    obj.frequency = FrequenyType.Monthly;
  }
  else {
    obj.frequency = FrequenyType.Daily;
  }
  let selectedTime = new Date();
  selectedTime.setHours(intCron[1]!);
  selectedTime.setMinutes(intCron[0]!);
  obj.selectedTime = selectedTime;
  return obj;
};