import { BaseModel } from '@models/base';
import { Priority } from '@models/auxiliary-company-models';
import { ConnectionForm } from './forms';

export type UserRole = 'investor' | 'analyst' | 'admin' | 'votercommittee' | 'investmentcommitteeadmin' | 'investorrelation' | 'newslettermoderator';

export type UserGroup = {
  name: string;
  isAdmin: boolean;
};

export enum UserHierarchy {
  Partners = "Partners",
  Principals = "Principals",
  VPs = "VPs",
  Associates = "Associates/Analysts"
};

export interface User extends BaseModel {
  name: string;
  email: string;
  token: string;
  isAD: boolean;
  roles: UserRole[];
  role: UserRole;
  groups: UserGroup[];
  // not stored in database
  isPodLeader?: boolean;
  priority?: Priority;
  priorityDays?: number;
}

export interface BaseUser {
  id: number;
  name: string;
  email: string;
  isActive?: boolean;
  profilePictureUrl?: string;
}

export interface ConnectedUser extends User {
  connections: Connection[];
}

export interface Staff extends User {
  firstName: string;
  lastName: string;
  workEmail: string;
  workPhone: string;
  position: string;
  externalUrl: string;
  companyId: number;
  count?: number;
  editing?: boolean;
  secondLevelConnections?: ConnectionForm[];
}

export interface Connection {
  id: number;
  level: number;
  companyPersonId: number;
  connectedUserId: number;
  count?: number;
  secondLevelConnections?: ConnectionForm[]
}
