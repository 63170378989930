import { Directive } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';

export const WEEK_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

@Directive({ selector: '[recurringWeekly]' })
export class RecurringWeeklyDirective {

  constructor(dropdown: Dropdown) {
    dropdown.options = WEEK_DAYS.map((day, index, all) => ({ name: day, id: (index + 1) % (all.length + 1) }));
    dropdown.optionValue = 'id';
    dropdown.optionLabel = 'name';
  }
}