import { Injectable } from '@angular/core';
import { Investor, InvestorRole, StatusId, WorkFlow } from '@core/models/auxiliary-company-models';
import { Company } from '@core/models/company';
import { CSVFile } from '@core/models/csv-file';
import { CompletelyNullable } from '@core/models/nullable';
import { User } from '@core/models/user';
import { EnrichmentStatusPipe } from '@core/pipes/tabs/enrichment-status.pipe';
import { StatusPipe } from '@core/pipes/tabs/status.pipe';
import { VotingCheckSizePipe } from '@core/pipes/tabs/voting-checkSize.pipe';
import { VotingClassesWorkflowPipe } from '@core/pipes/tabs/voting-classes-workflow.pipe';
import { VotingDatePipe } from '@core/pipes/tabs/voting-date.pipe';
import { VotingDescriptionPipe } from '@core/pipes/tabs/voting-description.pipe';
import { VotingSummaryPipe } from '@core/pipes/tabs/voting-summary.pipe';
import { BpcNumberPipe } from '@shared/pipes/number/bpc-number.pipe';

@Injectable({
  providedIn: 'root'
})
export class CSVService {

  constructor(
    private bpcNumberPipe: BpcNumberPipe,
    private statusPipe: StatusPipe,
    private enrichmentStatusPipe: EnrichmentStatusPipe,
    private votingCheckSize: VotingCheckSizePipe,
    private votingDatePipe: VotingDatePipe,
    private votingDescription: VotingDescriptionPipe,
    private votingSummary: VotingSummaryPipe,
    private votingClassesWorkflow: VotingClassesWorkflowPipe
  ) { }

  convertBpcNumber = (value: CompletelyNullable<number>, digitsInfo: string, currency: string) => {
    return this.bpcNumberPipe.transform(value, digitsInfo, currency);
  }

  convertStatus = (statusId: StatusId) => {
    return this.statusPipe.transform(statusId);
  }

  convertEnrichmentStatus = (workflows: WorkFlow[]) => {
    return this.enrichmentStatusPipe.transform(workflows);
  }

  getCheckSize = (workflows: WorkFlow[]) => {
    return this.votingCheckSize.transform(workflows);
  }

  getVotingDate = (workflows: WorkFlow[]) => {
    return this.votingDatePipe.transform(workflows);
  }

  getVotingDescription = (workflows: WorkFlow[]) => {
    return this.votingDescription.transform(workflows);
  }

  getVotingSummary = (workflows: WorkFlow[]) => {
    return this.votingSummary.transform(workflows);
  }

  getVotingClassesWorkflow = (workflows: WorkFlow[]) => {
    return this.votingClassesWorkflow.transform(workflows);
  }
  getCoverageUsersNames = (coverageUsers: User[]) => {
    return coverageUsers.map(({ name }) => name).join(',');
  }

  getLeadInvestors = (investors: Investor[]) => {
    return investors.filter(investor => investor.dominantRole === InvestorRole.LEAD).map(({ name }) => name).join(',');
  }

  getCSVTemplate = (currentTabIndex: number): CSVFile<Company> => {

    const commonHeader = [
      // { label: 'Score', keys: ['rank'] },
      { label: 'External URL', keys: ['externalUrl'] },
      { label: 'Name', keys: ['name'] },
      { label: 'Description', keys: ['description']},
      { label: 'Company Website', keys: ['website'] },
      { label: 'City', keys: ['address', 'city'] },
      { label: 'Country', keys: ['address', 'country', 'name'] },
      { label: 'PIC', keys: ['paidInCapital'], arguments: ['1.0-0', '$'] },  //pipe: this.convertBpcNumber,
      { label: 'Lead Investor', keys: ['investors'], pipe: this.getLeadInvestors, arguments: [] },
      { label: 'FTE (Full Time Equivalent)', keys: ['fullTimeEquivalent'], arguments: ['1.0-0', ''] },//pipe: this.convertBpcNumber,
      { label: 'FTE (Full Time Equivalent Growth)%', keys: ['fullTimeEquivalentGrowth'] },
      { label: 'Contacts (Primary Connections)', keys: ['connectionsPrimary'] },
      { label: 'Contacts (Secondary Connections)', keys: ['connectionsSecondary'] },
      { label: 'Status ID', keys: ['statusId'], pipe: this.convertStatus, arguments: [] },
      { label: 'Enrichment Status', keys: ['workflows'], pipe: this.convertEnrichmentStatus, arguments: [] },
      { label: 'Activity (Meeting Count)', keys: ['meetingsCount'] },
      { label: 'Activity (Calls Count)', keys: ['callsCount'] },
      { label: 'Activity (Email Count)', keys: ['emailsCount'] },
      { label: 'Activity (Video Call Count)', keys: ['videoCallsCount'] },
      { label: 'Last Interaction (User Name)', keys: ['latestInteraction', 'user', 'name'] },
      { label: 'Last Interaction (Time)', keys: ['latestInteraction', 'interactionDate'] },
      { label: 'Last Interaction (Summary)', keys: ['latestInteraction', 'summary'] },
      { label: 'Last Round (Date)', keys: ['latestRound', 'raiseDate']},
      { label: 'Last Round (Amount)', keys: ['latestRound', 'amount']},
      { label: 'Coverage', keys: ['coverageUsers'], pipe: this.getCoverageUsersNames, arguments: [] },
    ]

    const companies = {
      headers: commonHeader,
      data: []
    };

    const pipeline = {
      headers: commonHeader,
      data: []
    };

    const elevations = {
      headers: commonHeader,
      data: []
    };

    const priority = {
      headers: [
        // { label: 'Score', keys: ['rank'] },
        { label: 'External URL', keys: ['externalUrl'] },
        { label: 'Name', keys: ['name'] },
        { label: 'Company Website', keys: ['website'] },
        { label: 'City', keys: ['address', 'city'] },
        { label: 'Country', keys: ['address', 'country', 'name'] },
        { label: 'PIC', keys: ['paidInCapital'], arguments: ['1.0-3', '$'] },// pipe: this.convertBpcNumber,
        { label: 'FTE (Full Time Equivalent)', keys: ['fullTimeEquivalent'], arguments: ['1.0-0', ''] },//pipe: this.convertBpcNumber,
        { label: 'FTE (Full Time Equivalent Growth)%', keys: ['fullTimeEquivalentGrowth'] },
        { label: 'Contacts (Primary Connections)', keys: ['connectionsPrimary'] },
        { label: 'Contacts (Secondary Connections)', keys: ['connectionsSecondary'] },
        { label: 'Status ID', keys: ['statusId'], pipe: this.convertStatus, arguments: [] },
        { label: 'Enrichment Status', keys: ['workflows'], pipe: this.convertEnrichmentStatus, arguments: [] },
        { label: 'Activity (Meeting Count)', keys: ['meetingsCount'] },
        { label: 'Activity (Calls Count)', keys: ['callsCount'] },
        { label: 'Activity (Email Count)', keys: ['emailsCount'] },
        { label: 'Activity (Video Call Count)', keys: ['videoCallsCount'] },
        { label: 'Last Interaction (User Name)', keys: ['latestInteraction', 'user', 'name'] },
        { label: 'Last Interaction (Time)', keys: ['latestInteraction', 'interactionDate'] },
        { label: 'Last Interaction (Summary)', keys: ['latestInteraction', 'summary'] },
        { label: 'Days in priority', keys: ['priorityDays'] },
        { label: 'Coverage', keys: ['coverageUsers'], pipe: this.getCoverageUsersNames, arguments: [] },
      ],
      data: []
    };

    const voting = {
      headers: [
        // { label: 'Score', keys: ['rank'] },
        { label: 'Name', keys: ['name'] },
        { label: 'Company Website', keys: ['website'] },
        { label: 'External URL', keys: ['externalUrl'] },
        { label: 'City', keys: ['address', 'city'] },
        { label: 'Country', keys: ['address', 'country', 'name'] },
        { label: 'Check Size', keys: ['workflows'], pipe: this.getCheckSize, arguments: [] },
        { label: 'Status ID', keys: ['statusId'], pipe: this.convertStatus, arguments: [] },
        { label: 'IC Ask', keys: ['workflows'], pipe: this.getVotingDescription, arguments: [] },
        { label: 'IC Date', keys: ['workflows'], pipe: this.getVotingDate, arguments: [] },
        { label: 'Summary', keys: ['workflows'], pipe: this.getVotingSummary, arguments: [] },
        { label: 'Voting class', keys: ['workflows'], pipe: this.getVotingClassesWorkflow, arguments: [] },
      ],
      data: []
    };
    const tabs = [companies, pipeline, elevations, priority, voting];

    return tabs[currentTabIndex];
  }
}
