import { Injectable } from '@angular/core';
import { MainFilterRaw } from '@core/models/main-filter-view';
import { ChromeExtensionService } from './chrome-extension/chrome-extension.service';
import { Router } from '@angular/router';
import { TabData, customPod, getTabs, myCompaniesPod } from '@core/static/tabs';
import { Company } from '@core/models/company';
import { AuthService } from '@core/auth/auth.service';
import { User, UserRole } from '@core/models/user';
import { InvestorTab, ModalActions, Tab } from '@core/models/types';
import { Nullable } from '@core/models/nullable';
import { CompanyHelperService } from '@core/services/company-helper.service';
import { environment } from 'src/environments/environment';
import { Pod } from '@core/models/pod-filter';
import { getBaseFilter } from '../utils/tab-utils';
import { MenuItem } from '@shared/components/menu-native/menu-native-item';
import { StatusId, URLParams } from '@core/models/auxiliary-company-models';
import { BehaviorSubject, delay, } from 'rxjs';
import { FilterHelperService } from '@core/services/filter-helper.service';

@Injectable({
  providedIn: 'root'
})
export class TabUtilsService {

  refreshData = new BehaviorSubject<void>(void 0);
  tabs!: Record<UserRole, TabData<Company>[]>;
  currentTabIndex: Tab;
  currentChildIndex: Nullable<number> = null;
  companyDetailsDismissible = true;


  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  set mainFilterRaw(value: MainFilterRaw) {
    this.getCurrentTab(this.tabs,this.currentTabIndex,this.currentChildIndex).mainFilterRaw = value;
  }

  get mainFilterRaw(): MainFilterRaw {
    return this.getCurrentTab(this.tabs,this.currentTabIndex,this.currentChildIndex).mainFilterRaw!;
  }

  constructor(public chromeExtensionService: ChromeExtensionService,
    public router: Router,
    public authService: AuthService,
    public companyHelperService: CompanyHelperService,
    public filterHelper: FilterHelperService,) {
    this.loadTabsData();
    this.currentTabIndex = InvestorTab.COMPANIES;
    this.trackCompanyDetailsDismissibleState();
  }

  handleFilters() {
    return ({ currentTabIndex, currentChildIndex, params }: URLParams) => {
      if (currentTabIndex != null) {
        this.currentTabIndex = currentTabIndex;
        this.currentChildIndex = currentChildIndex;

        if (JSON.stringify(params) === "{}") {
          // if params are empty
          this.mainFilterRaw = Object.assign(getBaseFilter(this.mainFilterRaw, this.currentTabIndex), this.filterHelper.getDefaultFilter(currentTabIndex, currentChildIndex));
        } else {
          this.mainFilterRaw = { ...params };
        }
        // this.adjustActivePod();
      }
    };
  }

  trackCompanyDetailsDismissibleState() {
    this.companyHelperService.companyDetailsDismissible
      .pipe(delay(10))
      .subscribe((dismissible) => (this.companyDetailsDismissible = dismissible));
  }

  

  getCurrentTab(tabs: Record<UserRole, TabData<Company>[]>, currentTabIndex: Tab, currentChildIndex: Nullable<number>): TabData<Company> {
    const tab = this.getAvailableUserTabs(tabs)[currentTabIndex];
    return tab.children?.[currentChildIndex!] ?? tab;
  }

  getPriorityTab(tabs: Record<UserRole, TabData<Company>[]>): TabData<Company> {
    return this.getAvailableUserTabs(tabs)[InvestorTab.PRIORITY];
  }


  getCurrentTabData(tabs: Record<UserRole, TabData<Company>[]>, currentTabIndex: Tab, currentChildIndex: Nullable<number>): Company[] {
    const tab = this.getAvailableUserTabs(tabs)[currentTabIndex];
    return tab.children?.[currentChildIndex!].data ?? tab.data!;
  }

  getAvailableUserTabs(tabs: Record<UserRole, TabData<Company>[]>): TabData<Company>[] {
    return tabs[this.authService.userRole];
  }

  getElevationTabExtentedFilters(mainFilterRaw: MainFilterRaw): MainFilterRaw {
    let statuses = [];
    if (environment.firmWideElevationStatus) {
      statuses.push(environment.firmWideElevationStatus);
    }
    const extendedFilters = { ...mainFilterRaw, statusesInclude: statuses };
    return extendedFilters;
  }

  children(tab: TabData<Company>): TabData<Company>[] {
    return tab.children
      ? tab.children!.filter(
        ({ accessRoles }) =>
          !accessRoles || this.authService.user.getValue()!.roles.some((role) => accessRoles.includes(role))
      )
      : [tab];
  }


  getCurrentRoute(tabs: Record<UserRole, TabData<Company>[]>, currentTabIndex: Tab, currentChildIndex: Nullable<number>): string[] {
    return this.getAvailableUserTabs(tabs)[currentTabIndex].children
      ? this.getAvailableUserTabs(tabs)[currentTabIndex].children![currentChildIndex!].route
        ? [
          this.getAvailableUserTabs(tabs)[currentTabIndex].route,
          this.getAvailableUserTabs(tabs)[currentTabIndex].children![currentChildIndex!].route,
        ]
        : [this.getAvailableUserTabs(tabs)[currentTabIndex].route]
      : [this.getAvailableUserTabs(tabs)[currentTabIndex].route];
  }

  loadTabsData() {
      this.tabs = {
        admin: getTabs("admin", this.authService.userRoles),
        investor: getTabs("investor", this.authService.userRoles),
        analyst: getTabs("analyst", this.authService.userRoles),
        votercommittee: getTabs("votercommittee", this.authService.userRoles),
        investmentcommitteeadmin: getTabs("investmentcommitteeadmin", this.authService.userRoles),
        investorrelation: getTabs("investorrelation", this.authService.userRoles),
        newslettermoderator: getTabs("newslettermoderator", this.authService.userRoles),

      };
      if (this.authService.isVoterCommittee && this.authService.investmentcommitteeadmin) {
        // this.tabs.investor[InvestorTab.VOTING]?.children?.[0].columns?.push({ label: 'Archive', sortProperty: null });
      }
  }


  navigateToCurrentTab(mainFilterRaw: MainFilterRaw, tabs: Record<UserRole, TabData<Company>[]>, currentTabIndex: Tab, currentChildIndex: Nullable<number>, route?: string[]) {
    if (this.isExtension) {
      const url = this.router.createUrlTree(route || this.getCurrentRoute(tabs, currentTabIndex, currentChildIndex), { queryParams: mainFilterRaw }).toString();
      window.open(url);
      return;
    }
    this.router.navigate(route || this.getCurrentRoute(tabs, currentTabIndex, currentChildIndex), { queryParams: mainFilterRaw });
  }

  navigateToCompany(company: Company, replaceUrl = false, fragment: Nullable<string> = null) {
    if (fragment) {
      this.router.navigate(this.companyHelperService.generateCompanyDetailsUrl(company), {
        fragment,
        replaceUrl,
      });
    } else {
      this.router.navigate(this.companyHelperService.generateCompanyDetailsUrl(company), { replaceUrl });
    }
    document.title = company.name;
  }

  applyMainFilterRaw(mainFilterRaw: MainFilterRaw, currentTabIndex: Tab, currentChildIndex: Nullable<number>, tabs: Record<UserRole, TabData<Company>[]>, route?: string[]) {
    let combinedFilters = {
      ...mainFilterRaw,
      ...(getBaseFilter(mainFilterRaw, currentTabIndex)),
    };

    if (combinedFilters.filters && combinedFilters.filters.length > 0) {
      combinedFilters.filtersString = JSON.stringify(combinedFilters.filters);
    }

    delete combinedFilters.filters;

    this.navigateToCurrentTab(
      {
        ...combinedFilters,
        pageNumber: "1",
      },
      tabs,
      currentTabIndex,
      currentChildIndex,
      route
    );
  }

}


type ShowModalFunction = (param: ModalActions) => void;
type CompanyActionFunction = (param: Company) => void;
type OptionalCompanyActionFunction = (param?: Company) => void;