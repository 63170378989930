import { Pipe, PipeTransform } from '@angular/core';
import { getActiveOrLastICVotingWorkflow, getVotingBackgroundClasses } from '../../../utils/company-utils';
import { WorkFlow } from '@models/auxiliary-company-models';

@Pipe({
  name: 'votingClassesWorkflow'
})
export class VotingClassesWorkflowPipe implements PipeTransform {

  transform(workflows: WorkFlow[]): string {
    const score = getActiveOrLastICVotingWorkflow(workflows)?.details?.averageVote ?? 0;
    return getVotingBackgroundClasses(score);
  }
}
