<header [ngClass]="{ extenisonView: isExtension }" class="primary-header">
  <div class="fluid-container">
    <div class="grid align-items-center grid-no-tb-gutter">
      <div class="col">
        <a [ngStyle]="anchorStyle" class="brand-sec" routerLink="/">
          <img src="assets/logo.png" alt="BRIGHTON PARK CAPITAL" />
          <!-- <span class="icon-inner-logo f-34"
            ><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
            ><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
            ><span class="path9"></span><span class="path10"></span><span class="path11"></span
            ><span class="path12"></span><span class="path13"></span><span class="path14"></span
            ><span class="path15"></span><span class="path16"></span><span class="path17"></span
            ><span class="path18"></span
          ></span> -->
        </a>
      </div>
      <div class="col desktop-only">
        <bpc-auto-search *ngIf="authService.userId" id="desktop-search"></bpc-auto-search>
      </div>
      <div class="col flex align-items-center justify-content-end nav-list">
        <ng-container *ngIf="user$ | async as user">
          <ul class="nav-list">
            <!-- <li (click)="savedSearchClick()">
              <span class="icon-save-search-bar" pTooltip="Save Search" tooltipPosition="bottom"
                [hideDelay]="1000"></span>
            </li> -->
            <li (click)="openTagsSidebar(true)">
              <a pTooltip="Tags" tooltipPosition="bottom" [hideDelay]="500">
                <span class="icon-tag"></span>
              </a>
            </li>
            <li>
              <a [ngClass]="{ 'mx-6': isMobile }" pTooltip="Support" tooltipPosition="bottom" [hideDelay]="500"
                href="{{ supportService.supportEmail() }}">
                <span class="icon-mail"></span>
                <!-- <span class="pi pi-exclamation-triangle support-icon"></span> -->
              </a>
            </li>
            <li (click)="openAlertsSidebar()" class="opacity-50">
              <span class="icon-notification" style="position: relative;">
                <span *ngIf="unreadNotificationCount>0"
                  class="absolute text-white-400 bg-red-600 text-sm top-0 notification-count">
                  {{ unreadNotificationCount }}
                </span>
              </span>
            </li>
            <li>
              <bpc-menu-native [items]="menuItems" class="header__info__menu">
                <span class="icon-user"></span>
                <!-- <bpc-avatar #avatar [user]="user"></bpc-avatar> -->
              </bpc-menu-native>
            </li>
          </ul>
        </ng-container>
        <!-- [TODO] This seems to be old UI component which is not being used currently -->
        <!-- <div *ngIf="isMobile && authService.userId" class="w-full block md:hidden mt-4 mobile-search">
          <div class="hidden md:block">
            <bpc-auto-search *ngIf="authService.userId" class="h-12"></bpc-auto-search>
          </div>
          <div class="block md:hidden">
            <span class="p-input-icon-right w-full">
              <i class="pi pi-search"></i>
              <input type="text" class="h-12 w-full border-none" pInputText placeholder="Search"
                (click)="searchIconClick()" />
            </span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</header>
<bpc-meta-tags #metaTag></bpc-meta-tags>
<bpc-notification-alerts #notificationAlerts
  (setUnreadNotificationCount)="setUnreadNotificationCount($event)"></bpc-notification-alerts>
<p-sidebar [(visible)]="searchModal" [fullScreen]="true" [autoZIndex]="true"
  class="bg-blue-300 h-full mobile-search-box">
  <bpc-auto-search (closeModal)="searchModal = false" #autoSearch *ngIf="authService.userId" class="h-12">
  </bpc-auto-search>
</p-sidebar>