import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@models/nullable';
import { CompanyAddress } from '@models/auxiliary-company-models';

@Pipe({
  name: 'companyLocation'
})
export class CompanyLocationPipe implements PipeTransform {

  transform(address: Nullable<CompanyAddress>): string {
    let location = '-';
    if (address?.city) {
      location = address.city;
    }

    if (address?.country && address.country.code !== 'us') {
      location += ', ' + address?.country.name;
    }
    return location;
  }
}
