<div class="inline-block" *ngFor="let score of productScore; index as index">
  <div class="w-full h-full inline-flex items-center rounded-full justify-center select-none italic mr-1 font-semibold"
       [overlayPanelTooltip]="nameTooltip" >
    {{ score?.categoryName}}{{productScore && (index < productScore.length - 1) ? "," : null }}
  </div>
  <p-overlayPanel #nameTooltip>
    <div class="text-xs"> 
        <span class="font-semibold mr-2">Product:</span>
        <span>{{ score?.productName}}</span><br/>
        <span class="font-semibold mr-2">Growth:</span>
        <span>{{ score?.categoryGrowth ?? 'N/A' }}</span><br/>
        <span class="font-semibold mr-2">Traffic:</span>
        <span>{{ score?.categoryTrafficPercentage ?? 'N/A' }}</span><br/>
        <span class="font-semibold mr-2">Page View:</span>
        <span>{{ score?.pageView ?? 'N/A' }}</span>
    </div>
  </p-overlayPanel>
</div>