import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundType'
})
export class RoundTypePipe implements PipeTransform {
  transform(value: number, type: 'code' | 'description' = 'code'): string {
    const round = roundTypes.find(x => x.id === value);
    return round?.[type] ?? '/';
  }
}

const roundTypes: RoundType[] = [
  { id: 0, name: 'Undefined', description: 'Undefined', code: '/' },
  { id: 1, name: 'Seed', description: 'Seed', code: 'Seed' },
  { id: 2, name: 'Debt', description: 'Debt', code: 'Debt' },
  { id: 3, name: 'Acquisition', description: 'Acquisition', code: 'Acq' },
  { id: 4, name: 'Bridge', description: 'Bridge', code: 'Bridge' },
  { id: 5, name: 'SeriesA', description: 'Series A', code: 'A' },
  { id: 6, name: 'SeriesB', description: 'Series B', code: 'B' },
  { id: 7, name: 'SeriesC', description: 'Series C', code: 'C' },
  { id: 8, name: 'SeriesD', description: 'Series D', code: 'D' },
  { id: 9, name: 'SeriesE', description: 'Series E', code: 'E' },
  { id: 10, name: 'SeriesF', description: 'Series F', code: 'F' },
  { id: 11, name: 'SeriesG', description: 'Series G', code: 'G' },
  { id: 12, name: 'Venture', description: 'Venture', code: 'Venture' },
  { id: 13, name: 'Angel', description: 'Angel', code: 'Angel' },
  { id: 14, name: 'SeriesH', description: 'Series H', code: 'H' },
  { id: 15, name: 'SeriesI', description: 'Series I', code: 'I' },
  { id: 16, name: 'SeriesJ', description: 'Series J', code: 'J' },
  { id: 17, name: 'SeriesK', description: 'Series K', code: 'K' },
  { id: 18, name: 'SeriesL', description: 'Series L', code: 'L' },
  { id: 19, name: 'SeriesM', description: 'Series M', code: 'M' },
  { id: 20, name: 'SeriesN', description: 'Series N', code: 'N' },
  { id: 21, name: 'SeriesO', description: 'Series O', code: 'O' },
  { id: 22, name: 'SeriesP', description: 'Series P', code: 'P' },
  { id: 23, name: 'SeriesQ', description: 'Series Q', code: 'Q' },
  { id: 24, name: 'SeriesR', description: 'Series R', code: 'R' },
  { id: 25, name: 'SeriesS', description: 'Series S', code: 'S' },
  { id: 26, name: 'SeriesT', description: 'Series T', code: 'T' },
  { id: 27, name: 'SeriesU', description: 'Series U', code: 'U' },
  { id: 28, name: 'SeriesV', description: 'Series V', code: 'V' },
  { id: 29, name: 'SeriesW', description: 'Series W', code: 'W' },
  { id: 30, name: 'PrivateEquity', description: 'Private Equity', code: 'PE' },
  { id: 31, name: 'InitialPublicOffering', description: 'Initial Public Offering', code: 'IPO' }
];

interface RoundType {
  id: number,
  name: string,
  description: string,
  code: string
}