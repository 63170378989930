import { Injectable } from '@angular/core';
import { ApiResponse, BaseApiService, serialize } from '../../api/base';
import { AuthService } from '@core/auth/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tag } from '@models/tag';
import { OperationResult } from '@models/operation-result';

@Injectable({
  providedIn: 'root'
})
export class MetaService extends BaseApiService<Tag[]> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/meta', authService, router, messageService);
  }

  getTags(): Observable<ApiResponse<Tag[]>> {
    return this.get('/tags');
  }

  getTagsById(tagIds: Array<string>): Observable<ApiResponse<Tag[]>> {
    return this.get(`/tags?${serialize({tagIds})}`);
  }

  getTagsByName(query: string) {
    return this.get(`/tags/global?pageSize=500&term=${query}`);
  }

  removeTag(tagId: number): Observable<ApiResponse<OperationResult>> {
    return this.delete(`/tags/${tagId}`);
  }

  getUserTags(): Observable<ApiResponse<Tag[]>> {
    return this.get('/user/tags');
  }

  updateTag(id: number, tag: Tag): Observable<ApiResponse<OperationResult>> {
    return this.put(`/user/tags`, null, tag);
  }

  createTag(tag: Tag): Observable<ApiResponse<OperationResult>> {
    return this.post(`/user/tags`, null, tag);
  }

  private messageSource = new BehaviorSubject('default meta message');
  currentMessage: Observable<string> = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }
}