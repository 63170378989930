import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@models/nullable';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value?: string): string {
    return value?.split(' ').slice(0, 2).map((n) => n.substring(0, 1).toUpperCase()).join('') ?? '';
  }
}
