import {
  DataTrigger,
  Notification,
  NotificationTrigger,
  NotificationType,
  OneTimeTrigger,
  RecurringTrigger
} from '@models/notifications';
import moment from 'moment';
import { WEEK_DAYS } from '@core/directives/dropdown/recurring-weekly.directive';
import { ORDINARY_DAYS_IN_MONTH } from '@core/directives/dropdown/recurring-monthly.directive';

export function getTriggerDescription(trigger: NotificationTrigger): string {
  const format = (date: string): string => moment(date).format('LL');

  switch (trigger.type) {
    case NotificationType.OneTime:
      return format(trigger.dateValue);
    case NotificationType.Weekly:
      return weeklyTriggerDescription(trigger);
    case NotificationType.Monthly:
      return monthlyTriggerDescription(trigger);
    case NotificationType.FullTimeEquivalent:
      return dataTriggerDescription(trigger, 'FTE');
    case NotificationType.FullTimeEquivalentGrowth:
      return dataTriggerDescription(trigger, 'FTE%');
    case NotificationType.PaidInCapital:
      return dataTriggerDescription(trigger, 'PIC');
    case NotificationType.Revenue:
      return dataTriggerDescription(trigger, 'revenue');
  }
}

export function getNotificationDescription({ trigger }: Notification): string {
  const format = (date: string): string => moment(date).format('LL');

  switch (trigger.type) {
    case NotificationType.OneTime:
      return `You requested to be reminded of this company on ${ format(trigger.dateValue) }`;
    case NotificationType.Weekly:
      return `Please take a look at this company (${ weeklyTriggerDescription(trigger) })`;
    case NotificationType.Monthly:
      return `Please take a look at this company (${ monthlyTriggerDescription(trigger) })`;
    case NotificationType.FullTimeEquivalent:
      return dataAlertDescription(trigger, 'FTE');
    case NotificationType.FullTimeEquivalentGrowth:
      return dataAlertDescription(trigger, 'FTE%');
    case NotificationType.PaidInCapital:
      return dataAlertDescription(trigger, 'PIC');
    case NotificationType.Revenue:
      return dataAlertDescription(trigger, 'Revenue');
  }
}

export function weeklyTriggerDescription(trigger: RecurringTrigger): string {
  const weekdays = WEEK_DAYS.length;
  const weekday = WEEK_DAYS[(weekdays + trigger.dayOfValue - 1) % weekdays];

  return `${ weekday } weekly reminder`;
}

export function monthlyTriggerDescription(trigger: RecurringTrigger): string {
  const day = ORDINARY_DAYS_IN_MONTH[trigger.dayOfValue - 1];

  return `Monthly reminder on the ${ day } day of the week`;
}

export function dataTriggerDescription(trigger: DataTrigger, criteria: string): string {
  return `${ criteria } is ${ trigger.operator === '<' ? 'less than' : 'more than' } ${ trigger.scalarValue }`;
}

export function getOneTimeTrigger(triggers: NotificationTrigger[]): OneTimeTrigger | undefined {
  return triggers.find(({ type }) => type === NotificationType.OneTime) as OneTimeTrigger;
}

export function getWeeklyTrigger(triggers: NotificationTrigger[]): RecurringTrigger | undefined {
  return triggers.find(({ type }) => type === NotificationType.Weekly) as RecurringTrigger;
}

export function getMonthlyTrigger(triggers: NotificationTrigger[]): RecurringTrigger | undefined {
  return triggers.find(({ type }) => type === NotificationType.Monthly) as RecurringTrigger;
}

export function getFullTimeEquivalentTrigger(triggers: NotificationTrigger[]): DataTrigger | undefined {
  return triggers.find(({ type }) => type === NotificationType.FullTimeEquivalent) as DataTrigger;
}

export function getFullTimeEquivalentGrowthTrigger(triggers: NotificationTrigger[]): DataTrigger | undefined {
  return triggers.find(({ type }) => type === NotificationType.FullTimeEquivalentGrowth) as DataTrigger;
}

export function getPaidInCapitalTrigger(triggers: NotificationTrigger[]): DataTrigger | undefined {
  return triggers.find(({ type }) => type === NotificationType.PaidInCapital) as DataTrigger;
}

export function getRevenueTrigger(triggers: NotificationTrigger[]): DataTrigger | undefined {
  return triggers.find(({ type }) => type === NotificationType.Revenue) as DataTrigger;
}

function dataAlertDescription(trigger: DataTrigger, criteria: string): string {
  return `The company has reached the set criteria (${ dataTriggerDescription(trigger, criteria) })`;
}