import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@models/nullable';
import { StatusId } from '@models/auxiliary-company-models';
import { convertStatusIdToStatus } from '../../../utils/company-utils';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(statusId: StatusId): Nullable<string> {
    return convertStatusIdToStatus(statusId);
  }
}
