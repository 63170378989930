<a *ngIf="externalUrl" target="_blank" [href]="externalUrl">
  <img src="assets/svg/btn-cloud.svg" alt="DC" />
</a>
<img
  *ngIf="!externalUrl"
  class="grayscale opacity-30"
  src="assets/svg/btn-cloud.svg"
  alt="DC"
  pTooltip="Unavailable"
  [ngClass]="{ hidden: !displayIcon }"
/>
