<bpc-custom-modal [visible]="visible" (onHide)="close()">
  <ng-container modal-header>
    <span class="p-dialog-title"
      >{{ companyName }}
      <span class="location">{{ companyLocation }}</span>
    </span>
    <div class="flex">
      <div class="star-tag txt-blue w-i mr-2">
        <!-- <span class="star-circle">
          <i class="pi pi-user"></i>
        </span> -->
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#095A70" />
          <path
            d="M8.09383 9.79434V15H6V6.11568H8.00129V7.62532H8.1054C8.30977 7.12789 8.6356 6.73265 9.08291 6.43959C9.53406 6.14653 10.0913 6 10.7545 6C11.3676 6 11.9017 6.13111 12.3567 6.39332C12.8156 6.65553 13.1703 7.03535 13.421 7.53278C13.6754 8.03021 13.8008 8.63368 13.7969 9.34319V15H11.7031V9.66709C11.7031 9.07326 11.5488 8.60861 11.2404 8.27314C10.9357 7.93766 10.5135 7.76992 9.97365 7.76992C9.60733 7.76992 9.28149 7.8509 8.99614 8.01285C8.71465 8.17095 8.49293 8.40039 8.33098 8.70116C8.17288 9.00193 8.09383 9.36632 8.09383 9.79434Z"
            fill="white"
          />
        </svg>

        <span class="ml-2 star-value f-14">
          {{ votes.length }}
        </span>
      </div>

      <div class="star-tag w-i {{ votingStats.averageVote | votingClassesScore }}">
        <span class="star-circle">
          <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.51965 1.99998L4.09265 4.36498L5.47865 1.99998L7.61265 1.99998L5.13765 5.92698L7.66765 9.72198H5.47865L3.92765 7.38998L2.56365 9.72198H0.429649L2.88265 5.82798L0.33065 1.99998L2.51965 1.99998Z"
              fill="white"
            />
            <path d="M7.93165 0.899976L0.0666504 0.899975L0.0666504 0.349976L7.93165 0.349976V0.899976Z" fill="white" />
          </svg>
        </span>
        <span class="star-value">
          {{ votingStats.averageVote }}
        </span>
      </div>
    </div>
  </ng-container>

  <ng-container modal-content>
    <section id="vote-stats-container" class="voting-modal-wrapper" *ngIf="votingStats && !votingStatsLoading; else showLoaders">
      <div class="grid grid-no-tb-gutter mt-2">
        <div class="col list-action">
        </div>
        <div class="col flex-w-auto">
          <div class="grid grid-no-tb-gutter">
            <div class="col">
              <p-radioButton
                [(ngModel)]="selectedGroup"
                (ngModelChange)="filterByGroup()"
                [value]="1"
                label="All"
                class="t-filter-checkbox"
              >
              </p-radioButton>
            </div>
            <div class="col" *ngFor="let result of votingStats.groupResults">
              <p-radioButton
                [(ngModel)]="selectedGroup"
                (ngModelChange)="filterByGroup(result.group.name)"
                [value]="result.group.id"
                [label]="result.group.name"
                class="t-filter-checkbox"
              >
              </p-radioButton>
            </div>
          </div>
        </div>
      </div>
      <div class="voting-summary-container">
        <h4>Summary (AI Generated)</h4>
        <div class="voting-summary-loader" *ngIf="getSummaryStatus">
          <p-skeleton width="55rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="55rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="55rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="55rem" styleClass="mb-2"></p-skeleton>
        </div>
        <div *ngIf="!getSummaryStatus" class="voting-summary-text-container">
          <p>
            {{  filteredVotingSummary?.summary || "Click 'Refresh' to generate AI summary (IC Admins only)" }}
          </p>
          <div style="display: flex; gap: 4px; margin: 12px 0 0 0; flex-wrap: wrap;">
            <span *ngFor="let keyword of filteredVotingSummary?.keywords"
              class="tags no-hover filled">
              {{ keyword }}
            </span>
          </div>
        </div>
      </div>
      <div class="grid grid-no-tb-gutter mt-2">
        <div class="col list-action">
          <i class="pi pi-th-large mr-2" [class.active]="!detailedViewToggle" (click)="setDisplayToogle(false)"></i>
          <i class="pi pi-book" [class.active]="detailedViewToggle" (click)="setDisplayToogle(true)"></i>
        </div>
      </div>
      <section class="listview-wrapper" *ngIf="!detailedViewToggle">
        <div class="grid grid-no-tb-gutter">
          <div class="col flex-w-auto" *ngFor="let vote of sortedVotes; index as index; trackBy: trackBy">
            <span
              *ngIf="vote.group === selectedGroup || ICGroupSelected"
              class="cursor-pointer voting-members  space {{ vote.borderClass }}"
              [ngClass]="{ 'disabled-opacity': vote.disabled }"
              [overlayPanelTooltip]="VOTEDATA"
            >
            <div class="image-container">
              <img
                class="profile-image cursor-pointer"
                [src]="vote?.profilePictureUrl ? vote?.profilePictureUrl : 'assets/no-image.png'"
                alt="{{ vote.name }}"
                
              />
              <div *ngIf="isCrossIconVisible">
                <i
                  class="cross-icon disabled"
                  *ngIf="allVotingSummaryLoading"
                  (click)="showDeleteVotePopup(vote)"
                  aria-hidden="true"
                >&times;</i>
                <i
                class="cross-icon"
                *ngIf="!allVotingSummaryLoading"
                (click)="showDeleteVotePopup(vote)"
                aria-hidden="true"
                [ngClass]="{ 'opacity': vote.disabled }"
                >&times;</i>
              </div>
            </div>
              <span class="{{ vote.borderClass }}">{{ vote.score }}</span>
            </span>
            <span
              *ngIf="vote.group !== selectedGroup && !ICGroupSelected"
              class="cursor-pointer voting-members space {{ vote.borderClass }}"
              [ngClass]="{ 'disabled-opacity': vote.disabled }"
            >
            <div class="image-container">
              <img
                class="profile-image cursor-pointer"
                [src]="vote?.profilePictureUrl ? vote?.profilePictureUrl : 'assets/no-image.png'"
                alt="{{ vote.name }}"
                
              />
              <div *ngIf="isCrossIconVisible">
                <i
                  class="cross-icon disabled"
                  *ngIf="allVotingSummaryLoading"
                  (click)="showDeleteVotePopup(vote)"
                  aria-hidden="true"
                >&times;</i>
                <i
                class="cross-icon"
                *ngIf="!allVotingSummaryLoading"
                (click)="showDeleteVotePopup(vote)"
                aria-hidden="true"
                [ngClass]="{ 'opacity': vote.disabled }"
                >&times;</i>
              </div>
            </div>
              <span class="{{ vote.borderClass }}">{{ vote.score }}</span>
            </span>
            <p-overlayPanel #VOTEDATA>
              <!-- There is a bug in PrimeNG if the overlay is out of the ngFor loop, and same panel is used for multiple votes, keep it inside -->
              <ng-template pTemplate="content">
                <div class="flex items-center justify-between border-b w-72 pb-4 mt-2">
                  <div>
                    <div class="font-bold text-xl mb-1">{{ vote.name }}</div>
                    <div class="text-xs text-gray-500">{{ vote.modifyDate ?? vote.createDate | date: 'short' }}</div>
                  </div>
                  <div class="text-sm h-5">
                    <span class="text-center text-white w-6 inline-block {{ vote.score | votingClassesScore }}">{{
                      vote.score
                      }}</span>
                  </div>
                </div>
                <div class="w-72">
                  <div class="mb-1 vote-comment-title">Voting comment</div>
                  <div
                    *ngIf="vote.comment"
                    class="text-sm text-gray-500 listview-wrapper2">
                    {{ vote.comment }}
                  </div>
                  <div
                    *ngIf="!vote.comment"
                    class="text-sm text-gray-500 listview-wrapper">
                    No comment
                  </div>
                </div>
              </ng-template>
            </p-overlayPanel>
          </div>
        </div>
      </section>
      <section class="listview-wrapper" *ngIf="detailedViewToggle">
        <div class="grid grid-no-tb-gutter">
          <div class="col-12" *ngFor="let vote of sortedVotes; index as index; trackBy: trackBy">
            <div class="grid grid-no-tb-gutter flex align-items-center list-view">
              <div class="col">
                <div class="grid grid-no-tb-gutter flex align-items-center">
                  <div class="col flex-w-auto">
                    <span
                      [ngClass]="{ 'disabled-opacity': vote.disabled }"
                      class="cursor-pointer voting-members {{ vote.borderClass }} "
                    >
                    <div class="image-container">
                      <img
                        class="profile-image cursor-pointer"
                        [src]="vote?.profilePictureUrl ? vote?.profilePictureUrl : 'assets/no-image.png'"
                        alt="{{ vote.name }}"
                        
                      />
                      <div *ngIf="isCrossIconVisible">
                        <i
                          class="cross-icon disabled"
                          *ngIf="allVotingSummaryLoading"
                          (click)="showDeleteVotePopup(vote)"
                          aria-hidden="true"
                        >&times;</i>
                        <i
                        class="cross-icon"
                        *ngIf="!allVotingSummaryLoading"
                        (click)="showDeleteVotePopup(vote)"
                        aria-hidden="true"
                        [style.opacity]="1"
                        >&times;</i>
                      </div>
                    </div>
                    </span>
                  </div>
                  <div class="col flex-w-auto list-info">
                    <h2>{{ vote.name }}</h2>
                    <p>
                      {{vote | voterDesignation }} |
                      <span>{{ (vote.modifyDate ? vote.modifyDate : vote.createDate) | date : "dd MMM y" }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col flex-w-auto">
                <div class="star-tag {{ vote.borderClass }}">
                  <span class="star-circle">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.51965 1.99998L4.09265 4.36498L5.47865 1.99998L7.61265 1.99998L5.13765 5.92698L7.66765 9.72198H5.47865L3.92765 7.38998L2.56365 9.72198H0.429649L2.88265 5.82798L0.33065 1.99998L2.51965 1.99998Z"
                        fill="white"
                      />
                      <path
                        d="M7.93165 0.899976L0.0666504 0.899975L0.0666504 0.349976L7.93165 0.349976V0.899976Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span class="star-value">
                    {{ vote.score }}
                  </span>
                </div>
              </div>
              <div class="col-12">
                <p class="comment">{{ vote.comment }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <ng-template #showLoaders>
      <div class="voting-modal-wrapper">
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      </div>
    </ng-template>
  </ng-container>

  <ng-container modal-footer>
    <div class="ng-star-inserted">
      <div style="justify-content: flex-start;">
        <button 
          [disabled]="allVotingSummaryLoading || votingStatsLoading"
          pButton 
          class="button-dark" 
          (click)="onRefreshClick()"
          >Refresh
        </button>
      </div>
      <div style="justify-content: flex-end;">
        <button (click)="close()" class="p-element p-button p-component">Close</button>
      </div>
    </div>
  </ng-container>
</bpc-custom-modal>
<bpc-delete-vote-popup
  [vote]="voteToRemove"
  [visible]="deletePopupVisible"
  [companyId]="companyId"
  (toggleVisible)="toggleDeleteVotePopupVisible($event)"
  (refreshVotes)="refreshVotes()"
>
</bpc-delete-vote-popup>