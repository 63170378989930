<div class="fluid-container">
  <div class="grid grid-nogutter">
    <div class="col-12">
      <div class="sticky-tab" *ngIf="!isExtension">
        <p-tabView class="position-relative" [activeIndex]="currentTabIndex"
          (activeIndexChange)="navigateToTab($event)">
          <p-tabPanel *ngFor="let tab of onAvailableUserTabs(); let i = index" [disabled]="tab.disabled"
            [selected]="i === currentTabIndex">
            <ng-template pTemplate="header" *ngIf="!tab.hidden">
              <li [ngClass]="{'tab-mobile': isMobile && tab.mobileHidden}">
                <div class="inline-block">
                  <span>{{ tab.label }}</span>
                </div>
              </li>
            </ng-template>
            <div
              *ngIf="shouldShowCountHeader(tab)"
              class="tab-extra">
              <span class="extra-count">
                  <span *ngIf="shouldShowCurrentTotalInTabHeader(i)"> {{ currentTotal(i) }}</span>
                  <span *ngIf="shouldShowCurrentTotalInTabHeader(i)">/</span>
                <span>{{ total(i) }}</span>
              </span>
            </div>
            <div
            *ngIf="shouldShowCountHeader(tab)"
            class="tab-extra">
            <span class="extra-count">
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
            </span>  
            <span class="extra-label">{{ (currentChildIndex!=null && tab.children!=null && tab.children[currentChildIndex] != null) ?  tab.children[currentChildIndex].top_right_label : tab.label }}</span>
          </div>
            <div>
              <div [ngClass]="tab.hasScroll ? 'tab-scroll' : ''">
                <div [ngClass]="tab.hasScroll ? 'tab-scroll-margin' : ''">
                  <div *ngIf="tab.route !== 'ai-summary'" class="submenu-wrapper"
                    [ngClass]="{ 'filter-container-responsive': this.currentTabIndex === pipeLineTab }">
                    <div class="grid grid-no-tb-gutter flex justify-content-between">
                      <div class="col flex align-content-center flex-wrap flex-w-auto" *ngIf="tab.children!!"
                        [ngClass]="{ 'filter-pod-container': this.currentTabIndex === pipeLineTab }">
                        <a class="vote-tab-buttons" *ngFor="let child of children(tab)"
                          [routerLink]="child.route ? [tab.route, child.route] : [tab.route]"
                          [ngClass]="{ 'active-ic-vote-tab': isPathMatching(tab.route, child.route) }"
                          (click)="resetActivePod()"            >
                          {{ child.label }}
                        </a>
                      </div>
                      <div class="col flex align-content-center">
                        <bpc-filter-labels *ngIf="tab.hasFilterLabels" class="flex align-content-center"
                          (mainFilterRawChange)="applyMainFilterRaw($event)" [mainFilterRaw]="mainFilterRaw"
                          (clearAllFiltersEvent)="clearAllFilters()" [hasFilters]="tab.hasFilters"
                          [tabIndex]="currentTabIndex"></bpc-filter-labels>
                      </div>

                      <div class="col flex align-content-center flex-w-auto">
                        <div class="grid align-content-center p-30">
                          <div *ngIf="showHeatMap" class="col flex align-content-center flex-wrap">
                            <button 
                              (click)="toggleDataView('table')"
                              [class.bg-orange-500]="dataView === 'table'" 
                              [class.text-white]="dataView === 'table'" 
                              class="px-3 py-2 text-orange-500 border-orange-500 border-1 button-radius-left">
                                <i class="pi pi-table"></i>
                            </button>
                            <button 
                              (click)="toggleDataView('chart')"
                              [class.bg-orange-500]="dataView === 'chart'" 
                              [class.text-white]="dataView === 'chart'" 
                              class="px-3 py-2 bg-orange-500 border-orange-500 border-1 text-orange-500 button-radius-right">
                              <i class="pi pi-chart-bar"></i>
                            </button>
                          </div>

                          <div class="col flex align-content-center flex-wrap flex-w-auto" *ngIf="tab.hasPods">
                            <p-dropdown styleClass="no-padd sub-dropdown" optionDisabled="inactive" optionLabel="name"
                              scrollHeight="auto" (onChange)="onPodsChange()" [autoDisplayFirst]="false" [options]="allPods"
                              [(ngModel)]="activePod">
                            </p-dropdown>
                          </div>
                          <!-- <div *ngIf="tab.hasByPodLeaderFilter" class="col flex align-content-center flex-wrap">
                            <p-checkbox label="Filter by POD Leader" [(ngModel)]="filterByPodLeader"> </p-checkbox>
                          </div> -->
                          <div *ngIf="tab.hasFilterByMyElevations" class="col flex align-content-center flex-wrap">
                            <p-checkbox label="Filter for my outreach responsibility" [(ngModel)]="filterByMyElevations">
                            </p-checkbox>
                          </div>

                          <div class="col flex align-content-center flex-wrap flex-w-auto">
                            <bpc-circular-download-progress *ngIf="!!currentTab.enableDownload"
                              [loading]="!!currentTab.loading" (onDownloadCancelClick)="onDownloadCancelClick()"
                              (onDownloadClick)="onDownloadButtonClick()" [progress]="currentTab.downloadingProgress"
                              [selectedCompanies]="selectedItems"></bpc-circular-download-progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="tab.hasBarFilter">
                    <bpc-horizontal-bar 
                      [tab]="tab" 
                      [currentTabIndex]="currentTabIndex"
                      [currentChildIndex]="currentChildIndex"
                      [stages]="stages"
                      [stageLoading]="stagesLoading"
                      [interactions]="interactions"
                      [interactionLoading]="interactionsLoading"
                      (onApplyStageEmitter)="onApplyStatus($event)"
                      (onApplyInteractionEmitter)="onApplyInteraction($event)"
                      >
                    </bpc-horizontal-bar>
                  </div>
                  <div class="col mobile-only" *ngIf="tab.route === 'companies'">
                    <bpc-auto-search *ngIf="authService.userId" id="mobile-search"></bpc-auto-search>
                  </div>
                  <!-- [menuItems]="menuItems" -->
                  <bpc-grid *ngIf="shouldShowGrid(tab) && dataView === 'table'" [i]="i"
                    [currentTabIndex]="currentTabIndex" [currentChildIndex]="currentChildIndex"
                    [_currentTabData]="currentTabData" [currentTab]="currentTab" [tab]="tab" [tabs]="tabs"
                    [mainFilterRaw]="mainFilterRaw" [companyDetails]="companyDetails"
                    [isInvestmentCommitteeAdmin]="isInvestmentCommitteeAdmin" [loading]="loading"
                    (onColumnSortClickEmitter)="onNavigateToCurrentTab($event)"
                    (applyMainFilterRawEmitter)="onApplyMainFilterRawEmitter($event)"
                    (editOutReachOwnerEmitter)="onEditOutReachOwner($event)" (editNextStepEmitter)="onEditNextStep($event)"
                    (editICDateEmitter)="onEditICDateEmitter($event)"
                    (voteForCompanyEmitter)="onVoteForCompanyEmitter($event)"
                    (showNotificationTriggersAlertEmitter)="onShowNotificationTriggersAlertEmitter($event)"
                    (removeFromICEmitter)="onRemoveFromICEmitter($event)"
                    (removeSavedSearchEmitter)="onRemoveSavedSearchEmitter($event)"
                    (moveCompanyToArchiveEmitter)="onMoveCompanyToArchiveEmitter($event)"
                    (menuClickEmitter)="onMenuClickEmitter($event)" (selectedItemsEmitter)="onSelectedItemsEmitter($event)">
                  </bpc-grid>
                  <bpc-heatmap 
                    *ngIf="showHeatMap && dataView === 'chart'"
                    [companyData]="companyData"
                    [currentTabIndex]="currentTabIndex" 
                    [currentChildIndex]="currentChildIndex"
                    [mapLoading]="mapLoading"
                    [chartData]="companyData"
                    [showHeatmapGrid]="shouldShowHeatMapGrid"
                  ></bpc-heatmap>
                  <bpc-ai-prep-report #aiPrepReportRef *ngIf="tab.route === 'ai-summary'"
                    [currentTabIndex]="currentTabIndex" [currentChildIndex]="currentChildIndex">
                  </bpc-ai-prep-report>

                  <portfolio-update *ngIf="tab.route === 'weekly-update' && tabType===3"
                    [tabs]="tabs"></portfolio-update>

                  <bpc-prospect-update *ngIf="tab.route === 'weekly-update' && tabType===2" ></bpc-prospect-update>

                  <bpc-ir-update *ngIf="tab.route === 'weekly-update' && tabType===4"></bpc-ir-update>

                  <bpc-newsletter-update *ngIf="tab.route === 'weekly-update' && tabType===5"></bpc-newsletter-update>

                  <bpc-chatbot *ngIf="tab.route === 'chatbot' && tabType===9"></bpc-chatbot>
                  </div>
              </div>  
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</div>
