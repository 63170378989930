import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from '@core/auth/auth.component';
import { HomeComponent } from '@core/components/home/home.component';
import { AuthGuard } from '@core/auth/auth.guard';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { LogInGuard } from '@core/auth/log-in.guard';
import { MobileVoteComponent } from '@core/components/mobile-vote/mobile-vote.component';
import { MobileVoteGuard } from '@core/guards/mobile-vote.guard';
import { VotingPermitGuard } from '@core/guards/voting-permit.guard';
import { ExtensionComponent } from '@core/components/extension/extension.component';
import { ExtensionAuthGuard } from '@core/auth/extension.auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: AuthComponent,
    canActivate: [LogInGuard]
  },
  {
    path: 'login/sso',
    component: AuthComponent,
    canActivate: [LogInGuard]
  },
  { path: 'extension', component: ExtensionComponent, canActivate: [ExtensionAuthGuard, MobileVoteGuard] },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard, MobileVoteGuard],
    children: [
      { path: 'company/:id', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      { path: 'companies', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      { path: 'companies/interactions', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      // { path: 'pipeline', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      { path: 'elevations', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      { path: 'priority', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      { path: 'data-quality', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      { path: 'ai-summary', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      // { path: 'prospect-update', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      // { path: 'portfolio-update', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      // { path: 'saved-search', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      { path: 'chatbot', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] },
      {
        path: 'ic-voting',
        component: HomeComponent,
        canActivate: [AuthGuard, MobileVoteGuard],
        children: [
          {
            path: 'history',
            component: HomeComponent,
            canActivate: [AuthGuard, MobileVoteGuard],
            data: {
              accessRoles: ['admin', 'votercommittee', 'investmentcommitteeadmin'],
              redirectTo: 'ic-voting'
            }
          }
        ]
      },
      {
        path: 'admin-panel',
        component: HomeComponent,
        canActivate: [AuthGuard, MobileVoteGuard],
        children: [
          {
          path: 'alert',
            component: HomeComponent,
            canActivate: [AuthGuard, MobileVoteGuard],
            data: {
              accessRoles: ['admin', 'votercommittee', 'investmentcommitteeadmin'],
              redirectTo: 'admin-panel/alert'
            }
          }
        ]
      },
      {
        path: 'weekly-update',
        component: HomeComponent,
        canActivate: [AuthGuard, MobileVoteGuard],
        children: [
          {
            path: 'prospect-update',
            component: HomeComponent,
            canActivate: [AuthGuard, MobileVoteGuard],
            data: {
              accessRoles: ['admin', 'votercommittee', 'investmentcommitteeadmin', 'investor'],
              redirectTo: 'weekly-update'
            }
          },
          {
            path: 'ir-update',
            component: HomeComponent,
            canActivate: [AuthGuard, MobileVoteGuard],
            data: {
              accessRoles: ['admin', 'votercommittee', 'investmentcommitteeadmin', 'investorrelation'],
              redirectTo: 'weekly-update'
            }
          },
          {
            path: 'newsletter-update',
            component: HomeComponent,
            canActivate: [AuthGuard, MobileVoteGuard],
            data: {
              accessRoles: ['admin', 'votercommittee', 'investmentcommitteeadmin', 'newslettermoderator'],
              redirectTo: 'weekly-update'
            }
          },
        ]
      },
      { path: 'admin', component: HomeComponent, canActivate: [AuthGuard, MobileVoteGuard] }
    ]
  },
  {
    path: 'm-vote',
    component: MobileVoteComponent,
    canActivate: [AuthGuard, VotingPermitGuard]
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}