import { Component, OnInit, Input } from "@angular/core";
import { DestroyObservable } from "../../../rxjs/DestroyObservable";


@Component({
  selector: "bpc-search-pannel-item",
  templateUrl: "./search-pannel-item.component.html",
  styleUrls: ["./search-pannel-item.component.scss"],
  providers: [DestroyObservable],
})
export class SearchPannelItemComponent implements OnInit {

  @Input() item!: any;


  constructor() { }

  ngOnInit(): void {
  }

}
