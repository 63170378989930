<div [ngClass]="showEditButton ? 'cropper-container' : ''">
    <input #fileInput *ngIf="showEditButton" 
    type="file" 
    accept="image/*" 
    (change)="fileChangeEvent($event)" />

    <image-cropper [ngClass]="!showSaveButton ? 'hide-element' : 'picture-cropper'"
        [imageChangedEvent]="imageChangedEvent"  format="png"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"></image-cropper>

    <div [ngClass]="!showSaveButton ? 'modal-image-editor' : 'hide-element'">
        <img [ngClass]="!showSaveButton ? 'profile-picture' : 'hide-element'"
            [src]="base64Image !== '' ? base64Image : 'assets/no-image.png'" />
        <button *ngIf="!showEditButton" class="border-none profile-picture-edit-button" type="button" pButton
            (click)="handleEditButtonDisplay()">
            <img src="assets/svg/Edit.svg" alt="edit-icon" />
        </button>
    </div>
    <div class="image-action-buttons">
        <button *ngIf="showSaveButton" (click)="hideImage()" pButton>Cancel</button>
        <button *ngIf="showSaveButton && showDoneButton" class="button-dark" (click)="saveImage()" pButton>Done</button>
    </div>
</div>