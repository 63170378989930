import { Directive, HostListener } from '@angular/core';
import { Chips } from 'primeng/chips';

@Directive({
  selector: 'p-chips[company]'
})
export class ChipsDirective {

  constructor(chips: Chips) {
    chips.addOnTab = true;
    chips.addOnBlur = true;
    chips.allowDuplicate = false;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(keyboardEvent: KeyboardEvent) {
    if (keyboardEvent.key === ";") {
      keyboardEvent.preventDefault();
      const element = keyboardEvent.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }
}
