import { Pipe, PipeTransform } from '@angular/core';
import { AssigneeFilterType, MainFilterRaw } from '@models/main-filter-view';
import { WorkFlowId } from '@models/auxiliary-company-models';

@Pipe({
  name: 'filterCount'
})
export class FilterCountPipe implements PipeTransform {

  transform(f: MainFilterRaw): number {
    if (!f) {
      return 0;
    }
    let counter = 0;
    if (f.companyName) {
      counter++;
    }
    if (f.companyDescription) {
      counter++;
    }
    if (f.excludeLatestRoundDateTo) {
      counter++;
    }
    if (f.investors?.length) {
      counter++;
    }
    if (f.locationsInclude?.length || f.locationsExclude?.length) {
      counter++;
    }
    if (f.paidInCapitalMinTotal || f.paidInCapitalMaxTotal) {
      counter++;
    }
    if (f.fullTimeEquivalentMinTotal || f.fullTimeEquivalentMaxTotal || f.fullTimeEquivalentMinGrowth || f.fullTimeEquivalentMaxGrowth) {
      counter++;
    }
    if (f.revenueMinTotal || f.revenueMaxTotal || f.revenueMinGrowth || f.revenueMaxGrowth) {
      counter++;
    }
    if (f.statuses?.length) {
      counter++;
    }
    if (f.industries?.length) {
      counter++;
    }

    if (f.interactionFilterType) {
      counter++;
    }

    if (f.assigneeFilterType === AssigneeFilterType.NONE || f.assigneesInclude?.length) {
      counter++;
    }

    if (f.tags?.length) {
      counter++;
    }

    if (f.workflows == WorkFlowId.Enrich) {
      counter++;
    }

    return counter;
  }
}
