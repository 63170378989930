import { Component } from '@angular/core';
import { ChromeExtensionService } from '../../../services/chrome-extension/chrome-extension.service';
import { SupportService } from '../../services/support.service';

@Component({
  selector: 'bpc-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent {
  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  constructor(private chromeExtensionService: ChromeExtensionService,
    public supportService: SupportService,
  ) { }

}
