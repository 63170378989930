import { Directive } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { DropdownOption } from '@models/forms';
import { BaseMultipleAutoCompleteDirective } from '@core/directives/base-multiple-auto-complete.directive';
import { from } from 'rxjs';
import { ApiResponse } from '../../api/base';
import { companyStatusOptions } from '@shared/forms/dropdown-options';

@Directive({
  selector: 'p-autoComplete[statuses]'
})
export class CompanyStatusDirective extends BaseMultipleAutoCompleteDirective<DropdownOption> {
  loadData = from([<ApiResponse<DropdownOption[]>>{ data: companyStatusOptions }]);

  constructor(autoComplete: AutoComplete) {
    super(autoComplete, 'label');
  }
}
