import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Tab } from '@core/models/types';
import * as Highcharts from 'highcharts';
import MapModule from 'highcharts/modules/map';
import { Nullable } from 'primeng/ts-helpers';
import { ChartData } from '../../../components/grid-tabs/grid-tabs.component';
import { StatusId } from '@core/models/auxiliary-company-models';
// Initialize the map module
MapModule(Highcharts);

@Component({
  selector: 'bpc-interactions-chart',
  templateUrl: './interactions-chart.component.html',
  styleUrls: ['./interactions-chart.component.scss']
})
export class InteractionsChartComponent implements OnInit {
  @Input() currentTabIndex!: Tab;
  @Input() currentChildIndex!: Nullable<number>;
  @Input() mapLoading: boolean = true;
  @Input() companyData!: ChartData[];
  Highcharts: typeof Highcharts = Highcharts;
  popupChart!: Highcharts.Chart;
  options!: Highcharts.Options;
  chartConstructor = 'chart';  // Specifies that it's a map
  visible: boolean = false;
  @Input() selectedCompany: Nullable<ChartData> = null;

  ngOnInit() {
    this.initializeChart();  // Call the method to initialize the chart
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["companyData"]) {
      this.initializeChart();
    }
  }

  initializeChart() {
    if (!this.companyData) {
      return;
    }

    const getData = (state: number) => {
      const currentTime = new Date().getTime(); // Get the current timestamp
    
      return this.companyData
        .filter(elm => elm.statusId === state && new Date(elm.lastInteractionDate).getTime() <= currentTime) // Filter out future dates
        .map(elm => ({
          name: elm.name,
          x: new Date(elm.lastInteractionDate).getTime(),  // Convert date to timestamp
          y: elm.interactionScore
        }));
    };
    
    const isIpad = () => {
      const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
      return /iPad|Macintosh/i.test(userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
    }

    const series: Highcharts.SeriesOptionsType[] = [
      {
        type: 'scatter',
        name: 'Undefined',
        id: '1',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.None)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Unlikely',
        id: '2',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.Unlikely)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Early',
        id: '1',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.Early)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Monitor',
        id: '2',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.Monitor)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Contender',
        id: '1',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.Contender)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Must Meet',
        id: '2',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.MustMeet)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Pearl',
        id: '1',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.Pearl)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Cant Crack',
        id: '2',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.CantCrack)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Firmwide Elevation',
        id: '2',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.firmWideElevation)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'PipelineDeal',
        id: '2',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.PipelineDeal)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Priority Pipeline',
        id: '1',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.PriorityPipeline)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Active Deal',
        id: '1',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.ActiveDeal)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'IC',
        id: '2',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.IC)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Hearts Minds',
        id: '1',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.HeartsMinds)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Portfolio M&A',
        id: '2',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.PortfolioMA)  // Initialize with data
      },
      {
        type: 'scatter',
        name: 'Prospect M&A',
        id: '2',
        marker: {
          symbol: 'circle'
        },
        data: getData(StatusId.ProspectMA)  // Initialize with data
      },
    ];


    this.options = {
      chart: {
        type: 'scatter',
        zooming: {
          type: 'x'
        },
        events: {
          load: function () {
            const chart = this;
    
            // Position toggle button and fix its absolute position
            const buttonY = 45;  // Adjust vertical position
            const buttonX = chart.chartWidth - (chart.chartWidth * 0.085);  // Position the button from the right edge
            
            const toggleButton = chart.renderer.button('Toggle Stages', buttonX, buttonY, function () {
              let allSeriesVisible = chart.series.every(series => series.visible);
    
              chart.series.forEach(function (series) {
                series.setVisible(!allSeriesVisible, false); // Toggle visibility
              });
              chart.redraw();  // Redraw the chart to apply changes
            }, {
              zIndex: 3,
              fill: '#FF6C39',
              stroke: '#FF6C39',  // Border color
              r: 5,  // Border radius
              style: {
                color: '#FFFFFF',
                fontFamily: 'Popins, sans-serif',
              }
            }, {
              // Hover state styling
              fill: '#FFFFFF',  // Background color on hover
              stroke: '#FF6C39',  // Hover border color
              r: 5,  // Border radius
              style: {
                color: '#FF6C39',
                fontFamily: 'Popins, sans-serif',
              }
            }).add();
    
            // Reposition the toggle button when the chart is resized
            Highcharts.addEvent(chart, 'redraw', function () {
              const newX = chart.chartWidth - (chart.chartWidth * 0.085);  // Recalculate the X position
              toggleButton.attr({
                x: newX
              });
            });
          }
        },
        resetZoomButton: {
          position: {
            align: 'left',  // Keep aligned to the right
            x: 10,  // Fine-tune the position relative to the right edge
            y: 2  // Match the Y position with the toggle button
          },
          theme: {
            zIndex: 3,
            fill: '#FF6C39',
            stroke: '#FF6C39',  // Border color for default state
            r: 5,  // Border radius
            style: {
              color: '#FFFFFF',
              fontFamily: 'Popins, sans-serif',
            },
            states: {
              hover: {
                fill: '#FFFFFF',  // Background color on hover
                stroke: '#FF6C39',  // Border color on hover
                style: {
                  color: '#FF6C39',
                  fontFamily: 'Popins, sans-serif',
                }
              }
            }
          }
        },
      },
      title: {
        text: 'Company Interaction Score Over Time',
        align: 'left'
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Recency'
        },
        labels: {
          format: '{value:%e %b %Y}'
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true
      },
      yAxis: {
        title: {
          text: 'Intensity'
        },
        labels: {
          format: '{value}'
        },
        min: 0,
        max: 170
      },
      legend: {
        enabled: true
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: isIpad() ? 5: 2.5,
            symbol: 'circle',
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              enabled: false
            }
          },
          jitter: {
            x: 0.005
          },
          point: {
            events: {
              click: this.pointClick.bind(this)  // Bind the function to the component's context
            }
          }
        }
      },
      tooltip: {
        pointFormat: 'Name: {point.name} <br/> Date: {point.x:%e %b %Y} <br/> Score: {point.y}'
      },
      series,
    };
    
  }


  generateRandomCompanyName() {
    const adjectives = ['Tech', 'Innovative', 'Pioneer', 'Global', 'Strategic', 'Dynamic', 'Creative', 'Visionary'];
    const nouns = ['Solutions', 'Ventures', 'Industries', 'Corporation', 'Group', 'Networks', 'Technologies', 'Consulting'];
    return `${adjectives[Math.floor(Math.random() * adjectives.length)]} ${nouns[Math.floor(Math.random() * nouns.length)]}`;
  }

  getRandomDateFromLastSixMonths() {
    const today = new Date();

    // Calculate the timestamp of the date 6 months ago
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 6);  // Change to 6 months

    // Generate a random timestamp between the current date and six months ago
    const randomTimestamp = Math.random() * (today.getTime() - sixMonthsAgo.getTime()) + sixMonthsAgo.getTime();

    // Return the random date
    return new Date(randomTimestamp);
  }

  pointClick(event: any) {
    const pointData = event.point;
    // console.log(pointData);
    if (pointData.options) {
      const name = pointData.options.name;

      this.selectedCompany = this.companyData.find(e => e.name == name);
    }
    this.visible = true;
  }

  toggleVisible(visible: boolean) {
    this.visible = visible;
  }
}
