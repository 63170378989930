import { Pipe, PipeTransform } from '@angular/core';
import { getNotificationDescription } from '../../utils/notification-utils';
import { Notification } from '@models/notifications';

@Pipe({
  name: 'notificationDescription'
})
export class NotificationDescriptionPipe implements PipeTransform {

  transform(notification: Notification): string {
    return getNotificationDescription(notification);
  }
}