import { Params } from "@angular/router";
import { NotificationTriggerModal } from "@core/models/auxiliary-company-models";
import { Company } from "@core/models/company";
import { MainFilterRaw } from "@core/models/main-filter-view";
import { NotificationOperator } from "@core/models/notifications";
import { Nullable } from "@core/models/nullable";
import { InvestorTab, Tab } from "@core/models/types";
import { TabData, TableHeader } from "@core/static/tabs";
import { BasePagingFilter, BaseSortingFilter } from "../services/companies-search/companies-search.service";




export function iterateSortDirection(
    mainFilterRaw: MainFilterRaw,
    column: TableHeader,
    canHaveNeutral: boolean
): MainFilterRaw {
    if (mainFilterRaw.sortProperty === column.sortProperty) {
        switch (mainFilterRaw.sortDirection?.toLowerCase()) {
            case "asc":
                if (canHaveNeutral) {
                    mainFilterRaw = { ...mainFilterRaw };
                    delete mainFilterRaw.sortDirection;
                } else {
                    mainFilterRaw = { ...mainFilterRaw, sortDirection: "desc" };
                }
                break;
            case "desc":
                mainFilterRaw = { ...mainFilterRaw, sortDirection: "asc" };
                break;
            default:
                mainFilterRaw = { ...mainFilterRaw, sortDirection: "desc" };
                break;
        }
    } else {
        mainFilterRaw = { ...mainFilterRaw, sortProperty: column.sortProperty!, sortDirection: "desc" };
    }
    return mainFilterRaw;
}

export function removeCompanyFromTab(tab?: TabData<Company>, companyIndex?: number) {
    if (tab && typeof companyIndex !== "undefined") {
        tab.data!.splice(companyIndex, 1);
        tab.total!--;
        tab.currentTotal && tab.currentTotal--;
    }
}

export function increaseCounter(tab: TabData<Company>) {
    tab.total!++;
    tab.currentTotal && tab.currentTotal++;
}

export function decreaseCounter(tab: TabData<Company>) {
    tab.totalCountByAssignee && tab.totalCountByAssignee!--;
    tab.currentTotal && tab.currentTotal--;
}

export function blockScroll() {
    document.body.classList.add("p-overflow-hidden");
}

export function unblockScroll() {
    document.body.classList.remove("p-overflow-hidden");
}

export function convertRawQueryStringToParams(query: string): Params {
    const p: Params = {};

    if (query == null) {
        return p;
    }

    query.split("&").forEach((pair) => {
        const [key, ...values] = pair.split("=");
        let value = values.join("");
        value = value == null ? "" : decodeURIComponent(value);

        if (p[key] != null) {
            //if already exists
            if (!Array.isArray(p[key])) {
                // and if not array
                p[key] = [p[key]]; // convert to array
            }
            p[key].push(value); // and push the value
        } else {
            p[key] = value;
        }
    });
    if (p['sortProperty'] !== "status") {
        delete p['thenSortProperty'];
        delete p['thenSortDirection'];
    }

    return p;
}

export function createNotificationTriggerModal(
    isActive = false,
    operator: NotificationOperator = ">=",
    value: Nullable<number> = null
): NotificationTriggerModal {
    return { isActive, operator, value };
}

export function getPageSize(mainFilterRaw: MainFilterRaw): number {
    return +(mainFilterRaw.pageSize ?? "100");
}

export function getDefaultSortProperty(currentTabIndex: Tab): string | undefined {
    switch (currentTabIndex) {
        case InvestorTab.COMPANIES:
            return "rank";
        case InvestorTab.ELEVATIONS:
            return "name";
        case InvestorTab.VOTING:
            return "votingDate";
        case InvestorTab.PIPELINE:
            return "status";
        default:
            return;
    }
}
export function getDefaultSortDirection(currentTabIndex: Tab): "asc" | "desc" | undefined {
    switch (currentTabIndex) {
        case InvestorTab.COMPANIES:
        case InvestorTab.ELEVATIONS:
        case InvestorTab.VOTING:
            return "desc";
        case InvestorTab.PIPELINE:
            return "asc";
        default:
            return;
    }
}

export function getBaseFilter(mainFilterRaw: MainFilterRaw, currentTabIndex: Tab): BasePagingFilter & BaseSortingFilter {
    const baseFilter: BasePagingFilter & BaseSortingFilter = {
        sortProperty: mainFilterRaw.sortProperty ?? getDefaultSortProperty(currentTabIndex),
        sortDirection: getSortDirection(mainFilterRaw) ?? getDefaultSortDirection(currentTabIndex),
        pageSize: getPageSize(mainFilterRaw).toString(10),
        pageNumber: (+(mainFilterRaw.pageNumber ?? "1")).toString(10),
        //the above line is a getter function of page
    };
    //handle status sortproperty
    if (baseFilter.sortProperty === "status") {
        baseFilter.thenSortProperty = "name";
        baseFilter.thenSortDirection = "asc";
      }
      else {
        delete baseFilter.thenSortProperty;
        delete baseFilter.thenSortDirection;
      }
    return baseFilter;
}

export function getSortDirection(mainFilterRaw: MainFilterRaw): "asc" | "desc" | undefined {
    switch (mainFilterRaw.sortDirection?.toLowerCase()) {
        case "asc":
            return "asc";
        case "desc":
            return "desc";
        default:
            return;
    }
}