import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { MessageService } from 'primeng/api';
import { ApiResponse, BaseApiService } from 'src/app/api/base';
import { PagerData } from '../companies-search/companies-search.service';
import { Company } from '@core/models/company';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminPanelService extends BaseApiService<PagerData<Company>> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/Hangfire', authService, router, messageService);
  }

  getAllJobs(): Observable<ApiResponse<PagerData<Company>>> {
    return this.get('/recurringjobs');
  }

  updateJob(id: string, cronExpression: string) {
    const url = `/${id}/updatehangfirejob`;
    const body = { CronExpression: cronExpression };
    return this.put(url, null, body);
  }

  deleteJob(id: string) {
    const url = `/delete/${id}`;
    return this.delete(url);
  }
}
