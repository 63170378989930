import { Component, Input, OnInit } from "@angular/core";
import { Company } from "@models/company";
import * as moment from "moment";
import { Nullable } from "@models/nullable";
import { EmployeeSeries, Interaction, InvestorRole, Round } from "@models/auxiliary-company-models";
import { ChartData, ChartOptions } from "@core/models/chart-types";
import { formatDate } from "src/app/utils/utils";
import { MixpanelService } from "@core/services/mixpanel.service";
import { ChromeExtensionService } from "src/app/services/chrome-extension/chrome-extension.service";

@Component({
  selector: "bpc-activity-data",
  templateUrl: "./activity-data.component.html",
  styleUrls: ["./activity-data.component.scss"],
})
export class ActivityDataComponent implements OnInit {
  // @Input() companyDetails!: Company;
  @Input() set _companyDetails(value: Company) {
    this.companyDetails = value;
    this.generateFtesData();
    this.generateRoundsData();
    this.generateInteractionsData();
  }
  companyDetails!: Company;
  private chartData: number[][] = [];
  private chartLabels: string[][] = [];

  basicData!: ChartData;
  basicOptions!: ChartOptions;
  activityCalendar: ActivityPeriod[] = [
    { label: "All activity", value: 0 },
    { label: "Last 12 months", value: 1 },
    { label: "Last 24 months", value: 2 },
    { label: "Last 36 months", value: 3 },
  ];
  selectedActivity: ActivityPeriod = this.activityCalendar[1];
  roundsData: Round[][] = [];
  selectedInteraction: Nullable<number> = null;
  selectedRound: Nullable<number> = null;
  interactionsData: Interaction[][] = [];
  get rounds(): Round[] {
    return this.companyDetails.rounds ?? [];
  }

  get ftes(): EmployeeSeries[] {
    return this.companyDetails.employeeSeries ?? [];
  }

  get interactions(): Interaction[] {
    return this.companyDetails.interactions ?? [];
  }

  get validChartData(): boolean {
    return this.chartData.length > 0 && this.chartData[this.selectedActivity.value].length > 0;
  }

  get validPICData(): boolean {
    return this.roundsData.length > 0 && this.roundsData[this.selectedActivity.value].length > 0;
  }

  get validInteractionsData(): boolean {
    return this.interactionsData.length > 0 && this.interactionsData[this.selectedActivity.value].length > 0;
  }

  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  constructor(
    private mixpanelService: MixpanelService,
    public chromeExtensionService: ChromeExtensionService,
  ) { }

  ngOnInit(): void {
    this.generateFtesData();
    this.generateRoundsData();
    this.generateInteractionsData();
  }

  getInvestorName(id: number): string {
    return this.companyDetails.investors?.find((investor) => investor.id === id)?.name ?? "";
  }

  getLeadInvestorName(round: Round): Nullable<string> {
    const leadInvestor = round.investments?.find((i) => i.investorRole === InvestorRole.LEAD);
    if (leadInvestor) {
      return this.getInvestorName(leadInvestor.investorId);
    }

    return null;
  }

  onPeriodChange(): void {
    this.mixpanelService.trackEvent("Activity_Data_Period_Change", { 
      companyName: this.companyDetails.name,
      isExtension: this.isExtension,
      period: this.selectedActivity.label})
    this.loadBasicData(this.chartData[this.selectedActivity.value], this.chartLabels[this.selectedActivity.value]);
  }

  toggleInteraction(index: number) {
    this.selectedInteraction = this.selectedInteraction === index ? null : index;
  }

  toggleRound(index: number) {
    this.selectedRound = this.selectedRound === index ? null : index;
  }

  private generateFtesData() {
    const fullData = this.ftes.sort((a, b) => {
      return Date.parse(a.seriesDate) - Date.parse(b.seriesDate);
    });
    const lastYear = fullData.filter((x) => moment(x.seriesDate).diff(Date.now(), "years") === 0);
    const lastTwoYears = fullData.filter((x) => moment(x.seriesDate).diff(Date.now(), "years") >= -1);
    const lastThreeYears = fullData.filter((x) => moment(x.seriesDate).diff(Date.now(), "years") >= -2);

    this.chartData = [];
    this.chartData.push(
      fullData.map((x) => (x.count)),
      lastYear.map((x) => (x.count)),
      lastTwoYears.map((x) => (x.count)),
      lastThreeYears.map((x) => (x.count))
    );

    this.chartLabels = [];
    this.chartLabels.push(
      fullData.map((x) => formatDate(x.seriesDate)),
      lastYear.map((x) => formatDate(x.seriesDate)),
      lastTwoYears.map((x) => formatDate(x.seriesDate)),
      lastThreeYears.map((x) => formatDate(x.seriesDate))
    );

    this.loadBasicData(this.chartData[1], this.chartLabels[1]);
    this.loadOptions();
  }

  private generateRoundsData() {
    const fullData: Round[] = this.rounds.sort((a, b) => {
      return Date.parse(b.raiseDate) - Date.parse(a.raiseDate);
    });

    fullData.forEach((round) => {
      if (round.investments?.length) {
        round.leadInvestorName = this.getLeadInvestorName(round) ?? null;
        round.investments.forEach((i) => {
          i.investorName = this.getInvestorName(i.investorId) ?? null;
        });
      }
    });

    const lastYear = fullData.filter((x) => moment(x.raiseDate).diff(Date.now(), "years") === 0);
    const lastTwoYears = fullData.filter((x) => moment(x.raiseDate).diff(Date.now(), "years") >= -1);
    const lastThreeYears = fullData.filter((x) => moment(x.raiseDate).diff(Date.now(), "years") >= -2);

    this.roundsData.push(fullData, lastYear, lastTwoYears, lastThreeYears);
  }

  private generateInteractionsData() {
    const fullData = this.interactions.sort((a, b) => {
      return Date.parse(b.interactionDate) - Date.parse(a.interactionDate);
    });
    const lastYear = fullData.filter((x) => moment(x.interactionDate).diff(Date.now(), "years") === 0);
    const lastTwoYears = fullData.filter((x) => moment(x.interactionDate).diff(Date.now(), "years") >= -1);
    const lastThreeYears = fullData.filter((x) => moment(x.interactionDate).diff(Date.now(), "years") >= -2);

    this.interactionsData.push(fullData, lastYear, lastTwoYears, lastThreeYears);
  }


  private loadBasicData(chartData: number[], chartLabels: string[]): void {
    this.basicData = {
      labels: chartLabels,
      datasets: [
        {
          label: "FTE:",
          data: chartData,
          fill: false,
          radius: 4,
          borderColor: "rgba(0, 36, 56, .2)",
          borderWidth: 1,
          pointBackgroundColor: "#095A70",
          tension: 0.6,
        },
      ],
    };
  }

  private loadOptions(): void {
    this.basicOptions = lineOptions;
  }
}

interface ActivityPeriod {
  label: string;
  value: number;
}



const lineOptions: ChartOptions = {
  stacked: false,
  maintainAspectRatio: false,
  aspectRatio: 0.6,

  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      position: "top",
      grid: {
        display: true, // Hide grid lines on the x-axis
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false, // Show grid lines on the y-axis
      },
      ticks: {
        display: true, // Hide y-axis labels
      },
    },
  },
};

