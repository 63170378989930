import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { BaseApiService } from '../../api/base';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CompanyType } from '../models/company-type';

@Injectable({
  providedIn: 'root'
})
export class CompanyTypeService extends BaseApiService<CompanyType[]> {

  companyTypes$ = this.get().pipe(shareReplay(1));

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/meta/companytype', authService, router, messageService);
  }

  getCompanyTypes() {
    return this.get();
  }
}