import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import MapModule from 'highcharts/modules/map';
import { ChartData } from '../grid-tabs/grid-tabs.component';
import { Nullable } from 'primeng/ts-helpers';
import { Router } from "@angular/router";
import { slugify } from 'src/app/utils/slugify';
import { CompaniesSearchService } from 'src/app/services/companies-search/companies-search.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { catchError, of } from 'rxjs';
import { Company } from '@core/models/company';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

MapModule(Highcharts)
@Component({
  selector: 'bpc-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {
  @Input() visible!: boolean;
  @Input() selectedCompany!: Nullable<ChartData>;
  @Output() toggleVisible = new EventEmitter<boolean>();

  Highcharts: typeof Highcharts = Highcharts;  
  popupChart!: Highcharts.Chart;
  options!: Highcharts.Options;
  chartConstructor = 'chart';  // Specifies that it's a map
  companyDetails: Company | null = null;
  loading: boolean = true;
  selectedInteractionType: string = 'All';
  constructor(private router: Router,
    private companiesService: CompaniesService
  ){

  }
  ngOnInit(){
    this.getInteractionHistory();
    this.initPieChart(); 
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['selectedCompany']){
      
      this.getInteractionHistory();
      this.initPieChart();
    }
  }

  initPieChart() {
    const that = this;
    if (this.selectedCompany != null) {
      const series: Highcharts.SeriesOptionsType[] = [{
        type: 'pie',
        name: 'Interaction Score',
        data: [{
          name: 'Email',
          color: '#A3D2CA',
          y: this.selectedCompany.emailCount
        }, {
          name: 'Notes',
          color: '#FFB703',
          y: this.selectedCompany.notesCount
        }, {
          name: 'Phone',
          color: '#F94144',
          y: this.selectedCompany.phoneCount
        }, {
          name: 'Video Call',
          color: '#90BE6D',
          y: this.selectedCompany.videoCallCount
        }, {
          name: 'Meeting',
          color: '#577590',
          y: this.selectedCompany.meetingCount,
        }],
        dataLabels: {
          formatter: function() {
            if(this.y === 0) return '';
            return this.key + ': ' + this.y;
          },
        },
        showInLegend: true
      }];
  
      this.options = {
        chart: {
          type: 'pie'
        },
        title: {
          text: "",
          align: 'left',
          style: {
            fontFamily: 'Poppins',
            fontSize: '1em',
            fontWeight: '700',
            lineHeight: 'normal',
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              distance: 17,
              connectorWidth: 0,
              style: {
                fontSize: '1em',
                textOutline: 'none',
                opacity: 0.7
              }
            },
            point: {
              events: {
                click: function (event: any) {
                  event.point.series.points.forEach((point: any) => {
                    point.slice(false);
                  })

                  if(that.selectedInteractionType === event.point.name) {
                    that.selectedInteractionType = 'All';
                  } else {
                    that.selectedInteractionType = event.point.name;
                    event.point.slice();  
                  }
                }
              }
            }
          }
        },
        tooltip: {
          pointFormat: '{point.y}',  // Custom tooltip showing InteractionType: Score
          style: {
            fontSize: '1.2em'
          }
        },
        legend: {
          enabled: false,
          reversed: true
        },
        series,  // Use the corrected series configuration,
        credits: {
          enabled: false  // Disables the Highcharts trademark
        }
      };
    }
  }  

  onNegativeClick(){
    this.toggleVisible.emit(false);
  }

  get navigateToCompany() {
    if (this.selectedCompany != null)
      return ['company', this.selectedCompany.companyId + '-' + slugify(this.selectedCompany.name)]
    return ''
  }

  getInteractionHistory(){
    this.loading = true;
    if (this.selectedCompany != null){
      this.companiesService.getCompanyDetails(this.selectedCompany.companyId)
      .pipe(
        catchError((error) => {
          //this.displayErrorMessage(error, "Error getting chart data, try again.");
          return of(null);
        })
      ).subscribe(response => {
        if (response?.data) {
          this.companyDetails = response.data;
          this.loading = false;
        }
      });
    }
  }
}
