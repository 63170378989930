import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ApiResponse, BaseApiService } from '../../api/base';
import { Company } from '@models/company';
import { AuthService } from '@core/auth/auth.service';
import { CompaniesFilter, PagerData } from '../companies-search/companies-search.service';
import { CONVERT_TO_ARRAY_ITEMS } from '@core/services/filter-helper.service';

export interface CompaniesExportFilter extends CompaniesFilter {
  isExport?: boolean | string
}

@Injectable({
  providedIn: 'root'
})
export class CompaniesExportService extends BaseApiService<PagerData<Company>>  {

  transformBooleanAndNonArray(filterParams: any): any {
    let transformedFilterParams = { ...filterParams };
    if (transformedFilterParams && transformedFilterParams.filtersString?.length > 0) {
      const keywordFilters = JSON.parse(transformedFilterParams.filtersString);
      delete transformedFilterParams.filtersString;
      transformedFilterParams.keywordsFilter = {};
      if (keywordFilters.length > 0) {
        transformedFilterParams.keywordsFilter.keywords = keywordFilters;
      }
    }
    return this.covertBoolAndStrings(transformedFilterParams);
  }


  covertBoolAndStrings(filterParams: any): any {
    for (const key in filterParams) {
      if(Array.isArray(filterParams[key]) && filterParams[key].length>0){
        filterParams[key] = this.covertBoolAndStrings(filterParams[key]);
      }
      if (!Array.isArray(filterParams[key]) &&
        typeof filterParams[key] === 'object' && filterParams[key] !== null) {
        filterParams[key] = this.covertBoolAndStrings(filterParams[key]);
      } else if (!isNaN(filterParams[key])) {
        filterParams[key] = Number(filterParams[key]);
      } else if (filterParams[key] === 'false') {
        filterParams[key] = false;
      } else if (filterParams[key] === 'true') {
        filterParams[key] = true;
      }
      if (!!CONVERT_TO_ARRAY_ITEMS[key]) {
        if (typeof filterParams[key] === 'string' || typeof filterParams[key] === 'number') {
          filterParams[key] = [filterParams[key]];
        }
      }
    }
    return filterParams;
  }

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/companies/export', authService, router, messageService);
  }

  getExportActiveCompanies(filter: CompaniesExportFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    return this.post('/active', null, transformedFilter);
  }

  getExportElevations(filter: CompaniesExportFilter): Observable<ApiResponse<PagerData<Company>>> {
    return this.get('/active', filter);
  }

  getExportPipelineCompanies(filter: CompaniesExportFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    return this.post('/pipeline', null, transformedFilter);
  }

  getExportPriorityCompanies(filter: CompaniesExportFilter): Observable<ApiResponse<PagerData<Company>>> {
    return this.get('/priority', filter);
  }

  getExportActiveVotingCompanies(filter: CompaniesExportFilter): Observable<ApiResponse<PagerData<Company>>> {
    return this.get('/reviewed', filter);
  }

  getExportArchieveVotingCompanies(filter: CompaniesExportFilter): Observable<ApiResponse<PagerData<Company>>> {
    return this.get('/reviewed/archive', filter);
  }

  getExportDataQualityCompanies(filter: CompaniesExportFilter): Observable<ApiResponse<PagerData<Company>>> {
    return this.get('/enriched', filter);
  }
}
