import { Pipe, PipeTransform } from '@angular/core';
import { TableHeader } from '@core/static/tabs';

@Pipe({
  name: 'hideColumnOnTablet'
})
export class HideColumnOnTabletPipe implements PipeTransform {

  transform(column: TableHeader): boolean {
    return column.label === 'Latest round'
      || column.label === 'Investors'
      || column.label === 'Growth%'
      ;
  }
}
