import { AssigneeFilterType } from '@models/main-filter-view';

export enum PodGroupType {
  Pod = 1
}

export interface PodMember {
  userId: number;
  name?: string
  initials?: string;
}

export interface Pod {
  name: string;
  members: PodMember[];
  assigneeFilterType?: AssigneeFilterType;
  groupType?: PodGroupType;
  inactive?: boolean;
}

export interface PodStage{
  statuses: Stage[];
  totalCompanies: number;
  filteredCompanies: number;
}

export interface PodInteraction{
  totalInteractions: number,
  meetingInteractions: number,
  phoneInteractions: number,
  emailInteractions: number,
  videoCallInteractions: number,
  undefinedInteractions: number
}

export interface Stage {
  id: number;
  name: string;
  dispalyName: string;
  ordinal: number;
  display?: string;
  count: number;
  color?: string;
  tooltip?: string;
  selected?: boolean;
}