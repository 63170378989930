import { Pipe, PipeTransform } from '@angular/core';
import { Investor, InvestorRole } from '@models/auxiliary-company-models';

@Pipe({
  name: 'investorsFilter'
})
export class InvestorsFilterPipe implements PipeTransform {

  transform(value: Investor[]): Investor[] {
    const allInvestors = value;
    if (allInvestors.length) {
      const leadInvestors = allInvestors.filter(investor => investor.dominantRole === InvestorRole.LEAD);
      if (leadInvestors.length) {
        return leadInvestors.slice(0, 5);
      } else {
        return allInvestors.slice(0, 1);
      }
    }

    return allInvestors;
  }

}
