<div>
  <p-dialog
    [modal]="true"
    [resizable]="false"
    [closeOnEscape]="true"
    [visible]="visible"
  >
    <section class="wrapper">
      <h1 style="font-size: 1.8rem; display: flex; align-items: center;">
        {{ selectedCompany?.name }} 
        <a class="company-link underline" (click)="onNegativeClick()" [routerLink]="navigateToCompany" style="margin-left: 10px;">
          (Open Details)
        </a>
      </h1>

      <div class="chart-interaction-wrapper">
         <div class="split-container">
          <highcharts-chart
            [Highcharts]="Highcharts"
            [constructorType]="chartConstructor"
            [options]="options"
            class="chart"
            style="background-color: #FFFFFF;"
          >
          </highcharts-chart>
          <div  class="interaction-history">
            <h2 class="sticky-header">
              Interaction History ({{selectedInteractionType}})
            </h2>
            <div *ngIf="!loading; else showLoaders">
              <bpc-interaction-history
                id="activity-data"
                [companyDetails]="companyDetails"
                [interactions]="companyDetails.interactions ?? []"
                [selectedInteractionType]="selectedInteractionType"
                *ngIf="companyDetails != null"
              >
              </bpc-interaction-history>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ng-template pTemplate="footer">
      <button
        pButton
        label="Close"
        (click)="onNegativeClick()"
      ></button>
    </ng-template>
  </p-dialog>
</div>

<ng-template #showLoaders>
  <div class="voting-modal-wrapper">
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
  </div>
</ng-template>
