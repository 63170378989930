import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '@models/company';
import { SocialLink } from '@models/social-link';

@Pipe({
  name: 'socialLinks'
})
export class SocialLinksPipe implements PipeTransform {

  transform(company: Company): SocialLink[] {
    const g2Link = company?.companyScore?.slug? `https://www.g2.com/sellers/${company?.companyScore?.slug}` : null;
    const grataLink = `https://search.grata.com/redirect?ref=ext&domain=${company?.domain}`;
    const socialLinks: SocialLink[] = [
      { type: 'www', sourceId: 0, link: company.website },
      { type: 'dealCloud', sourceId: 1, link: null },
      // { type: 'sourceScrub', sourceId: 2, link: null },
      // Source Scrub Exclusive
      // { type: 'linkedIn', sourceId: 3, link: null },
      // { type: 'crunchbase', sourceId: 7, link: null },
      // // { type: 'mixRank', sourceId: 4, link: null },
      // { type: 'twitter', sourceId: 6, link: null },
      // { type: 'facebook', sourceId: 5, link: null },
      // { type: 'g2', sourceId: 8, link: g2Link }
      { type: 'grata', sourceId: 9, link: grataLink },
    ];

    socialLinks.forEach(social => {
      social.link = company.sources.find(({ sourceType }) => sourceType === social.sourceId)?.sourceUrl ?? social.link;
    });

    return socialLinks;
  }
}
