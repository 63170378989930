import { BaseMultipleAutoCompleteDirective } from '@core/directives/base-multiple-auto-complete.directive';
import { User } from '@models/user';
import { AutoComplete } from 'primeng/autocomplete';
import { DestroyObservable } from '../../rxjs/DestroyObservable';
import { UsersService } from '../../services/users/users.service';
import { Observable, takeUntil } from 'rxjs';
import { ApiResponse } from '../../api/base';
import { Directive } from '@angular/core';

@Directive({
  selector: 'p-autoComplete[owners]',
  providers: [DestroyObservable]
})
export class OwnersDirective extends BaseMultipleAutoCompleteDirective<User> {
  loadData: Observable<ApiResponse<User[]>> = this.usersService.getActiveUsers().pipe(takeUntil(this.destroyObservable));

  constructor(autoComplete: AutoComplete, private usersService: UsersService, private destroyObservable: DestroyObservable) {
    super(autoComplete);
  }
}
