import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { GroupType } from '@core/models/auxiliary-company-models';
import { BasicCompany } from '@core/models/company';
import { MessageService } from 'primeng/api';
import { shareReplay } from 'rxjs';
import { BaseApiService } from 'src/app/api/base';

@Injectable({
  providedIn: 'root'
})
export class CompanyProspectService extends BaseApiService<BasicCompany[]> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/GroupUpdates', authService, router, messageService);
  }

  getUpdates(groupType: GroupType, date?: Date) {
    return this.get(`/${groupType}/updates` + (date ? `?publishedTimestamp=${date}`: ''));
  }

  getPublishedDateTimes(groupType: GroupType) {
    return this.get(`/${groupType}/published-timestamps`);
  }

  updateProspect(portfolioCompanyUpdateId: number, updates: string, previousUpdates: string) {
    return this.post(`/${portfolioCompanyUpdateId}/updates`, null, { updates, previousUpdates }
    );
  }

  publishProspect(groupType: GroupType) {
    return this.post(`/${groupType}/publish-updates`, null);
  }

  getEmailPreview(groupType: GroupType) {
    return this.get(`/${groupType}/preview-updates`);
  }
}
