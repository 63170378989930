import { Directive, ElementRef, EventEmitter, HostListener, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { environment } from "../../../environments/environment";
import { CompanyType } from "@models/company-type";

import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  from,
  fromEvent,
  map,
  merge,
  mergeAll,
  MonoTypeOperatorFunction,
  Observable,
  pipe,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throttle,
  timer,
} from "rxjs";

import { Company } from "@models/company";
import { DestroyObservable } from "../../rxjs/DestroyObservable";
import { CompaniesService } from "../../services/companies/companies.service";
import { CSVService } from "src/app/services/csv-service/csv.service";
import { ApiResponse } from "../../api/base";
import {
  FilterDropdown,
  Interaction,
  InteractionType,
  Investor,
  NavigateCompany,
  NotificationTriggerModal,
  Priority,
  RawValueMappingType,
  StatusId,
  URLParams,
  WorkFlowId,
  WorkFlowStatusId,
} from "@models/auxiliary-company-models";
import {
  BasePagingFilter,
  BaseSortingFilter,
  CompaniesSearchService,
  PagerData,
} from "../../services/companies-search/companies-search.service";
import { CompaniesExportService } from "../../services/companies-export/companies-export.service";
import {
  ColumnLabel,
  customPod,
  firmPod,
  getTabs,
  myCompaniesPod,
  TabData,
  TableHeader,
  TableHeaderSortIcon,
  TabType,
} from "@core/static/tabs";
import { AssigneeFilterType, AssigneeRole, IdNamePair, MainFilterRaw } from "@models/main-filter-view";
import { DropdownOption } from "@models/forms";
import { companyStatusOptions } from "@shared/forms/dropdown-options";
import { Nullable } from "@models/nullable";
import { AdminTab, AnalystTab, InvestorTab, ModalActions, Tab } from "./types";
import { CompanyHelperService } from "@core/services/company-helper.service";
import { StatsService } from "@core/services/stats.service";
import { OperationResult } from "@models/operation-result";
import { AuthService } from "@core/auth/auth.service";
import { User, UserRole } from "@models/user";
import { appendToRawValueMapping, FilterHelperService } from "@core/services/filter-helper.service";
import {
  addEnrichmentWorkflow,
  addVotingWorkflow,
  getActiveICVotingWorkflow,
  getActiveOrLastICVotingWorkflow,
  getCurrentUserVotingComment,
  getCurrentUserVotingScore,
  getEnrichmentStatusId,
  getVotingBackgroundClasses,
  getVotingTextColorClasses,
  removeEnrichmentWorkflow,
  updateEnrichmentStatus,
} from "../../utils/company-utils";
import { UsersService } from "../../services/users/users.service";
import { Pod, PodGroupType } from "@models/pod-filter";
import { SearchHelperService } from "@core/services/search-helper.service";
import { VotingService } from "@core/services/voting.service";
import { generalConstants } from "@core/static/constants";
import { ChromeExtensionService } from "../../services/chrome-extension/chrome-extension.service";
import { mergeMapWithShareReplayableProjections } from "../../rxjs/mergeMapWithShareReplayableProjections";
import { DeviceDetectorService } from "ngx-device-detector";
import { TimeBasedTriggerOccurrence } from "@core/directives/dropdown/time-based-trigger-occurrences.directive";

import moment from "moment";
import {
  DataTrigger,
  NotificationOperator,
  NotificationTrigger,
  NotificationType,
  OneTimeTrigger,
  RecurringTrigger,
} from "@models/notifications";
import {
  getFullTimeEquivalentGrowthTrigger,
  getFullTimeEquivalentTrigger,
  getMonthlyTrigger,
  getOneTimeTrigger,
  getPaidInCapitalTrigger,
  getRevenueTrigger,
  getWeeklyTrigger,
} from "../../utils/notification-utils";
import { NotificationsService } from "@core/services/notifications.service";
import { Tag } from "@models/tag";
import { InvestorRolePipe } from "@core/pipes/investor-role.pipe";
import { TagsService } from "@core/services/tags.service";
import { InvestorService } from "@core/services/investor.service";
import { MenuItem } from "@shared/components/menu-native/menu-native-item";
import { CompanyTypeService } from "@core/services/company-type.service";
import { MainFilterComponent } from "@core/components/main-filter/main-filter.component";
import { IndustryService } from "@core/services/industry.service";
import { LocationService } from "@core/services/location.service";
import { SavedSearchService } from "src/app/services/companies-search/saved-search.service";
import { TabUtilsService } from "src/app/services/tab-utils.service";
import { blockScroll, convertRawQueryStringToParams, createNotificationTriggerModal, decreaseCounter, getBaseFilter, getDefaultSortDirection, getDefaultSortProperty, getPageSize, increaseCounter, removeCompanyFromTab, unblockScroll } from "src/app/utils/tab-utils";
import { ModalUtilsService } from "src/app/services/modal-utils.service";
import { GridTabsComponent } from "src/app/components/grid-tabs/grid-tabs.component";
import { CompanyNewsletterService } from "@core/services/company-newsletter.service";
import { MixpanelService } from "@core/services/mixpanel.service";
import { AdminPanelService } from "src/app/services/admin/admin-panel.service";
import { AIPrepReportComponent } from "@core/components/ai-prep-report/ai-prep-report.component";
//import { Options } from '@angular-slider/ngx-slider';

@Directive()
export abstract class TabsBaseComponent implements OnInit {
  protected cancelModal = new Subject<void>();
  protected companyInteractionsSubject = new Subject<number>();

  @ViewChild("mainFilterChildRef") mainFilterChildComponent!: MainFilterComponent;
  @ViewChild("gridTabRef") gridRefComponent!: GridTabsComponent;
  @ViewChild("aiPrepReportExtensionRef") aiPrepReportExtensionRef!: AIPrepReportComponent;

  recurringCheckbox: boolean = true;
  oneTimeCheckBox: boolean = false;
  weeklySubscription: boolean = false;

  protected podsAdjustedAfterMainFilterApply = false;
  selectedCompany: Nullable<Company> = null;
  savedSearchId: Nullable<number> = null;
  currentTabIndex: Tab;
  currentChildIndex: Nullable<number> = null;
  // isSelectAll: boolean = false
  // selectedExclude: any[] = []
  previousTabIndex: Nullable<number> = 0;
  previousChildTabIndex: Nullable<number> = 0;
  selectedItems: Company[] = [];
  users: User[] = [];
  companyInteractions = new Map<number, { meetings: Nullable<Interaction>; mail: Nullable<Interaction> }>();
  companyTypes: CompanyType[] = [];
  displayFiltersMenu = false;
  displayModal = false;
  modalLoading = false;
  currentModalAction: Nullable<ModalActions> = null;
  companyDetailsLoading: boolean = false;
  interactionLoading = true;
  deletedTags: string[] = [];
  displayCompanyDetails = false;
  companyDetails: Nullable<Company> = null;
  changeCompanyStatusDropdownOptions: DropdownOption[];
  newStatusId: Nullable<StatusId> = null;
  title: Nullable<string> = null;
  comment: Nullable<string> = null;
  dateAdded!: string | Date;
  companyWorkFlowId!: number;
  checkSize?: number;
  // coverageUsers: User[] = [];
  outreachOwner?: User;
  nextStep?: string;
  actionTaken: Nullable<InteractionType> = null;
  icVote: Nullable<number> = null;
  allPods: Pod[] = [];
  isExpanding = false;
  tabType: TabType = TabType.PortfolioUpdate;
  dataView: string = "table";

  get tabs() {
    return this.tabUtilsService.tabs;
  }

  get isInvestmentCommitteeAdmin() {
    return this.authService.investmentcommitteeadmin;
  }

  toggleSideBar() {
    this.isExpanding = !this.isExpanding;
  }

  getVotingClass(vote: number) {
    return getVotingBackgroundClasses(vote);
  }

  getVotingTextClass(vote: number) {
    return getVotingTextColorClasses(vote);
  }

  emitWeeklySubscription(value: boolean) {
    this.weeklySubscription = value;
  }
  loadNextCompanyIndex: NavigateCompany = 0;

  @ViewChild("headerRow") headerRow: Nullable<ElementRef> = null;

  showBackToTopButton$: Observable<boolean> = fromEvent(document, "scroll")
    .pipe(map(() => (this.headerRow?.nativeElement?.getBoundingClientRect().y ?? 0) < 0))
    .pipe(throttle(() => timer(200), { leading: true, trailing: true }));

  timeBasedChecked = false;
  timeBasedTriggerType: TimeBasedTriggerOccurrence = TimeBasedTriggerOccurrence.Recurring;
  recurring: Nullable<boolean> = null;
  recurringWeekly: number = 1;
  recurringMonthly: number = 1;
  triggerOn: Nullable<Date> = null;
  fteTrigger: NotificationTriggerModal = createNotificationTriggerModal();
  fteGrowthTrigger: NotificationTriggerModal = createNotificationTriggerModal();
  picTrigger: NotificationTriggerModal = createNotificationTriggerModal();
  revenueTrigger: NotificationTriggerModal = createNotificationTriggerModal();
  previousUrl: string = "";

  get currentTab(): TabData<Company> {
    const tab = this.tabUtilsService.getAvailableUserTabs(this.tabs)[this.currentTabIndex];
    return tab.children?.[this.currentChildIndex!] ?? tab;
  }

  get interactionType(): typeof InteractionType {
    return InteractionType;
  }

  get selectedCompanyId(): number {
    return this.selectedCompany?.id ?? this.companyDetails!.id;
  }

  get isSelectedCompanyActive(): boolean {
    return this.companyDetails?.isActive ?? this.selectedCompany?.isActive ?? true;
  }

  get selectedCompanyStatusId(): StatusId | null {
    return this.companyDetails?.statusId ?? this.selectedCompany?.statusId ?? null;
  }

  get selectedCompanyEnrichmentStatusId(): WorkFlowStatusId {
    return getEnrichmentStatusId(this.companyDetails?.workflows ?? this.selectedCompany?.workflows ?? []);
  }

  get disableEnrich(): boolean {
    return (
      this.authService.isAnalyst ||
      (this.selectedCompanyEnrichmentStatusId !== WorkFlowStatusId.NoStatus &&
        this.selectedCompanyEnrichmentStatusId !== WorkFlowStatusId.Accepted) ||
      !this.isSelectedCompanyActive
    );
  }

  get disableICVoting(): boolean {
    return (
      getActiveICVotingWorkflow(this.companyDetails?.workflows ?? this.selectedCompany?.workflows ?? []) != null ||
      !this.isSelectedCompanyActive
    );
  }

  get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  get page(): number {
    return +(this.mainFilterRaw.pageNumber ?? "1");
  }

  set page(value: number) {
    this.tabUtilsService.navigateToCurrentTab({ ...this.mainFilterRaw, pageNumber: value.toString(10) }, this.tabs, this.currentTabIndex, this.currentChildIndex);
  }

  get currentTotalPages(): number {
    return Math.ceil(this.currentTotal(this.currentTabIndex) / getPageSize(this.mainFilterRaw)) || 1;
  }

  set mainFilterRaw(value: MainFilterRaw) {
    this.currentTab.mainFilterRaw = value;
  }

  get mainFilterRaw(): MainFilterRaw {
    return this.currentTab.mainFilterRaw!;
  }

  get modalClosable(): boolean {
    return !this.modalLoading;
  }

  get user(): User {
    return this.authService.user.getValue()!;
  }

  get loading(): boolean {
    return this.currentTab.loading!;
  }

  set loading(value: boolean) {
    this.currentTab.loading = value;
  }

  get pipelineTab(): TabData<Company> {
    return this.tabUtilsService.getAvailableUserTabs(this.tabs)[InvestorTab.PIPELINE];
  }

  get priorityTab(): TabData<Company> {
    return this.tabUtilsService.getAvailableUserTabs(this.tabs)[InvestorTab.PRIORITY];
  }

  get activePod(): Pod {
    return this.currentTab.gridFilter!.activePod;
  }

  set activePod(value: Pod) {
    this.currentTab.gridFilter!.activePod = value;
  }

  get enableCompaniesNavigation(): boolean {
    if (this.displayModal) {
      this.modalUtilsService.toggleNavigation.next(false);
      return false;
    }
    if (this.currentTab.label === "AI Prep (alpha)" || this.currentTab.label === "Portfolio") {
      this.modalUtilsService.toggleNavigation.next(false);
      return false;
    }
    if(this.currentTab.currentTotal! > 1) {
      this.modalUtilsService.toggleNavigation.next(true);
      return true;
    }
    this.modalUtilsService.toggleNavigation.next(true);
    return true;
  }

  get isVotingTab(): boolean {
    return this.currentTabIndex === InvestorTab.VOTING;
  }
  get isPriorityTab(): boolean {
    return this.currentTabIndex === InvestorTab.PRIORITY;
  }
  get isDataQualityTab(): boolean {
    if (this.authService.isAnalyst) {
      return this.currentTabIndex === AnalystTab.DATA_QUALITY;
    }
    return false;
  }
  get isPipelineTab(): boolean {
    return this.currentTabIndex === InvestorTab.PIPELINE;
  }
  get isElevationTab(): boolean {
    return this.currentTabIndex === InvestorTab.ELEVATIONS;
  }
  get isCompanytab(): boolean {
    return this.currentTabIndex === InvestorTab.COMPANIES;
  }

  get canHaveNeutral(): boolean {
    return (
      this.currentTabIndex !== InvestorTab.COMPANIES &&
      this.currentTabIndex !== InvestorTab.ELEVATIONS &&
      this.currentTabIndex !== InvestorTab.VOTING
    );
  }

  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  get currentNotificationTriggers(): NotificationTrigger[] {
    return this.selectedCompany?.notificationTriggers ?? this.companyDetails?.notificationTriggers ?? [];
  }

  get companyDetailsDismissible() {
    return this.tabUtilsService.companyDetailsDismissible;
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected companiesService: CompaniesService,
    protected companiesSearchService: CompaniesSearchService,
    protected savedSearchService: SavedSearchService,
    protected adminPanelService: AdminPanelService,
    protected companiesExportService: CompaniesExportService,
    protected readonly destroy$: DestroyObservable,
    protected messageService: MessageService,
    protected companyHelperService: CompanyHelperService,
    protected statsService: StatsService,
    public authService: AuthService,
    protected filterHelper: FilterHelperService,
    protected usersService: UsersService,
    protected searchHelperService: SearchHelperService,
    public votingService: VotingService,
    public csvService: CSVService,
    public chromeExtensionService: ChromeExtensionService,
    protected deviceDetectorService: DeviceDetectorService,
    private notificationsService: NotificationsService,
    protected investorRolePipe: InvestorRolePipe,
    protected tagsService: TagsService,
    protected investorService: InvestorService,
    protected companyTypeService: CompanyTypeService,
    private viewContainerRef: ViewContainerRef,
    protected industryService: IndustryService,
    protected locationService: LocationService,
    public tabUtilsService: TabUtilsService,
    public modalUtilsService: ModalUtilsService,
    public newsletterService: CompanyNewsletterService,
    private mixpanelService: MixpanelService,
  ) {
    this.currentTabIndex = InvestorTab.COMPANIES;
    this.currentChildIndex = 0;
    this.changeCompanyStatusDropdownOptions = companyStatusOptions;
    this.initializeDataLoadingStream();
  }

  ngOnInit() {
    this.scrollToTop();
    this.filterHelper.getStatuses();
    document.title = environment.config.appTitle;
    this.usersService.getAllUsers().subscribe((res) => {
      if (res.data) {
        this.users = res.data;
      }
    });
    if (!this.isExtension) {
      this.subscribeToSearchHelperService();
    }
  }

  clearAllFilters() {
    if (this.mainFilterChildComponent) {
      this.mainFilterChildComponent.clearFilterAndSubmit();
    }
  }

  excludeFromArr(arr: string[], exclude: string[]): string[] {
    const excludeMap = exclude.reduce<Record<string, boolean>>((all, item) => ({ ...all, [item]: true }), {});
    return arr.filter((item) => !excludeMap?.[item]);
  }

  upsertDeletedTags(tagId: string) {
    this.deletedTags.push(tagId);
  }

  closeCompanyDetailsPanel(tag?: Tag) {
    if (this.companyDetails || this.displayCompanyDetails) {
      // PrimeNg fires onHide event two times, this check prevents double navigation
      if (tag) {
        this.scrollToTop();
        // Removing existing filters
        // this.filterHelper.getDefaultFilter(this.currentTabIndex)
        const defaultFilter = Object.assign(getBaseFilter(this.mainFilterRaw, this.currentTabIndex));
        this.deletedTags = [];
        let tagsPairInclude: IdNamePair[] = [{ id: tag.id, name: tag.name }]
        let filterRawObj = {
          ...defaultFilter,
          tagsInclude: [tag.id],
          sortProperty: "rank",
          sortDirection: "asc",
        };
        filterRawObj = appendToRawValueMapping(filterRawObj, RawValueMappingType.tagsInclude, tagsPairInclude);
        this.tabUtilsService.navigateToCurrentTab({ ...filterRawObj }, this.tabs, this.currentTabIndex, this.currentChildIndex, ["companies"]);
      } else {
        const toDeleteSet = new Set(this.deletedTags);
        if (this.mainFilterRaw.tags) {
          if (!Array.isArray(this.mainFilterRaw.tags)) {
            this.mainFilterRaw.tags = [this.mainFilterRaw.tags];
          }
          const newTagsArr = this.mainFilterRaw.tags.filter((name) => {
            // return those elements not in the toDeleteSet
            return !toDeleteSet.has(name);
          });
          this.mainFilterRaw.tags = newTagsArr;
        }
        this.deletedTags = [];
        this.tabUtilsService.navigateToCurrentTab(this.mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex);
      }

      if (!this.isExtension) {
        this.displayCompanyDetails = false;
        this.companyDetails = null;
        this.selectedCompany = null;
        unblockScroll();
        document.title = environment.config.appTitle;
      }
    }
  }

  closeCompanyDetailsInvestorPanel(investor: Investor) {
    if (this.companyDetails || this.displayCompanyDetails) {
      const investorId = investor?.id;
      // PrimeNg fires onHide event two times, this check prevents double navigation
      if (investorId) {
        // Removing existing filters
        // this.filterHelper.getDefaultFilter(this.currentTabIndex)
        this.scrollToTop();
        const defaultFilter = Object.assign(getBaseFilter(this.mainFilterRaw, this.currentTabIndex));
        this.deletedTags = [];
        let investorsPairInclude: IdNamePair[] = [{ id: investorId, name: investor.name }];
        let rawFilterObj = {
          ...defaultFilter,
          investorsInclude: [investorId],
          sortProperty: "rank",
          sortDirection: "asc",
        };
        rawFilterObj = appendToRawValueMapping(
          rawFilterObj,
          RawValueMappingType.investorsInclude,
          investorsPairInclude
        );
        this.tabUtilsService.navigateToCurrentTab({ ...rawFilterObj }, this.tabs, this.currentTabIndex, this.currentChildIndex, ["companies"]);
      } else {
        this.tabUtilsService.navigateToCurrentTab(this.mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex);
      }

      if (!this.isExtension) {
        this.displayCompanyDetails = false;
        this.companyDetails = null;
        this.selectedCompany = null;
        unblockScroll();
        document.title = environment.config.appTitle;
      }
    }
  }

  toggleDisplayFilterMenu() {
    this.displayFiltersMenu = !this.displayFiltersMenu;
  }

  currentTotal(page: number): number {
    return (
      this.tabUtilsService.getAvailableUserTabs(this.tabs)[page].children?.[this.currentChildIndex!]?.currentTotal ??
      this.tabUtilsService.getAvailableUserTabs(this.tabs)[page].currentTotal!
    );
  }

  beforeApplyMainFilterRaw(mainFilterRaw: MainFilterRaw, route?: string[]) {
    this.isExpanding = false;
    this.scrollToTop();
    this.displayFiltersMenu = false;
    if (this.currentTabIndex === InvestorTab.COMPANIES || InvestorTab.PIPELINE) {
      this.adjustActivePodAfterMainFilterApply(mainFilterRaw);
    }
    this.tabUtilsService.applyMainFilterRaw(mainFilterRaw, this.currentTabIndex, this.currentChildIndex, this.tabs, route);
  }

  firstPage() {
    this.page = 1;
    this.scrollToTop();
  }

  previousPage() {
    if (this.page > 1) {
      this.page--;
      this.scrollToTop();
    }
  }

  nextPage() {
    if (this.page < this.currentTotalPages) {
      this.page++;
      this.scrollToTop();
    }
  }

  lastPage() {
    this.page = this.currentTotalPages;
    this.scrollToTop();
  }

  onMenuClick(company: Company) {
    this.selectedCompany = company;
  }

  initializeDataLoadingStream() {
    const routeChange = this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        startWith(this.router)
      )
      .pipe(takeUntil(this.destroy$));
      const urlParamsChange = routeChange.pipe(
        map((event) => {
          //check if event.url contains "/company"
          if (!event.url.includes("/company")) {
            if (event.url !== "/" && event.url.split("?")[0] !== this.previousUrl) {
              this.previousUrl = event.url.split("?")[0];
              this.mixpanelService.trackPageView();
            }
            if (event.url === "/") {
              this.previousUrl = "/pipeline"
              this.mixpanelService.trackPageView();
            }
          }
          const url = event.url;
          const [path, query] = url.split("?");
          const params = convertRawQueryStringToParams(query);

          let currentTabIndex: Nullable<Tab> = null;
          let currentChildIndex: Nullable<number> = null;

            if(this.isMobile) {
              switch (path) {
                case "/companies":
                  currentTabIndex = InvestorTab.COMPANIES; // Both InvestorTab.COMPANIES and AnalystTab.COMPANIES have the same value, so it doesn't matter
                  currentChildIndex = null;
                  break;
                case "/":
                case "/chatbot":
                default:
                  if(event.url.includes("/company")) {
                    break;
                  }
                  currentTabIndex = InvestorTab.DEEP_CHAT;
                  currentChildIndex = null;
                  this.tabType = TabType.DeepChat;
                  this.displayCompanyDetails = false;
                  break;

                  
              }
            } else {
              switch (path) {
                case "/":
                case "/companies":
                  currentTabIndex = InvestorTab.COMPANIES; // Both InvestorTab.COMPANIES and AnalystTab.COMPANIES have the same value, so it doesn't matter
                  currentChildIndex = 0;
                  break;
                case "/companies/interactions":
                  currentTabIndex = InvestorTab.COMPANIES; // Both InvestorTab.COMPANIES and AnalystTab.COMPANIES have the same value, so it doesn't matter
                  currentChildIndex = 1;
                  this.displayCompanyDetails = false;
                  break;
                case "/pipeline":
                  if (this.authService.isAnalyst) {
                    currentTabIndex = InvestorTab.COMPANIES;
                    currentChildIndex = 0;
                  } else {
                    currentTabIndex = InvestorTab.PIPELINE;
                    currentChildIndex = null;
                  }
                  break;
                case "/elevations":
                  currentTabIndex = InvestorTab.ELEVATIONS;
                  currentChildIndex = null;
                  break;
                case "/priority":
                  currentTabIndex = InvestorTab.PRIORITY;
                  currentChildIndex = null;
                  break;
                case "/data-quality":
                  currentTabIndex = AnalystTab.DATA_QUALITY;
                  currentChildIndex = null;
                  break;
  
                case "/saved-search":
                  currentTabIndex = this.authService.isAnalyst ? AnalystTab.SAVED_SEARCH : this.authService.isAdmin ? AdminTab.SAVED_SEARCH : InvestorTab.SAVED_SEARCH;
                  currentChildIndex = null;
                  this.tabType = TabType.SavedSearch;
                  break;
  
                case "/ai-summary":
                  currentTabIndex = InvestorTab.AI_SUMMARY;
                  currentChildIndex = null;
                  break;
  
                case "/weekly-update":
                  currentTabIndex = InvestorTab.WEEKLY_UPDATE;
                  currentChildIndex = 0;
                  this.tabType = TabType.PortfolioUpdate;
                  this.displayCompanyDetails = false;
                  break;
  
                case "/ic-voting":
                  currentTabIndex = InvestorTab.VOTING;
                  currentChildIndex = 0;
                  this.displayCompanyDetails = false;
                  break;
                
                case "/weekly-update/prospect-update":
                  currentTabIndex = InvestorTab.WEEKLY_UPDATE;
                  currentChildIndex = 1;
                  this.tabType = TabType.ProspectUpdate;
                  this.displayCompanyDetails = false;
                  break;
  
                case "/weekly-update/ir-update":
                  currentTabIndex = InvestorTab.WEEKLY_UPDATE;
                  currentChildIndex = 2;
                  this.tabType = TabType.IRUpdate;
                  this.displayCompanyDetails = false;
                  break;
  
                case "/weekly-update/newsletter-update":
                  currentTabIndex = InvestorTab.WEEKLY_UPDATE;
                  if (this.authService.isInvestorRelation) {
                    currentChildIndex = 3;
                  } else {
                    currentChildIndex = 2;
                  }
                  this.tabType = TabType.NewsletterUpdate;
                  this.displayCompanyDetails = false;
                  break;
  
                case "/ic-voting/history":
                  currentTabIndex = InvestorTab.VOTING;
                  currentChildIndex = 1;
                  this.displayCompanyDetails = false;
                  break;
                case "/admin-panel":
                  currentTabIndex = this.authService.isInvestor ?  InvestorTab.ADMIN_PANEL : this.authService.isAdmin ? AdminTab.ADMIN_PANEL : AnalystTab.COMPANIES;
                  currentChildIndex = 0;
                  break;
                case "/admin-panel/alert":
                  currentTabIndex = this.authService.isInvestor ?  InvestorTab.ADMIN_PANEL : AdminTab.ADMIN_PANEL;
                  currentChildIndex = 1;
                  break;
                case "/chatbot":
                  currentTabIndex = InvestorTab.DEEP_CHAT;
                  currentChildIndex = null;
                  this.tabType = TabType.DeepChat;
                  this.displayCompanyDetails = false;
                  break;
              }
            }

          if (currentTabIndex == null && this.tabUtilsService.getCurrentTabData(this.tabs, this.currentTabIndex, this.currentChildIndex).length === 0) {
            currentTabIndex = this.currentTabIndex;
            currentChildIndex = this.currentChildIndex;
          }
          return <URLParams>{ currentTabIndex, currentChildIndex, params };
        })
      );

      combineLatest([urlParamsChange, this.tabUtilsService.refreshData])
        .pipe(
          map(([urlParams]) => urlParams),
          filter(({ currentTabIndex }) => currentTabIndex != null),
          tap(this.handleFilters()),
          switchMap(() => {
            if (this.currentTab && this.currentTab?.type === TabType.SavedSearch) {
              this.previousTabIndex = this.currentTabIndex;
              this.previousChildTabIndex = this.currentChildIndex;
              this.isExpanding = false;
              this.displayCompanyDetails = false;
              this.companyDetails = null;
              return this.savedSearchService
                .getSavedSearches({
                  ...Object.assign(getBaseFilter(this.mainFilterRaw, this.currentTabIndex)),
                  ...this.mainFilterRaw,
                  sortProperty: "createDate",
                  sortDirection: "desc",
                })
                .pipe(this.saveCompanies(this.currentTabIndex));
            } else if (this.currentTab && this.currentTab?.type === TabType.AiSummary) {
              this.previousTabIndex = this.currentTabIndex;
              this.previousChildTabIndex = this.currentChildIndex;
              this.isExpanding = false;
              this.displayCompanyDetails = false;
              // this.companyDetails = null;
              if (this.isExtension) {
                if (this.aiPrepReportExtensionRef) {
                  this.aiPrepReportExtensionRef.submitDataOnRouteChange(this.mainFilterRaw.companyDomain, this.mainFilterRaw.companyId);
                  this.mainFilterRaw.companyDomain = undefined;
                }
              } else {
                if (this.gridRefComponent && this.gridRefComponent.aiPrepReportComponent) {
                  this.gridRefComponent.aiPrepReportComponent.submitDataOnRouteChange(this.mainFilterRaw.companyDomain, this.mainFilterRaw.companyId);
                  this.mainFilterRaw.companyDomain = undefined;
                }
              }
              return from([]);
            } else if (this.currentTab && this.currentTab?.type === TabType.ProspectUpdate) {
              return from([]);
            } else if (this.currentTab && this.currentTab?.type === TabType.PortfolioUpdate) {
              return from([]);
            }
            else if (this.currentTab && this.currentTab?.type === TabType.AlertPanel) {
              this.previousTabIndex = this.currentTabIndex;
              this.previousChildTabIndex = this.currentChildIndex;
              this.isExpanding = false;
              this.displayCompanyDetails = false;
              this.companyDetails = null;
              return this.adminPanelService
                .getAllJobs()
                .pipe(this.saveCompanies(this.currentTabIndex, this.currentChildIndex ?? undefined));
            } else if (this.currentTab && this.currentTab?.type === TabType.UserPanel) {
              this.previousTabIndex = this.currentTabIndex;
              this.previousChildTabIndex = this.currentChildIndex;
              this.isExpanding = false;
              this.displayCompanyDetails = false;
              this.companyDetails = null;
              return this.usersService
                .getUsers(this.mainFilterRaw)
                .pipe(this.saveCompanies(this.currentTabIndex, this.currentChildIndex ?? undefined));
            }
            else {
              return this.getLoadDataObservable();
            }
          })
        )
        .subscribe(() => {
          // this.dataTable?.resetScrollTop();
        });

    const companyIdRouteChanges = routeChange.pipe(
      map((event) => {
        const url = (<NavigationEnd>event).url;
        if (url.startsWith("/company")) {
          const [id] = url.split("/company")[1].split("/")[1].split("-");
          if (!this.displayCompanyDetails) {
            //TODO: check why this route change event is running multiple times
            this.companyDetailsLoading = true;
          }
          if (isNaN(id as any)) {
            this.tabUtilsService.navigateToCurrentTab(this.mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex);
            setTimeout(() => this.showErrorToastMessage("Unknown company identifier."), 10);
            this.displayCompanyDetails = false;
            return null;
          }
          this.displayCompanyDetails = true;
          return +id;

          return null;
        }

        return null;
      }),
      distinctUntilChanged()
    );
    const messageChanges = this.companyHelperService.currentMessage.pipe(
      map((message) => {
        if (message === "refresh-details" && this.displayCompanyDetails) {
          return this.selectedCompanyId;
        }
        return null;
      })
    );

    merge(messageChanges, companyIdRouteChanges)
      .pipe(
        switchMap((id) => {
          return id != null ? this.companiesService.getCompanyDetails(id) : EMPTY;
        })
      )
      .subscribe((response) => {
        this.companyDetailsLoading = false;
        const { data } = response;
        if (this.displayCompanyDetails) {
          if (data) {
            this.adjustCoverageData(data);
            this.companyDetails = data!;
            blockScroll();
            this.selectedCompany = data!;
            this.tabUtilsService.navigateToCompany(data!, true, this.route.snapshot.fragment);
            this.companyHelperService.changeMessage("refresh-finished");
          } else if (response.statusCode === 404) {
            this.showErrorToastMessage("Company details not found");
            this.tabUtilsService.navigateToCurrentTab(this.mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex);
            this.displayCompanyDetails = false;
          } else {
            this.showErrorToastMessage("Error loading company details");
            this.tabUtilsService.navigateToCurrentTab(this.mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex);
          }
        }
      });
    merge(messageChanges, companyIdRouteChanges)
      .pipe(
        switchMap((id) => {
          return id != null ? this.newsletterService.getSubscriptionStatus(id) : EMPTY;
        })
      )
      .subscribe(({ data }) => {
        if (data) {
          this.weeklySubscription = data.data ?? false;
        }
      });
  }

  scrollToTop() {
    scrollTo(0, 0);
  }

  updateEnrichmentStatus(enrichStatusId: WorkFlowStatusId) {
    this.modalUtilsService.updateCompany((company, tab, companyIndex) => {
      switch (enrichStatusId) {
        case WorkFlowStatusId.Done:
        case WorkFlowStatusId.RevisionRequired:
          updateEnrichmentStatus(company, enrichStatusId);
          break;
        case WorkFlowStatusId.Accepted:
          if (!!this.mainFilterRaw.workflows) {
            removeCompanyFromTab(tab, companyIndex);
          } else {
            removeEnrichmentWorkflow(company);
          }
          break;
      }
    }, this.tabs, this.selectedCompany, this.companyDetails);
  }

  navigateThruCompanies(value: NavigateCompany) {
    const index = this.currentTab.data!.findIndex((company) => company.id === this.selectedCompanyId);
    if (this.currentTab.data![index + value]) {
      this.tabUtilsService.navigateToCompany(this.currentTab.data![index + value]);
      return;
    }

    if (value === NavigateCompany.NEXT && index === this.currentTab.data!.length - 1) {
      this.page === this.currentTotalPages ? this.firstPage() : this.nextPage();
      this.loadNextCompanyIndex = NavigateCompany.NEXT;
    } else if (value === NavigateCompany.PREVIOUS && index === 0) {
      this.page === 1 ? this.lastPage() : this.previousPage();
      this.loadNextCompanyIndex = NavigateCompany.PREVIOUS;
    }
  }

  protected subscribeToSearchHelperService() {
    this.searchHelperService.currentMessage
      .pipe(takeUntil(this.destroy$))
      .pipe(delay(300))
      .subscribe((message) => {
        const defaultFilter = Object.assign(getBaseFilter(this.mainFilterRaw, this.currentTabIndex));
        let filters: MainFilterRaw | undefined = { ...this.mainFilterRaw, ...defaultFilter, pageNumber: 1 };
        switch (message.type) {
          case "companies-search": {
            if (message?.payload) {
              const messagePayload = message.payload as string;
              if (this.currentTabIndex !== InvestorTab.COMPANIES) {
                filters = this.tabUtilsService.getAvailableUserTabs(this.tabs)[InvestorTab.COMPANIES].mainFilterRaw;
              }
              const filterData: MainFilterRaw = {
                companyName: [messagePayload],
                ...defaultFilter,
                sortProperty: "rank",
                sortDirection: "asc",
              };
              this.tabUtilsService.navigateToCurrentTab(
                {
                  ...filterData,
                },
                this.tabs,
                this.currentTabIndex,
                this.currentChildIndex,
                ["companies"]
              );
            }

            break;
          }

          case "source-search": {
            if (message?.payload) {
              const messagePayload = message.payload as Tag;
              const defaultFilter = Object.assign(getBaseFilter(this.mainFilterRaw, this.currentTabIndex));
              let tagsPairInclude: IdNamePair[] = [{ id: messagePayload.id, name: messagePayload.name }];
              let filterData: MainFilterRaw = {
                ...defaultFilter,
                tagsInclude: [messagePayload.id],
                sortProperty: "rank",
                sortDirection: "asc",
              };
              filterData = appendToRawValueMapping(filterData, RawValueMappingType.tagsInclude, tagsPairInclude);
              this.tabUtilsService.navigateToCurrentTab({ ...filterData }, this.tabs, this.currentTabIndex, this.currentChildIndex, ["companies"]);
            }
            break;
          }
          default:
            break;
        }
      });
  }

  /**
   * If user apply filtering through main filter, set pods filter to All Pods
   */
  adjustActivePodAfterMainFilterApply(mainFilterRaw: MainFilterRaw) {
    this.podsAdjustedAfterMainFilterApply = true;
    if (mainFilterRaw.assigneesInclude?.length) {
      this.activePod = customPod;
    } else {
      this.activePod = firmPod;
    }
    if (mainFilterRaw.interactionInitiatorsInclude?.length) {
      this.activePod = customPod;
    } else {
      this.activePod = firmPod;
    }
  }

  protected getLoadDataObservable(): Observable<ApiResponse<PagerData<Company>>> {
    this.getStats();
    this.isExpanding = false;
    const defaultFilter = Object.assign(getBaseFilter(this.mainFilterRaw, this.currentTabIndex));

    // below if is used to handle select all functionality
    if (this.previousTabIndex !== this.currentTabIndex) {
      this.previousTabIndex = this.currentTabIndex;
      this.previousChildTabIndex = this.currentChildIndex;
      this.displayCompanyDetails = false;
      this.companyDetails = null;
      // this.isSelectAll = false
      // this.selectedExclude = []
      this.selectedItems = [];
    }
    else{
      if (this.currentTabIndex == InvestorTab.COMPANIES 
        && this.previousChildTabIndex != this.currentChildIndex){
        this.previousTabIndex = this.currentTabIndex;
        this.previousChildTabIndex = this.currentChildIndex;
        this.displayCompanyDetails = false;
        this.companyDetails = null;
        // this.isSelectAll = false
        // this.selectedExclude = []
        this.selectedItems = [];
      }
    }
    let dataStream: Observable<ApiResponse<PagerData<Company>>> | null = null;
    let childIndex = -1;
    switch (this.authService.userRole) {
      case "admin":
        switch (this.currentTabIndex) {
          case AdminTab.COMPANIES:
            childIndex = this.currentChildIndex === 0 ? 0 : 1;
            (childIndex === 0) ?
              dataStream = this.companiesSearchService
              .getActiveCompanies({ ...defaultFilter, ...this.mainFilterRaw })
              :
              dataStream = this.companiesSearchService
              .getActiveCompaniesByInteractions({ ...defaultFilter, ...this.mainFilterRaw });
            break;
          case AdminTab.ELEVATIONS:
            this.tabUtilsService.getElevationTabExtentedFilters(this.mainFilterRaw);
            dataStream = this.companiesSearchService
              .getElevations(this.tabUtilsService.getElevationTabExtentedFilters(this.mainFilterRaw));
            break;

          case AdminTab.PIPELINE:
            dataStream = this.companiesSearchService
              .getPipelineCompanies(this.mainFilterRaw);
            break;

          case AdminTab.PRIORITY:
            dataStream = this.companiesSearchService
              .getPriorityCompanies(this.mainFilterRaw);
            break;

          case AdminTab.VOTING:
            childIndex = this.currentChildIndex === 0 ? 0 : 1;
            dataStream = this.currentChildIndex === 0
              ? this.companiesSearchService
                .getICVotingCompanies(this.mainFilterRaw)
              : this.companiesSearchService
                .getICVotingHistoryCompanies(this.mainFilterRaw);
            break;
        }
        break;
      case "investor":
        switch (this.currentTabIndex) {
          case InvestorTab.COMPANIES:
            childIndex = this.currentChildIndex === 0 ? 0 : 1;
            (childIndex === 0) ?
              dataStream = this.companiesSearchService
              .getActiveCompanies({ ...defaultFilter, ...this.mainFilterRaw })
              :
              dataStream = this.companiesSearchService
              .getActiveCompaniesByInteractions({ ...defaultFilter, ...this.mainFilterRaw });
            break;
          case InvestorTab.ELEVATIONS:
            dataStream = this.companiesSearchService
              .getElevations(this.tabUtilsService.getElevationTabExtentedFilters(this.mainFilterRaw));
            break;
          case InvestorTab.PIPELINE:
            dataStream = this.companiesSearchService
              .getPipelineCompanies(this.mainFilterRaw);
            break;
          case InvestorTab.PRIORITY:
            dataStream = this.companiesSearchService
              .getPriorityCompanies(this.mainFilterRaw);
            break;
          case InvestorTab.VOTING:
            childIndex = this.currentChildIndex === 0 ? 0 : 1;
            dataStream = this.currentChildIndex === 0
              ? this.companiesSearchService
                .getICVotingCompanies(this.mainFilterRaw)
              : this.companiesSearchService
                .getICVotingHistoryCompanies(this.mainFilterRaw);
            break;
        }
        break;
      case "analyst":
        switch (this.currentTabIndex) {
          case AnalystTab.COMPANIES:
            childIndex = this.currentChildIndex === 0 ? 0 : 1;
            (childIndex === 0) ?
              dataStream = this.companiesSearchService
              .getActiveCompanies({ ...defaultFilter, ...this.mainFilterRaw })
              :
              dataStream = this.companiesSearchService
              .getActiveCompaniesByInteractions({ ...defaultFilter, ...this.mainFilterRaw });
            break;
          case AnalystTab.DATA_QUALITY:
            dataStream = this.companiesSearchService
              .getEnrichedCompanies(this.mainFilterRaw)
            break;
        }
    }
    if (dataStream) {
      return dataStream.pipe(this.saveCompanies(this.currentTabIndex, childIndex));
    }
    return EMPTY;
  }

  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected parseSavedSearchResults(
    index: Tab,
    childIndex = -1
  ): MonoTypeOperatorFunction<ApiResponse<PagerData<any>>> {
    return tap({
      subscribe: () => (this.loading = true),
      next: ({ data, error }) => {
        this.loading = false;
        if (!error) {
          const responseData = data!;
          if (responseData.data.length) {
            const tabs = this.tabUtilsService.getAvailableUserTabs(this.tabs)[index];
            const tab = tabs?.children?.[childIndex] ?? tabs;
            tab.data = responseData.data;
            tab.currentTotal = responseData.totalCount;
          }
        } else {
          this.showErrorToastMessage("Service unavailable.");
        }
      },
    });
  }
  protected saveCompanies(index: Tab, childIndex = -1): MonoTypeOperatorFunction<ApiResponse<PagerData<Company>>> {
    return tap({
      subscribe: () => (this.loading = true),
      next: ({ data, error }) => {
        this.loading = false;
        if (!error) {
          const responseData = data!;
          if (this.currentTab.type !== TabType.AdminPanel && responseData?.data?.length) {
            responseData.data.forEach((company) => {
              this.adjustCoverageData(company);
            });
          }
          const tabs = this.tabUtilsService.getAvailableUserTabs(this.tabs)[index];
          const tab = tabs?.children?.[childIndex] ?? tabs;
          if (this.currentTab.type === TabType.UserPanel) {
            tab.data = responseData as any;
          } else {
            tab.data = responseData.data;
          }
          // below if is used to handle select all functionality
          // if (this.isSelectAll) {
          //   this.selectedItems = tab.children?.[this.currentChildIndex!].data ?? tab.data!;
          //   this.selectedItems = this.selectedItems.filter(obj1 => !this.selectedExclude.some(obj2 => obj2.id === obj1.id));
          // }
          tab.currentTotal = responseData.totalCount;
          if (this.loadNextCompanyIndex !== NavigateCompany.NONE) {
            const navigateIndex =
              this.loadNextCompanyIndex === NavigateCompany.NEXT ? 0 : this.currentTab.data!.length - 1;
            this.tabUtilsService.navigateToCompany(this.currentTab.data![navigateIndex]);
            this.loadNextCompanyIndex = NavigateCompany.NONE;
          }
          if (responseData.totalCountByAssignee) {
            this.tabs.investor[InvestorTab.PIPELINE].totalCountByAssignee = responseData.totalCountByAssignee;
          }
        } else {
          this.showErrorToastMessage("Service unavailable.");
        }
        window.scrollTo(0, -500);
      },
    });
  }


  protected getStats() {
    this.statsService.stats$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data) {
        switch (this.authService.userRole) {
          case "investor":
            this.tabs.investor[InvestorTab.COMPANIES].total = response.data.companiesTotal;
            if (this.tabs.investor[InvestorTab.COMPANIES].children){
              this.tabs.investor[InvestorTab.COMPANIES].children[0].total = response.data.companiesTotal;
              this.tabs.investor[InvestorTab.COMPANIES].children[1].total = response.data.totalInteractions;// Store interactions total here
            }
            this.tabs.investor[InvestorTab.ELEVATIONS].total = response.data.companiesElevation;
            //this.tabs.investor[InvestorTab.PIPELINE].total = response.data.companiesPipeline;
            //this.tabs.investor[InvestorTab.PRIORITY].total = response.data.companiesPriority;
            break;
          case "analyst":
            this.tabs.analyst[AnalystTab.COMPANIES].total = response.data.companiesTotal;
            this.tabs.analyst[AnalystTab.DATA_QUALITY].total = response.data.companiesEnriched;
            break;
        }
      }
    });
  }

  protected handleFilters() {
    return ({ currentTabIndex, currentChildIndex, params }: URLParams) => {
      if (currentTabIndex != null) {
        this.currentTabIndex = currentTabIndex;
        this.currentChildIndex = currentChildIndex;

        if (JSON.stringify(params) === "{}") {
          // if params are empty
          this.mainFilterRaw = Object.assign(getBaseFilter(this.mainFilterRaw, this.currentTabIndex), this.filterHelper.getDefaultFilter(currentTabIndex, currentChildIndex));
        } else {
          this.mainFilterRaw = { ...params };
          if (this.mainFilterRaw.sortProperty !== 'status') {
            delete this.mainFilterRaw.thenSortProperty;
            delete this.mainFilterRaw.thenSortDirection;
          }
        }
        // this.adjustActivePod();
      }
    };
  }

  protected adjustCoverageData(company: Company) {
    const currentUserCoverageData = company.coverageUsers?.find((user) => user.id === this.user?.id);
    if (currentUserCoverageData) {
      company.priority = currentUserCoverageData.priority!;
      company.priorityDays = currentUserCoverageData.priorityDays!;
    }
  }

  get isTablet(): boolean {
    return this.deviceDetectorService.isTablet();
  }

  onDataView(value: string){
    this.dataView = value;
  }
}