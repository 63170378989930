import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Company } from '@core/models/company';
import { Nullable } from '@core/models/nullable';
import { InvestorTab, Tab } from '@core/models/types';
import { UserRole } from '@core/models/user';
import { AIPrepService } from '@core/services/ai-prep.service';
import { TabData } from '@core/static/tabs';
import { Observable, catchError, concat, concatMap, of, takeUntil } from 'rxjs';
import { DestroyObservable } from 'src/app/rxjs/DestroyObservable';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { CompanyType } from '@core/models/company-type';
import { CompanyTypeService } from '@core/services/company-type.service';
import { Location } from '@angular/common';
import { MainFilterRaw } from '@core/models/main-filter-view';
import html2canvas from 'html2canvas';
import { convertStatusIdToStatus } from 'src/app/utils/company-utils';
import { MessageService } from 'primeng/api';
import moment from 'moment';
import { Round } from '@core/models/auxiliary-company-models';
import { Chart } from 'chart.js';
import { formatDate } from 'src/app/utils/utils';
import { MixpanelService } from '@core/services/mixpanel.service';
import { TabUtilsService } from 'src/app/services/tab-utils.service';
import { ChromeExtensionService } from 'src/app/services/chrome-extension/chrome-extension.service';
import { slugify } from 'src/app/utils/slugify';

export type Section = {
  title: string;
  isSelected: boolean;
  isLoading: boolean;
  data: any;
  promptComplexity: string;
  lowPrompt: string;
  highPrompt: string;
  error: string;
}

export type AIPrepRequestBody = {
  domain: string;
  companyDescription?: string;
  userPrompt: string;
  useGpt4: boolean;
}

@Component({
  selector: 'bpc-ai-prep-report',
  templateUrl: './ai-prep-report.component.html',
  styleUrls: ['./ai-prep-report.component.scss']
})
export class AIPrepReportComponent {

  @Input() currentTabIndex!: Tab;
  @Input() currentChildIndex!: Nullable<number>;
  @ViewChild('chart') chart!: ElementRef;
  @ViewChild('pdfDiv', { static: false }) pdfDiv!: ElementRef<HTMLDivElement>;

  stateOptions = [{ label: 'Low', value: 'low', disabled: true }, { label: 'High', value: 'high', disabled: true }];
  languageModelOptions = [{ label: 'Gpt 3.5', value: false, }, { label: 'Gpt 4', value: true }];
  useGpt4: boolean = true;
  visible: boolean = false;
  selectedSection: Nullable<Section> = null;
  refreshPrompt: boolean = false;
  company: Nullable<Company> = null;
  companyDetails: Nullable<Company> = null;
  companyTypes: CompanyType[] = [];
  isCompanyNotFound: boolean = false;
  domain: Nullable<string> = null;
  previousDomain: Nullable<string> = null;
  isPDFDownloading: boolean = false;
  chartImage: string | null = null;
  master_sections: Section[] = [
    { title: "Description", isSelected: true, isLoading: false, data: null, promptComplexity: "low", lowPrompt: "", highPrompt: "", error: "" },
    // { title: "Product", isSelected: true, isLoading: false, data: null, promptComplexity: "low", lowPrompt: "", highPrompt: "", error: "" },
    { title: "Market", isSelected: true, isLoading: false, data: null, promptComplexity: "low", lowPrompt: "", highPrompt: "", error: "" },
    { title: "Competitors", isSelected: true, isLoading: false, data: null, promptComplexity: "low", lowPrompt: "", highPrompt: "", error: "" },
    // { title: "Thesis", isSelected: true, isLoading: false, data: null, promptComplexity: "low", lowPrompt: "", highPrompt: "", error: "" },
    // { title: "Leadership", isSelected: true, isLoading: false, data: null, promptComplexity: "low", lowPrompt: "", highPrompt: "", error: "" },
    { title: "Questions", isSelected: true, isLoading: false, data: null, promptComplexity: "low", lowPrompt: "", highPrompt: "", error: "" },
    { title: "Recent News", isSelected: true, isLoading: false, data: null, promptComplexity: "low", lowPrompt: "", highPrompt: "", error: "" }
  ];
  name: string | undefined;

  get tabs() {
    return this.tabUtilsService.tabs;
  }

  get currentTab(): TabData<Company> {
    const tab = this.tabUtilsService.getAvailableUserTabs(this.tabs)[this.currentTabIndex];
    return tab.children?.[this.currentChildIndex!] ?? tab;
  }

  get mainFilterRaw(): MainFilterRaw {
    return this.currentTab.mainFilterRaw!;
  }

  get isPDFDownloadEnabled(): boolean {
    return this.master_sections.every((section) => !section.isLoading && (section.data !== null || section.error !== ""));
  }

  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  constructor(private aiPrepService: AIPrepService,
    protected router: Router,
    private readonly destroy$: DestroyObservable,
    public companiesService: CompaniesService,
    private companyTypeService: CompanyTypeService,
    private location: Location,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private mixpanelService: MixpanelService,
    private tabUtilsService: TabUtilsService,
    public chromeExtensionService: ChromeExtensionService,
  ) {
    this.companyTypeService.companyTypes$.pipe(takeUntil(destroy$)).subscribe((response) => {
      this.companyTypes = response.data ?? [];
    });
  }

  get companyType(): string | undefined {
    if (!this.company) return undefined;
    return this.companyTypes.find((x) => x.id === this.company?.companyType)?.name;
  }

  ngOnInit() {
    const params = this.route.snapshot?.queryParams;
    let companyDomain = params ? params['companyDomain'] : null;
    let companyId = params ? params['companyId'] : null;
    if (companyDomain && companyId) {
      this.submitDataOnRouteChange(companyDomain, companyId);
    }
  }

  reloadSection(i: number) {
    this.refreshPrompt = false;
    let section = this.master_sections[i];
    let apiEndpoint: Observable<any> = this.getApiEndpoint(section);
    this.getSectionData(section, apiEndpoint);
  }

  getSectionData(section: Section, apiEndpoint: Observable<any>) {
    section.data = null;
    section.error = "";
    section.isLoading = true;
    apiEndpoint.pipe(
      catchError((error) => {
        section.isLoading = false;
        section.error = 'An error occured. Please reload this section';
        return of(null);
      })).subscribe((res) => {
        section.isLoading = false;
        if(section.title === "Recent News") {
          section.data = res;
        } else {
          section.data = this.replaceSingleNewlinesWithDouble(res.section_data);
        }
        if (section.promptComplexity === "low") {
          section.lowPrompt = res.section_prompt;
        } else {
          section.highPrompt = res.section_prompt;
        }
      });
  }

  replaceSingleNewlinesWithDouble(text: string): string {
    const regex = /([^\n])\n([^\n])/g;
    return text.replace(regex, '$1\n\n$2');
  }

  submitDataOnRouteChange(domain?: string, companyId?: number) {
    if (companyId) {
      this.companyDetails = null;
      this.companiesService.getCompanyDetails(companyId).subscribe(({ data }) => {
        if (data) {
          this.companyDetails = data!;
          this.generateChartData();
        }
        else {
          this.companyDetails = null;
        }
      });
    }
    if (domain) {
      this.domain = domain;
      this.previousDomain = this.domain;
      this.companiesService.getCompanyByDomain(this.cleanUrl(this.domain!)).subscribe(({ data }) => {
        if (data) {
          this.company = data;
          this.isCompanyNotFound = false;
          this.handleSubmit();

        }
        else {
          this.company = null;
          this.isCompanyNotFound = true;
        }
      });
    }
  }

  getApiEndpoint(section: Section): Observable<any> {
    let apiBody: AIPrepRequestBody = {
      domain: this.cleanUrl(this.domain!),
      companyDescription: this.company?.description ?? "",
      userPrompt: this.refreshPrompt ? "" : section.lowPrompt,
      useGpt4: this.useGpt4,
    };
    switch (section.title) {
      case "Description":
        return this.aiPrepService.getCompanyDescription(apiBody);
      case "Product":
        return this.aiPrepService.getProducts(apiBody);
      case "Competitors":
        return this.aiPrepService.getKeyCompetitors(apiBody);
      case "Market":
        return this.aiPrepService.getMarketOverview(apiBody);
      case "Thesis":
        return this.aiPrepService.getInvestmentThesis(apiBody);
      case "Leadership":
        return this.aiPrepService.getLeadership(apiBody);
      case "Questions":
        return this.aiPrepService.getQuestionsForCEO(apiBody);
      case "Recent News":
        let recentNewsBody: any = {
          "news_item":{
            "company_name": this.company?.name,
            "company_domain": this.cleanUrl(this.domain!)
          },
          "freshness": "month"
        }
        return this.aiPrepService.getRecentNews(recentNewsBody);
      default:
        return of(null);
    }
  }

  handleSubmit() {
    this.previousDomain = this.domain;
    const apiCalls: Observable<any>[] = [];
    this.refreshPrompt = true;
    this.master_sections.forEach((section) => {
      if (section.isSelected) {
        let apiEndpoint: Observable<any> = this.getApiEndpoint(section);
        this.getSectionData(section, apiEndpoint);
      }
    });
    this.refreshPrompt = false;
    this.companiesService.getCompanyByDomain(this.cleanUrl(this.domain!)).subscribe(({ data }) => {
      if (data) {
        this.company = data;
        this.isCompanyNotFound = false;
      }
      else {
        this.company = null;
        this.isCompanyNotFound = true;
      }
    });
    this.mainFilterRaw.companyDomain = undefined;
    this.mainFilterRaw.companyId = undefined;
    this.clearCompanyDomainQueryParam();
  }

  clearCompanyDomainQueryParam() {
    this.location.replaceState(this.location.path().split('?')[0]);
  }

  handleClearForm() {
    this.domain = null;
  }

  selectAll() {
    this.master_sections.forEach((section) => {
      section.isSelected = true;
    });
  }

  clearAll() {
    this.master_sections.forEach((section) => {
      section.isSelected = false;
    });
  }

  toggleSections(index: number) {
    this.master_sections[index].isSelected = !this.master_sections[index].isSelected;
  }

  openModal(index: number) {
    this.selectedSection = this.master_sections[index];
    this.visible = true;
  }

  goBack() {
    this.router.navigate([`/company/${this.companyDetails!.id}-${slugify(this.companyDetails!.name)}`]);
  }

  isValidForm() {
    return this.domain !== null && this.domain !== "";
  }

  onHide() {
    this.visible = false;
    this.master_sections.forEach((section) => {
      if (section.title === this.selectedSection!.title) {
        section = this.selectedSection!;
      }
    });
    this.selectedSection = null;
  }

  cleanUrl(url: string) {
    let domain = url.replace(/^(https?:\/\/)?(w{3,}\d*\.)|(https?:\/\/)/, '');
    var firstSlashAfterTopLevelDomain = domain.indexOf('/');
    if (firstSlashAfterTopLevelDomain !== -1) {
      domain = domain.substring(0, firstSlashAfterTopLevelDomain);
    }
    return domain;
  }

  submitPrompt() {
    this.previousDomain = this.domain;
    this.refreshPrompt = false;
    this.visible = false;
    this.master_sections.forEach((section) => {
      if (section.title === this.selectedSection!.title) {
        section = this.selectedSection!;
      }
    });
    // this.selectedSection!.highPrompt = this.selectedSection!.highPrompt.trim();
    let apiEndpoint: Observable<any> = this.getApiEndpoint(this.selectedSection!);
    this.getSectionData(this.selectedSection!, apiEndpoint);
    this.selectedSection = null;
  }

  formatPDFHtml() {
    const pdfDiv = this.pdfDiv.nativeElement;
    const pdfDivString = pdfDiv.innerHTML;
    const parser = new DOMParser();
    const serializer = new XMLSerializer();
    const pdfDoc = parser.parseFromString(pdfDivString, 'text/html');
    const divs = pdfDoc.querySelectorAll(`div.section-utility-buttons-container`);
    if (divs) {
      divs.forEach((element) => {
        element.remove();
      });
    }
    const sections = pdfDoc.querySelectorAll(`section`);
    if (sections) {
      sections.forEach((element) => {
        element.classList.remove('sub-block');
      });
    }
    const images = pdfDoc.querySelectorAll(`img`);
    if (images) {
      images.forEach((element) => {
        element.remove();
      });
    }
    return serializer.serializeToString(pdfDoc);
  }

  addNewLine(text: string, lineLength: number) {
    const words = text.split(' ');
    let currentLine = '';
    let result = '';
    for (const word of words) {
      if (currentLine.length + word.length <= lineLength) {
        // Add the word to the current line
        currentLine += (currentLine === '' ? '' : ' ') + word;
      } else {
        // Start a new line with the current word
        result += currentLine + '\n';
        currentLine = word;
      }
    }
    // Append the remaining content to the result
    result += currentLine + '\n';
    return result;
  }

  private displayToastMessage(message: string, severity = "error", life = 3000) {
    this.messageService.add({
      key: "home-bc",
      severity,
      detail: message,
      life,
    });
  }

  public async downloadAsPDF(event: Event) {
    event.preventDefault();
    this.isPDFDownloading = true;
    let pdfRequestBody = {
      content: this.master_sections.map((section) => {
        if (section.title === 'Recent News') {
          let recentNewsSection = section.data.news;
          if (recentNewsSection.length > 0 && Array.isArray(recentNewsSection)) {
            let convertedText = "";
            recentNewsSection.forEach((news: any, index: number) => {
              const questionLine = `${index + 1}. <strong>${news.article_title}</strong>`;
              const descriptionLines = news.article_description;
              const articleDate = `<i>${news.article_date}</i>`;
              convertedText += `${questionLine}<br>${descriptionLines}<br>${articleDate}\n`;
            });
          return {
            sectionTitle: section.title,
            sectionData: convertedText
          };
        } else {
          let message = 'No recent news available.';
          return {
            sectionTitle: section.title,
            sectionData: message
          }
        }
        } else {
          return {
            sectionTitle: section.title,
            sectionData: section.data
          }
        }
        
      }),
      companyId: this.companyDetails?.id,
      activityGraphImage: this.chartImage ?? "",
      companyDomain: this.cleanUrl(this.domain!),
      companyType: this.companyType ?? "",
      companyStatus: convertStatusIdToStatus(this.companyDetails?.statusId!) ?? "",
    }
    this.aiPrepService.generatePDF(pdfRequestBody).pipe(
      catchError((error) => {
        this.isPDFDownloading = false;
        return of(null);
      })).subscribe((res) => {
        if (res && res.body) {
          const blob = new Blob([res.body], { type: 'application/pdf' });

          // Create a download link and trigger the download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `AI Prep - ${this.cleanUrl(this.domain!)}.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

          this.mixpanelService.trackEvent('Ai_Prep_Download_PDF');
        }
        else {
          this.displayToastMessage("Error generating pdf");
        }
        this.isPDFDownloading = false;
      });
  }

  generateChartData() {
    this.generateFtesData();

  }

  private loadBasicData(chartData: number[], chartLabels: string[]): void {

    const chartCanvas = this.chart.nativeElement;
    const existingChart = Chart.getChart(chartCanvas); // Get the existing chart instance
    if (existingChart) {
      existingChart.destroy(); // Destroy the existing chart
    }

    if (chartData.length > 0 && chartData.length > 0) {
      const datasets = [
        {
          label: "FTE:",
          data: chartData,
          fill: false,
          radius: 4,
          borderColor: "rgba(0, 36, 56, .2)",
          borderWidth: 1,
          barThickness: 25,
          backgroundColor: "#095A70",
          tension: 0.6,
        },
      ]

      const ctx = this.chart.nativeElement.getContext('2d');


      // ctx.clearRect(0, 0, this.chart.nativeElement.width, this.chart.nativeElement.height); // Clear the chart canvas
      const self = this;
      const options: any = {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets,
        },
        options: {
          aspectRatio: 0.6,
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              position: "bottom",
              grid: {
                display: false, // Hide grid lines on the x-axis
              },
            },
            y: {
              grace: 1,
              grid: {
                display: true, // Show grid lines on the y-axis
              },
              ticks: {
                display: true, // Hide y-axis labels
              },
            },
          },
          animation: {
            onComplete: function () {
              self.chartImage = myChart.toBase64Image();
            },
          },
        },
      }
      const myChart = new Chart(ctx, options);
    }
    else {
      this.chartImage = null;
    }


  }

  private generateFtesData() {
    const ftes = this.companyDetails?.employeeSeries || [];
    const fullData = ftes.sort((a, b) => {
      return Date.parse(a.seriesDate) - Date.parse(b.seriesDate);
    });
    const lastYear = fullData.filter((x) => moment(x.seriesDate).diff(Date.now(), "years") === 0);
    const lastTwoYears = fullData.filter((x) => moment(x.seriesDate).diff(Date.now(), "years") >= -1);
    const lastThreeYears = fullData.filter((x) => moment(x.seriesDate).diff(Date.now(), "years") >= -2);

    const chartData = [];
    chartData.push(
      fullData.map((x) => (x.count)),
      lastYear.map((x) => (x.count)),
      lastTwoYears.map((x) => (x.count)),
      lastThreeYears.map((x) => (x.count))
    );

    const chartLabels = [];
    chartLabels.push(
      fullData.map((x) => formatDate(x.seriesDate)),
      lastYear.map((x) => formatDate(x.seriesDate)),
      lastTwoYears.map((x) => formatDate(x.seriesDate)),
      lastThreeYears.map((x) => formatDate(x.seriesDate))
    );
    this.loadBasicData(chartData[1], chartLabels[1]);
  }

  navigateToCompanyTab() {
    let queryParams = { ...this.tabUtilsService.getAvailableUserTabs(this.tabs)[InvestorTab.COMPANIES].mainFilterRaw };
    this.router.navigate([this.tabUtilsService.getAvailableUserTabs(this.tabs)[InvestorTab.COMPANIES].route], { queryParams });
  }
}
