import { Directive, EventEmitter, Output } from '@angular/core';
import { Sidebar } from 'primeng/sidebar';
import { fromEvent, takeUntil } from 'rxjs';
import { DestroyObservable } from '../../../rxjs/DestroyObservable';

@Directive({
  selector: 'p-sidebar[companyDetails]',
  providers: [DestroyObservable]
})
export class CompanyDetailsDirective {

  @Output() maskClicked = new EventEmitter<void>();

  constructor(sidebar: Sidebar, destroyObservable: DestroyObservable) {
    sidebar.onShow
      .pipe(takeUntil(destroyObservable))
      .subscribe(() => {
        fromEvent(sidebar.mask!, 'click')
          .pipe(takeUntil(sidebar.onHide))
          .subscribe(() => {
            this.maskClicked.emit();
          });
      });
  }
}
