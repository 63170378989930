import { Component, Input } from '@angular/core';

@Component({
  template: `
    <div class="flex flex-row-reverse">{{ currentLength }} / {{ maxLength }}</div>
  `
})
export class MaxLengthComponent {
  @Input() maxLength!: number;
  @Input() currentLength = 0;
}
