import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Company } from '@models/company';
import * as moment from 'moment';
import { Nullable } from '@models/nullable';
import { EmployeeSeries, Interaction, InvestorRole, Round } from '@models/auxiliary-company-models';

@Component({
  selector: 'bpc-company-pic-data',
  templateUrl: './company-pic-data.component.html',
  styleUrls: ['./company-pic-data.component.scss']
})
export class CompanyPICDataComponent implements OnChanges {
  @Input() companyDetails!: Company;

  validPICData: boolean = false;
  roundsData: Round[] = [];
  selectedInteraction: Nullable<number> = null;
  selectedRound: Nullable<number> = null;
  interactionsData: Interaction[][] = [];

  get rounds(): Round[] {
    return this.companyDetails.rounds ?? [];
  }

  // ngOnInit(): void {
  //   this.generateRoundsData();
  // }

  ngOnChanges(changes: SimpleChanges){
    this.generateRoundsData();
  }

  getInvestorName(id: number): string {
    return this.companyDetails.investors?.find(investor => investor.id === id)?.name ?? '';
  }

  getLeadInvestorName(round: Round): Nullable<string> {
    const leadInvestor = round.investments?.find(i => i.investorRole === InvestorRole.LEAD);
    if (leadInvestor) {
      return this.getInvestorName(leadInvestor.investorId);
    }

    return null;
  }

  toggleRound(index: number) {
    this.selectedRound = this.selectedRound === index ? null : index;
  }
  private generateRoundsData() {
    this.validPICData = false;
    const fullData: Round[] = this.rounds.sort((a, b) => {
      return Date.parse(b.raiseDate) - Date.parse(a.raiseDate);
    });

    fullData.forEach(round => {
      if (round.investments?.length) {
        round.leadInvestorName = this.getLeadInvestorName(round) ?? null;
        round.investments.forEach(i => {
          i.investorName = this.getInvestorName(i.investorId) ?? null;
        });
      }
    });

    this.roundsData = fullData;
    if(this.roundsData.length>0){
      this.validPICData = true;
    }
  }
}
