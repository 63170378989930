import { BasicCompany } from '@models/company';
import { Nullable } from '@models/nullable';

export type NotificationOperator = '<' | '>=';

export interface Notification {
  id: number;
  trigger: NotificationTrigger;
  company: BasicCompany;
  isRead: boolean;
}

export type NotificationTrigger = OneTimeTrigger | RecurringTrigger | DataTrigger;

export interface NotificationTriggerBase {
  id: number;
  type: NotificationType;
  isActive: boolean;

  dateValue: Nullable<string>;
  dayOfValue: Nullable<number>;
  operator: Nullable<NotificationOperator>;
  scalarValue: Nullable<number>;
}

/* Time-based */
export interface OneTimeTrigger extends NotificationTriggerBase {
  type: NotificationType.OneTime;
  dateValue: string;
}

/* Time-based */
export interface RecurringTrigger extends NotificationTriggerBase {
  type: NotificationType.Weekly | NotificationType.Monthly;
  dayOfValue: number;
}

/* Data-based */
export interface DataTrigger extends NotificationTriggerBase {
  type: NotificationType.FullTimeEquivalent | NotificationType.FullTimeEquivalentGrowth | NotificationType.PaidInCapital | NotificationType.Revenue;
  operator: NotificationOperator;
  scalarValue: number;
}

export enum NotificationType {
  // Time-based
  OneTime = 1,
  Weekly,
  Monthly,

  // Data-based
  FullTimeEquivalent,
  FullTimeEquivalentGrowth,
  PaidInCapital,
  Revenue
}

export interface NotificationsCount {
  unreadCount: number;
}

export interface NotificationsPager {
  page: number;
  notifications: Notification[];
}