import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { AuthService } from './auth.service';
import { ChromeExtensionService } from 'src/app/services/chrome-extension/chrome-extension.service';

@Injectable({
  providedIn: 'root'
})
export class ExtensionAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router,
    private chromeExtensionService: ChromeExtensionService,) {
    this.chromeExtensionService.isExtension.next(true);
    const htmlTag = document.querySelector("html");
    if (htmlTag) {
      htmlTag.classList.add('extension-html-class');
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.user.pipe(
      take(1),
      map(user => {

        if (!user) {
          return this.router.createUrlTree(['/login'], { queryParams: { returnUrl: state.url } });
        } else if (!user.roles.some(role => route.data?.['accessRoles']?.includes(role))) {
          const redirectTo = route.data?.['redirectTo'];

          return redirectTo ? this.router.createUrlTree([redirectTo]) : true;
        } else {
          return true;
        }
      })
    );
  }
}
