import { Component, OnInit } from '@angular/core';
import { Company } from '@models/company';
import { CompaniesSearchService } from '../../../services/companies-search/companies-search.service';
import { DestroyObservable } from '../../../rxjs/DestroyObservable';
import { finalize, takeUntil } from 'rxjs';
import { getCurrentUserVotingComment, getCurrentUserVotingScore } from '../../../utils/company-utils';
import { CompaniesService } from '../../../services/companies/companies.service';
import { MessageService } from 'primeng/api';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'bpc-mobile-vote',
  templateUrl: './mobile-vote.component.html',
  styleUrls: ['./mobile-vote.component.scss'],
  providers: [DestroyObservable]
})
export class MobileVoteComponent implements OnInit {

  loading = false;
  companies: ExtendedCompany[] = [];

  constructor(private companiesSearchService: CompaniesSearchService,
              private destroyObservable: DestroyObservable,
              private messageService: MessageService,
              private companiesService: CompaniesService) {
  }

  ngOnInit(): void {
    document.title = environment.config.appTitle;
    this.loadCompaniesData();
  }

  loadCompaniesData() {
    this.loading = true;
    this.companiesSearchService.getICVotingCompanies()
      .pipe(takeUntil(this.destroyObservable), finalize(() => {
        this.loading = false;
      }))
      .subscribe(response => {
        if (response?.data && response.data.data) {
          this.companies = response.data.data.map(company => {
            return {
              ...company,
              vote: getCurrentUserVotingScore(company.workflows) ?? 1,
              comment: getCurrentUserVotingComment(company.workflows)
            };
          });
        }
      });
  }

  voteForCompany(company: ExtendedCompany) {
    this.loading = true;
    this.companiesService.voteForCompany(company.id, company.vote, company.comment)
      .pipe(takeUntil(this.destroyObservable), finalize(() => {
        this.loading = false;
      }))
      .subscribe(response => {
        if (response.data?.message) {
          this.messageService.add({ key: 'mv', severity: 'success', detail: response.data.message, life: 5_000 });
          this.loadCompaniesData();
          return;
        }
        if ((response.error || !response.data?.result) && response?.error?.status !== 403) {
          let message = response?.error?.response?.title ?? response?.data?.message ?? 'An error happened while performing the operation.';
          this.messageService.add({ key: 'mv', severity: 'error', detail: message, life: 5_000 });
        }
      });
  }

  trackBy(_: any, company: ExtendedCompany): number {
    return company.id;
  }
}

interface ExtendedCompany extends Company {
  comment: string;
  vote: number;
}