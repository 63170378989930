<section class="pic-wrapper">
  <div class="grid grid-nogutter">
    <div class="col-12" *ngIf="companyDetails.paidInCapital">
      <span class="total-pic">
        Total PIC <br />
        <strong>{{ companyDetails.paidInCapital | bpcNumber : "1.0-1" : "$" }}</strong>
      </span>
    </div>
    <div *ngIf="validPICData" class="col-12">
      <p-accordion styleClass="pic-accordian">
        <p-accordionTab
          *ngFor="let round of roundsData; let index = index"
          (click)="toggleRound(index)"
          [selected]="selectedRound === index"
        >
          <ng-template pTemplate="header">
            <div class="grid grid-nogutter">
              <div class="col flex-w-auto">
                <span class="amount"> {{ (round.amount | bpcNumber : "1.0-3" : "$") ?? "-" }}</span>
                <span class="date"> {{ round.raiseDate | date : "MMM Y" }}</span>
              </div>
              <div class="col flex-w-auto">
                <p-tag styleClass="ml-40 tag-blue" value="{{ round.roundType | roundType : 'description' }}"> </p-tag>
              </div>
              <div
                class="col-12 flex-w-auto flex justify-content-start flex-wrap mg-t-20"
                *ngIf="round.leadInvestorName && this.selectedRound !== index"
              >
                <div class="grid grid-nogutter">
                  <div class="col flex-w-auto random">
                    <p-avatarGroup *ngIf="round?.investments?.length">
                      <p-avatar
                        *ngFor="let investment of round.investments | slice : 0 : 3"
                        [overlayPanelTooltip]="investmentToolTip"
                        label="{{ investment.investorName | initials }}"
                        size="large"
                        styleClass="cyan"
                        shape="circle"
                      >
                        <p-overlayPanel #investmentToolTip>
                          <p>{{ investment.investorName ?? "" }}</p>
                        </p-overlayPanel>
                      </p-avatar>
                      <p-avatar
                        styleClass="grey"
                        *ngIf="round.investments && round.investments.length > 3"
                        size="large"
                        shape="circle"
                        [overlayPanelTooltip]="allInvestmentTooltip"
                        >+{{ round.investments.length - 3 }}
                      </p-avatar>
                      <p-overlayPanel #allInvestmentTooltip>
                        <p *ngFor="let investment of round.investments">{{ investment.investorName ?? "" }}</p>
                      </p-overlayPanel>
                    </p-avatarGroup>
                  </div>
                  <div class="col flex align-items-center justify-content-center">
                    <p-tag styleClass="ml-15 tag-light-blue" value="Lead: {{ round.leadInvestorName }}"></p-tag>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template *ngIf="round.investments?.length; else noDataBlock" pTemplate="content" let-investment>
            <div class="accordian-content">
              <div
                *ngFor="let investment of round.investments"
                class="grid grid-no-tb-gutter flex justify-content-between flex-wrap"
              >
                <div class="col flex align-items-center">
                  <p>{{ investment.amount | bpcNumber : "1.0-1" : "$" }} - {{ investment.investorName }}</p>
                </div>
                <div class="col flex-w-auto flex align-items-center">
                  <p-tag
                    *ngIf="round.leadInvestorName === investment.investorName"
                    styleClass="tag-light-blue"
                    value="Lead"
                  ></p-tag>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template #noDataBlock>
            <div class="accordian-content">
              <div class="col-12 no-data">
                <p>No Data</p>
                <!-- <img src="assets/svg/no-data.gif" alt="No Data" /> -->
              </div>
            </div>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </div>
    <div *ngIf="!validPICData" class="col-12 no-data">
      <p>No PIC Data</p>
      <!-- <img src="assets/svg/no-data.gif" alt="No Data" /> -->
    </div>
  </div>
</section>
