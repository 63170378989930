import { Pipe, PipeTransform } from '@angular/core';
import { IndustryWrapper } from '@models/auxiliary-company-models';

@Pipe({
  name: 'companySectors'
})
export class CompanySectorsPipe implements PipeTransform {

  transform(industries: IndustryWrapper[]): string[] {
    return industries.sort((a, b) => b.rank - a.rank).map(x => x.industry.name);
  }
}
