import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@models/nullable';

@Pipe({
  name: 'urlTransform'
})
export class UrlTransformPipe implements PipeTransform {
  public transform(value?: Nullable<string>): Nullable<string> {
    const link = value;

    if (!link || link.includes(' ')) {
      return null;
    }

    if (link.startsWith('https://') || link.startsWith('http://')) {
      return link;
    } else {
      return 'https://' + link;
    }
  }
}
