import { Component } from "@angular/core";
import { Investor, InvestorRole } from "@core/models/auxiliary-company-models";
import { TabsBaseComponent } from "@core/models/tabs-base-component";

import { MenuItem } from "primeng/api";
@Component({
  selector: "bpc-web-tabs",
  templateUrl: "./web-tabs.component.html",
  styleUrls: ["./web-tabs.component.scss"],
})
export class WebTabsComponent extends TabsBaseComponent {

  getCompanyTypeName(companyId: number) {
    return this.companyTypes.find(x => x.id === companyId)?.name;

  }
}
