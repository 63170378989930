<div>
    <div *ngIf="currentChildIndex != null && tab.children != null && tab.children[currentChildIndex] != null && tab.children[currentChildIndex].hasStageBar != null && tab.children[currentChildIndex].hasStageBar">
      <ul class="grid-tabs-wrapper" *ngIf="!stageLoading else showLoaders">
        <ng-container *ngFor="let stage of stages">
          <li 
              pTooltip="{{ stage.dispalyName }}"
              tooltipPosition="bottom"
              [ngClass]="stage.selected ? 'status-segment-selected' : 'status-segment'"
              (click)="onApplyStatus(stage)"
              *ngIf="stage!=stages[2] && stage!=stages[1]"> 
                  <p class="stage-info">
                      <strong>{{ stage.count }}</strong>
                      {{ stage.dispalyName }}
                  </p>
            </li>
        </ng-container>
      </ul>
    </div>
    <div *ngIf="currentChildIndex!= null && tab.children != null && tab.children[currentChildIndex] != null && tab.children[currentChildIndex].hasInteractionsBar != null && tab.children[currentChildIndex].hasInteractionsBar">
      <ul class="grid-tabs-wrapper-interactions" *ngIf="!interactionLoading else showLoaders">
        <ng-container *ngFor="let interaction of interactions">
          <li 
              pTooltip="{{ interaction.name }}"
              tooltipPosition="bottom"
              [ngClass]="interaction.selected ? 'status-segment-selected' : 'status-segment'"
              (click)="onApplyInteraction(interaction)"> 
                  <p class="stage-info">
                      <strong>{{ interaction.total }}</strong>
                      {{ interaction.name }}
                  </p>
            </li>
        </ng-container>
      </ul>
    </div>
    <ng-template #showLoaders>
      <div style="display: flex; flex-direction: column; ">
        <p-skeleton width="95vw" styleClass="mb-3"></p-skeleton>
        <p-skeleton width="95vw" styleClass="mb-3"></p-skeleton>
      </div>
    </ng-template>
  </div>