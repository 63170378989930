import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlow } from '@models/auxiliary-company-models';
import { getCurrentUserVotingScore } from '../../../utils/company-utils';

@Pipe({
  name: 'votingDisabled'
})
export class VotingDisabledPipe implements PipeTransform {

  transform(workflows: WorkFlow[]): boolean {
    return !!getCurrentUserVotingScore(workflows);
  }
}
