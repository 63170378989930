import { Component, Input } from '@angular/core';
import { Interaction } from '@models/auxiliary-company-models';
import { Nullable } from '@models/nullable';

@Component({
  selector: 'bpc-interaction-details',
  templateUrl: './interaction-details.component.html'
})
export class InteractionDetailsComponent {
  @Input() interaction!: Interaction;

  get initiator(): string {
    return this.interaction.user.name;
  }

  get internalAttendees(): Nullable<string> {
    return this.interaction.internalAttendees;
  }

  get participants(): Nullable<string> {
    return this.interaction.externalContacts;
  }
}
