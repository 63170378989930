<!-- search form -->
<section [ngClass]="isExtension ? 'sub-form-extension' : 'sub-form'">
  <form (ngSubmit)="handleSubmit()">
    <div 
      *ngIf="isExtension" 
      class="grid align-items-center align-content-center" 
      style="width: fit-content; margin-left: 0.85rem !important; cursor: pointer; margin-bottom: 0.5rem !important;"
      (click)="goBack()"
    >
      <i class="pi pi-angle-left mr-2"></i>
      <p style="width: fit-content; text-decoration: underline;">Go Back</p>
    </div>
    <div class="grid justify-content-between align-items-center align-content-center" [ngClass]="{ 'extension-padding': isExtension }">
      <div class="col">
        <div class="grid grid-nogutter info-wrapper" style="margin-bottom: 2px" *ngIf="company">
          <div class="col extention-full-w">
            <div class="grid grid-no-tb-gutter">
              <div class="col flex-w-auto">
                <!-- <p-avatarGroup>
                  <p-avatar [overlayPanelTooltip]="scoreTooltip" label="{{ company.rank | number : '1.0-0' }}"
                    styleClass="cyan w-38" shape="circle"></p-avatar>
                </p-avatarGroup> -->
                <!-- Company Score -->
                <!-- <p-overlayPanel styleClass="tool-tip-class" #scoreTooltip>
                  <bpc-company-score></bpc-company-score>
                </p-overlayPanel> -->
              </div>
              <div class="col">
                <a class="company-link" [routerLink]="company | companyDetailsUrl">
                  <h1 [ngClass]="{ 'extension-company-name': isExtension }">
                    {{ company.name }}
                  </h1>
                </a>
                <!-- <p class="type mb-2" *ngIf="companyType">{{ companyType }}</p> -->
                <div style="display: flex; align-items: center; gap:0.3rem">
                  <!-- Social Links -->
                  <bpc-social-icon *ngFor="let link of company | socialLinks" class="social-links" [socialLink]="link">
                  </bpc-social-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p *ngIf="!company && !isExtension">Please <u class="navigate-to-company"><span (click)="navigateToCompanyTab()">search for a company</span></u> and click the 'AI Prep' button on the company detail tab to generate a summary.</p>
        <div *ngIf="isCompanyNotFound">
          <h2 style="color: red;">This company does not exist in jarvis</h2>
        </div>
      </div>
      <div class="col flex-w-auto">
        <div class="grid">
          <!-- <div class="col">
            <div class="required-input">
              <p class="f-18">Domain:*</p>
              <input type="text" pInputText required [(ngModel)]="domain" name="domain" />
            </div>
          </div>
          <div class="col">
            <button style="min-width: 100px" type="submit" pButton class="submit-button btn-primary"
              [disabled]="!isValidForm()">
              Go
            </button>
          </div> -->
          <!-- <div class="col">
            <button type="button" pButton class="reset-form btn-secondary" (click)="handleClearForm()">
              Reset
            </button>
          </div> -->
          <div class="col">
            <button pButton class="btn-secondary pdf-download-button" [ngClass]="{'opacity-50': !isPDFDownloadEnabled}"
              [disabled]="!isPDFDownloadEnabled" (click)="downloadAsPDF($event)">
              <i *ngIf="!isPDFDownloading" class="fa-regular fa-file-pdf"></i>
              <i *ngIf="isPDFDownloading" class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
            </button>
          </div>
          <div class="col">
            <p-selectButton [options]="languageModelOptions" name="useGpt4" [(ngModel)]="useGpt4"
              styleClass="model-selector-button" optionLabel="label" optionValue="value"></p-selectButton>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- section selection -->

  <!-- <div class="grid grid-no-tb-gutter">
    <div class="col flex">
      <div class="tags-wrap-labels card-container">
        <span *ngFor="let section of master_sections; let i = index" class="tags" (click)="toggleSections(i)"
          [ngClass]="{ 'section-selected': section.isSelected }">
          <span [innerHtml]="section.title"></span>
        </span>
      </div>
      <div class="buttons-wrap-labels ml-2">
        <button pButton title="Select All" (click)="selectAll()" class="mr-2">Select All</button>
        <a class="lbl-clear-filter mgr-0" title="Clear All" (click)="clearAll()">Clear All</a>
      </div>
    </div>
  </div> -->
</section>

<!-- report sections -->
<section [ngClass]="isExtension ? 'report-sections-extension' : 'report-sections'">
  <div id="pdfDiv" #pdfDiv class="grid grid-no-tb-gutter no-hr-scroll">
    <ng-container *ngFor="let section of master_sections; let i = index">
      <div *ngIf="section.isSelected" class="col-12 abc">
        <section class="sub-block sub-block2">
          <div class="section-content">
            <div class="section-header">
              <div style="display: flex; align-items: center; gap: 0.4rem;">
                <img width="20px" height="20px" [src]="section.title | aiPrepReportIcon" alt="report-icon" />
                <h2 class="f-16">{{section.title}}</h2>
              </div>
              <div class="section-utility-buttons-container">
                <div style="display: flex;align-items: center;">
                  <button *ngIf="section.title !== 'Recent News'" class="border-none background-none" type="button" pButton (click)="openModal(i)"
                    [disabled]="section.isLoading">
                    <img src="assets/svg/Edit.svg" alt="edit-icon" />
                  </button>
                  <button class="border-none background-none" type="button" pButton (click)="reloadSection(i)"
                    [disabled]="section.isLoading || !(section.data || section.error)">
                    <img src="assets/svg/reload.svg" alt="reload-icon" />
                  </button>
                </div>
              </div>
            </div>
            <ng-container *ngIf="section.data; else noData">
              <ng-template [ngIf]="section.title === 'Description'">
                <div class="company-description-container">
                  <p class="formatted-paragraph">{{ section.data }}</p>
                </div>
              </ng-template>
              <ng-template [ngIf]="section.title === 'Product'">
                <div class="company-description-container">
                  <p class="formatted-paragraph">{{ section.data }}</p>
                </div>
              </ng-template>
              <ng-template [ngIf]="section.title === 'Leadership'">
                <div class="company-description-container">
                  <p class="formatted-paragraph">{{ section.data }}</p>
                </div>
              </ng-template>
              <ng-template [ngIf]="section.title === 'Jarvis Info'">
                <div class="grid">
                  <div class="col-6">
                    <div class="grid">
                      <div class="xs:col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6">
                        <div class="company-info">
                          <h3>Company Info</h3>
                          <p>Location: {{ section.data?.address?.city }}, {{ section.data?.address?.country?.name }}</p>
                          <p>Founding Date: {{ section.data?.foundDate }}</p>
                          <p>Number of Employees: {{ section.data?.fullTimeEquivalent }}</p>
                          <p>Employee Growth: {{ section.data?.fullTimeEquivalentGrowth }}%</p>
                        </div>
                      </div>
                      <div class="xs:col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
                        <div class="financials">
                          <h3>Financials</h3>
                          <p>Paid in Capital: {{ section.data?.paidInCapital }}</p>
                          <p>Recent Raise: {{ section.data.latestRound?.amount }}</p>
                        </div>
                      </div>
                      <div class="xs:col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
                        <div class="investors">
                          <h3>Investors</h3>
                          <p class="investors">{{ section.data.investorString }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="interactions">
                      <h3>Interactions</h3>
                      <div class="interactions-container">
                        <ng-container *ngIf="section.data.interactions.length; else noInteractionData">
                          <bpc-interaction-history [interactions]="section.data.interactions"></bpc-interaction-history>
                        </ng-container>
                        <ng-template #noInteractionData>No interaction data found.</ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template [ngIf]="section.title === 'Competitors'">
                <div class="company-description-container">
                  <p class="formatted-paragraph">{{section.data}}</p>
                </div>
              </ng-template>
              <ng-template [ngIf]="section.title === 'Thesis'">
                <div class="company-description-container">
                  <p class="formatted-paragraph">{{ section.data }}</p>
                </div>
              </ng-template>
              <ng-template [ngIf]="section.title === 'Reasons not to Invest'">
                <div class="grid">
                  <ng-container *ngIf="section.data; instanceof: Array">
                    <div class="col-12" *ngFor="let reason of section.data">
                      <span>
                        <h4>Reason</h4>
                      </span>
                      <p>{{reason?.reason ? reason.reason : "Could not generate reason. Check OpenAI API
                        Limit." }}</p>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
              <ng-template [ngIf]="section.title === 'Questions'">
                <div class="company-description-container">
                  <p class="formatted-paragraph">{{ section.data }}</p>
                </div>
              </ng-template>
              <ng-template [ngIf]="section.title === 'Recent News'">
                <div class="company-description-container">
                  <div *ngIf="section.data.news.length > 0; else noData">
                    <div *ngFor="let article of section.data.news">
                      <h3><a class="section-url" href="{{ article.article_url }}" target="_blank" >{{ article.article_title }}</a></h3>
                      <p>{{ article.article_description }}</p>
                      <p>{{ article.article_date }}</p>
                      <br>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template [ngIf]="section.title === 'Market'">
                <div class="company-description-container">
                  <p class="formatted-paragraph">{{section.data}}</p>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #noData>
              <div *ngIf="section.isLoading">
                <p-skeleton styleClass="mb-2"></p-skeleton>
                <p-skeleton styleClass="mb-2"></p-skeleton>
                <p-skeleton styleClass="mb-2"></p-skeleton>
                <p-skeleton *ngIf="!isExtension" width="50rem" styleClass="mb-2"></p-skeleton>
              </div>
              <p *ngIf="section.error" style="color: red;">{{section.error}}</p>
              <p *ngIf="!section.isLoading && !section.error">No data for {{ section.title }} section. Please try
                reloading this section.
              </p>
            </ng-template>
          </div>
        </section>
      </div>
    </ng-container>
  </div>
</section>

<bpc-custom-modal *ngIf="selectedSection" [visible]="visible" [contentStyleClass]="'scrollable'" (onHide)="onHide()">
  <ng-container modal-header>
    <h2>Prompt</h2>
    <div style="display: flex;align-items: center;">
      <span class="select-button-title">Prompt Complexity</span>
      <p-selectButton [options]="stateOptions" [(ngModel)]="selectedSection.promptComplexity" optionLabel="label"
        optionValue="value"></p-selectButton>
    </div>
  </ng-container>
  <ng-container modal-content>
    <textarea *ngIf="selectedSection!.promptComplexity === 'low'" [ngClass]="isExtension ? 'prompt-text-area-extension' : 'prompt-text-area'" rows="5" cols="30"
      pInputTextarea [(ngModel)]="selectedSection!.lowPrompt"></textarea>
    <textarea *ngIf="selectedSection!.promptComplexity === 'high'" [ngClass]="isExtension ? 'prompt-text-area-extension' : 'prompt-text-area'" rows="5" cols="30"
      pInputTextarea [(ngModel)]="selectedSection!.highPrompt"></textarea>
  </ng-container>
  <span class="select-button-title">Prompt Complexity</span>
  <p-selectButton [options]="stateOptions" [(ngModel)]="selectedSection.promptComplexity" optionLabel="label"
    optionValue="value" (click)="openModal(0)"></p-selectButton>
  <ng-container modal-footer>
    <button pButton class="button-light" (click)="onHide()">Cancel</button>
    <button pButton class="button-dark" [disabled]="!selectedSection.lowPrompt" (click)="submitPrompt()">Submit</button>
  </ng-container>
</bpc-custom-modal>

<div style="opacity: 0;width: 400px; height: 280px;">
  <canvas #chart></canvas>
</div>