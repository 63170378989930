<p-sidebar (onHide)="onHide()" [(visible)]="isVisible" #sidebar position="right" styleClass="w-25rem">
  <ng-template pTemplate="content">
    <section class="tags-sidebar">
      <div class="grid grid-no-tb-gutter flex justify-content-between flex-wrap mg-b-30">
        <div class="col flex-w-auto flex align-items-center">
          <h3>Tags</h3>
        </div>
        <div class="col flex-w-auto">
          <p-button id="add-new" (click)="onEditTagClick()" label="Create Tag" styleClass="btn-sec">
            <i class="pi pi-plus mr-2"></i>
          </p-button>
        </div>
      </div>

      <ng-template [ngIf]="!displayEditCustomTag">
        <div class="grid grid-no-tb-gutter">
          <div class="col-12">
            <p-tabView styleClass="details-tabs pannel">
              <p-tabPanel header="Private">
                <ng-container *ngIf="privateTagList && privateTagList.length">
                  <ng-container *ngFor="let tag of privateTagList; trackBy: trackByTag">
                    <div class="grid grid-no-tb-gutter flex justify-content-between mg-b-10">
                      <div class="col align-items-center">
                        <span class="tags inline-flex" (click)="onTagClick($event, tag)">
                          {{ tag.name }}
                        </span>
                      </div>
                      <div class="col flex-w-auto flex align-items-center">
                        <bpc-menu-native [items]="menuItems">
                          <span class="pi pi-ellipsis-v cursor-pointer f-18" (click)="onMenuClick(tag)"> </span>
                        </bpc-menu-native>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!privateTagList?.length">
                    <p class="modal-content-msg">No record found.</p>
                  </ng-container>
                </ng-container>
              </p-tabPanel>
              <p-tabPanel header="Public ">
                <ng-container *ngIf="publicTagList && publicTagList.length">
                  <ng-container *ngFor="let tag of publicTagList; trackBy: trackByTag">
                    <div class="grid grid-no-tb-gutter flex justify-content-between mg-b-10">
                      <div class="col flex-w-auto flex align-items-center">
                        <span class="tags inline-flex" (click)="onTagClick($event, tag)">
                          {{ tag.name }}
                        </span>
                      </div>
                      <div
                        (click)="onMenuClick(tag)"
                        class="col flex-w-auto flex align-items-center"
                        *ngIf="loggedInUserId === tag.createBy"
                      >
                        <bpc-menu-native [items]="menuItems">
                          <span class="pi pi-ellipsis-v ellipse-icon"> </span>
                        </bpc-menu-native>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!publicTagList?.length">
                  <p class="modal-content-msg">No record found.</p>
                </ng-container>
              </p-tabPanel>
            </p-tabView>
          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="displayEditCustomTag">
        <bpc-edit-custom-tag
          *ngIf="displayEditCustomTag"
          [mode]="editCustomTagMode"
          [company]="undefined"
          [isBulk]="isBulk"
          [(selectedCompanies)]="selectedTagCompanies"
          [(tag)]="tag"
        >
        </bpc-edit-custom-tag>
        <div class="p-sidebar-footers">
          <button pButton (click)="resetTags()">Cancel</button>
          <button
            pButton
            class="button-dark"
            type="submit"
            [disabled]="!tag.name || !tag.name.length"
            (click)="saveTag()"
          >
            Save
          </button>
        </div>
      </ng-template>
    </section>
  </ng-template>
</p-sidebar>

<!-- <p-dialog
  (onHide)="resetTags()"
  [showHeader]="false"
  contentStyleClass="header-less"
  [(visible)]="displayEditCustomTag"
>
  <bpc-edit-custom-tag
    *ngIf="displayEditCustomTag"
    [mode]="editCustomTagMode"
    [company]="undefined"
    [isBulk]="isBulk"
    [(selectedCompanies)]="selectedTagCompanies"
    [(tag)]="tag"
  >
  </bpc-edit-custom-tag>

  <ng-template pTemplate="footer">
    <div>
      <button pButton (click)="resetTags()">Cancel</button>
      <button pButton class="button-dark" type="submit" [disabled]="!tag.name || !tag.name.length" (click)="saveTag()">
        Save
      </button>
    </div>
  </ng-template>
</p-dialog> -->

<p-dialog (onHide)="resetTags()" [(visible)]="displayRemoveCustomTag" header="Remove Tag" id="remove-tag-popup">
  <p class="modal-content-msg">
    Are you sure you want to remove <strong>{{ tag.name }}</strong> tag?
  </p>

  <ng-template pTemplate="footer">
    <div>
      <button pButton (click)="cancelTagRemove()">No</button>
      <button pButton class="button-dark" type="submit" (click)="removeTag()">Yes</button>
    </div>
  </ng-template>
</p-dialog>
