import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConnectedUser, Staff } from '@models/user';
import { NgForm, NgModel } from '@angular/forms';
import { ConnectionForm } from '@models/forms';
import { ConnectionsService } from '@core/services/connections.service';
import { delay, finalize, Observable, takeUntil } from 'rxjs';
import { DestroyObservable } from '../../../rxjs/DestroyObservable';
import { ApiResponse } from '../../../api/base';
import { Nullable } from '@models/nullable';
import { cloneDeep } from '../../../utils/utils';
import { RelationshipGraph } from '@core/models/relationship-graph';
import { Company } from '@core/models/company';
import { CompanyHelperService } from '@core/services/company-helper.service';
import { UsersService } from 'src/app/services/users/users.service';
import { MixpanelService } from '@core/services/mixpanel.service';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'bpc-connection-panel',
  templateUrl: './connection-panel.component.html',
  styleUrls: ['./connection-panel.component.scss'],
  providers: [DestroyObservable]
})
export class ConnectionPanelComponent implements OnInit {

  @Input() visible!: boolean;
  @Input() companyId!: number;
  @Input() isExtension?: boolean;
  @Input() isNonAnalystUser?: boolean;
  @Input() name?: string;
  @Input() addNew = false;
  @Input() profile: Staff = <Staff>{};
  @Input() companyDetails!: Company;
  @Input() selectedInternal!: ConnectedUser;
  @Input() allInternalUsers!: ConnectedUser[];
  @Output() closeConnection = new EventEmitter<boolean>();
  @Output() editing = new EventEmitter<boolean>();
  @Output() submitForm = new EventEmitter<ConnectionForm>();
  @Output() deleteUser = new EventEmitter<{ id: number, deleteFromSystem: boolean }>();
  @Output() onHide = new EventEmitter<void>();
  @Input() isConnected: boolean = false;
  formLoaded: boolean = false;
  connectionsCount: number = 1;
  editConnection = false;
  closePanel = false;
  formData!: ConnectionForm;
  connectionLevel = 1;
  secondLevelFormData!: ConnectionForm;
  secondLevelConnections: ConnectionForm[] = [];
  secondLevelEditMode: boolean = false;
  formDisabled = false;
  secondLevelFormDisabled = false;
  displayModal = false;
  deleteFromSystem = false;
  emailInUseByAnotherCompany: Nullable<number> = null;
  connectionLevelOptions: LevelOption[] = [{ label: '1st', value: 1 }, { label: '2nd', value: 2 }];
  displayConfirmationModal: boolean = false;

  constructor(private connectionsService: ConnectionsService,
    private destroyObservable: DestroyObservable,
    private companyHelperService: CompanyHelperService,
    private usersService: UsersService,
    private readonly destroy$: DestroyObservable,
    private mixpanelService: MixpanelService,
    private authService: AuthService
  ) {
  }


  get relationshipDetails(): RelationshipGraph {
    return { internal: this.companyDetails.connectedUsers, external: this.companyDetails.staff };
  }

  get internal(): ConnectedUser[] {
    return this.relationshipDetails.internal;
  }

  get external(): Staff[] {
    return this.relationshipDetails.external;
  }

  ngOnInit(): void {
    if (!this.addNew) {
      this.editConnection = true;
    }
    this.loadFormData();
  }

  onProfileBadgeClick(externalData: any) {

  }

  onSelectedInternalChange() {
    // Force re-render of external users
    this.relationshipDetails.external = cloneDeep(this.relationshipDetails.external);
    this.reloadBasicFormData();
    if (this.profile) {
      this.isConnected = !!this.selectedInternal?.connections.find(x => x.companyPersonId === this.profile.id);
    }
  }

  editSecondConnection(linkedinUrl?: string) {
    const data = this.secondLevelConnections.find(x => x.externalUrl === linkedinUrl);
    if (data) {
      this.secondLevelEditMode = true;
      this.secondLevelFormData = data;
    }
  }

  toNumber(value: string | number | undefined) {
    return Number(value);
  }

  toggleDeleteModal() {
    this.deleteFromSystem = false;
    this.displayModal = !this.displayModal;
  }

  onLevelChange(value: number) {
    if (this.formData) {
      this.formData.count = value === 1 ? value : 0;
    }
    this.secondLevelConnections = this.editConnection ? this.secondLevelConnections : [];
  }

  onFocusOutLinkedIn(formField: NgModel) {
    this.checkIfUserExists(false, formField, this.profile.externalUrl, this.connectionsService.getUserByExternalUrl(formField.value));
  }

  onFocusOutEmail(formField: NgModel) {
    this.checkIfUserExists(true, formField, this.profile.workEmail, this.connectionsService.getUserByWorkEmail(formField.value));
  }

  onFocusOutSecondLevelLinkedIn(formField: NgModel) {
    this.checkIfSecondLevelUserExists(true, formField, this.profile.externalUrl, this.connectionsService.getUserByExternalUrl(formField.value));
  }

  onFocusOutSecondLevelEmail(formField: NgModel) {
    this.checkIfSecondLevelUserExists(true, formField, this.profile.workEmail, this.connectionsService.getUserByWorkEmail(formField.value));
  }

  removeSecondConnection(linkedinUrl?: string) {
    this.secondLevelConnections = this.secondLevelConnections.filter(x => x.externalUrl !== linkedinUrl);
  }

  removeUser() {
    this.deleteUser.emit({ id: this.profile.id, deleteFromSystem: this.deleteFromSystem });
    this.toggleDeleteModal();
  }

  clearData() {
    this.secondLevelEditMode = false;
    this.secondLevelFormData = this.initiliazeForm();
  }

  onSubmit(form: NgForm) {
    let eventProperties = {
      companyName: this.companyDetails.name,
      isExtension: this.isExtension,
      role: this.authService.isAnalyst ? 'Analyst' : 'BPC User',
    } as { [key: string]: any };
    
    if (this.authService.isAnalyst) {
      eventProperties['level'] = form.value?.level;
    }
    this.mixpanelService.trackEvent('Add_New_Contact', eventProperties);
    let data = {
      ...form.value,
      count: this.connectionsCount,
      connectionId: this.getConnectionId(this.profile?.id),
      selectedInternal: this.selectedInternal
    }
    if (this.toNumber(this.formData.level) === 2) {
      data = {
        ...data,
        secondLevelConnections: this.secondLevelConnections,
        count: this.secondLevelConnections.length
      }
    }
    this.submitForm.emit(data);
  }

  on2ndLevelSubmit(form: NgForm) {
    if (!form.value.externalUrl) { return; }

    const isExist = this.secondLevelConnections.findIndex(x => x.externalUrl === form.value.externalUrl);
    if (isExist >= 0) {
      this.secondLevelConnections[isExist] = {
        ...this.secondLevelConnections[isExist],
        ...form.value
      };
    } else {
      this.secondLevelConnections.push(form.value);
      if (this.formData) {
        this.formData.count = this.secondLevelConnections.length;
      }
    }
    this.clearData();
  }

  closeEditPanel(form: NgForm, event: Event) {
    // console.log("formmm",form.form.dirty)
    if (form.form.dirty) {
      this.displayConfirmationModal = true;
    } else {
      this.closeConnection.emit(true);
      event.stopPropagation();
    }
    // event.stopPropagation();
    // this.closeConnection.emit(true);
  }

  private checkIfSecondLevelUserExists(checkByEmail: boolean, formField: NgModel, oldValue: string, apiCall: Observable<ApiResponse<Staff>>) {
    if (formField.value && formField.valid && oldValue !== formField.value) {
      this.secondLevelFormDisabled = true;
      apiCall.pipe(takeUntil(this.destroyObservable), finalize(() => {
        this.secondLevelFormDisabled = false;
      }))
        .subscribe(response => {
          if (response.data) {
            this.populate2ndLevelForm(response.data, checkByEmail);
          }
        });
    }
  }

  private populate2ndLevelForm(data: Staff, checkByEmail: boolean) {
    if (data.companyId === this.companyId) {
      this.secondLevelFormData.workEmail = data.workEmail;
    }
    this.secondLevelFormData.workEmail = data.workEmail;
    this.secondLevelFormData.firstName = data.firstName ?? this.secondLevelFormData.firstName;
    this.secondLevelFormData.lastName = data.lastName ?? this.secondLevelFormData.lastName;
    this.secondLevelFormData.externalUrl = data.externalUrl ?? this.secondLevelFormData.externalUrl;
    this.secondLevelFormData.workPhone = data.workPhone ?? this.secondLevelFormData.workPhone;
    this.secondLevelFormData.position = data.position ?? this.secondLevelFormData.position;
  }

  private loadFormData() {
    this.connectionsCount = this.getConnectionsCount(this.profile?.id);
    this.connectionLevel = this.getConnectionLevel(this.profile?.id);
    this.formData = cloneDeep<any>({
      ...this.profile,
      level: this.connectionLevel,
      count: this.connectionsCount
    });

    this.secondLevelFormData = this.initiliazeForm();
    if (this.connectionLevel == 1) {
      this.secondLevelConnections = [];
    } else {
      const data = this.selectedInternal?.connections.find(x => x.companyPersonId === this.profile.id)?.secondLevelConnections;
      this.secondLevelConnections = data || [];
      this.formLoaded = true;
    }
    this.formLoaded = true;
  }

  private reloadBasicFormData() {
    this.connectionsCount = this.getConnectionsCount(this.profile?.id);
    this.connectionLevel = this.getConnectionLevel(this.profile?.id);

    this.secondLevelFormData = this.initiliazeForm();
    if (this.connectionLevel == 1) {
      this.secondLevelConnections = [];
    } else {
      const data = this.selectedInternal?.connections.find(x => x.companyPersonId === this.profile.id)?.secondLevelConnections;
      this.secondLevelConnections = data || [];
      this.formLoaded = true;
    }
    this.formLoaded = true;
  }

  private initiliazeForm() {
    const data = {
      externalUrl: undefined,
      firstName: undefined,
      lastName: undefined,
      position: undefined,
      workEmail: undefined,
      workPhone: undefined,
      secondLevelConnectionId: undefined
    }
    return data;
  }

  private checkIfUserExists(checkByEmail: boolean, formField: NgModel, oldValue: string, apiCall: Observable<ApiResponse<Staff>>) {
    if (formField.value && formField.valid && oldValue !== formField.value) {
      this.formDisabled = true;
      apiCall.pipe(takeUntil(this.destroyObservable), finalize(() => {
        this.formDisabled = false;
      }))
        .subscribe(response => {
          if (response.data) {
            this.populateForm(response.data, checkByEmail);
          }
        });
    }
  }

  private populateForm(data: Staff, checkByEmail: boolean) {
    if (checkByEmail && data.companyId !== this.companyId) {
      this.emailInUseByAnotherCompany = data.companyId;
    }
    if (data.companyId === this.companyId) {
      this.formData.workEmail = data.workEmail;
    }
    this.formData.firstName = data.firstName ?? this.formData.firstName;
    this.formData.lastName = data.lastName ?? this.formData.lastName;
    this.formData.externalUrl = data.externalUrl ?? this.formData.externalUrl;
    this.formData.workPhone = data.workPhone ?? this.formData.workPhone;
    this.formData.position = data.position ?? this.formData.position;
  }

  getConnectionLevel(companyPersonId: number): number {
    const connection = this.selectedInternal?.connections.find(x => x.companyPersonId === companyPersonId);
    return connection?.level ?? 1;
  }

  getConnectionsCount(companyPersonId: number): number {
    const connection = this.selectedInternal?.connections.find(x => x.companyPersonId === companyPersonId);
    return connection?.count ?? 1;
  }



  private getConnectionId(companyPersonId: number): number {
    const connection = this.selectedInternal?.connections.find(x => x.companyPersonId === companyPersonId);
    return connection ? connection?.id : 0;
  }

  closeConnectionPanel(event: Event) {
    event.stopPropagation();
    this.closeConnection.emit(true);
  }

  closeConfirmationPanel() {
    this.displayConfirmationModal = false;
  }




}

interface LevelOption {
  label: string;
  value: number;
}