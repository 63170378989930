import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from 'highcharts';
import MapModule from 'highcharts/modules/map';
import markerClusters from "highcharts/modules/marker-clusters";

import { CompaniesSearchService } from "src/app/services/companies-search/companies-search.service";
import { TabData } from "@core/static/tabs";
import { Company } from "@core/models/company";
import { TabUtilsService } from "src/app/services/tab-utils.service";
import { MainFilterRaw } from "@core/models/main-filter-view";
import { Tab } from "@core/models/types";
import { Nullable } from "primeng/ts-helpers";
import { MessageService } from "primeng/api";
import { slugify } from "../../../utils/slugify";
import usMap from '@highcharts/map-collection/countries/us/us-all.topo.json';
import { Router } from "@angular/router";
import { StatusId } from "../../models/auxiliary-company-models";


// Initialize the map module
MapModule(Highcharts);
markerClusters(Highcharts);

@Component({
  selector: "bpc-heatmap",
  templateUrl: "./heatmap.component.html",
  styleUrls: ["./heatmap.component.scss"],
})
export class HeatMapComponent implements OnInit, OnChanges {
  @Input() currentTabIndex!: Tab;
  @Input() currentChildIndex!: Nullable<number>;
  @Input() mapLoading: boolean = true;
  @Input() chartData: any[] = [];
  @Input() companyData: any[] = [];

  Highcharts: typeof Highcharts = Highcharts;  // Required for the highcharts-angular wrapper
  chartConstructor = 'mapChart';  // Specifies that it's a map
  chartOptions: Highcharts.Options = {};
  showHeatmapGrid: boolean = false;  
  clusterData: any[] = [];


  get tabs() {
    return this.tabsUtilsService.tabs;
  }

  get currentTab(): TabData<Company> {
    const tab = this.tabsUtilsService.getAvailableUserTabs(this.tabs)[this.currentTabIndex];
    return tab.children?.[this.currentChildIndex!] ?? tab;
  }

  get mainFilterRaw(): MainFilterRaw {
    return this.currentTab.mainFilterRaw!;
  }

  set mainFilterRaw(value: MainFilterRaw) {
    this.currentTab.mainFilterRaw = value;
  }

  constructor(
    private router: Router,
    private companiesSearchService: CompaniesSearchService,
    private tabsUtilsService: TabUtilsService,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["companyData"]) {
      this.loadChart();
      this.showHeatmapGrid = false;
    }
  }

  onCloseGrid(value: boolean) {
    this.showHeatmapGrid = value;
  }

  loadChart() {
    const that = this;
    this.chartOptions = {
      chart: {
        map: usMap,  // Use the loaded map
        events: {
          load: function () {
            const chart = this as Highcharts.MapChart; // Ensure it's treated as a MapChart

            // Add custom button after chart loads
            const button = chart.renderer.button('⟲', 10, 74, function () {
              if (chart.mapZoom) {
                chart.mapZoom();
              }
            }, {
              zIndex: 3,
              title: 'Reset Zoom',
              fill: '#ffffff',
              stroke: '#e6e6e6',
              d: '5',
              style: {
                color: '#666666',
                fontSize: '1.55em',
                fontWeight: 'bold'
              }
            }).attr({ width: 12, height: 13 }).add();
          }
        }
      },
      title: {
        text: ''
      },

      mapNavigation: {
        enabled: true,
        buttons: {
          zoomIn: {
            x: 0, // Position the zoom-in button
            y: 0
          },
          zoomOut: {
            x: 0, // Position the zoom-out button
            y: 32
          },
        }
      },

      plotOptions: {
        mappoint: {
          tooltip: {
            clusterFormat: 'Companies: <b>{point.clusterPointsAmount}</b>',
            pointFormatter: function() {
              // Use the mapping function to convert status id to status name
              const that: any = this;
              return `<b>${this.name}</b><br>Company ID: <b>${that.id}</b><br>Status: <b>${StatusId[that.status]}</b>`;
            },
            headerFormat: '',
          },
          cluster: {
            enabled: true,
            allowOverlap: false,
            marker: {
              color: '#fff',
              fillColor: '#FF6C39',  // New fill color for the cluster
            },
            animation: {
              duration: 450
            },
            layoutAlgorithm: {
              type: 'grid',
              gridSize: 70
            },
            zones: [{
              from: 1,
              to: 4,
              marker: {
                radius: 13
              }
            }, {
              from: 5,
              to: 9,
              marker: {
                radius: 15
              }
            }, {
              from: 10,
              to: 15,
              marker: {
                radius: 17
              }
            }, {
              from: 16,
              to: 20,
              marker: {
                radius: 19
              }
            }, {
              from: 21,
              to: 100,
              marker: {
                radius: 21
              }
            }]
          },
          point: {
            events: {
              click: function (event: any) {
                if (event.point && event.point.isCluster) {
                  that.clusterData = [];
                  const clusteredData = event.point.clusteredData;
                  clusteredData.forEach((clusterItem: any) => {
                    if (clusterItem.options) {
                      that.clusterData.push(clusterItem.options);
                    }
                  });
                  that.showHeatmapGrid = true;
                } else {
                  // Triggered when a point is clicked
                  var id = event.point.options.id;
                  var name = event.point.options.name;
                  var route = ['company', id + '-' + slugify(name)];
                  that.router.navigate(route);
                }
              }
            }
          }
        }
      },
      series: [
        {
          name: 'US',
          type: 'map',
          accessibility: {
            exposeAsGroupOnly: true
          },
          borderColor: '#A0A0A0',
          nullColor: '#074b5c',
          showInLegend: false,
          dataLabels: {
            enabled: true,
            format: '{point.name}',  // This will display state names
            color: '#FFFFFF',  // Label text color
            style: {
              fontWeight: 'bold',
              textOutline: 'none'  // Remove text outline for better visibility
            }
          }
        },
        {
          type: 'mappoint',
          enableMouseTracking: true,
          showInLegend: false,
          accessibility: {
            point: {
              descriptionFormat: '{#if isCluster}' +
                'Grouping of {clusterPointsAmount} points.' +
                '{else}' +
                '{name}, state code: {state}.' +
                '{/if}'
            }
          },
          colorKey: 'clusterPointsAmount',
          name: 'Companies',
          data: this.chartData,
          color: '#FF6C39',
          marker: {
            lineWidth: 1,
            lineColor: '#095A70',
            symbol: 'mapmarker',
            radius: 8
          },
          dataLabels: {
            verticalAlign: 'top',
          },
          states: {
            hover: {
              enabled: false,
              opacity: 1
            }
          }
        }
      ]
    };
  }
}
