import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { BasicCompany, FilteredResult } from "@models/company";
import { historyDataKey } from "@core/localStorageKeys.ts";
import { CompaniesSearchService } from "../../../services/companies-search/companies-search.service";
import { Router } from "@angular/router";
import { CompanyHelperService } from "@core/services/company-helper.service";
import { DestroyObservable } from "../../../rxjs/DestroyObservable";
import { AuthService } from "@core/auth/auth.service";
import { debounceTime, delay, distinctUntilChanged, filter, Subject, switchMap, takeUntil, tap } from "rxjs";
import { SearchHelperService } from "@core/services/search-helper.service";
import { Nullable } from "@models/nullable";
import { Tag } from "@models/tag";
import { FilterHelperService } from "@core/services/filter-helper.service";
import { OverlayPanel } from "primeng/overlaypanel";
import { NgModel } from "@angular/forms";
import { MixpanelService } from "@core/services/mixpanel.service";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "bpc-auto-search",
  templateUrl: "./auto-search.component.html",
  styleUrls: ["./auto-search.component.scss"],
  providers: [DestroyObservable],
  animations: [
    trigger("displayResults", [
      state(
        "closed",
        style({
          display: "none",
        })
      ),
      state("open", style({ display: "block" }), { params: { maxHeight: "300px" } }),
      transition("* => *", animate(100)),
    ]),
  ],
})
export class AutoSearchComponent implements OnInit {
  @ViewChild("resultsContainer") resultsContainer!: ElementRef;
  @ViewChild("op") searchInputElement!: OverlayPanel;
  @Input() id!: string;
  @Output() closeModal = new EventEmitter();
  overlayContainer: HTMLElement = document.getElementById(this.id) as HTMLElement;
  private localStorageKey = historyDataKey(this.authService.userId!);

  searchQueryChange$ = new Subject<string>();

  loading = false;
  state: "open" | "closed" = "closed";
  searchQuery: Nullable<string> = null;
  selectedIndex: Nullable<number> = null;
  highlightClass = "highlight-result";
  recentCompanies: BasicCompany[] = [];
  filteredCompanies: BasicCompany[] = [];
  combinedResults: FilteredResult[] = [];
  filteredTags: Tag[] = [];

  get totalResults(): number {
    return this.filteredCompanies.length + this.filteredTags.length;
  }

  get totalFilteredCompanies(): number {
    return this.filteredCompanies.length;
  }

  get totalFilteredTags(): number {
    return this.filteredTags.length;
  }

  get totalRecent(): number {
    return this.recentCompanies.length;
  }

  get showRecent(): boolean {
    return !!this.totalRecent && (!this.searchQuery || this.searchQuery.length < 2);
  }

  get showNoResults(): boolean {
    return this.searchQuery != null && this.searchQuery.length > 1 && this.totalResults === 0;
  }

  get showRealResults(): boolean {
    return !this.showRecent && !this.showNoResults;
  }

  /**
   * Need to calculate max height because animation is requiring two values in order to work
   * No results and Recent header is 40px
   * Companies and tags headers are 42px
   * each filter record (max 5) is 40px
   */
  get maxHeight(): string {
    const companiesHeader = this.totalFilteredCompanies > 0 ? 42 : 0;
    const tagsHeader = this.totalFilteredTags > 0 ? 42 : 0;
    const headersHeight = this.showRealResults ? companiesHeader + tagsHeader : 40;
    const itemsHeights = this.showRecent
      ? this.totalRecent * 40
      : (this.totalFilteredCompanies + this.totalFilteredTags) * 40;
    return headersHeight + itemsHeights + "px";
  }

  get highlightClasses(): string {
    return ` ${this.highlightClass}`;
  }

  constructor(
    private companiesSearchService: CompaniesSearchService,
    private router: Router,
    private companyHelper: CompanyHelperService,
    private destroyObservable: DestroyObservable,
    private searchHelperService: SearchHelperService,
    private filterHelper: FilterHelperService,
    private authService: AuthService,
    public mixpanelService: MixpanelService,
    public deviceDetectorService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.loadRecentData();
    this.subscribeToSearchHelperService();
    this.subscribeToSearchQueryChanges();
  }


  @HostListener("window:keyup.ArrowUp")
  onKeyupArrowUp() {
    if (this.state === "open") {
      this.goToPrevious();
    }
  }

  @HostListener("window:keyup.ArrowDown")
  onKeyupArrowDown() {
    if (this.state === "open") {
      this.goToNext();
    }
  }

  focusSearch() {
    this.searchInputElement.focus();
  }

  goToPrevious() {
    if (this.selectedIndex === null) {
      this.selectedIndex = 0;
    } else if (this.selectedIndex > 0) {
      this.selectedIndex--;
      this.scrollIntoView();
    }
  }

  get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  goToNext() {
    if (this.selectedIndex === null) {
      this.selectedIndex = 0;
    } else if (this.selectedIndex < this.totalResults - 1) {
      this.selectedIndex++;
      this.scrollIntoView();
    }
  }

  scrollIntoView() {
    setTimeout(() => {
      const result = document.getElementsByClassName(this.highlightClass)[0];
      result?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }, 10);
  }

  onEnter() {
    if (!this.isMobile) {
      this.mixpanelService.trackEvent("Search_Bar");
      if (this.searchQuery && this.searchQuery.length > 1) {

        // Sources
        if (this.selectedIndex !== null && this.selectedIndex > this.totalFilteredCompanies - 1) {
          this.searchBySource(this.filteredTags[this.selectedIndex - this.totalFilteredCompanies]);
          return;
        }

        // Companies
        if (this.selectedIndex === null) {
          this.searchForCompanies();
        } else if (this.totalResults === 1) {
          this.openCompanyDetails(this.filteredCompanies[0]);
        } else {
          this.openCompanyDetails(this.filteredCompanies[this.selectedIndex]);
        }
      } else if (this.totalRecent && this.selectedIndex !== null) {
        // Recent
        this.openCompanyDetails(this.recentCompanies[this.selectedIndex]);
      }
      this.searchInputElement.hide();
    }
  }

  onChange(newValue: string) {
    this.selectedIndex = null;
    this.searchQueryChange$.next((this.searchQuery = newValue));
  }

  onFocusIn(event: Event) {
    this.state = "open";
    if (this.searchInputElement) {
      this.searchInputElement.show(event);
    }
  }

  onFocusOut(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target?.id !== "clearButton") {
      setTimeout(() => {
        this.state = "closed";
        window.scrollTo(0, -500);
        document.body.scrollTop = 0;
      }, 100);
    }
  }

  onEnterPress() {
    this.onEnter();
    if (this.searchInputElement) {
      this.searchInputElement.hide();
    }
  }

  openDetails(result: FilteredResult) {
    if (result.type === 0) {
      this.openCompanyDetails(result.item as BasicCompany)
    }
    else {
      this.searchBySource(result.item as Tag)
    }
  }
  openCompanyDetails(company: BasicCompany) {
    this.mixpanelService.trackEvent("Search_Bar");
    this.router.navigate(this.companyHelper.generateCompanyDetailsUrl(company));
    this.storeSearch(company);
    this.searchQuery = company.name;
    this.selectedIndex = 0;
  }

  onClearButtonClick(event: Event) {
    event.stopPropagation();
    this.clearSearch(true);
  }

  clearSearch(shouldFocus = false) {
    this.searchQuery = null;
    if (shouldFocus) {
      setTimeout(() => {
        this.searchInputElement.focus();
      }, 0);
    }
  }

  searchBySource(item: Tag) {
    this.mixpanelService.trackEvent("Search_Bar");
    this.searchHelperService.changeMessage({ type: "source-search", payload: item! });
    this.searchQuery = item.name;
    this.selectedIndex = 0;
    this.closeModal.emit();
  }

  searchForCompanies() {
    this.searchHelperService.changeMessage({ type: "companies-search", payload: this.searchQuery ?? "" });
  }

  identify(index: number, item: Tag) {
    return item.id;
  }

  private storeSearch(option: BasicCompany) {
    if (!option.id) {
      return;
    }
    this.recentCompanies.splice(0, 0, option);
    this.recentCompanies = this.recentCompanies
      .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
      .slice(0, 5);
    localStorage.setItem(this.localStorageKey, JSON.stringify(Array.from(new Set(this.recentCompanies))));
  }

  private loadRecentData() {
    const recentData = JSON.parse(localStorage.getItem(this.localStorageKey)!) ?? [];
    if (this.isRecentSearchDataValid(recentData)) {
      this.recentCompanies = recentData;
    } else {
      localStorage.removeItem(this.localStorageKey);
    }
  }

  private isRecentSearchDataValid(data: BasicCompany[]): data is BasicCompany[] {
    return Array.isArray(data) && data.every((item) => typeof item.name === "string" && typeof item.id === "number");
  }

  private subscribeToSearchHelperService() {
    this.searchHelperService.currentMessage
      .pipe(takeUntil(this.destroyObservable))
      .pipe(delay(10))
      .subscribe((message) => {
        switch (message.type) {
          case "clear-search":
            this.clearSearch();
            break;
          default:
            break;
        }
      });
  }

  private subscribeToSearchQueryChanges() {
    this.searchQueryChange$
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(filter((query) => !!query.length))
      .pipe(tap(() => (this.loading = true)))
      .pipe(switchMap((query) => this.companiesSearchService.quickSearch(query)))
      .pipe(takeUntil(this.destroyObservable))
      .subscribe(({ data }) => {
        this.loading = false;
        this.filteredCompanies = data?.companies ?? [];
        this.filteredTags = data?.lists ?? [];
        this.combinedResults = [];
        for (let i = 0; i < this.filteredCompanies.length / 2; i++) {
          this.combinedResults.push({ item: this.filteredCompanies[i], type: 0 });
        }
        for (let i = 0; i < this.filteredTags.length / 2; i++) {
          this.combinedResults.push({ item: this.filteredTags[i], type: 1 });
        }
        // this.filteredCompanies.forEach((item) => {
        //   this.combinedResults.push({ item, type: 0 })
        // })
        // this.filteredTags.forEach((item) => {
        //   this.combinedResults.push({ item, type: 1 })
        // })
        // this.combinedResults = [...this.filteredCompanies, this.filteredTags];
      });
  }
}
