import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { delay, exhaustMap, fromEvent, merge, MonoTypeOperatorFunction, of, takeUntil, tap } from 'rxjs';
import { DestroyObservable } from '../../rxjs/DestroyObservable';

@Directive({
  selector: '[longTouch]',
  providers: [DestroyObservable]
})
export class LongTouchDirective {
  private touchStart$ = fromEvent<TouchEvent>(this.el.nativeElement, 'touchstart');
  private touchMove$ = fromEvent<TouchEvent>(this.el.nativeElement, 'touchmove');
  private touchEnd$ = fromEvent<TouchEvent>(this.el.nativeElement, 'touchend');

  private preventDefault = false;

  @Output() longTouch = new EventEmitter<TouchEvent>();

  constructor(private el: ElementRef, destroyObservable: DestroyObservable) {
    const setPreventDefault = (prevent: boolean) => tap(() => this.preventDefault = prevent) as MonoTypeOperatorFunction<TouchEvent>;
    const stopTouch: MonoTypeOperatorFunction<TouchEvent> = takeUntil(merge(this.touchMove$, this.touchEnd$).pipe(setPreventDefault(false)));

    this.touchStart$
      .pipe(exhaustMap(touch => of(touch).pipe(delay(1_000), stopTouch, setPreventDefault(true))))
      .pipe(takeUntil(destroyObservable))
      .subscribe(this.longTouch);
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.preventDefault) {
      event.preventDefault();
    }
  }
}
