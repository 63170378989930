import { Injectable } from '@angular/core';
import { filter, finalize, map, switchMap, tap } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { UsersService } from '../../services/users/users.service';
import { Router } from '@angular/router';
import { userDataKey } from '@core/localStorageKeys.ts';
import { MessageService } from 'primeng/api';
import { MixpanelService } from '@core/services/mixpanel.service';

@Injectable({
  providedIn: 'root'
})
export class AdAuthService {

  constructor(private authService: AuthService,
              private oidcSecurityService: OidcSecurityService,
              private usersService: UsersService,
              private eventService: PublicEventsService,
              private router: Router,
              private messageService: MessageService,
              private mixpanelService: MixpanelService) {
  }

  public checkADAuth() {
    const user = this.authService.user.getValue();

    if (user && !user.isAD) { // Logged in local
      return;
    }

    this.authService.loading = true;
    this.oidcSecurityService.checkAuth().pipe(
      tap((result) => {
        if (!!(result.isAuthenticated && result.accessToken && user)) {
          this.subscribeToADEvents();
        }
      }),
      filter((result) => !!(result.isAuthenticated && result.accessToken && !user)),
      switchMap((result) => {
        return this.usersService.getCurrentUser(result.accessToken).pipe(map(response => ({
          token: result.accessToken,
          response
        })));
      }))
      .pipe(
        finalize(() => {
          this.authService.loading = false;
        })
      )
      .subscribe(({ token, response }) => {
        if (response.data) {
          const user = response.data;
          user.token = token;
          user.isAD = true;
          this.authService.setUserRole(user);
          this.mixpanelService.onLogin(user, true);
          this.authService.user.next(user);
          this.router.navigate(['/']);
          this.subscribeToADEvents();
        } else if (response.statusCode === 400) {
          this.displayToastMessage("Wrong email or password.");
          sessionStorage.clear();
        }
        this.authService.loading = false;
      });
  }

  public subscribeToADEvents() {
    this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.NewAuthenticationResult),
        switchMap(() => {
          return this.oidcSecurityService.getAccessToken();
        })
      ).subscribe((at) => {
      const user = this.authService.user.getValue();
      if (user) {
        user.token = at;
        this.authService.setUserData(user);
        this.mixpanelService.onLogin(user, true);
        this.authService.user.next(user);
      }
    });
  }

  private displayToastMessage(message: string, severity = "error", life = 3000) {
    this.messageService.add({
      key: "login",
      severity,
      detail: message,
      life,
    });
  }
}
