import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '@models/company';
import { SocialLink } from '@models/social-link';

@Pipe({
  name: 'websiteLink'
})
export class WebsiteLinkPipe implements PipeTransform {

  transform(company: Company): SocialLink {
    return { type: 'www', sourceId: 0, link: company.website };
  }

}
