import { catchError, defer, map, Observable, OperatorFunction, pipe, tap } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '@core/auth/auth.service';
import { userDataKey } from '@core/localStorageKeys.ts';
import { MessageService } from 'primeng/api';

export interface ApiResponse<T> {
  message?: string;
  statusCode: number;
  error?: any;
  data?: T;
}

export class BaseApiService<T = any> {
  private readonly url: string;

  constructor(
    rootPath: string,
    protected authService: AuthService,
    protected router: Router,
    protected messageService: MessageService
  ) {
    this.url = environment.baseApiUrl + rootPath;
  }

  protected get<O = T>(path: string = '', queryParams?: any, headers?: any): Observable<ApiResponse<O>> {
    return defer(() => ajax({
      url: this.url + path,
      headers: headers ?? this.getHeaders(),
      queryParams: serialize(queryParams)
    })).pipe(
      this.mapResponseAndError<O>()
    );
  }

  protected post<O = T>(path: string = '', queryParams?: any, body?: any): Observable<ApiResponse<O>> {
    return defer(() => ajax({
      url: this.url + path,
      method: 'POST',
      headers: this.getHeaders(),
      queryParams: serialize(queryParams),
      body
    })).pipe(
      this.mapResponseAndError()
    );
  }

  protected put<O = T>(path: string = '', queryParams?: any, body?: any): Observable<ApiResponse<O>> {
    return defer(() => ajax({
      url: this.url + path,
      method: 'PUT',
      headers: this.getHeaders(),
      queryParams: serialize(queryParams),
      body
    })).pipe(
      this.mapResponseAndError()
    );
  }

  protected delete<O = T>(path: string = '', queryParams?: any, body?: any): Observable<ApiResponse<O>> {
    return defer(() => ajax({
      url: this.url + path,
      method: 'DELETE',
      headers: this.getHeaders(),
      queryParams: serialize(queryParams),
      body
    })).pipe(
      this.mapResponseAndError()
    );
  }

  protected patch<O = T>(path: string = '', queryParams?: any, body?: any): Observable<ApiResponse<O>> {
    return defer(() => ajax({
      url: this.url + path,
      method: 'PATCH',
      headers: this.getHeaders(),
      queryParams: serialize(queryParams),
      body
    })).pipe(
      this.mapResponseAndError()
    );
  }

  private getHeaders() {
    const user = this.authService.user.getValue();

    if (user) {
      return {
        Authorization: `Bearer ${ user.token }`
      };
    } else {
      return {};
    }
  }

  private mapResponseAndError<T>(): OperatorFunction<AjaxResponse<any>, ApiResponse<T>> {
    return pipe(
      map(({ response, status }) => ({ data: <T>response, statusCode: status })),
      tap({
        error: err => {
          if (err.status === 401) { // Unauthorized
            this.authService.user.next(null);
            this.authService.removeUserData();
            this.router.navigate(['/login']);
            this.authService.oidcSecurityService.logoffLocal();
          } else if (err.status === 403) { // Forbidden
            this.displayInfoToastMessage(`You don't have enough permissions to complete the action.`);
          }
        }
      }),
      catchError(err => [{ error: err, statusCode: err.status }])
    );
  }

  private displayInfoToastMessage(message: string) {
    this.messageService.add({
      key: 'home-bc',
      severity: 'info',
      detail: message,
      life: 10_000
    });
  }
}

export function serialize(obj: any, prefix: any = ''): string {
  const str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p) && typeof obj[p] !== 'undefined') {
      const k = prefix ? prefix : p,
        v = obj[p];
      str.push((v !== null && typeof v === 'object') ?
        serialize(v, k) :
        encodeURIComponent(k) + '=' + encodeURIComponent(v));
    }
  }

  return str.join('&');
}
