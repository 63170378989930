import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { OpenSearch } from '@core/components/portfolio-update/portfolio-update.component';
import { GroupType } from '@core/models/auxiliary-company-models';
import { BasicCompany } from '@core/models/company';
import { MessageService } from 'primeng/api';
import { BaseApiService } from 'src/app/api/base';

@Injectable({
  providedIn: 'root'
})
export class GenericUpdatesService extends BaseApiService<BasicCompany[]> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/GenericUpdates', authService, router, messageService);
  }

  getOpenSearches(groupType: GroupType, date?: Date){
    if (date != null){  
      return this.get(`?updateTypes=${groupType}&publishedTimestamp=${date}` );
    }
    else{
      return this.get(`?updateTypes=${groupType}` );
    }
  }

  postGenericUpdate(id: number, openSearch: OpenSearch){

    return this.post(`/${id}`, null, openSearch)
  }
}
