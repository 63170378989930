import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "@core/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { userDataKey } from "@core/localStorageKeys.ts";
import { SupportService } from "@core/services/support.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { DeviceDetectorService } from "ngx-device-detector";
import { ChromeExtensionService } from "../../services/chrome-extension/chrome-extension.service";
import { AutoSearchComponent } from "@core/components/auto-search/auto-search.component";
import { AvatarComponent } from "@shared/components/avatar/avatar.component";
import { MetaService } from "../../core/services/meta.service";
import { Tag } from "../../core/models/tag";
import { takeUntil } from "rxjs";
import { ApiResponse } from "../../api/base";
import { OperationResult } from "../../core/models/operation-result";
import { MessageService } from "primeng/api";
import { MenuItem } from "@shared/components/menu-native/menu-native-item";
import { MetaTagsComponent } from "./meta-tags/meta-tags.component";
import { NotificationAlertsComponent } from "./notification-alerts/notification-alerts.component";
import { MixpanelService } from "@core/services/mixpanel.service";

type AnchorStyle = {
  pointerEvents?: string;
};

@Component({
  selector: "bpc-top-navigation",
  templateUrl: "./top-navigation.component.html",
  styleUrls: ["./top-navigation.component.scss"],
})
export class TopNavigationComponent implements OnInit {
  @ViewChild("autoSearch") autoSearchComponent!: AutoSearchComponent;
  @ViewChild("avatar") avatar!: AvatarComponent;
  @ViewChild("metaTag") metaTag!: MetaTagsComponent;
  @ViewChild("notificationAlerts") notificationAlerts!: NotificationAlertsComponent;
  unreadNotificationCount: number = 0;

  tagSideBarIsVisible: boolean = false;
  user$ = this.authService.user;
  anchorStyle: AnchorStyle = {};
  searchModal = false;

  get menuItems(): MenuItem[] {
    const items: MenuItem[] = [
      {
        id: "logout",
        label: "Logout",
        icon: "pi pi-user",
        command: () => {
          this.authService.logout();
        },
      },
    ];

    if (this.authService.isAD) {
      items.unshift({
        id: "viewAccount",
        label: "View account",
        icon: "pi pi-user",
        command: () => {
          window.open("https://myaccount.microsoft.com", "_blank");
        },
      });
    }

    return items;
  }
  setUnreadNotificationCount(count: number) {
    this.unreadNotificationCount = count;
  }
  openAlertsSidebar() {
    this.mixpanelService.trackEvent("Alerts_Sidebar_Opened");
    this.notificationAlerts.showAlerts();
  }
  get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  constructor(
    public authService: AuthService,
    private router: Router,
    public supportService: SupportService,
    private oidcSecurityService: OidcSecurityService,
    private deviceDetectorService: DeviceDetectorService,
    public chromeExtensionService: ChromeExtensionService,
    private metaService: MetaService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private mixpanelService: MixpanelService,
  ) { }

  showSearchModal() {
    this.searchModal = true;
  }

  openTagsSidebar(val?: boolean) {
    this.metaTag.showTags();
  }

  searchIconClick() {
    if (this.isMobile) {
      this.showSearchModal();
      setTimeout(() => {
        this.autoSearchComponent.focusSearch();
      }, 500);
    }
  }


  hideUserProfileTooltip() {
    this.avatar.hideTooltip();
  }

  savedSearchClick() {
    this.router.navigate(["/saved-search"]);
  }

  ngOnInit() {
    this.chromeExtensionService.isExtension.subscribe((isExtension) => {
      if (isExtension) {
        this.anchorStyle.pointerEvents = "none";
      }
    });
  }
}
