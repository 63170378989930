import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { BasicCompany } from '@core/models/company';
import { MessageService } from 'primeng/api';
import { shareReplay } from 'rxjs';
import { BaseApiService } from 'src/app/api/base';

@Injectable({
  providedIn: 'root'
})
export class CompanyPortfolioService extends BaseApiService<BasicCompany[]> {
  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/portfoliocompanies', authService, router, messageService);
  }

  getUpdates(date?: Date) {
    return this.get(`/updates` + (date ? `?publishedTimestamp=${date}`: ''));
  }

  getPublishedDateTimes() {
    return this.get(`/published-timestamps`);
  }
  updatePortfolio(portfolioCompanyUpdateId: number, updates: string, previousUpdates: string) {
    return this.post(`/${portfolioCompanyUpdateId}/updates`, null, { updates, previousUpdates }
    );
  }

  publishPortfolio() {
    return this.post(`/publish-updates`, null);
  }

  getEmailPreview() {
    return this.get(`/preview-updates`);
  }

}
