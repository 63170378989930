<button
  *ngIf="!isTablet"
  class="btn-download"
  [disabled]="(loading && !progress) || selectedCompanies.length === 0"
  (click)="onButtonClick()"
>
  <span *ngIf="progress" class="pi pi-times"></span>
  <span *ngIf="!progress" class="icon-download mr-2"></span>
  <!-- <span *ngIf="!progress" class="pi pi-download"></span> -->
  Download CSV
</button>
