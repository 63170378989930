  <div class="inline-block" [ngClass]="{ 'w-[32px] h-[32px] text-sm': size == 'xs', 'w-6 h-6 text-xs': size == 'sm', 'w-8 h-8 text-lg': size === 'md' }">
  <div
    class="w-full h-full inline-flex items-center rounded-full justify-center select-none bg-pink-100"
    [overlayPanelTooltip]="nameTooltip"
    [overlayPanelTooltipDisabled]="disableTooltip">
    {{ company?.outreachOwner?.name | initials }}
  </div>
  <p-overlayPanel #nameTooltip>
    {{ company?.outreachOwner?.name || '' }}
  </p-overlayPanel>
</div>