import { Injectable } from '@angular/core';
import { RelationshipGraph } from '@models/relationship-graph';

@Injectable({
  providedIn: 'root'
})
export class CanvasHelperService {
  private canvas!: HTMLCanvasElement;
  private greenColor = '#47D8BF';
  private grayColor = '#CBCBCB';
  private canvasWidth = 300;
  private leHeight = 24;      // Left element profile badge height in px
  private leMargin = 36;      // Left element profile badge margin bottom in px
  private reHeight = 56;      // Right element profile badge height in px
  private reMargin = 16;      // Right element profile badge margin bottom in px

  constructor() {
  }

  private initCanvas(width: number, height: number) {
    this.canvas = document.getElementById('connectionsCanvas')! as HTMLCanvasElement;
    this.canvas.width = width;
    this.canvas.height = height;
  }

  public generateConnections(relationshipDetails: RelationshipGraph) {
    const internal = relationshipDetails.internal;
    const external = relationshipDetails.external;

    // In order to draw canvas we need to calculate height based  on bigger height on left or right elements
    const leftElemHeight = internal.length * (this.leHeight + this.leMargin);
    const rightElemHeight = external.length * (this.reHeight + this.reMargin);

    this.initCanvas(this.canvasWidth, Math.max(leftElemHeight, rightElemHeight));

    internal.forEach((int, index) => {
      int.connections.forEach(connection => {
        let start = index + 1;
        let end = external.findIndex(x => x.id === connection.companyPersonId) + 1;
        let points = this.calculatePoints(start, end);
        let color = connection.level === 2 ? this.grayColor : this.greenColor;
        this.drawCanvasLine(points.start, points.end, color);

        if (connection.level === 2 && connection.count) {
          this.drawDot(points.start, points.end, connection.count);
        }
      });
    });
  }

  private calculatePoints(lPos: number, rPos: number): { start: Dot, end: Dot } {
    const start: Dot = {
      x: 0,
      y: (this.leHeight / 2) + ((lPos - 1) * (this.leHeight + this.leMargin))
    };

    const end: Dot = {
      x: this.canvasWidth - 6,
      y: (this.reHeight / 2) + ((rPos - 1) * (this.reHeight + this.reMargin))
    };

    return { start, end };
  }

  private drawCanvasLine(start: { x: number, y: number },
                         end: { x: number, y: number }, color: string = this.greenColor): void {
    const ctx = this.canvas.getContext('2d')!;
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.lineWidth = 1.5;
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.stroke();
  }

  private drawDot(start: { x: number, y: number },
                  end: { x: number, y: number }, count: number): void {
    const fontSize = 12;
    const color = 'gray';
    const midX = ((start.x + end.x) / 2) - fontSize / 2;
    const midY = ((start.y + end.y) / 2) + fontSize / 4;

    const ctx = this.canvas.getContext('2d')!;
    ctx.font = `${ fontSize }px Arial`;
    ctx.fillStyle = color;
    ctx.fillText(count.toString(), midX, midY);
  }
}

interface Dot {
  x: number;
  y: number
}
