import { Component, Input, ViewChild } from '@angular/core';
import { Company } from '@core/models/company';

import { Nullable } from '@models/nullable';
import { User } from '@models/user';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'bpc-avatar-outreachowner',
  templateUrl: './avatar-outreachowner.component.html'
})
export class AvatarOutreachOwnerComponent {
  @ViewChild("nameTooltip") nameTooltip!: OverlayPanel

  @Input() company: Nullable<Company> = null;
  @Input() size: AvatarSize = 'md';
  @Input() disableTooltip = false;
  
  hideTooltip() {
    this.nameTooltip?.hide();

  }

}

type AvatarSize = 'xs' | 'sm' | 'md';
