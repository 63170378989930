import { Injectable } from '@angular/core';
import { MetaService } from '@core/services/meta.service';
import { EMPTY, Observable, shareReplay } from 'rxjs';
import { Tag } from '@models/tag';
import { ApiResponse } from '../../api/base';
import { Nullable } from '@core/models/nullable';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  tags$: Observable<ApiResponse<Tag[]>> = this.metaService.getTags().pipe(shareReplay(1));
  tagsById$: Observable<ApiResponse<Tag[]>> = EMPTY;
  constructor(private metaService: MetaService) {
  }

  fetchUpdatedTags() {
    this.tags$ = this.metaService.getTags().pipe(shareReplay(1));
  }

  getTagsById(tagIds: Array<string>) {
    this.tagsById$ = this.metaService.getTagsById(tagIds).pipe(shareReplay(1))
    return this.tagsById$;
  }

  getTagsByName(query: string) {
    return this.metaService.getTagsByName(query);
  }
}