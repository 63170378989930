import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '@models/company';
import { User } from '@models/user';

@Pipe({
  name: 'coveragePersonsWithPodLeader'
})
export class CoveragePersonsWithPodLeaderPipe implements PipeTransform {

  transform(company: Company): User[] {
    const { assignee } = company;

    if (assignee) {
      const podLeaderInCoverageUsers = company.coverageUsers.find(({ id }) => id === assignee.id);

      if (podLeaderInCoverageUsers) {
        podLeaderInCoverageUsers.isPodLeader = true;
        return company.coverageUsers.sort((a, b) => {
          return a.isPodLeader === b.isPodLeader ? 0 : b.isPodLeader ? 1 : -1;
        });
      }

      return [{ ...assignee, isPodLeader: true }, ...company.coverageUsers];
    }

    return company.coverageUsers;
  }
}
