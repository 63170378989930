import { Directive } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';

export enum TimeBasedTriggerOccurrence {
  OneTime,
  Recurring
}

@Directive({ selector: 'p-dropdown[timeBasedTriggerOccurrences]' })
export class TimeBasedTriggerOccurrencesDirective {
  constructor(dropdown: Dropdown) {
    dropdown.options = [
      { name: 'One time', id: TimeBasedTriggerOccurrence.OneTime },
      { name: 'Recurring', id: TimeBasedTriggerOccurrence.Recurring }
    ];
    dropdown.optionValue = 'id';
    dropdown.optionLabel = 'name';
  }
}