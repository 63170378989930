<p-toast position="bottom-center" key="rd" [ngClass]="{ 'extension-toast': isExtension }"></p-toast>

<div class="grid mg-t-20">
  <ng-template [ngIf]="!isNonAnalystUser">
    <div class="col lex align-items-end mg-b-16">
      <p-button id="add-new" (click)="toggleAddNew()" label="Add New Connection" styleClass="btn-sec">
        <i class="pi pi-plus mr-2"></i>
      </p-button>
    </div>

    <div class="col mg-b-16" *ngIf="!isNonAnalystUser">
      <p-dropdown id="select-user" styleClass="w-100" (onChange)="onSelectedInternalChange()" optionValue="id"
        [(ngModel)]="selectedInternalId" [options]="allInternalUsers" optionLabel="name"></p-dropdown>
    </div>
  </ng-template>
  <ng-template [ngIf]="isNonAnalystUser && !isMobile">
    <div class="col mg-b-16">
      <p-button id="add-new" (click)="toggleAddNew()" label="Add New Contact" styleClass="btn-sec">
        <span class="pi pi-user-plus mr-2"></span>
      </p-button>
    </div>
  </ng-template>
</div>
<ng-template [ngIf]="external.length > 0" [ngIfElse]="noRelationData">
  <div class="grid">
    <div class="col-12">
      <bpc-profile-badge
        *ngFor="let externalData of external | slice : currentPage*pageTotal : currentPage * pageTotal + pageTotal; trackBy: identify"
        [extendedUser]="externalData | withoutEmailSubaddress" [companyName]="companyName" [internal]="internal"
        (onEditContact)="onEditContact($event)" (onRemoveContact)="onRemoveContact($event)"
        (onRemoveConnection)="onRemoveConnection($event)" (onMakeConnection)="onMakeConnection($event)"
        (toggleCompanyNavigation)="onToggleCompanyNavigation($event)" [isConnected]="isConnected(externalData.id)"
        [isNonAnalystUser]="isNonAnalystUser">
      </bpc-profile-badge>
      <div *ngIf="external.length > pageTotal" class="contacts-pagination">
        <button type="button" class="pi pi-angle-left cursor-pointer p-paginator-prev"
          (click)="currentPage = currentPage - 1" [disabled]="currentPage===0"
          [class.p-disabled]="currentPage===0"></button>
        <span class="paginator-contacts">Showing {{currentPage*pageTotal}} - {{currentPage*pageTotal+pageTotal}} of
          {{external.length}}
          records</span>
        <button type="button" class="pi pi-angle-right cursor-pointer p-paginator-next" [class.p-disabled]="isLastPage"
          (click)="navigateToNextPage()" [disabled]="isLastPage"></button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #noRelationData>
  <div class="grid">
    <div class="col-12">
      <p>No Contact Data</p>
    </div>
  </div>
</ng-template>

<bpc-connection-panel *ngIf="showAddNewPanel" [visible]="showAddNewPanel" [addNew]="!editProfile"
  [companyId]="companyId" (submitForm)="onSubmit($event, editProfile ? 'edit' : 'new')" (onHide)="resetProfile()"
  (editing)="onEditing(editProfile, $event)" [profile]="editProfile | withoutEmailSubaddress"
  [isNonAnalystUser]="isNonAnalystUser" [isExtension]="isExtension" [companyDetails]="companyDetails"
  [selectedInternal]="selectedInternal" [allInternalUsers]="allInternalUsers" (closeConnection)="closeConnectionPanel()"
  [isConnected]="isConnected(editProfile?.id)">
</bpc-connection-panel>