import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlow } from '@models/auxiliary-company-models';
import { Nullable } from '@models/nullable';
import { getActiveOrLastICVotingWorkflow } from '../../../utils/company-utils';

@Pipe({
  name: 'votingDescription'
})
export class VotingDescriptionPipe implements PipeTransform {

  transform(workflows: WorkFlow[]): Nullable<string> {
    return getActiveOrLastICVotingWorkflow(workflows)?.description ?? null;
  }
}
