import { Component, Input, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'bpc-grid-loader-line',
  templateUrl: './grid-loader-line.component.html',
  styleUrls: ['./grid-loader-line.component.scss']

})
export class GridLoaderLineComponent {

  @Input() loading: boolean = false;

}

