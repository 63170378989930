import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiResponse, BaseApiService } from '../../api/base';
import { AuthService } from '@core/auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Company } from '@models/company';

@Injectable({
  providedIn: 'root'
})
export class ChromeExtensionService extends BaseApiService<Company> {

  isExtension = new BehaviorSubject<boolean>(false);
  loading = false;

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/companies', authService, router, messageService);
  }

  getCompanyByDomain(domain: string): Observable<ApiResponse<Company>> {
    return this.get(`/domain/${ domain }`);
  }
}
