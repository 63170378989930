import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { IncludeOrExclude, LogicalOperator } from "./main-filter-view";


export interface filterCountsType {
  [key: string]: number
}

export interface keywordsGroup {
  outerOperator: FormControl<LogicalOperator>;
  includeOrExclude: FormControl<IncludeOrExclude>;
  filterLines: FormArray<FormGroup<keywordInnerFilter>>;
}

export interface keywordInnerFilter {
  innerOperator: FormControl<LogicalOperator>;
  keyword: FormControl;
  field: FormControl;
}

export interface CompanyOverviewForm {
  companyOverviewIndustryInclude: FormControl<any[]>;
  companyOverviewIndustryExclude: FormControl<any[]>;
  companyOverviewLocationDropdown: FormControl<any[]>;
  companyOverviewHeadquarterCheckbox: FormControl;
  companyOverviewRadiusSlider: FormControl;
  companyOverviewMinEmployee: FormControl;
  companyOverviewMaxEmployee: FormControl;
  companyOverviewGrowthRateMin: FormControl;
  companyOverviewGrowthRateMax: FormControl;
  // companyOverviewButton1: FormControl;     
  // companyOverviewButton3: FormControl;     
  // companyOverviewButton6: FormControl;     
  // companyOverviewButton1Year: FormControl;     
  companyOverviewStartYear: FormControl;
  companyOverviewEndYear: FormControl;
}

export interface FilterLines {
  innerOperator: LogicalOperator,
  keyword: string,
  field: string,
}
export interface FilterKeywords {
  outerOperator: LogicalOperator,
  includeOrExclude: IncludeOrExclude,
  filterLines: FilterLines
}

export interface FundingForm {
  fundingTotalMin: FormControl;
  fundingTotalMax: FormControl;
  fundingLastMin: FormControl;
  fundingLastMax: FormControl;
  fundingLastDateStart: FormControl;
  fundingLastDateEnd: FormControl;
}
export interface InvestorsForm {
  investorsInclude: FormControl<any[]>;
  investorsExclude: FormControl<any[]>;
}
export interface EndMarketsForm {
  endMarketsInclude: FormControl<any[]>;
  endMarketsExclude: FormControl<any[]>;
}
export interface ProductsServicesForm {
  productsServicesInclude: FormControl<any[]>;
  productsServicesExclude: FormControl<any[]>;
}
export interface EstimatedRevenueForm {
  estimatedRevenueMin: FormControl;
  estimatedRevenueMax: FormControl;
  estimatedRevenueGrowthMin: FormControl;
  estimatedRevenueGrowthMax: FormControl;
}
export interface TagsForm {
  // tagsTaggedSince: FormControl;
  // tagsTaggedBefore: FormControl;
  tagsInclude: FormControl<any[]>;
  tagsExclude: FormControl<any[]>;
}
export interface BpcCoverageForm {
  bpcNoExistingCoverage: FormControl;
  bpcInclude: FormControl<any[]>;
  bpcExclude: FormControl<any[]>;
  bpcDealCloudDropDown: FormControl<any[]>;
  bpcDealCloudDropDownExclude: FormControl<any[]>;
  bpcDCStartDate: FormControl;
  bpcDCEndDate: FormControl;
  bpcLastInteractionFrom: FormControl;
  bpcLastInteractionTo: FormControl;
  // bpcContact: FormControl;
  // bpcByEmail: FormControl;
  // bpcByCall: FormControl;
  // bpcBoth: FormControl;
  // bpcNeverContacted: FormControl;
  bpcInteractionType: FormControl;
  bpcRecallDateFrom: FormControl;
  bpcRecallDateTo: FormControl;
}

export interface MainFormGroup {

  keywordsGroup: FormArray<FormGroup<keywordsGroup>>;
  companyOverviewForm: FormGroup<CompanyOverviewForm>;
  fundingForm: FormGroup<FundingForm>;
  investorsForm: FormGroup<InvestorsForm>;
  endMarketsForm: FormGroup<EndMarketsForm>;
  productsServicesForm: FormGroup<ProductsServicesForm>;
  estimatedRevenueForm: FormGroup<EstimatedRevenueForm>;
  tagsForm: FormGroup<TagsForm>;
  bpcCoverageForm: FormGroup<BpcCoverageForm>;
  companyName: FormControl;
  companyTypesInclude: FormControl;
  companyTypeExclude: FormControl;
  companyDescription: FormControl;
  excludeLatestRoundDateTo: FormControl;
  latestRoundDateTo: FormControl;
  // Default value is 6 months
  statuses: FormControl;
  statusesExclude: FormControl;
  industries: FormControl;
  industriesExclude: FormControl;
  locationsInclude: FormControl;
  locationsExclude: FormControl;
  investors: FormControl;
  investorsExclude: FormControl;
  paidInCapitalMinTotal: FormControl;
  paidInCapitalMaxTotal: FormControl;
  revenueMinTotal: FormControl;
  revenueMaxTotal: FormControl;
  revenueMinGrowth: FormControl;
  revenueMaxGrowth: FormControl;
  predefinedFilter: FormControl;
  interactionFilterType: FormControl;
  interactionExclude: FormControl;
  interactionCountFilterType: FormControl;
  interactionMinTotal: FormControl;
  interactionMaxTotal: FormControl;
  interactionDate: FormControl;
  assigneeFilterType: FormControl;
  assignees: FormControl;
  tags: FormControl;
  tagsExclude: FormControl;
  workflows: FormControl;
  isActive: FormControl;
  hasNotificationTriggers: FormControl;

}


export function formatFilterLines(filterLines: any) {
  return filterLines.map((filter: any, index: number) => {
    const nextTick = filterLines[index + 1];
    const operator = LogicalOperator[nextTick?.innerOperator];
    return ((`${filter.field}: ${filter.keyword}`) + (nextTick ? (` <strong>${operator}</strong> `) : ''));
  }).join(' ');
}

export function formatFilterGroup(filtersArray: any) {
  return (filterGroup: any, index: number) => {
    const nextTick = filtersArray[index + 1];
    const operator = LogicalOperator[nextTick?.outerOperator];
    const includeExclude = IncludeOrExclude[filterGroup.includeOrExclude];
    const filterLines = formatFilterLines(filterGroup.filterLines);
    return `<strong >${includeExclude}</strong>(${filterLines}) ${nextTick ? `<strong>${operator}</strong>` : ''} `;
  }
}

export function getKeywordsItemsCount(arr: any[] = []) {
  const cleansedData = cleanEmptyFilters(arr);
  const combinedFilterItems = cleansedData.flatMap((obj: any) => obj.filterLines);
  const allCountItems = arr.flatMap((obj: any) => obj.filterLines);

  return { cleansedCount: combinedFilterItems?.length, allCount: allCountItems?.length };
}
export function cleanEmptyFilters(data: any) {
  let filteredData: any[] = [];
  data.map((item: any) => {
    let innerItems = item.filterLines.filter((filter: any) => !!filter.keyword);
    if (innerItems.length > 0) {
      filteredData.push(({
        ...item,
        filterLines: innerItems,
      }))
    }
  });
  return filteredData;
}