import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '@core/models/company';
import { SocialLink } from '@models/social-link';

@Pipe({
  name: 'linkedInLink'
})
export class LinkedInLinkPipe implements PipeTransform {

  transform(company: Company): SocialLink {

    const linkedInSource = company.sources.find(source => source.sourceType === 3);

    if (linkedInSource && linkedInSource.sourceUrl) {
      return {
        type: 'linkedIn',
        sourceId: 3,
        link: linkedInSource.sourceUrl
      };
    }

    return {
      type: 'linkedIn',
      sourceId: 3,
      link: null
    };

  }

}
