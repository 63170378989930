import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { CompletelyNullable, Nullable } from '@models/nullable';

@Pipe({
  name: 'bpcNumber'
})
export class BpcNumberPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {
  }

  transform(value: CompletelyNullable<number>, digitsInfo: string, currency: string): Nullable<string> {
    if (value == null) {
      return null;
    }

    // Currency values are always in M
    if (currency || value / 1_000_000 > 1) {
      return currency + this.decimalPipe.transform(value / 1_000_000, digitsInfo) + 'M';
    }

    if (value / 1_000 > 1) {
      return currency + this.decimalPipe.transform(value / 1_000, digitsInfo) + 'k';
    }

    return currency + this.decimalPipe.transform(value, '1.0-0');
  }
}
