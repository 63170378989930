import { Company } from "@models/company";
import { Nullable } from "@models/nullable";
import { companyStatusOptions, nonAssignableCompanyStatusOptions } from "@shared/forms/dropdown-options";
import { StatusId, WorkFlow, WorkFlowId, WorkFlowStatusId } from "@models/auxiliary-company-models";
import { User } from "@models/user";
import { InvestorTab, Tab } from "../core/models/types";

export function convertStatusIdToStatus(statusId: StatusId): Nullable<string> {
  const label = companyStatusOptions.concat(nonAssignableCompanyStatusOptions).find(({ id }) => id === statusId)?.label;
  return label == null || label === "None" ? null : label;
}

export function getCurrentUserVotingScore(workflows: WorkFlow[]): Nullable<number> {
  return getActiveICVotingWorkflow(workflows)?.details?.currentUserVote ?? null;
}

export function getCurrentUserVotingComment(workflows: WorkFlow[]): string {
  return getActiveICVotingWorkflow(workflows)?.details?.currentUserComment ?? "";
}

export function getAllICVotingWorkflows(workflows: WorkFlow[]): WorkFlow[] {
  return workflows.filter((w) => w.workflowTypeId === WorkFlowId.ICVote);
}

export function getActiveICVotingWorkflow(
  workflows: WorkFlow[],
  status = WorkFlowStatusId.InReview
): WorkFlow | undefined {
  let clonedWorkflows = [...workflows];
  const sorted = clonedWorkflows.sort((a, b) => Date.parse(b.votingDate) - Date.parse(a.votingDate));
  return sorted.find(({ workflowTypeId, statusId }) => workflowTypeId === WorkFlowId.ICVote && statusId == status);
}

export function getActiveOrLastICVotingWorkflow(workflows: WorkFlow[]): WorkFlow | undefined {
  const active = getActiveICVotingWorkflow(workflows);
  if (active) {
    return active;
  }
  const icVotingWorkflows = getAllICVotingWorkflows(workflows).sort(
    (a, b) => Date.parse(b.votingDate) - Date.parse(a.votingDate)
  );
  return icVotingWorkflows[icVotingWorkflows.length - 1];
}

export function getVotingBackgroundClasses(score: number): string {
  if (score < 1) {
    return "grey";
  } else if (score <= 4.9) {
    return "red";
  } else if (score <= 7.9) {
    return "orange";
  } else {
    return "blue";
  }
}

export function getVotingBorderClasses(score: number): string {
  if (score < 1) {
    return "";
  } else if (score <= 4.9) {
    return "red";
  } else if (score <= 7.9) {
    return "orange";
  } else {
    return "blue";
  }
}

export function getVotingTextColorClasses(score: number): string {
  if (score < 1) {
    return "";
  } else if (score <= 4.9) {
    return "text-white";
  } else if (score <= 7.9) {
    return "text-black";
  } else {
    return "text-white";
  }
}

export function getEnrichmentWorkflow(workflows: WorkFlow[]): WorkFlow | undefined {
  return workflows.find(({ workflowTypeId }) => workflowTypeId === WorkFlowId.Enrich);
}

export function getEnrichmentWorkflowIndex(workflows: WorkFlow[]): number {
  return workflows.findIndex(({ workflowTypeId }) => workflowTypeId === WorkFlowId.Enrich);
}

export function getEnrichmentStatusId(workflows: WorkFlow[]): WorkFlowStatusId {
  return getEnrichmentWorkflow(workflows)?.statusId ?? WorkFlowStatusId.NoStatus;
}

export function addEnrichmentWorkflow(company: Company, newWorkflow: WorkFlow) {
  const index = getEnrichmentWorkflowIndex(company.workflows);

  if (index > -1) {
    company.workflows[index] = newWorkflow; // found, replace existing
  } else {
    company.workflows.push(newWorkflow); // not found, add new
  }

  company.workflows = company.workflows.slice(); // copy array
}

export function addVotingWorkflow(company: Company, newWorkflow: WorkFlow) {
  company.workflows.push(newWorkflow);
  company.workflows = company.workflows.slice();
}

export function updateEnrichmentStatus(company: Company, newStatusId: WorkFlowStatusId) {
  const workflow = getEnrichmentWorkflow(company.workflows);

  if (workflow) {
    workflow.statusId = newStatusId;
  }

  company.workflows = company.workflows.slice(); // copy array
}

export function removeEnrichmentWorkflow(company: Company) {
  const index = getEnrichmentWorkflowIndex(company.workflows);

  if (index > -1) {
    company.workflows.splice(index, 1);
  }

  company.workflows = company.workflows.slice(); // copy array
}

export function convertWorkflowStatusIdToEnrichmentStatus(user: User, workflows: WorkFlow[]): Nullable<string> {
  const userRole = user.role;
  const enrichmentStatusId: WorkFlowStatusId = getEnrichmentStatusId(workflows);

  switch (enrichmentStatusId) {
    case WorkFlowStatusId.ToDo:
      return userRole === "investor" ? "In Progress" : "To Do";
    case WorkFlowStatusId.InProgress:
      return "In Progress";
    case WorkFlowStatusId.Done:
      return "Done";
    case WorkFlowStatusId.RevisionRequired:
      return userRole === "investor" ? "In Progress" : "Revision Required";
    case WorkFlowStatusId.Accepted:
      return "Accepted";
    case WorkFlowStatusId.NoStatus:
    default:
      return null;
  }
}

export function getGridBackgroundClasses(user: User, company: Company, tabIndex: Tab): string[] {
  // if (tabIndex === InvestorTab.PRIORITY && company.priorityDays >= 6) {
  //   return ['!bg-pink-100'];
  // }

  const userRole = user.role;
  const workflows = company.workflows;
  const enrichmentStatusId: WorkFlowStatusId = getEnrichmentStatusId(workflows);

  if (userRole === "investor") {
    const workflow = getEnrichmentWorkflow(workflows);

    if (workflow?.owner.id === user.id) {
      switch (enrichmentStatusId) {
        case WorkFlowStatusId.ToDo:
        case WorkFlowStatusId.InProgress:
        case WorkFlowStatusId.Done:
        case WorkFlowStatusId.RevisionRequired:
          return ["!bg-blue-300"];
        case WorkFlowStatusId.NoStatus:
        case WorkFlowStatusId.Accepted:
        default:
          return [];
      }
    }

    return [];
  }

  switch (enrichmentStatusId) {
    case WorkFlowStatusId.ToDo:
    case WorkFlowStatusId.RevisionRequired:
      return ["!bg-pink-100"];
    case WorkFlowStatusId.InProgress:
      return ["!bg-orange-100"];
    case WorkFlowStatusId.Done:
    case WorkFlowStatusId.Accepted:
    case WorkFlowStatusId.NoStatus:
    default:
      return [];
  }
}

export function getEnrichmentClasses(user: User, workflows: WorkFlow[]): string[] {
  const userRole = user.role;
  const enrichmentStatusId: WorkFlowStatusId = getEnrichmentStatusId(workflows);

  switch (enrichmentStatusId) {
    case WorkFlowStatusId.ToDo:
      return ["text-blue", "enrich-red"];
    case WorkFlowStatusId.InProgress:
      return ["text-orange", "enrich-orange"];
    case WorkFlowStatusId.RevisionRequired:
      return ["text-blue", "enrich-red"];
    case WorkFlowStatusId.Accepted:
      return userRole === "analyst" ? ["text-green", "enrich-green"] : ["hidden"];
    case WorkFlowStatusId.Done:
      return ["text-green", "enrich-green"];
    case WorkFlowStatusId.NoStatus:
    default:
      return ["enrich"];
  }
}

export function getCompanyDetailsEnrichmentClass(workflows: WorkFlow[]) {
  const workflow = getEnrichmentWorkflow(workflows);

  if (!workflow) {
    return "hidden";
  }

  const { statusId } = workflow;

  const hasEnrichDataWorkflow = statusId !== WorkFlowStatusId.NoStatus && statusId !== WorkFlowStatusId.Accepted;

  if (!hasEnrichDataWorkflow) {
    return "hidden";
  }

  return {
    "bg-orange-500": statusId === WorkFlowStatusId.InProgress,
    "bg-pink-500": statusId === WorkFlowStatusId.ToDo || statusId === WorkFlowStatusId.RevisionRequired,
    "bg-teal-300": statusId === WorkFlowStatusId.Done,
    "mr-2": hasEnrichDataWorkflow,
  };
}
