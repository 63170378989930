import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { Tag, TagType } from "../../../core/models/tag";
import { MetaService } from "../../../core/services/meta.service";
import { map, takeUntil } from "rxjs";
import { DestroyObservable } from "../../../rxjs/DestroyObservable";
import { MessageService } from "primeng/api";
import { ApiResponse, serialize } from "../../../api/base";
import { OperationResult } from "../../../core/models/operation-result";
import { EditCustomTagMode } from "../../../core/components/edit-custom-tag/edit-custom-tag.component";
import { AuthService } from "../../../core/auth/auth.service";
import { BasicCompany } from "@core/models/company";
import { CompaniesService } from "src/app/services/companies/companies.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TagsService } from "@core/services/tags.service";
import { FilterHelperService } from "@core/services/filter-helper.service";
import { MenuItem } from "@shared/components/menu-native/menu-native-item";
import { RawValueMappingType } from "@core/models/auxiliary-company-models";
import { MixpanelService } from "@core/services/mixpanel.service";

type QueryParams = { tags?: [string]; tagsExclude?: string };

//define type for style
type Style = {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  "z-index"?: number;
};

@Component({
  selector: "bpc-meta-tags",
  templateUrl: "./meta-tags.component.html",
  styleUrls: ["./meta-tags.component.scss"],
  viewProviders: [DestroyObservable],
})
export class MetaTagsComponent {
  @ViewChild("content") content!: ElementRef;
  isVisible: boolean = false;

  isBulk = false;
  privateTagList: Tag[] = [];
  publicTagList: Tag[] = [];
  tag: Tag = { type: TagType.User } as Tag;
  displayEditCustomTag = false;
  displayRemoveCustomTag = false;
  editCustomTagMode: EditCustomTagMode = "edit";
  selectedTagCompanies: BasicCompany[] = [];
  loggedInUserId?: number;
  style: Style = {};
  queryParams: QueryParams = {};
  selectedTag?: Tag;

  constructor(
    private metaService: MetaService,
    private companiesService: CompaniesService,
    private readonly destroy$: DestroyObservable,
    private messageService: MessageService,
    private authService: AuthService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected tagsService: TagsService,
    private filterHelper: FilterHelperService,
    private mixpanelService: MixpanelService,
  ) {}

  trackByTag(index: number, { id }: Tag): number {
    return id;
  }

  get user() {
    return this.authService.user.getValue();
  }

  get contentElement(): HTMLElement {
    return this.content.nativeElement;
  }

  get menuItems(): MenuItem[] {
    const removeTag: MenuItem = {
      label: "Remove Tag",
      // icon: "icon-check-square",
      command: () => this.tagAction("remove"),
    };
    const editTag: MenuItem = {
      label: "Edit Tag",
      // icon: "icon-enrich",
      command: () => this.tagAction("edit"),
    };

    return [editTag, removeTag];
  }

  tagAction(action: string) {
    if (action === "remove") {
      this.onRemoveTagClick(this.selectedTag);
    } else if (action === "edit") {
      this.onEditTagClick(this.selectedTag);
    }
  }

  showTags() {
    this.isVisible = true;
    document.body.style.overflow = 'hidden';
    this.getTagsData();
  }

  getTagsData() {
    this.route.queryParams.subscribe((params) => (this.queryParams = params));
    if (this.authService.userId) {
      this.loggedInUserId = this.authService.userId;
    }
    this.getUserTag();
    this.subscribeToMetaTagService();
  }

  onMenuClick(tag: Tag) {
    this.selectedTag = tag;
  }

  ngOnInit() {
  }

  onTagClick(event: Event, tag: Tag) {
    event.stopPropagation();
    this.isVisible = false;
    if (tag.id) {
      this.mixpanelService.trackEvent("Sidebar_Tag_Click");
      let tagsPairInclude = [{ id: tag.id.toString(), name: tag.name }];
      const key = RawValueMappingType[RawValueMappingType.tagsInclude]
      let jsonMapping: Record<string, any> = {};
      jsonMapping[key]=tagsPairInclude;
      let rawValueMapping = JSON.stringify({ ...jsonMapping });
      this.router.navigateByUrl(`/companies?tagsInclude=${tag.id}&rawValueMapping=${rawValueMapping}`);
    }
  }
  private subscribeToMetaTagService() {
    this.metaService.currentMessage.pipe(takeUntil(this.destroy$)).subscribe((message) => {
      if (message === "refresh-tags") {
        this.getUserTag();
      }
    });
  }

  toggle() {
    this.calculateOffset();
  }

  extractCompaniesIds(companies: BasicCompany[]) {
    return companies.map((company) => company.id);
  }

  getTagIndex(params: QueryParams) {
    if (params.tags) {
      if (!Array.isArray(params.tags)) {
        params.tags = [params.tags];
      }
      return params.tags.indexOf(this.tag.id.toString());
    }
    return -1;
  }

  removeTag() {
    let params = { ...this.queryParams };
    let tagIndexInParams = this.getTagIndex(params);

    this.metaService
      .removeTag(this.tag.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, error }) => {
        if (data?.result) {
          this.getUserTag();
          this.showSuccessToastMessage(data.message);
          this.updateFiltersMap();
          // if tab is companies or pipeling then handling remove tag logic
          if (this.router.url.includes("/companies") || this.router.url.includes("/pipeline")) {
            if (tagIndexInParams > -1 && params && params.tags) {
              params.tags.splice(tagIndexInParams, 1);
              let tags = [...params.tags];
              if (tags.length === 0) {
                delete params.tagsExclude;
              }
              this.router.navigateByUrl(`/companies?${serialize(params)}`);
            }
          }
        } else if (error) {
          this.displayErrorMessage(error, "An error happened while removing tag.");
        }
        this.resetTags();
      });
  }

  onRemoveTagClick(tag?: Tag) {
    this.isBulk = !tag;
    this.openRemoveTagModal(tag);
  }

  openRemoveTagModal(tag?: Tag) {
    this.displayRemoveCustomTag = true;
    if (tag) {
      this.tag = tag;
      return;
    }
  }

  onEditTagClick(tag?: Tag) {
    this.isBulk = !tag;
    this.openEditTagModal(tag);
  }

  openEditTagModal(tag?: Tag) {
    this.displayEditCustomTag = true;
    if (tag) {
      this.tag = tag;
      this.editCustomTagMode = "edit";
      return;
    }
    this.editCustomTagMode = "create";
  }

  cancelTagRemove() {
    this.displayRemoveCustomTag = false;
    this.selectedTagCompanies = [];
    this.tag = { type: TagType.User } as Tag;
  }

  resetTags() {
    this.displayEditCustomTag = false;
    this.displayRemoveCustomTag = false;
    this.selectedTagCompanies = [];
    this.tag = { type: TagType.User } as Tag;
  }

  updateFiltersMap() {
    this.tagsService.fetchUpdatedTags();
    this.tagsService.tags$.subscribe((tags) => {
      if (tags.data) {
        this.filterHelper.upsertTags(tags.data);
      }
    });
  }

  onHide() {
    this.isVisible = false;
    document.body.style.overflow = 'auto';
  }

  saveTag() {
    const isNew = !this.tag.id;
    let upsertObs$;
    if (this.isBulk) {
      upsertObs$ = isNew
        ? this.companiesService.createTag({
            ...this.tag,
            companyIds: this.extractCompaniesIds(this.selectedTagCompanies),
          })
        : this.companiesService.updateTag({
            ...this.tag,
            companyIds: this.extractCompaniesIds(this.selectedTagCompanies),
          });
    } else {
      upsertObs$ = isNew ? this.metaService.createTag(this.tag) : this.metaService.updateTag(this.tag.id, this.tag);
    }
    upsertObs$.pipe(takeUntil(this.destroy$)).subscribe(({ data, error }) => {
      if (data?.result) {
        this.tag.id = data.data; // data is an ID of the newly created tag
        this.tag.createBy = this.user!.id;
        this.getUserTag();
        this.resetTags();
        // getting the updated names and map of tags
        this.updateFiltersMap();
        this.showSuccessToastMessage(data?.message);
        const eventName = isNew ? "Sidebar_Tag_Added" : "Sidebar_Tag_Updated";
        this.mixpanelService.trackEvent(eventName);
      } else if (error) {
        this.displayErrorMessage(error.response, "An error happened while saving tag.");
      }
    });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  private getUserTag() {
    this.metaService.getUserTags().subscribe(({ data, error }) => {
      if (data) {
        this.privateTagList = data.filter((x) => x.type === 2);
        this.publicTagList = data.filter((x) => x.type === 1);
      } else if (error) {
        this.displayErrorMessage(error, "An error happened while removing tag.");
      }
    });
  }

  private displayErrorMessage(response: ApiResponse<OperationResult>, defaultErrorMessage: string) {
    this.messageService.add({
      key: "cd",
      severity: "error",
      detail: response.message ?? response?.message ?? defaultErrorMessage,
    });
  }

  private calculateOffset() {
    const { height } = this.contentElement.getBoundingClientRect();
    this.style.top = height + 5 + "px";
    this.style.left = "auto";
    this.style.right = "0px";
    this.style.bottom = "auto";
    this.style["z-index"] = 2000;
  }
}
