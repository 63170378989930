import { AfterViewInit, Directive, inject } from '@angular/core';
import { InputNumber } from 'primeng/inputnumber';
import { debounceTime, fromEvent, takeUntil } from 'rxjs';
import { DestroyObservable } from '../../rxjs/DestroyObservable';

@Directive({
  selector: 'p-inputNumber[updateOnChange]',
  providers: [DestroyObservable]
})
export class UpdateOnChangeInputNumberDirective implements AfterViewInit {
  inputNumber = inject(InputNumber);
  destroy$ = inject(DestroyObservable);

  ngAfterViewInit() {
    fromEvent(this.inputNumber.input.nativeElement, 'keydown')
      .pipe(debounceTime(10))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.inputNumber.updateModel(null!, this.inputNumber.validateValue(this.inputNumber.parseValue(this.inputNumber.input.nativeElement.value)));
      });
  }
}