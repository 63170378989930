import { Pipe, PipeTransform } from '@angular/core';
import { Staff } from '@models/user';

@Pipe({
  name: 'withoutEmailSubaddress'
})
export class WithoutEmailSubaddressPipe implements PipeTransform {

  transform(user: Staff): Staff {
    const workEmail = user?.workEmail?.replace(/\+(.*)@/, '@') ?? null;

    return { ...user, workEmail };
  }
}