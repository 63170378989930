import { Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { interactions, RawValueMappingType } from '@core/models/auxiliary-company-models';
import { Company } from '@core/models/company';
import { AssigneeRole, IdNamePair, MainFilterRaw } from '@core/models/main-filter-view';
import { Nullable } from '@core/models/nullable';
import { Pod, PodGroupType, PodInteraction, Stage } from '@core/models/pod-filter';
import { InvestorTab, Tab } from '@core/models/types';
import { UserRole } from '@core/models/user';
import { appendToRawValueMapping } from '@core/services/filter-helper.service';
import { SearchHelperService } from '@core/services/search-helper.service';
import { TabData, customPod, myCompaniesPod, getTabs, TabType, firmPod, myInteractionsPod } from '@core/static/tabs';
import { ChromeExtensionService } from 'src/app/services/chrome-extension/chrome-extension.service';
import { BasePagingFilter, BaseSortingFilter, CompaniesSearchService } from 'src/app/services/companies-search/companies-search.service';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from "ngx-device-detector";
import { MenuItem } from '@shared/components/menu-native/menu-native-item';
import { generalConstants } from '@core/static/constants';
import { CompaniesExportService } from 'src/app/services/companies-export/companies-export.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  of,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { CSVService } from 'src/app/services/csv-service/csv.service';
import { CompanyHelperService } from '@core/services/company-helper.service';
import { MessageService } from 'primeng/api';
import { TabUtilsService } from 'src/app/services/tab-utils.service';
import { UsersService } from 'src/app/services/users/users.service';
import { DestroyObservable } from 'src/app/rxjs/DestroyObservable';
import { AIPrepReportComponent } from '@core/components/ai-prep-report/ai-prep-report.component';
import { MixpanelService } from '@core/services/mixpanel.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { ModalUtilsService } from 'src/app/services/modal-utils.service';
import { InteractionList } from '@core/models/forms';
import { ApiResponse } from 'src/app/api/base';
import { OperationResult } from '@core/models/operation-result';
import stateData from './states.json';

interface StateDataInterface {
  code: string;
  value: number;
}

export interface ChartData {
  companyId: number;
  name: string;
  statusId: number;
  lastInteractionDate: string;  // Or use Date if you're handling this as a Date object
  interactionScore: number;
  notesCount: number;
  emailCount: number;
  phoneCount: number;
  videoCallCount: number;
  meetingCount: number;
}

@Component({
  selector: 'bpc-grid-tabs',
  templateUrl: './grid-tabs.component.html',
  styleUrls: ['./grid-tabs.component.scss']
})
export class GridTabsComponent implements OnInit {
  // currentTabIndex: Tab = InvestorTab.COMPANIES;
  // currentChildIndex: Nullable<number> = null;
  displayFiltersMenu = false;
  podsAdjustedAfterMainFilterApply: boolean = false;
  pipeLineTab = InvestorTab.PIPELINE;
  nonCountHeaderTabs: string[] = ['ic-voting', 'saved-search', 'ai-summary', 'weekly-update','admin-panel', 'chatbot']
  nonGridTabs: string[] = ['ai-summary', 'weekly-update', 'chatbot']
  @Input() isExpanding: boolean = false;
  @Input() currentTabIndex!: Tab;
  @Input() currentChildIndex!: Nullable<number>;
  @Input() companyDetails!: Nullable<Company>;
  @Input() menuItems!: MenuItem[];
  @Input() loading!: boolean;
  @Input() tabType!: TabType;
  @Output() clearFiltersEmitter = new EventEmitter();
  @Output() editOutReachOwnerEmitter = new EventEmitter();
  @Output() editNextStepEmitter = new EventEmitter();
  @Output() editICDateEmitter = new EventEmitter();
  @Output() voteForCompanyEmitter = new EventEmitter();
  @Output() showNotificationTriggersAlertEmitter = new EventEmitter();
  @Output() removeSavedSearchEmitter = new EventEmitter();
  @Output() removeFromICEmitter = new EventEmitter();
  @Output() moveCompanyToArchiveEmitter = new EventEmitter();
  @Output() menuClickEmitter = new EventEmitter();
  @Output() DataViewEmitter = new EventEmitter<string>();
  @ViewChild("aiPrepReportRef") aiPrepReportComponent!: AIPrepReportComponent;

  selectedItems: Company[] = [];
  allPods: Pod[] = [];
  stages: Stage[] = [];
  totalCompanies: number = 0;
  filteredCompanies: number = 0;
  stagesLoading: boolean = true;
  interactionsLoading: boolean = true;
  interactions: InteractionList[] = [];
  totalInteractions: number = 0;
  companyData: any[] = [];
  stateData: StateDataInterface[] = []
  mapLoading: boolean = true;
  shouldShowHeatMapGrid: boolean  = false;
  chartData!: ChartData[];
  
  get currentTab(): TabData<Company> {
    const tab = this.tabUtilsService.getAvailableUserTabs(this.tabs)[this.currentTabIndex];
    return tab.children?.[this.currentChildIndex!] ?? tab;
  }

  get activePod(): Pod {
    return this.currentTab.gridFilter!.activePod;
  }

  set activePod(value: Pod) {
    this.currentTab.gridFilter!.activePod = value;
  }

  get mainFilterRaw(): MainFilterRaw {
    return this.currentTab.mainFilterRaw!;
  }

  set mainFilterRaw(value: MainFilterRaw) {
    this.currentTab.mainFilterRaw = value;
  }

  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  get filterByPodLeader(): boolean {
    return this.mainFilterRaw.assigneeRole == AssigneeRole.LEADER;
  }

  set filterByPodLeader(value: boolean) {
    this.mixpanelService.trackEvent('Filter_By_Pod');
    let mainFilterRaw: MainFilterRaw;
    if (value) {
      mainFilterRaw = {
        ...this.mainFilterRaw,
        assigneeRole: AssigneeRole.LEADER,
      };
    } else {
      mainFilterRaw = { ...this.mainFilterRaw };
      delete mainFilterRaw.assigneeRole;
    }
    this.tabUtilsService.navigateToCurrentTab(mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex);
  }

  get filterByMyElevations(): boolean {
    if (environment.weeklyElevationTag) {
      return this.mainFilterRaw.isMyElevation === "true";
    } else {
      return false;
    }
  }

  set filterByMyElevations(value: boolean) {
    let mainFilterRaw: MainFilterRaw;
    if (value) {
      mainFilterRaw = { ...this.mainFilterRaw, isMyElevation: "true" };
    } else {
      mainFilterRaw = { ...this.mainFilterRaw, isMyElevation: "false" };
    }
    this.tabUtilsService.navigateToCurrentTab(mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex);
  }

  get baseFilter(): BasePagingFilter & BaseSortingFilter {
    return {
      sortProperty: this.sortProperty ?? this.defaultSortProperty,
      sortDirection: this.sortDirection ?? this.defaultSortDirection,
      pageSize: this.pageSize.toString(10),
      pageNumber: this.page.toString(10),
    };
  }

  get page(): number {
    return +(this.mainFilterRaw.pageNumber ?? "1");
  }

  set page(value: number) {
    this.tabUtilsService.navigateToCurrentTab({ ...this.mainFilterRaw, pageNumber: value.toString(10) }, this.tabs, this.currentTabIndex, this.currentChildIndex);
  }

  get pageSize(): number {
    return +(this.mainFilterRaw.pageSize ?? "100");
  }

  get sortProperty(): string | undefined {
    return this.mainFilterRaw.sortProperty;
  }

  get showHeatMap(){
    if (this.currentTab.hasHeatMap!=null){
      return this.currentTab.hasHeatMap;
    }
    return false
  }

  get showInteractionsChart(){
    if (this.currentTab.hasInteractionsChart!=null){
      return this.currentTab.hasInteractionsChart;
    }
    return false
  }

  get showChartNavigator(){
    if (this.currentTab.hasHeatMap!=null){
      return this.currentTab.hasChartNavigator;
    }
    return false
  }

  get defaultSortProperty(): string | undefined {
    switch (this.currentTabIndex) {
      case InvestorTab.COMPANIES:
        return "rank";
      case InvestorTab.ELEVATIONS:
        return "name";
      case InvestorTab.VOTING:
        return "votingDate";
      case InvestorTab.PIPELINE:
        return "status";
      default:
        return;
    }
  }

  get sortDirection(): "asc" | "desc" | undefined {
    switch (this.mainFilterRaw.sortDirection?.toLowerCase()) {
      case "asc":
        return "asc";
      case "desc":
        return "desc";
      default:
        return;
    }
  }

  get defaultSortDirection(): "asc" | "desc" | undefined {
    switch (this.currentTabIndex) {
      case InvestorTab.COMPANIES:
      case InvestorTab.ELEVATIONS:
      case InvestorTab.VOTING:
        return "desc";
      case InvestorTab.PIPELINE:
        return "asc";
      default:
        return;
    }
  }

  get currentTabData(): Company[] {
    const tab = this.tabUtilsService.getAvailableUserTabs(this.tabs)[this.currentTabIndex];
    return tab.children?.[this.currentChildIndex!].data ?? tab.data!;
  }

  get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  constructor(private router: Router,
    private searchHelperService: SearchHelperService,
    public authService: AuthService,
    public chromeExtensionService: ChromeExtensionService,
    protected deviceDetectorService: DeviceDetectorService,
    protected companiesExportService: CompaniesExportService,
    protected companyHelperService: CompanyHelperService,
    protected companiesService: CompaniesService,
    public csvService: CSVService,
    protected messageService: MessageService,
    public tabUtilsService: TabUtilsService,
    protected usersService: UsersService,
    protected readonly destroy$: DestroyObservable,
    public mixpanelService: MixpanelService,
    private companiesSearchService: CompaniesSearchService,
    protected modalsUtilsService: ModalUtilsService,
    private destroyObservable: DestroyObservable,
  ) {
    if (!this.isExtension) {
      this.loadPods();
    }
  }
  ngOnInit(): void {
    this.searchHelperService.messageSourceBehaviorSubject.subscribe(message=>{
      this.toggleDataView("table");
    })
    this.stateData = stateData.map(s => {
      return {
        ...s,
        code: s.code.toUpperCase(),
        // value between 1 and 100 for the heat map
        value: Math.floor(Math.random() * 100) + 1
      };
    });
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes){
      if(changes["currentTabIndex"]){
        this.toggleDataView('table');
        if (this.currentTabIndex == InvestorTab.COMPANIES){
          this.initializeBarDataLoadingStream(false);
        }
      }
      if (this.modalsUtilsService.changeStatus && this.currentChildIndex != 1){
        this.initializeBarDataLoadingStream(false);
        this.modalsUtilsService.stopRefreshStatusBar();
        this.generateCompanyData();
      }
      this.checkBar();
    }
  }
  
  get tabs() {
    return this.tabUtilsService.tabs;
  }

  get isInvestmentCommitteeAdmin() {
    return this.authService.investmentcommitteeadmin;
  }

  get isInvestorRelation() {
    return this.authService.isInvestorRelation;
  }

  protected loadPods() {
    this.currentChildIndex == 0 ? this.allPods = [firmPod, myCompaniesPod, customPod] : this.allPods = [firmPod, myInteractionsPod, customPod]
    this.usersService
      .getPods()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response.data && response.data.length) {
          let pods: Pod[] = response.data
            .filter(({ groupType }) => groupType === PodGroupType.Pod)
            .map((pod) => ({ ...pod }));
          pods = pods.map((pod) => {
            const sortedPodMembers = pod.members.sort((a: any, b: any) => {
              return b.isAdmin - a.isAdmin;
            });
            pod.name = "Pod: " + sortedPodMembers.map((member) => member.initials).join(", ");
            return pod;
          });
          this.currentChildIndex == 0 ? this.allPods = [firmPod, myCompaniesPod, ...pods, customPod] : this.allPods = [firmPod, myInteractionsPod, ...pods, customPod]
    
          this.adjustActivePod();
        }
      });
  }

  protected adjustActivePod() {
    if (!this.podsAdjustedAfterMainFilterApply) {
      const assignees = Array.isArray(this.mainFilterRaw.assigneesInclude)
        ? this.mainFilterRaw.assigneesInclude
        : this.mainFilterRaw.assigneesInclude
          ? [this.mainFilterRaw.assigneesInclude]
          : [];

      if (this.allPods.length > 2 && assignees.length) {
        // pods are loaded, try to find the match
        for (const pod of this.allPods) {
          if (
            pod.members.length === assignees.length &&
            pod.members.map(({ userId }) => userId).every((userId) => assignees.includes(userId))
          ) {
            this.activePod = pod;
            break;
          }
        }
      } else {
        this.adjustActivePodAfterMainFilterApply(this.mainFilterRaw);
      }
    }
    this.podsAdjustedAfterMainFilterApply = false;
  }


  
  navigateToTab(tab: Tab) {
    this.isExpanding = false;
    let queryParams = { ...this.tabUtilsService.getAvailableUserTabs(this.tabs)[tab].mainFilterRaw };
    // Setting Tag filter for elevations tab
    if (tab === InvestorTab.ELEVATIONS) {
      let tagsPairInclude: IdNamePair[] = [{ id: Number(environment.weeklyElevationTag), name: "Weekly Elevations" }]
      queryParams.tagsInclude = [environment.weeklyElevationTag || ""];
      queryParams.rawValueMapping = JSON.stringify({
        tagsInclude: tagsPairInclude,
      });
    }

    this.mixpanelService.trackEvent('Navigate_To_Tab', { tab: InvestorTab[tab] });
    this.router.navigate([this.tabUtilsService.getAvailableUserTabs(this.tabs)[tab].route], { queryParams });
    this.searchHelperService.changeMessage({ type: "clear-search", payload: "" });
  }

  shouldShowCurrentTotalInTabHeader(tab: Tab): boolean {
    // return this.currentTotal(tab) !== this.total(tab) && this.currentTotal(tab) !== 0;
    return this.currentTotal(tab) !== this.total(tab);
  }

  currentTotal(page: number): number {
    if (this.currentTabIndex === InvestorTab.COMPANIES && this.currentChildIndex === 1) return (this.totalInteractions) 
    else
     return (
      this.tabUtilsService.getAvailableUserTabs(this.tabs)[page].children?.[this.currentChildIndex!]?.currentTotal ??
      this.tabUtilsService.getAvailableUserTabs(this.tabs)[page].currentTotal!
    );
  }

  total(page: number): number {
    return (
      this.tabUtilsService.getAvailableUserTabs(this.tabs)[page].totalCountByAssignee! ??
      this.tabUtilsService.getAvailableUserTabs(this.tabs)[page].children?.[this.currentChildIndex!]?.total ??
      this.tabUtilsService.getAvailableUserTabs(this.tabs)[page].total
    );
  }

  children(tab: TabData<Company>): TabData<Company>[] {
    return tab.children
      ? tab.children!.filter(
        ({ accessRoles }) =>
          !accessRoles || this.authService.user.getValue()!.roles.some((role) => accessRoles.includes(role))
      )
      : [tab];
  }

  onAvailableUserTabs() {
    return this.tabUtilsService.getAvailableUserTabs(this.tabs);
  }

  isPathMatching(tabRoute: string, childRoute: string): boolean {
    const currentPathWithoutParams = this.router.url.split("?")[0];
    if (childRoute == "" && this.currentTabIndex == InvestorTab.COMPANIES && currentPathWithoutParams==="/"){
      return true;
    }
    return currentPathWithoutParams === "/" + tabRoute + (childRoute ? "/" + childRoute : "");
  }

  onPodsChange() {
    this.mixpanelService.trackEvent('Filter_By_Pod');
    let assigneesPairInclude: IdNamePair[] = [];
    let interactionInitiatorsInclude: IdNamePair[] = [];
    // let assigneeFilterType;
    
    if (this.activePod == myCompaniesPod || this.activePod == myInteractionsPod){
      if (this.authService.userId)
        this.activePod.members = [{userId: this.authService.userId, name: this.authService.userName || undefined}]
    }
    if (this.activePod.members.length) {
      assigneesPairInclude = this.activePod.members.map((member) => ({
        id: member.userId,
        name: member?.name,
      }));

      if (this.currentChildIndex == 1) {   
        interactionInitiatorsInclude = this.activePod.members.map((member) => ({
          id: member.userId,
          name: member?.name,
        }));
        this.mainFilterRaw.interactionInitiatorsInclude = assigneesPairInclude.map((assignee: IdNamePair) => Number(assignee.id));
        this.mainFilterRaw = appendToRawValueMapping(
          this.mainFilterRaw,
          RawValueMappingType.interactionInitiatorsInclude,
          interactionInitiatorsInclude
        );
      } 
      else{

        this.mainFilterRaw.assigneesInclude = assigneesPairInclude.map((assignee: IdNamePair) => Number(assignee.id));
        this.mainFilterRaw = appendToRawValueMapping(
          this.mainFilterRaw,
          RawValueMappingType.assigneesInclude,
          assigneesPairInclude
        );
      }
    }
    else{
      delete this.mainFilterRaw.assigneesInclude;
      delete this.mainFilterRaw.interactionInitiatorsInclude;
    }
    let assigneeRole;
    assigneeRole = AssigneeRole.LEADER;
    if (this.activePod.name == firmPod.name) {
      delete this.mainFilterRaw.assigneeRole;
    } else {
      this.mainFilterRaw = {
        ...this.mainFilterRaw,
        assigneeRole: AssigneeRole.COVERAGE,
      };
    }
    this.initializeBarDataLoadingStream(true);
    this.generateCompanyData();
    this.tabUtilsService.navigateToCurrentTab({ ...this.mainFilterRaw, pageNumber: "1" }, this.tabs, this.currentTabIndex, this.currentChildIndex);
  }

  scrollToTop() {
    // if (this.dataTable) {
    //   this.dataTable.scrollTo({top: 0}); // Scroll to top
    // }
    scrollTo(0, 0);
  }

  adjustActivePodAfterMainFilterApply(mainFilterRaw: MainFilterRaw) {
    this.podsAdjustedAfterMainFilterApply = true;
    if (mainFilterRaw.assigneesInclude?.length) {
      this.activePod = customPod;
    } else {
      this.activePod = firmPod;
    }
    if (mainFilterRaw.interactionInitiatorsInclude?.length) {
      this.activePod = customPod;
    } else {
      this.activePod = firmPod;
    }
    this.initializeBarDataLoadingStream(true);
  }

  resetActivePod(){
    this.toggleDataView('table');
    this.stagesLoading = true;
    this.interactionsLoading = true;
    this.loadPods();
  }

  //this function is temporarily added by ahmed raza and needs to be reviewed and removed later during refactoring.
  onApplyMainFilterRawEmitter(values: any) {
    this.applyMainFilterRaw(values.filters, values.route);
  }

  applyMainFilterRaw(mainFilterRaw: MainFilterRaw, route?: string[]) {
    this.isExpanding = false;
    this.scrollToTop();
    this.displayFiltersMenu = false;
    if (this.currentTabIndex === InvestorTab.PIPELINE) {
      this.adjustActivePodAfterMainFilterApply(mainFilterRaw);
    }

    let combinedFilters = {
      ...mainFilterRaw,
      ...this.baseFilter,
    };

    if (combinedFilters.filters && combinedFilters.filters.length > 0) {
      combinedFilters.filtersString = JSON.stringify(combinedFilters.filters);
    }

    delete combinedFilters.filters;

    this.tabUtilsService.navigateToCurrentTab(
      {
        ...combinedFilters,
        pageNumber: "1",
      }, this.tabs, this.currentTabIndex, this.currentChildIndex,
      route
    );
  }

  onDownloadCancelClick() {
    this.currentTab.downloadSubscription?.unsubscribe();
    this.currentTab.downloadSubscription = null;
    this.currentTab.downloadingProgress = 0;
  }

  onNavigateToCurrentTab(mainFilterRaw: MainFilterRaw) {
    this.tabUtilsService.navigateToCurrentTab(mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex);
  }

  onDownloadButtonClick() {
    let makeSingleAPICall = false;
    let filterObj = {};
    makeSingleAPICall = true;
    filterObj = {
      ...this.mainFilterRaw,
      companiesInclude: this.selectedItems.map((obj) => obj.id),
      // sortProperty: this.mainFilterRaw.sortProperty,
      // sortDirection: this.mainFilterRaw.sortDirection,
      // isActive: this.mainFilterRaw.isActive,
      // hasNotificationTriggers: this.mainFilterRaw.hasNotificationTriggers,
    };
    const services = [];
    const currentTabIndex = this.currentTabIndex;
    const currentTotal = this.currentTotal(this.currentTabIndex);
    const currentTab = this.currentTab;
    currentTab.downloadingProgress = 1;
    let progressCount = 0;
    let totalAPICalls = Math.trunc(currentTotal / generalConstants.pageNumber) || 1;
    if (
      currentTotal % generalConstants.pageNumber !== 0 &&
      currentTotal % generalConstants.pageNumber !== currentTotal
    ) {
      totalAPICalls++;
    }

    if (makeSingleAPICall) {
      totalAPICalls = 1;
    }
    if (totalAPICalls > 23) {
      alert(
        "Kindly refine your filter criteria to narrow down the data selection. The current dataset is substantial, and limiting the selection to 20,000 items will help with faster and more efficient downloads."
      );
      currentTab.downloadingProgress = 0;
      currentTab.downloadSubscription = null;
      return;
    }

    for (let i = 1; i <= totalAPICalls; i++) {
      const filter = {
        ...filterObj,
        pageNumber: i.toString(),
        pageSize: generalConstants.pageNumber.toString(),
        isExport: "true",
      };
      switch (this.currentTabIndex) {
        case InvestorTab.COMPANIES:
          services.push(this.companiesExportService.getExportActiveCompanies(filter));
          break;
        case InvestorTab.ELEVATIONS:
          services.push(this.companiesExportService.getExportElevations(this.tabUtilsService.getElevationTabExtentedFilters(this.mainFilterRaw)));
          break;
        case InvestorTab.PIPELINE:
          if (this.authService.userRole === "analyst") {
            services.push(this.companiesExportService.getExportDataQualityCompanies(filter));
          } else {
            services.push(this.companiesExportService.getExportPipelineCompanies(filter));
          }
          break;
        case InvestorTab.PRIORITY:
          services.push(this.companiesExportService.getExportPriorityCompanies(filter));
          break;
        case InvestorTab.VOTING:
          this.currentChildIndex === 0
            ? services.push(this.companiesExportService.getExportActiveVotingCompanies(filter))
            : services.push(this.companiesExportService.getExportArchieveVotingCompanies(filter));
          break;
      }
    }

    currentTab.downloadSubscription = forkJoin(
      services.map((o) =>
        o.pipe(
          tap(() => {
            progressCount += 1;
            currentTab.downloadingProgress = (progressCount / services.length) * 100;
          })
        )
      )
    ).subscribe((response) => {
      const errorFound = response.find((item) => item.error);
      const csvTemplate = this.csvService.getCSVTemplate(currentTabIndex);
      const currentTabTitle = currentTab.label;

      if (errorFound) {
        this.showErrorToastMessage("Failed to generated CSV file. Please try again.");
      } else {
        const data: Company[] = [];
        response.forEach((item) => data.push(...(item.data?.data ?? [])));
        csvTemplate.data = data;
      }
      this.companyHelperService.convertJSONToCSV(csvTemplate, currentTabTitle);
      currentTab.downloadingProgress = 0;
      currentTab.downloadSubscription = null;
    });
  }

  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }


  onEditOutReachOwner(company: Company) {
    this.editOutReachOwnerEmitter.emit(company);
  }

  onEditNextStep(company: Company) {
    this.editNextStepEmitter.emit(company);
  }
  onEditICDateEmitter(company: Company) {
    this.editICDateEmitter.emit(company);
  }
  onVoteForCompanyEmitter(company: Company) {
    this.voteForCompanyEmitter.emit(company);
  }
  onShowNotificationTriggersAlertEmitter(company: Company) {
    this.showNotificationTriggersAlertEmitter.emit(company);
  }
  onRemoveSavedSearchEmitter(id: number) {
    this.removeSavedSearchEmitter.emit(id);
  }
  onRemoveFromICEmitter(company: Company) {
    this.removeFromICEmitter.emit(company);
  }
  onMoveCompanyToArchiveEmitter(company: Company) {
    this.moveCompanyToArchiveEmitter.emit(company);
  }
  onMenuClickEmitter(company: Company) {
    this.menuClickEmitter.emit(company);
  }

  clearAllFilters() {
    this.clearFiltersEmitter.emit();
    this.initializeBarDataLoadingStream(true);
    this.generateCompanyData();
  }

  onSelectedItemsEmitter(items: Company[]) {
    this.selectedItems = items;
  }
  
  shouldShowCountHeader(tab: TabData<Company>): boolean {
    return !tab.disabled && !this.nonCountHeaderTabs.includes(tab.route); 
  }

  shouldShowGrid(tab: TabData<Company>): boolean {
    return !this.nonGridTabs.includes(tab.route);
  }

  checkBar(){
    if (this.stages){
      if (this.mainFilterRaw?.rawValueMapping){
        let raw = JSON.parse(this.mainFilterRaw?.rawValueMapping);
        let statusesInclude = raw.statusesInclude;
        
        this.stages.forEach((stage : any)=>{
          var status = statusesInclude?.find((status: any) => stage.id == status.id);
          if(status) {
            stage.selected = true;
          }
          else{
            stage.selected = false;
          }
        })
        
      }
      else{
        this.stages.forEach((stage : any)=>{
            stage.selected = false;
        })
      }
    }
    if (this.interactions){
      if (this.mainFilterRaw?.interactionTypes != null && this.mainFilterRaw?.interactionTypes.length > 0){
        this.interactions.forEach((interaction : any)=>{
          var it = this.mainFilterRaw.interactionTypes?.find((it: any) => interaction.id == it);
          if(it != null) {
            interaction.selected = true;
            this.totalInteractions= interaction.total;
          }
          else{
            interaction.selected = false;
          }
        })
        
      }
      else{
        this.totalInteractions = 0;
        this.interactions.forEach((interaction : any)=>{
          interaction.selected = false;
          this.totalInteractions += interaction.total;
        })
      }
    }
  }

  onApplyInteraction(interaction: InteractionList){  
    if (this.mapLoading == true && this.dataView == 'chart'){
      return
    }
    if (interaction.selected){
      interaction.selected = false;
    }
    else{
      interaction.selected = true;
    }
    // Comment out for multi select functionality.
    this.interactions.forEach((it: InteractionList)=>{
      if (interaction != it){
        it.selected = false;
      }
    })
    var interactionTypes: number[] = [];
    var rawinteractionTypes: IdNamePair[] = [];
    this.interactions.forEach((it: InteractionList)=>{
      if (it.selected){
        interactionTypes.push(it.id);
        rawinteractionTypes.push({id: it.id, name: it.name});
      }
    })

    if (interactionTypes.length > 0){
      this.mainFilterRaw.interactionTypes = [...interactionTypes, -1];
    }
    else{
      if (this.mainFilterRaw.interactionTypes){
        delete this.mainFilterRaw.interactionTypes
      }
    }
    this.mainFilterRaw = appendToRawValueMapping(
      this.mainFilterRaw,
      RawValueMappingType.interactionTypes,
      rawinteractionTypes
    );
    this.tabUtilsService.navigateToCurrentTab({ ...this.mainFilterRaw, pageNumber: "1" }, this.tabs, this.currentTabIndex, this.currentChildIndex);
    this.generateCompanyData();
  }

  onApplyStatus(stage: Stage){  
    if (this.mapLoading == true && this.dataView == 'chart'){
      return
    }
    if (stage.selected){
      stage.selected = false;
    }
    else{
      stage.selected = true;
    }

    // Comment out for multi select functionality.
    this.stages.forEach((st: Stage)=>{
      if (st != stage){
        st.selected = false;
      }
    })

    if (this.stages[0].selected){
      this.stages[1].selected = true;
      this.stages[2].selected = true;
    }
    else{
      this.stages[1].selected = false;
      this.stages[2].selected = false;
    }

    var statusesInclude: number[] = [];
    var rawStatusesInclude: IdNamePair[] = [];
    this.stages.forEach((st: Stage)=>{
      if (st.selected){
        statusesInclude.push(st.id);
        rawStatusesInclude.push({id: st.id, name: st.name});
      }
    })

    if (statusesInclude.length > 0){
      this.mainFilterRaw.statusesInclude = statusesInclude;
    }
    else{
      if (this.mainFilterRaw.statusesInclude){
        delete this.mainFilterRaw.statusesInclude
      }
    }
    this.mainFilterRaw = appendToRawValueMapping(
      this.mainFilterRaw,
      RawValueMappingType.statusesInclude,
      rawStatusesInclude
    );

    this.tabUtilsService.navigateToCurrentTab({ ...this.mainFilterRaw, pageNumber: "1" }, this.tabs, this.currentTabIndex, this.currentChildIndex);
    this.generateCompanyData();
  }
  
  initializeBarDataLoadingStream(con: boolean){
    var cTab = this.tabUtilsService.getAvailableUserTabs(this.tabs)[this.currentTabIndex];
    if (this.currentTabIndex == InvestorTab.COMPANIES && cTab.children != null){
      if (this.currentChildIndex == 1){
        cChildTab = cTab.children[1];
        activePod = cChildTab.gridFilter!.activePod
        var interactionInitiatorsInclude : number[] | undefined  = (activePod != firmPod) ? interactionInitiatorsInclude = cChildTab.mainFilterRaw!.interactionInitiatorsInclude : interactionInitiatorsInclude = [];
        (typeof interactionInitiatorsInclude == "string") ? interactionInitiatorsInclude = [Number(interactionInitiatorsInclude)] : null;
        this.getCompanyInteractions(interactionInitiatorsInclude);
      }
      if (this.currentChildIndex == 0){
        var cChildTab = cTab.children[0];
        var activePod = cChildTab.gridFilter!.activePod
        var assigneesInclude : number[] | undefined = (activePod != firmPod) ? assigneesInclude = cChildTab.mainFilterRaw!.assigneesInclude : assigneesInclude = [];
        (typeof assigneesInclude == "string") ? assigneesInclude = [Number(assigneesInclude)] : null;
        this.getCompanyStages(assigneesInclude);
      }
    }
  }
  getCompanyInteractions(interactionInitiatorsInclude: number[] | undefined) {
    this.interactionsLoading = true;
  
    of(interactionInitiatorsInclude)
    .pipe(
      switchMap(include => this.companiesService.getCompanyInteraction(include))
    ).subscribe((response) => {
        if (response?.data != null) {
          const data: PodInteraction = response.data;
          var int = interactions;
          Object.entries(data).forEach(([key, value]) => {
            int.forEach((interaction) => {
              if (interaction.label === key) {
                interaction.total = value;
              }
            });
          });
          this.interactions = int;
          this.checkBar();
          this.interactionsLoading = false;
        }
      });
  }
  
  getCompanyStages(assigneesInclude: number[] | undefined) {
    this.stagesLoading = true;
  
    of(assigneesInclude).pipe(
      switchMap(include => this.companiesService.getCompanyStages(include))
    ).subscribe({
      next: (response) => {
        if (response?.data) {
          const stages = response?.data?.statuses;
          this.totalCompanies = response?.data?.totalCompanies;
          this.filteredCompanies = response?.data?.filteredCompanies;
  
          this.stages = [];
          stages.forEach((stage) => {
            if (stage.dispalyName === "Perl Company") stage.dispalyName = "Pearl";
            stage.selected = false;
            this.stages.push(stage);
          });
  
          this.stages.sort((a, b) => b.ordinal - a.ordinal);
  
          this.stages[0].dispalyName = `${this.stages[0].dispalyName} / ${this.stages[1].dispalyName} / ${this.stages[2].dispalyName}`;
          this.stages[0].count += this.stages[1].count + this.stages[2].count;
          this.checkBar();
          this.stagesLoading = false;
        }
      },
      error: () => {
        this.stagesLoading = false;
        this.showErrorToastMessage("Service unavailable.");
      }
    });
  }

  dataView = 'table';
  toggleDataView(view: string) {
    this.dataView = view;
    this.DataViewEmitter.emit(this.dataView);
    if (view=='chart'){
      this.mixpanelService.trackEvent('View_HeatMap');
      this.generateCompanyData();
    }
  }

  generateCompanyData(){
    this.mapLoading = true;
    this.shouldShowHeatMapGrid = false;
    if (this.currentChildIndex == 0){
      this.companiesSearchService.getActiveGeoLocationCompanies({assigneeNone: this.mainFilterRaw.assigneeNone, assigneeRole: this.mainFilterRaw.assigneeRole, statusesInclude : this.mainFilterRaw.statusesInclude, assigneesInclude: this.mainFilterRaw.assigneesInclude, rawValueMapping: this.mainFilterRaw.rawValueMapping})
      .pipe(
        catchError((error) => {
          this.displayErrorMessage(error, "Error getting map data, try again.");
          return of(null);
        })
      ).subscribe(response => {
        if (response?.data) {
          this.companyData = response.data.data.companies;
          this.mapLoading = false;
        }
      });
    }
    else if(this.currentChildIndex == 1){
      var interactionInitiatorsInclude : number[] | undefined;
      (typeof this.mainFilterRaw.interactionInitiatorsInclude == "string") ? interactionInitiatorsInclude = [Number(this.mainFilterRaw.interactionInitiatorsInclude)] : interactionInitiatorsInclude = this.mainFilterRaw.interactionInitiatorsInclude;
        
      this.companiesService.getInteractionChart(this.mainFilterRaw.interactionTypes, 90, interactionInitiatorsInclude)
      .pipe(
        catchError((error) => {
          this.displayErrorMessage(error, "Error getting chart data, try again.");
          return of(null);
        })
      ).subscribe(response => {
        if (response?.data) {
          this.chartData = response.data;
          this.mapLoading = false;
        }
      });
    }
  }

  private displayErrorMessage(response: ApiResponse<OperationResult>, defaultErrorMessage: string) {
    this.messageService.add({
      key: "cd",
      severity: "error",
      detail: response.message ?? response?.message ?? defaultErrorMessage,
    });
  }
}
