<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [contentStyleClass]="contentStyleClass"
  [showHeader]="showHeader"
  (onHide)="onHide.emit()"
  [resizable]="false"
  [closeOnEscape]="true"
  [dismissableMask]="dismissableMask"
  [styleClass]="styleClass"
>
  <ng-template pTemplate="header">
    <ng-content select="[modal-header]"></ng-content>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-content select="[modal-content]"></ng-content>
  </ng-template>
  <ng-container *ngIf="showFooter">
    <ng-template pTemplate="footer">
      <ng-content select="[modal-footer]"></ng-content>
    </ng-template>
  </ng-container>
</p-dialog>
