import { Pipe, PipeTransform } from "@angular/core";
import { Nullable } from "@models/nullable";
import { Interaction, InteractionType } from "@models/auxiliary-company-models";

@Pipe({
  name: "interactionIcon",
})
export class InteractionIconPipe implements PipeTransform {
  transform(interaction: Nullable<Interaction>): Nullable<string> {
    if (!interaction) {
      return null;
    }

    switch (interaction.interactionType) {
      case InteractionType.Meeting:
        return "assets/svg/interation-coffe.svg";
      case InteractionType.Phone:
        return "assets/svg/interation-phone.svg";
      case InteractionType.Email:
        return "assets/svg/interation-email.svg";
      case InteractionType.VideoCall:
        return "assets/svg/interation-video.svg";
      case InteractionType.Notes:
        return "assets/svg/interation-notes.svg";
      case InteractionType.All:
        return null;
      case InteractionType.NeverContacted:
        return null;
    }
  }
}
