<section class="auth-wrap" [ngClass]="{ extenisonView: isExtension }">
  <div class="loader-container" *ngIf="loading">
    <div class="loader-background"></div>
    <div class="spinner"></div>
  </div>
  <div class="section-auth">
    <div class="auth-left">
      <!--  *ngIf="loginType !== 'remote' && loginWithEmailPassword" -->
      <form class="w-form" #authForm="ngForm" (ngSubmit)="onSubmit()">
        <input type="hidden" name="remember" value="true" />
        
        <div class="grid grid-nogutter">
        
          <div class="col-12">
            <a class="brand" href="/" title="BRIGHTON PARK CAPITAL">
              <img src="assets/logo-auth.png" alt="BRIGHTON PARK CAPITAL" />
              <!-- <span class="icon-logo f-45"
                ><span class="path1"></span><span class="path2"></span><span class="path3"></span
                ><span class="path4"></span><span class="path5"></span><span class="path6"></span
                ><span class="path7"></span><span class="path8"></span><span class="path9"></span
                ><span class="path10"></span><span class="path11"></span><span class="path12"></span
                ><span class="path13"></span><span class="path14"></span><span class="path15"></span
                ><span class="path16"></span><span class="path17"></span><span class="path18"></span
                ><span class="path19"></span
              ></span> -->
            </a>
          </div>
          <div *ngIf="isNonBPC" class="col-12">
            <h2>Sign In</h2>
            <h3>Non-BPC Employee</h3>
          </div>
          <div *ngIf="isNonBPC" class="col-12 mb-20">
            <label for="email" class="w-100">Email address</label>
            <input
              pInputText
              class="w-full"
              required
              email
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              placeholder="Email address"
              [(ngModel)]="email"
            />
          </div>
          <div *ngIf="isNonBPC" class="col-12 mb-20">
            <div>
              <label for="password" class="w-100">Password</label>
              <p-password
                required
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                styleClass="w-full"
                placeholder="Password"
                [(ngModel)]="password"
                [toggleMask]="true"
                [feedback]="false"
              ></p-password>
            </div>
          </div>
          <div *ngIf="isNonBPC" class="col-12 mb-20">
            <a class="lbl-sec" (click)="onForgotPasswordClick()">Forgot your password?</a>
          </div>
          <div class="col-12 mt-20 mb-20" *ngIf="!isNonBPC && loginType !== 'local'">
            <button
              pButton
              (click)="loginOIDC()"
              [disabled]="loading"
              type="button"
              label="BPC Employee Login"
              class="btn-primary"
            ></button>
          </div>
          <div *ngIf="!isNonBPC" class="col-12 mb-20">
            <button label="Non-BPC / other login" pButton class="btn-secondary" (click)="isNonBPC = true;"></button>  
          </div>
          <div *ngIf="isNonBPC" class="col-12">
            <button type="submit" [disabled]="!authForm.valid || simpleLogin" label="Sign in" pButton class="btn-primary">
              <span *ngIf="simpleLogin" class="pi pi-spinner pi-spin mr-2"></span>
            </button>
          </div>
          <!-- <div class="col-12 mb-20" *ngIf="!loginWithEmailPassword" class="text-sm flex items-center justify-center">
          <span           
            (click)="loginWithEmailPassword = true"
            >Non-BPC / other login</span
          >
        </div> -->
        </div>
      </form>
    </div>
    <div class="auth-right" *ngIf="!isExtension">
      <!-- <img alt="Manage & discover  prospects from one place" src="assets/auth-animation.gif" />
      <h1>
        <strong>Manage & discover</strong>
        prospects from one place
      </h1> -->
    </div>
  </div>
</section>
<p-toast position="bottom-center" key="login"></p-toast>
