import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SocialLink } from "@models/social-link";
import { Nullable } from "@models/nullable";
import { UrlTransformPipe } from "@core/pipes/url-transform.pipe";

@Component({
  selector: "bpc-social-icon",
  templateUrl: "./social-icon.component.html",
})
export class SocialIconComponent {
  @Input() socialLink!: SocialLink;
  @Output() urlClick = new EventEmitter<SocialLink>();

  constructor(private validateUrl: UrlTransformPipe) {}

  get image(): string {
    return "assets/svg/" + this.socialLink.type + ".svg";
  }

  get url(): Nullable<string> {
    return this.validateUrl.transform(this.socialLink.link ?? null);
  }

  onUrlClick() {
    if (this.url) {
      this.urlClick.emit(this.socialLink);
    }
  };

}
