import { Component, Input } from '@angular/core';
import { Interaction } from '@models/auxiliary-company-models';

@Component({
  selector: 'bpc-latest-interaction',
  templateUrl: './latest-interaction.component.html'
})
export class LatestInteractionComponent {
  @Input() latestInteraction!: Interaction;
}
