import { Pipe, PipeTransform } from '@angular/core';
import { getVotingBackgroundClasses } from '../../../utils/company-utils';

@Pipe({
  name: 'votingClassesScore'
})
export class VotingClassesScorePipe implements PipeTransform {

  transform(score: number): string {
    return getVotingBackgroundClasses(score);
  }

}
