import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ApiResponse, BaseApiService } from '../../api/base';
import { Company } from '@models/company';
import { OperationResult } from '@models/operation-result';
import { AuthService } from '@core/auth/auth.service';
import { DeleteUser, DropdownOption, StatusOptions } from '@models/forms';
import { Interaction, InteractionType, StatusId, WorkFlowStatusId } from '@models/auxiliary-company-models';
import { VotingStats } from '@models/voting';
import { User } from '@models/user';
import { Nullable } from '@models/nullable';
import { HistoryRecord } from '@models/company-history';
import { Tag } from '@models/tag';
import { PodInteraction, PodStage, Stage } from '@core/models/pod-filter';
import { HttpParams } from '@angular/common/http';
import { ChartData } from 'src/app/components/grid-tabs/grid-tabs.component';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseApiService<Company> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/companies', authService, router, messageService);
  }

  getCompanyDetails(id: number): Observable<ApiResponse<Company>> {
    return this.get(`/${id}/details`);
  }

  getCompanyByDomain(domain: string): Observable<ApiResponse<Company>> {
    return this.get(`/v2/domain/${domain}`);
  }

  getVotingStats(id: number): Observable<ApiResponse<VotingStats>> {
    return this.get(`/workflows/${id}/votes`);
  }

  getCompanyHistory(id: number): Observable<ApiResponse<HistoryRecord[]>> {
    return this.get(`/${id}/history`);
  }

  moveToPipeline(id: number): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/assign`);
  }

  archive(id: number): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/archivate`);
  }

  activate(id: number): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/activate`);
  }

  moveToPriority(id: number): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/prioritize`);
  }

  moveFromPriority(id: number, comment: Nullable<string>): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/deprioritize`, { comment: comment ?? undefined });
  }

  changeStatus(id: number, statusId: StatusId): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/status`, { status: statusId });
  }

  getStatuses(): Observable<ApiResponse<StatusOptions[]>> {
    return this.get('/statuses');
  }

  editCoveragePersons(id: number, assignees: User[], comment: Nullable<string>): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/assignees`, { comment: comment ?? undefined }, assignees.map(({ id }) => id));
  }

  interact(id: number, interactionType: InteractionType, summary: string, details: string, interactionDate: string): Observable<ApiResponse<OperationResult>> {
    return this.post('/interact', null, {
      companyId: id,
      interactionType,
      summary,
      details,
      interactionDate
    });
  }

  getInteractions(id: number): Observable<ApiResponse<OperationResult<Interaction>>> {
    return this.get(`/${id}/interactions`);
  }

  deleteUser(data: DeleteUser): Observable<ApiResponse<OperationResult>> {
    return this.delete('/people/' + data.id + '/' + data.deleteFromSystem);
  }

  enrichData(id: number): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/enrich`);
  }

  updateEnrichmentStatus(id: number, data: { status: WorkFlowStatusId, assigneeId?: number }): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/enrich/status`, data);
  }

  flagForICVoting(id: number, description: string, checkSize: number): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/review`, { description: description ?? undefined, checkSize });
  }

  voteForCompany(id: number, vote: number, comment: string): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/votes`, { vote, comment });
  }

  removeFromIC(id: number): Observable<ApiResponse<OperationResult>> {
    return this.delete(`/${id}/review`);
  }

  moveCompanyFromActiveICtoHistory(id: number): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/review/complete`);
  }

  editICAsk(id: number, description: string): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/review/description`, { description: description });
  }

  releaseCompany(id: number): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/release`);
  }

  getTags(id: number, query: string): Observable<ApiResponse<Tag[]>> {
    //back slashes and other characters are possible
    return this.get(`/${id}/tags`, { term: query });
  }

  createTag(tag: Tag): Observable<ApiResponse<OperationResult>> {
    return this.post(`/tags`, null, tag);
  }

  updateTag(tag: Tag): Observable<ApiResponse<OperationResult>> {
    return this.put(`/tags`, null, tag);
  }

  createTagForSingleCompany(id: number, tag: Tag): Observable<ApiResponse<OperationResult>> {
    return this.post(`/${id}/tags`, null, tag);
  }

  updateTagForSingleCompany(id: number, tag: Tag): Observable<ApiResponse<OperationResult>> {
    return this.put(`/${id}/tags`, null, tag);
  }

  removeTag(id: number, { id: tagId }: Tag): Observable<ApiResponse<OperationResult>> {
    return this.delete(`/${id}/tags/${tagId}`);
  }

  updateICDateAdded(companyWorkFlowId: number, iCDateTime: string): Observable<ApiResponse<OperationResult>> {
    return this.put(`/IcVotingDate?companyWorkFlowId=${companyWorkFlowId}&iCDateTime=${iCDateTime}`, null);
  }
  updateOutReachOwner(companyId: number, email?: string): Observable<ApiResponse<OperationResult>> {
    return this.put(`/${companyId}/outreachowner`, null, { email });
  }

  updateNextStep(companyId: number, nextStep?: string): Observable<ApiResponse<OperationResult>> {
    return this.put(`/${companyId}/nextstep`, null, { nextStep });
  }

  updateRoundSummary(workflowId: number, roundId: number, summary: string) {
    return this.patch(`/workflows/${workflowId}/rounds/${roundId}/update-round-summary`, null, { roundSummary: summary});
  }

  getCompanyStages(assigneesInclude: number[] | undefined): Observable<ApiResponse<PodStage>> {
    let url = '/stats';
    
    if (assigneesInclude && assigneesInclude.length > 0) {
      const queryParams = assigneesInclude.map(assignee => `assigneesInclude=${assignee}`).join('&');
      url = `${url}?${queryParams}`;
    }
  
    return this.get(url);
  }

  getCompanyInteraction(interactionInitiatorsInclude: number[] | undefined): Observable<ApiResponse<PodInteraction>> {
    let url = '/interaction/stats';
    
    if (interactionInitiatorsInclude && interactionInitiatorsInclude.length > 0) {
      const queryParams = interactionInitiatorsInclude.map(interaction => `interactionInitiatorsInclude=${interaction}`).join('&');
      url = `${url}?${queryParams}`;
    }
  
    return this.get(url);
  }

  updateInvestorsDetails(companyId: number, investorsDetails?:  Nullable<string>, previousInvestorsDetails?:  Nullable<string>){
    return this.patch(`/${companyId}/investors-details`, null, {investorsDetails: investorsDetails, previousInvestorsDetails: previousInvestorsDetails})
  }

  deleteVote(companyId: number, voteId: number): Observable<ApiResponse<any>>{
    return this.post(`/${companyId}/reset-vote/${voteId}`)
  }

  getInteractionChart(interactionType: number[] | undefined, days: number | undefined, interactionInitiatorsInclude: number[] | undefined): Observable<ApiResponse<ChartData[]>> {
    return this.post(`/interaction/graph-data`, null, 
      {
      interactionTypes: interactionType, 
      days: days, 
      interactionInitiatorsInclude: interactionInitiatorsInclude
      }
      );
  }
  
}