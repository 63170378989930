<div class="flex flex-col text-xs">
  <div class="flex flex-row">
    <img class="v-base" [src]="latestInteraction | interactionIcon" />
    <!-- <i class="mr-2" [class]="latestInteraction | interactionIcon"></i> -->
    <span class="font-bold" [innerHTML]="latestInteraction!.summary"></span>
  </div>
  <span class="text-gray-800">{{ latestInteraction!.interactionDate | date }}</span>
</div>
<div class="max-w-xl">
  <bpc-interaction-details [interaction]="latestInteraction!"></bpc-interaction-details>
</div>
