import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.config.adConfiguration.authority,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.config.adConfiguration.clientId,
        scope: environment.config.adConfiguration.scope,
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        ignoreNonceAfterRefresh: true,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
        autoUserInfo: false
      }
    })
  ],
  exports: [AuthModule]
})
export class AuthConfigModule {
}
