import { Pipe, PipeTransform } from '@angular/core';
import { convertWorkflowStatusIdToEnrichmentStatus } from '../../../utils/company-utils';
import { WorkFlow } from '@models/auxiliary-company-models';
import { Nullable } from '@models/nullable';
import { AuthService } from '@core/auth/auth.service';

@Pipe({
  name: 'enrichmentStatus'
})
export class EnrichmentStatusPipe implements PipeTransform {

  constructor(private authService: AuthService) {
  }

  transform(workflows: WorkFlow[]): Nullable<string> {
    return convertWorkflowStatusIdToEnrichmentStatus(this.authService.user.getValue()!, workflows);
  }
}
