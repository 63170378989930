import { NgModule } from "@angular/core";

import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { TabViewModule } from "primeng/tabview";
import { TableModule } from "primeng/table";
import { SidebarModule } from "primeng/sidebar";
import { RadioButtonModule } from "primeng/radiobutton";
import { ChipsModule } from "primeng/chips";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { CheckboxModule } from "primeng/checkbox";
import { ChartModule } from "primeng/chart";
import { ToastModule } from "primeng/toast";
import { AutoCompleteModule } from "primeng/autocomplete";
import { InputNumberModule } from "primeng/inputnumber";
import { AccordionModule } from "primeng/accordion";
import { DialogModule } from "primeng/dialog";
import { TooltipModule } from "primeng/tooltip";
import { EditorModule } from "primeng/editor";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { InputSwitchModule } from "primeng/inputswitch";
import { CalendarModule } from "primeng/calendar";
import { MenuModule } from "primeng/menu";
import { TagModule } from "primeng/tag";
import { BadgeModule } from "primeng/badge";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { CarouselModule } from "primeng/carousel";
@NgModule({
  declarations: [],
  imports: [
    ButtonModule,
    InputTextModule,
    TabViewModule,
    TableModule,
    SidebarModule,
    RadioButtonModule,
    ChipsModule,
    DropdownModule,
    CheckboxModule,
    ChartModule,
    ToastModule,
    AutoCompleteModule,
    InputNumberModule,
    AccordionModule,
    DialogModule,
    TooltipModule,
    EditorModule,
    InputSwitchModule,
    CalendarModule,
    MenuModule,
    MultiSelectModule,
    TagModule,
    BadgeModule,
    ProgressSpinnerModule,
    CarouselModule,
  ],
  exports: [
    ButtonModule,
    InputTextModule,
    TabViewModule,
    TableModule,
    SidebarModule,
    RadioButtonModule,
    ChipsModule,
    DropdownModule,
    CheckboxModule,
    ChartModule,
    ToastModule,
    AutoCompleteModule,
    InputNumberModule,
    DialogModule,
    AccordionModule,
    TooltipModule,
    EditorModule,
    OverlayPanelModule,
    InputSwitchModule,
    CalendarModule,
    MenuModule,
    MultiSelectModule,
    TagModule,
    BadgeModule,
    ProgressSpinnerModule,
    CarouselModule,
  ],
})
export class PrimeNgModule {}
