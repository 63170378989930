import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  supportEmailSubject(isExtension: boolean): string {

    if (isExtension) {
      return `MISSING COMPANY: ${document.location.ancestorOrigins[0]}`;
    }

    const url = this.router.url;
    let subject = 'Jarvis feedback';
    if (url === '/' || url.includes('companies')) {
      subject += ': Companies tab';
    } else if (url.includes('pipeline')) {
      subject += ': Pipeline tab';
    } else if (url.includes('priority')) {
      subject += ': Priority tab';
    } else if (url.includes('company')) {
      subject += ': Company details';
    } else if (url.includes('data-quality')) {
      subject += ': Data Quality details';
    }

    return subject;
  }

  supportEmail(isExtension = false): string {
    return `mailto:${environment.config.supportEmail}?subject=${this.supportEmailSubject(isExtension)}`;
  }

  constructor(private router: Router) {
  }
}
