import { Directive } from '@angular/core';

import { takeUntil } from 'rxjs';
import { AutoComplete } from 'primeng/autocomplete';

import { DestroyObservable } from '../../rxjs/DestroyObservable';
import { BaseMultipleAutoCompleteDirective } from './base-multiple-auto-complete.directive';
import { CompanyType } from '../models/company-type';
import { CompanyTypeService } from '../services/company-type.service';

@Directive({
  selector: 'p-autoComplete[companyType]',
  providers: [DestroyObservable]
})
export class CompanyTypeDirective extends BaseMultipleAutoCompleteDirective<CompanyType> {

  loadData = this.companyTypeService.companyTypes$.pipe(takeUntil(this.destroyObservable));

  constructor(autoComplete: AutoComplete, private companyTypeService: CompanyTypeService, private destroyObservable: DestroyObservable) {
    super(autoComplete);
  }
}
