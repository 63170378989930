import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Nullable } from '@models/nullable';

@Component({
  selector: 'bpc-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditComponent {

  @Input() value: Nullable<string> = null;
  @Output() focusOut: EventEmitter<Nullable<string>> = new EventEmitter<Nullable<string>>();

  editMode = false;

  enterEditMode() {
    this.editMode = true;
  }

  onFocusOut() {
    this.editMode = false;
    this.focusOut.emit(this.value);
  }
}
