import { Injectable } from '@angular/core';
import {
  AssigneeFilterType,
  AssigneeRole,
  FilterLabel,
  FilterPriority,
  IdNamePair,
  Interaction,
  MainFilterRaw,
  MainFilterRawMeta,
  MainFilterReal
} from '@models/main-filter-view';
import * as moment from 'moment';
import { User } from '@models/user';
import { Industry, InteractionType, Investor, RawValueMappingType, StatusId, WorkFlowId } from '@models/auxiliary-company-models';
import { Zone } from '@models/zone';
import { InvestorTab, Tab } from '@models/types';
import { AuthService } from '@core/auth/auth.service';
import { Tag } from '@models/tag';
import { CompanyType, GroupedCompanyTypes } from '@models/company-type';
import { environment } from 'src/environments/environment';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { StatusOptions } from '@core/models/forms';
import { cleanEmptyFilters, formatFilterGroup } from '@core/models/filters-types';
import { Nullable } from 'primeng/ts-helpers';

/**
 * NOTE: Item which may occur as a 
 * single element but needs to be an array for api request
 */
export const CONVERT_TO_ARRAY_ITEMS: any = {
  locationsInclude: true,
  assigneesInclude: true,
  assigneesExclude: true,
  investorsInclude: true,
  investorsExclude: true,
  tagsInclude: true,
  tagsExclude: true,
  companyTypesInclude: true,
  statusesInclude: true,
  statusesExclude: true,
  companiesInclude: true,
  companiesExclude: true,
  companyName: true,
  industriesInclude: true,
  industriesExclude: true,
  InteractionTypes: true,
  interactionInitiatorsInclude: true,
}

export function addSpacesToCamelCase(inputString: string): string {
  let result = '';

  for (let i = 0; i < inputString.length; i++) {
    const currentChar = inputString[i];
    if (i > 0 && currentChar === currentChar.toUpperCase()) {
      result += ' ';
    }
    result += currentChar;
  }

  return result;
}

export function appendToRawValueMapping(mainFilterRaw: MainFilterRaw, rawValueMappingTypeValue: RawValueMappingType, valueToAppend: IdNamePair[]) {
  const key = RawValueMappingType[rawValueMappingTypeValue]
  let jsonMapping: Record<string, any> = {};
  if (mainFilterRaw.rawValueMapping) {
    jsonMapping = JSON.parse(mainFilterRaw.rawValueMapping);
  }
  jsonMapping[key] = valueToAppend;
  mainFilterRaw.rawValueMapping = JSON.stringify({ ...jsonMapping });
  return mainFilterRaw
}

@Injectable({
  providedIn: 'root'
})
export class FilterHelperService {
  private tagsMap: Map<number, Tag> = new Map();
  statuses: StatusOptions[] = [];
  defaultStatuses = [StatusId.IC.toString(), StatusId.ActiveDeal.toString(), StatusId.Unlikely.toString()];
  users: User[] = [];
  industries: Industry[] = [];
  countries: Zone[] = [];
  cities: Zone[] = [];
  regions: Zone[] = [];
  investors: Investor[] = [];
  companyTypes: CompanyType[] = [];

  getStatuses() {
    this.companyService.getStatuses().subscribe(res => {
      if (res.data) {
        let statusMapping = res.data.map((_d) => {
          if (_d.name) {
            _d.label = _d.name;
          }
          _d.id = _d.statusId;
          return _d;
        });
        this.statuses = statusMapping;
      }
    });
  }

  get zones(): Zone[] {
    return this.countries.concat(this.cities).concat(this.regions);
  }

  constructor(private authService: AuthService,
    protected companyService: CompaniesService) {
  }

  removeItemFromFilter(filterData: MainFilterRaw, label: FilterLabel): MainFilterRaw {
    let jsonMapping: Record<string, any> = {};
    const updatedData = Object.entries(filterData);
    const del = updatedData.find(([key]) => key === label.key);

    if (del) {
      if (filterData.rawValueMapping) {
        jsonMapping = JSON.parse(filterData.rawValueMapping);
      }
      if (del[1] instanceof Array) {
        del[1].splice(del[1].indexOf(del[1].find(x => x === label.rawValue || x.toString() === label.rawValue || x.label === label.rawValue || x.name === label.rawValue)), 1);
        if (jsonMapping[del[0]]) {
          if (del[0] === "locationsInclude") {
            jsonMapping[del[0]] = jsonMapping[del[0]].filter((item: any) => del[1].includes(item.id))
          }
          else {
            jsonMapping[del[0]] = jsonMapping[del[0]].filter((item: any) => del[1].includes(Number(item.id)))
          }
        }

        if (del[1].length === 0) {
          updatedData.splice(updatedData.indexOf(del), 1);
          if (jsonMapping[del[0]]) {
            delete jsonMapping[del[0]];
          }
        } else if (del[1].length === 1) {
          del[1] = del[1][0]; // convert from array to a single item
        }
      } else {
        updatedData.splice(updatedData.indexOf(del), 1);
        if (jsonMapping[del[0]]) {
          delete jsonMapping[del[0]];
        }
      }
    }

    const raw = Object.fromEntries(updatedData);
    raw['rawValueMapping'] = JSON.stringify({ ...jsonMapping });
    if (!(raw['assigneesInclude'] || raw['assigneesExclude'])) {
      if (raw['assigneeRole']) {
        delete raw['assigneeRole']
      }
    }

    return raw;
  }

  getDefaultFilter(tab: Tab, currentChildIndex: Nullable<number> = null): MainFilterRaw {
    if (this.authService.isAnalyst) {
      return {};
    }

    // const locationsPairInclude: IdNamePair[] = [{ id: null, name: 'United States', code: 'us' }];
    const tagsPairInclude: IdNamePair[] = [{ id: Number(environment.weeklyElevationTag), name: 'Weekly Elevations' }];
    switch (tab) {
      case InvestorTab.COMPANIES:
        return (currentChildIndex == 0) ? {
          // rawValueMapping: JSON.stringify({ statusesInclude: [{id: StatusId.MustMeet, name: 'Must Meet'}] }),
          // statusesInclude: [StatusId.MustMeet],
          
          sortProperty: "status",
          sortDirection: "asc"
        } : {
          sortProperty: "latestinteraction",
          sortDirection: "desc"
        };
      case InvestorTab.PIPELINE:
        return {
          // locationsInclude: ['us'],
          //rawValueMapping: JSON.stringify({ locationsInclude: locationsPairInclude })
        };
      case InvestorTab.ELEVATIONS:
        return {
          tagsInclude: [environment.weeklyElevationTag || ''],
          //rawValueMapping: JSON.stringify({ tagsInclude: tagsPairInclude })
        };
      default:
        return {};
    }
  }

  getPredefinedFilterValue(priority: FilterPriority): MainFilterRaw {
    switch (priority) {
      case FilterPriority.TOP:
        return {
          fullTimeEquivalentMinTotal: '70',
          fullTimeEquivalentMinGrowth: '50',
          paidInCapitalMaxTotal: '30000000',
        };
      case FilterPriority.SECOND:
        return {
          fullTimeEquivalentMinTotal: '150',
          fullTimeEquivalentMinGrowth: '25',
          paidInCapitalMaxTotal: '30000000',
        };
      case FilterPriority.THIRD:
        return {
          fullTimeEquivalentMinTotal: '70',
          fullTimeEquivalentMinGrowth: '30',
          paidInCapitalMaxTotal: '30000000',
        };
      default:
        return {};
    }
  }

  transformFilterLabels(filters: string) {
    if (filters) {
      const filtersArray = JSON.parse(filters);
      const formattedString = filtersArray.map(formatFilterGroup(filtersArray)).join(' ');
      return formattedString;
    }
    return filters
  }

  getFilterLabels(mainFilterRaw: MainFilterRaw): FilterLabel[] {
    const rawOptions = mainFilterRaw.rawValueMapping ? JSON.parse(mainFilterRaw.rawValueMapping) : {}
    const labels: FilterLabel[] = [];

    for (const [key, value] of Object.entries(mainFilterRaw)) {
      const valueAsArray = Array.isArray(value) ? value : [isNaN(Number(value)) ? value : Number(value)];
      switch (key) {
        case 'companyName':
          if (valueAsArray.length > 0) {
            valueAsArray.forEach(v => {
              labels.push({ key, value: `Name: ${v}` });
            });
          }
          break;

        case 'filtersString':
          if (value) {
            labels.push({
              key: "filtersString",
              value: this.transformFilterLabels(value),
              rawValue: value,
            });
          }
          break;

        case 'companyDescription':
          if (valueAsArray.length > 0) {
            valueAsArray.forEach((v: any) => {
              labels.push({ key, value: `Desc: ${v}` });
            });
          }
          break;
        case 'paidInCapitalMinTotal':
          labels.push({ key, value: `PIC ≥ ${getValueDividedByOneMillion(value)}M` });
          break;
        case 'paidInCapitalMaxTotal':
          labels.push({ key, value: `PIC ≤ ${getValueDividedByOneMillion(value)}M` });
          break;
        case 'fullTimeEquivalentMinTotal':
          labels.push({ key, value: `FTE ≥ ${value}` });
          break;
        case 'fullTimeEquivalentMaxTotal':
          labels.push({ key, value: `FTE ≤ ${value}` });
          break;
        case 'fullTimeEquivalentMinGrowth':
          labels.push({ key, value: `FTE% ≥ ${value}` });
          break;
        case 'fullTimeEquivalentMaxGrowth':
          labels.push({ key, value: `FTE% ≤ ${value}` });
          break;
        case 'revenueMinTotal':
          labels.push({ key, value: `Rev ≥ ${getValueDividedByOneMillion(value)}M` });
          break;
        case 'revenueMaxTotal':
          labels.push({ key, value: `Rev ≤ ${getValueDividedByOneMillion(value)}M` });
          break;
        case 'revenueMinGrowth':
          labels.push({ key, value: `Rev% ≥ ${value}` });
          break;
        case 'revenueMaxGrowth':
          labels.push({ key, value: `Rev% ≤ ${value}` });
          break;
        case 'workflows':
          labels.push({ key, value: 'In data enrichment' });
          break;
        case 'isActive':
          if (value === 'false') {
            labels.push({ key, value: 'Removed companies' });
          }
          break;
        case 'hasNotificationTriggers':
          if (value === 'true') {
            labels.push({ key, value: 'Has alerts' });
          }
          break;
        case 'locationsInclude':
          if (valueAsArray.length > 0 && rawOptions.locationsInclude?.length > 0) {
            const state = 'inc ';
            const raw: IdNamePair[] = this.filterLocationRawFunction(rawOptions.locationsInclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.code! });
              }
            });
          }
          break;
        case 'locationsExclude':
          if (valueAsArray.length > 0 && rawOptions.locationsExclude?.length > 0) {
            const state = 'exc ';
            const raw: IdNamePair[] = this.filterLocationRawFunction(rawOptions.locationsExclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.code! });
              }
            });
          }
          break;
        case 'companyTypesInclude':
          if (valueAsArray.length > 0 && rawOptions.companyTypesInclude?.length > 0) {
            const state = 'inc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.companyTypesInclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }

          break;
        case 'companyTypesExclude':

          if (valueAsArray.length > 0 && rawOptions.companyTypesExclude?.length > 0) {
            const state = 'exc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.companyTypesExclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'investorsInclude':
          if (valueAsArray.length > 0 && rawOptions.investorsInclude?.length > 0) {
            const state = 'inc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.investorsInclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'investorsExclude':
          if (valueAsArray.length > 0 && rawOptions.investorsExclude?.length > 0) {
            const state = 'exc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.investorsExclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'endMarketsInclude':
          break;
        case 'endMarketsExclude':
          break;
        case 'productsInclude':
          break;
        case 'productsExclude':
          break;
        case 'tagsInclude':
          if (valueAsArray.length > 0 && rawOptions.tagsInclude?.length > 0) {
            const state = 'inc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.tagsInclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'tagsExclude':
          if (valueAsArray.length > 0 && rawOptions.tagsExclude?.length > 0) {
            const state = 'exc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.tagsExclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'assigneeNone':
          labels.push({ key, value: `No coverage: ${value}` });
          break;
          case 'assigneesInclude':
            if (valueAsArray.length > 0 && rawOptions.assigneesInclude?.length > 0) {
              const state = 'inc ';
              const raw: IdNamePair[] = this.filterRawFunction(rawOptions.assigneesInclude, valueAsArray);
              raw.forEach(v => {
                if (v) {
                  labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
                }
              });
            }
            break;
          case 'assigneesExclude':
          if (valueAsArray.length > 0 && rawOptions.assigneesExclude?.length > 0) {
            const state = 'exc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.assigneesExclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'statusesInclude':
          if (valueAsArray.length > 0 && rawOptions.statusesInclude?.length > 0) {
            const state = 'inc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.statusesInclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'statusesExclude':
          if (valueAsArray.length > 0 && rawOptions.statusesExclude?.length > 0) {
            const state = 'exc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.statusesExclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'interactionDateFrom':
          labels.push({ key, value: `Interaction Start Date ≥ ${moment(new Date(value)).format('DD MMM YYYY')}` });
          break;
        case 'interactionDateTo':
          labels.push({ key, value: `Interaction End Date ≤ ${moment(new Date(value)).format('DD MMM YYYY')}` });
          break;
        case 'interactionType':
          labels.push({ key, value: `Interaction Type: ${addSpacesToCamelCase(InteractionType[value])}` });
          break;
        case 'latestRoundAmountMin':
          labels.push({ key, value: `Last Round ≥ ${getValueDividedByOneMillion(value)}M` });
          break;
        case 'latestRoundAmountMax':
          labels.push({ key, value: `Last Round ≤ ${getValueDividedByOneMillion(value)}M` });
          break;
        case 'latestRoundDateFrom':
          labels.push({ key, value: `Last Round Start Date ≥ ${moment(new Date(value)).format('MMM YYYY')}` });
          break;
        case 'latestRoundDateTo':
          labels.push({ key, value: `Last Round End Date ≤ ${moment(new Date(value)).format('MMM YYYY')}` });
          break;
        case 'foundingDateFrom':
          labels.push({ key, value: `Founding Start Year ≥ ${moment(value).format('YYYY')}` });
          break;
        case 'foundingDateTo':
          labels.push({ key, value: `Founding End Year ≤ ${moment(value).format('YYYY')}` });
          break;
        case 'industriesInclude':

          if (valueAsArray.length > 0 && rawOptions.industriesInclude?.length > 0) {
            const state = 'inc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.industriesInclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'industriesExclude':
          if (valueAsArray.length > 0 && rawOptions.industriesExclude?.length > 0) {
            const state = 'exc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.industriesExclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'interactionFilterType':
          switch (value) {
            case Interaction.RECENT:
              const state = mainFilterRaw.interactionDateTo ? 'exc' : 'inc';
              const interactionDate = mainFilterRaw.interactionDateTo ? mainFilterRaw.interactionDateTo : mainFilterRaw.interactionDateFrom;
              const interactionDateDiff = diffInMonths(interactionDate!).toString(10);
              labels.push({ key, value: `${state} interactions in last ${interactionDateDiff} month(s)` });
              break;
            case Interaction.NONE:
              labels.push({ key, value: 'no interactions' });
              break;
            case Interaction.ANY:
              labels.push({ key, value: 'any interaction' });
              break;
          }
          break;
        case 'assigneeFilterType':
          if (value === AssigneeFilterType.NONE) {
            labels.push({ key, value: 'No owner' });
          }
          break;
        case 'tags':
          if (valueAsArray.length > 0) {
            const state = mainFilterRaw.tagsExclude === 'true' ? 'exc ' : 'inc ';

            valueAsArray.forEach(v => {
              const tag = this.tagsMap.get(+v)?.name ?? '';
              labels.push({ key, value: state + tag, rawValue: v });
            });
          }
          break;
        case 'interactionTypes':
          if (valueAsArray.length > 0 && rawOptions.interactionTypes?.length > 0) {
            const state = 'inc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.interactionTypes, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
        case 'interactionInitiatorsInclude':
          if (valueAsArray.length > 0 && rawOptions.interactionInitiatorsInclude?.length > 0) {
            const state = 'inc ';
            const raw: IdNamePair[] = this.filterRawFunction(rawOptions.interactionInitiatorsInclude, valueAsArray);
            raw.forEach(v => {
              if (v) {
                labels.push({ key, value: state + v?.name, rawValue: v.id!.toString() });
              }
            });
          }
          break;
      }
    }

    return labels;
  }

  filterRawFunction<T extends { id: number, name: string }>(items: T[], valueAsArray: (number | string)[]) {
    if (valueAsArray.length > 0 && items) {
      // const convertedArray: string[] = valueAsArray.map(str => str.toString());
      return items.filter(({ id }) => {
        return valueAsArray.includes(Number(id));
      });
    } else {
      return [];
    }
  };

  filterLocationRawFunction(items: IdNamePair[], valueAsArray: (string)[]) {
    if (valueAsArray.length > 0 && items) {
      // const convertedArray: string[] = valueAsArray.map(str => str.toString());
      return items.filter(({ code }) => {
        return valueAsArray.includes(code!);
      });
    } else {
      return [];
    }
  };

  convertRawToReal(raw: MainFilterRaw): MainFilterReal {
    const real: MainFilterReal = {};
    real.companyOverviewForm = {};
    real.ownershipForm = {};
    real.fundingForm = {};
    real.investorsForm = {};
    real.endMarketsForm = {};
    real.productsServicesForm = {};
    real.estimatedRevenueForm = {};
    real.tagsForm = {};
    real.bpcCoverageForm = {};
    const rawOptions = raw.rawValueMapping ? JSON.parse(raw.rawValueMapping) : {}

    for (const [key, value] of Object.entries(raw)) {
      // const valueAsArray = Array.isArray(value) ? value : [value];
      const valueAsArray = Array.isArray(value) ? value : [isNaN(Number(value)) ? value : Number(value)];


      switch (key) {
        case 'pageNumber':
        case 'pageSize':
        case 'sortProperty':
        case 'sortDirection':
        case 'assigneeFilterType':
        case 'excludeLatestRoundDateTo':
        case 'interactionFilterType':
        case 'interactionCountFilterType':
        case 'interactionMinTotal':
        case 'interactionMaxTotal':
        case 'isActive':
        case 'hasNotificationTriggers':
          // case 'flagtoMFD':
          real[key] = value;
          break;
        case 'companyName':
        case 'companyDescription':
          real[key] = valueAsArray;
          break;
        case 'workflows':
          real.workflows = value == WorkFlowId.Enrich.toString();
          break;

        //COMPANY OVERVIEW GROUP -- START
        case 'industriesInclude':
          real.companyOverviewForm = { ...real.companyOverviewForm };
          real.companyOverviewForm.companyOverviewIndustryInclude = this.filterRawFunction(rawOptions.industriesInclude, valueAsArray);
          break;
        case 'industriesExclude':
          real.companyOverviewForm = { ...real.companyOverviewForm };
          real.companyOverviewForm.companyOverviewIndustryExclude = this.filterRawFunction(rawOptions.industriesExclude, valueAsArray);
          break;
        case 'locationsInclude':
          real.companyOverviewForm = { ...real.companyOverviewForm };
          real.companyOverviewForm.companyOverviewLocationDropdown = this.filterLocationRawFunction(rawOptions.locationsInclude, valueAsArray);
          // this.zones.filter(({ code }) => valueAsArray.includes(code));
          break;

        case 'fullTimeEquivalentMinTotal':
          real.companyOverviewForm = { ...real.companyOverviewForm };
          real.companyOverviewForm.companyOverviewMinEmployee = valueAsArray[0];
          break;

        case 'fullTimeEquivalentMaxTotal':
          real.companyOverviewForm = { ...real.companyOverviewForm };
          real.companyOverviewForm.companyOverviewMaxEmployee = valueAsArray[0];
          break;

        case 'fullTimeEquivalentMinGrowth':
          real.companyOverviewForm = { ...real.companyOverviewForm };
          real.companyOverviewForm.companyOverviewGrowthRateMin = valueAsArray[0];
          break;

        case 'fullTimeEquivalentMaxGrowth':
          real.companyOverviewForm = { ...real.companyOverviewForm };
          real.companyOverviewForm.companyOverviewGrowthRateMax = valueAsArray[0];
          break;
        case 'foundingDateFrom':
          real.companyOverviewForm = { ...real.companyOverviewForm };
          real.companyOverviewForm.companyOverviewStartYear = new Date(value);
          break;
        case 'foundingDateTo':
          real.companyOverviewForm = { ...real.companyOverviewForm };
          real.companyOverviewForm.companyOverviewEndYear = new Date(value);
          break;
        //COMPANY OVERVIEW GROUP -- END


        //ESTIMATED REVENUE FORM GROUP -- START
        case 'revenueMinTotal':
          real.estimatedRevenueForm = { ...real.estimatedRevenueForm };
          real.estimatedRevenueForm.estimatedRevenueMin = getValueDividedByOneMillion(value);
          break;

        case 'revenueMaxTotal':
          real.estimatedRevenueForm = { ...real.estimatedRevenueForm };
          real.estimatedRevenueForm.estimatedRevenueMax = getValueDividedByOneMillion(value);
          break;
        case 'revenueMinGrowth':
          real.estimatedRevenueForm = { ...real.estimatedRevenueForm };
          real.estimatedRevenueForm.estimatedRevenueGrowthMin = value;
          break;

        case 'revenueMaxGrowth':
          real.estimatedRevenueForm = { ...real.estimatedRevenueForm };
          real.estimatedRevenueForm.estimatedRevenueGrowthMax = value;
          break;
        //ESTIMATED REVENUE FORM GROUP -- END


        //FUNDING FORM GROUP -- START
        case 'paidInCapitalMinTotal':
          real.fundingForm = { ...real.fundingForm };
          real.fundingForm.fundingTotalMin = getValueDividedByOneMillion(value);
          break;

        case 'paidInCapitalMaxTotal':
          real.fundingForm = { ...real.fundingForm };
          real.fundingForm.fundingTotalMax = getValueDividedByOneMillion(value);
          break;

        case 'latestRoundAmountMin':
          real.fundingForm = { ...real.fundingForm };
          real.fundingForm.fundingLastMin = getValueDividedByOneMillion(value);
          break;

        case 'latestRoundAmountMax':
          real.fundingForm = { ...real.fundingForm };
          real.fundingForm.fundingLastMax = getValueDividedByOneMillion(value);
          break;

        case 'latestRoundDateFrom':
          real.fundingForm = { ...real.fundingForm };
          real.fundingForm.fundingLastDateStart = new Date(value);
          break;

        case 'latestRoundDateTo':
          real.fundingForm = { ...real.fundingForm };
          real.fundingForm.fundingLastDateEnd = new Date(value);
          break;
        //FUNDING FORM GROUP -- END

        //INVESTOR FORM GROUP -- START
        case 'investorsInclude':
          real.investorsForm = { ...real.investorsForm };

          real.investorsForm.investorsInclude = this.filterRawFunction(rawOptions.investorsInclude, valueAsArray);
          break;

        case 'investorsExclude':
          real.investorsForm = { ...real.investorsForm };
          real.investorsForm.investorsExclude = this.filterRawFunction(rawOptions.investorsExclude, valueAsArray);
          break;
        //INVESTOR FORM GROUP -- END


        //TAGS FORM GROUP -- START
        case 'tagsInclude':
          real.tagsForm = { ...real.tagsForm };
          real.tagsForm.tagsInclude = this.filterRawFunction(rawOptions.tagsInclude, valueAsArray);
          break;

        case 'tagsExclude':
          real.tagsForm = { ...real.tagsForm };
          real.tagsForm.tagsExclude = this.filterRawFunction(rawOptions.tagsExclude, valueAsArray);
          break;
        //TAGS FORM GROUP -- END


        //BPC COVERAGE FORM GROUP -- START
        case 'assigneeNone':
          real.bpcCoverageForm = { ...real.bpcCoverageForm };
          real.bpcCoverageForm.bpcNoExistingCoverage = value === 'true';
          break;
        case 'assigneesInclude':
          real.bpcCoverageForm = { ...real.bpcCoverageForm };
          real.bpcCoverageForm.bpcInclude = this.filterRawFunction(rawOptions.assigneesInclude, valueAsArray);
          break;
        case 'assigneesExclude':
          real.bpcCoverageForm = { ...real.bpcCoverageForm };
          real.bpcCoverageForm.bpcExclude = this.filterRawFunction(rawOptions.assigneesExclude, valueAsArray);
          break;

        case 'statusesInclude':
          real.bpcCoverageForm = { ...real.bpcCoverageForm };
          real.bpcCoverageForm.bpcDealCloudDropDown = this.filterRawFunction(rawOptions.statusesInclude, valueAsArray);
          break;

        case 'statusesExclude':
          real.bpcCoverageForm = { ...real.bpcCoverageForm };
          real.bpcCoverageForm.bpcDealCloudDropDownExclude = this.filterRawFunction(rawOptions.statusesExclude, valueAsArray);
          break;

        case 'interactionDateFrom':
          real.bpcCoverageForm = { ...real.bpcCoverageForm };
          real.bpcCoverageForm.bpcLastInteractionFrom = new Date(value);
          break;

        case 'interactionDateTo':
          real.bpcCoverageForm = { ...real.bpcCoverageForm };
          real.bpcCoverageForm.bpcLastInteractionTo = new Date(value);
          break;

        case 'interactionType':
          real.bpcCoverageForm = { ...real.bpcCoverageForm };
          real.bpcCoverageForm.bpcInteractionType = value;
          break;
        
        case 'interactionInitiatorsInclude':
          real.bpcCoverageForm = { ...real.bpcCoverageForm };
          real.bpcCoverageForm.bpcInclude = this.filterRawFunction(rawOptions.assigneesInclude, valueAsArray);
          break;
      //BPC COVERAGE FORM GROUP -- END
      }
    }

    return real;
  }

  /**
   * filters parsing checking if filter has valid data
   * @param filters 
   * @returns filters object
   */
  getKeywordsFilters(keywordFilter: any) {

    if (keywordFilter && keywordFilter.length > 0) {
      const filters = cleanEmptyFilters(keywordFilter);
      return filters;
    }
    return null;
  }


  formatUTCDate(originalDate?: string) {
    if (originalDate) {
      return moment(originalDate).format('MM/DD/YYYY')
    }
    return undefined;
  }
  isValidValue(val: any) {
    if (val !== null && val !== undefined) {
      return true;
    }
    return false;
  }

  convertRealToRaw(real: MainFilterReal, groupedOwnership?: GroupedCompanyTypes): MainFilterRaw {
    const selectedCompanyTypes: CompanyType[] = [];
    if (groupedOwnership) {
      Object.keys(groupedOwnership).forEach((companyType) => {
        groupedOwnership[companyType].companies.forEach((company: CompanyType) => {
          if (company.isChecked) {
            selectedCompanyTypes.push(company);
          }
        });
      });
    }
    if (!real) {
      return <MainFilterRaw>{};
    }
    const filterObject: any = {};

    let rawValueMapping = this.getMetaFilters(real, selectedCompanyTypes);
    const rawFilterKeys = Object.keys(rawValueMapping);
    rawFilterKeys.map((keyVal: string) => {
      const objValue = rawValueMapping[keyVal];
      if (keyVal) {
        if (Array.isArray(objValue)) {
          filterObject[keyVal] = objValue.map((_) => {
            if (this.isValidValue(_.id)) {
              return Number(_.id);
            } else if (this.isValidValue(_.code)) {
              return _.code;
            } else {
              return _.name;
            }
          })
        } else if (objValue) {
          if (keyVal === 'locationsInclude' || keyVal === 'locationsExclude') {
            filterObject[keyVal] = this.isValidValue(objValue.code) ? objValue.code : objValue.name;
          } else {
            filterObject[keyVal] = this.isValidValue(objValue.id) ? Number(objValue.id) : objValue.name;
          }
        }
      }
    })
    filterObject.rawValueMapping = this.getRawValueMapping(rawValueMapping);
    return filterObject;
  }

  getRawValueMapping(rawObject: any) {
    const { industriesInclude,
      industriesExclude,
      investorsExclude,
      investorsInclude,
      assigneesInclude,
      assigneesExclude,
      statusesInclude,
      statusesExclude,
      tagsInclude,
      tagsExclude,
      locationsInclude,
      companyTypesInclude,
      interactionInitiatorsInclude } = rawObject;
    const filterObject: any = {};
    if (industriesInclude) {
      filterObject.industriesInclude = industriesInclude;
    }

    if (industriesExclude) {
      filterObject.industriesExclude = industriesExclude;
    }

    if (investorsExclude) {
      filterObject.investorsExclude = investorsExclude;
    }

    if (investorsInclude) {
      filterObject.investorsInclude = investorsInclude;
    }

    if (assigneesInclude) {
      filterObject.assigneesInclude = assigneesInclude;
    }
    if (interactionInitiatorsInclude) {
      filterObject.interactionInitiatorsInclude = interactionInitiatorsInclude;
    }

    if (assigneesExclude) {
      filterObject.assigneesExclude = assigneesExclude;
    }

    if (statusesExclude) {
      filterObject.statusesExclude = statusesExclude;
    }

    if (tagsInclude) {
      filterObject.tagsInclude = tagsInclude;
    }

    if (tagsExclude) {
      filterObject.tagsExclude = tagsExclude;
    }

    if (locationsInclude) {
      filterObject.locationsInclude = locationsInclude;
    }

    if (statusesInclude) {
      filterObject.statusesInclude = statusesInclude;
    }

    if (companyTypesInclude) {
      filterObject.companyTypesInclude = companyTypesInclude;
    }
    return JSON.stringify(filterObject);
  }


  getMetaFilters(real: MainFilterReal, selectedCompanyTypes: CompanyType[]) {
    const filters = this.getKeywordsFilters(real.keywordsGroup);
    const { companyOverviewIndustryInclude, companyOverviewIndustryExclude, companyOverviewMinEmployee, companyOverviewMaxEmployee,
      companyOverviewGrowthRateMin, companyOverviewGrowthRateMax, companyOverviewStartYear, companyOverviewEndYear, companyOverviewLocationDropdown } = real.companyOverviewForm;
    const { investorsInclude, investorsExclude } = real.investorsForm;
    const { endMarketsInclude, endMarketsExclude } = real.endMarketsForm;
    const { productsServicesInclude, productsServicesExclude } = real.productsServicesForm;
    const { tagsInclude, tagsExclude } = real.tagsForm;
    const { bpcNoExistingCoverage, bpcInclude, bpcExclude, bpcDealCloudDropDown, bpcDealCloudDropDownExclude, bpcLastInteractionFrom,
      bpcLastInteractionTo, bpcInteractionType } = real.bpcCoverageForm;
    const { estimatedRevenueMin, estimatedRevenueMax, estimatedRevenueGrowthMin, estimatedRevenueGrowthMax } = real.estimatedRevenueForm;
    const { fundingTotalMin, fundingTotalMax, fundingLastMin, fundingLastMax, fundingLastDateStart, fundingLastDateEnd } = real.fundingForm;

    const filterObject: MainFilterRawMeta = {};
    filterObject.companyName = { name: real.companyName?.length ? removeExtraSpaces(real.companyName) : undefined, id: null };
    filterObject.companyTypesInclude = selectedCompanyTypes?.length ? selectedCompanyTypes.map(_ => ({ name: _.name, id: _.id })) : undefined;
    filterObject.companyTypesExclude = real.companyTypeExclude?.length ? real.companyTypeExclude.map(_ => ({ name: _.name, id: _.id })) : undefined;
    filterObject.companyDescription = { name: real.companyDescription?.length ? removeExtraSpaces(real.companyDescription) : undefined, id: null };
    filterObject.industriesInclude = companyOverviewIndustryInclude?.length ? companyOverviewIndustryInclude.map((_: Industry) => ({ id: _.id, name: _.name })) : undefined;
    filterObject.industriesExclude = companyOverviewIndustryExclude?.length ? companyOverviewIndustryExclude.map((_: Industry) => ({ id: _.id, name: _.name })) : undefined;
    filterObject.fullTimeEquivalentMinTotal = companyOverviewMinEmployee ? { name: companyOverviewMinEmployee, id: null } : undefined;
    filterObject.fullTimeEquivalentMaxTotal = companyOverviewMaxEmployee ? { name: companyOverviewMaxEmployee, id: null } : undefined;
    filterObject.fullTimeEquivalentMinGrowth = companyOverviewGrowthRateMin ? { name: companyOverviewGrowthRateMin, id: null } : undefined;
    filterObject.fullTimeEquivalentMaxGrowth = companyOverviewGrowthRateMax ? { name: companyOverviewGrowthRateMax, id: null } : undefined;
    filterObject.foundingDateFrom = companyOverviewStartYear ? { name: this.formatUTCDate(companyOverviewStartYear), id: null } : undefined;
    filterObject.foundingDateTo = companyOverviewEndYear ? { name: this.formatUTCDate(companyOverviewEndYear), id: null } : undefined;
    filterObject.locationsInclude = companyOverviewLocationDropdown?.length > 0 ? companyOverviewLocationDropdown.map((_: any) => ({ id: null, name: _.name, code: _.code.toString() })) : undefined;
    filterObject.investorsInclude = investorsInclude?.length > 0 ? investorsInclude.map((_: Investor) => ({ name: _.name, id: _.id })) : undefined;
    filterObject.investorsExclude = investorsExclude?.length > 0 ? investorsExclude.map((_: Investor) => ({ name: _.name, id: _.id })) : undefined;
    filterObject.endMarketsInclude = endMarketsInclude?.length ? endMarketsInclude.map((_: any) => ({ name: _.name, id: _.id })) : undefined;
    filterObject.endMarketsExclude = endMarketsExclude?.length ? endMarketsExclude.map((_: any) => ({ name: _.name, id: _.id })) : undefined;
    filterObject.productsInclude = productsServicesInclude?.length > 0 ? productsServicesInclude : undefined;
    filterObject.productsExclude = productsServicesExclude?.length > 0 ? productsServicesExclude : undefined;
    filterObject.tagsInclude = tagsInclude?.length > 0 ? tagsInclude.map((tag: Tag) => ({ name: tag.name, id: tag.id })) : undefined;
    filterObject.tagsExclude = tagsExclude?.length > 0 ? tagsExclude.map((tag: Tag) => ({ name: tag.name, id: tag.id })) : undefined;

    filterObject.assigneeNone = { name: bpcNoExistingCoverage === true ? true : undefined, id: null };
    filterObject.assigneeRole = {
      name: bpcInclude?.length > 0 ? AssigneeRole.COVERAGE :
        bpcExclude?.length > 0 ? AssigneeRole.COVERAGE : bpcNoExistingCoverage === true ? AssigneeRole.COVERAGE : undefined, id: null
    };
    filterObject.assigneesInclude = bpcInclude?.length > 0 ? bpcInclude.map((assignee: any) => ({ id: assignee.id, name: assignee.name })) : undefined;
    filterObject.assigneesExclude = bpcExclude?.length > 0 ? bpcExclude.map((assignee: any) => ({ id: assignee.id, name: assignee.name })) : undefined;
    filterObject.statusesInclude = bpcDealCloudDropDown?.length ? bpcDealCloudDropDown?.map((status: any) => ({ name: status.name, id: status.id })) : undefined;
    filterObject.statusesExclude = bpcDealCloudDropDownExclude?.length ? bpcDealCloudDropDownExclude?.map((status: any) => ({ name: status.name, id: status.id })) : undefined;
    filterObject.interactionDateFrom = { name: this.formatUTCDate(bpcLastInteractionFrom), id: null };
    filterObject.interactionDateTo = { name: this.formatUTCDate(bpcLastInteractionTo), id: null };
    filterObject.interactionType = { name: bpcInteractionType, id: null };
    const revenueMin = estimatedRevenueMin != null ? (+estimatedRevenueMin * 1_000_000).toString(10) : undefined;
    const revenueMax = estimatedRevenueMax != null ? (+estimatedRevenueMax * 1_000_000).toString(10) : undefined;
    filterObject.revenueMinTotal = { name: revenueMin, id: null };
    filterObject.revenueMaxTotal = { name: revenueMax, id: null };
    filterObject.revenueMinGrowth = { name: estimatedRevenueGrowthMin, id: null };
    filterObject.revenueMaxGrowth = { name: estimatedRevenueGrowthMax, id: null };
    filterObject.paidInCapitalMinTotal = { name: fundingTotalMin != null ? (+fundingTotalMin * 1_000_000).toString(10) : undefined, id: null };
    filterObject.paidInCapitalMaxTotal = { name: fundingTotalMax != null ? (+fundingTotalMax * 1_000_000).toString(10) : undefined, id: null };;
    filterObject.latestRoundAmountMin = { name: fundingLastMin != null ? (+fundingLastMin * 1_000_000).toString(10) : undefined, id: null };
    filterObject.latestRoundAmountMax = { name: fundingLastMax != null ? (+fundingLastMax * 1_000_000).toString(10) : undefined, id: null };
    filterObject.latestRoundDateFrom = { name: this.formatUTCDate(fundingLastDateStart), id: null };
    filterObject.latestRoundDateTo = { name: this.formatUTCDate(fundingLastDateEnd), id: null };
    filterObject.interactionMinTotal = { name: real.interactionMinTotal ?? undefined, id: null };
    filterObject.interactionMaxTotal = { name: real.interactionMaxTotal ?? undefined, id: null };
    filterObject.interactionCountFilterType = { name: real.interactionMinTotal || real.interactionMaxTotal ? real.interactionCountFilterType : undefined, id: null };
    filterObject.interactionFilterType = { name: real.interactionFilterType, id: null };

    filterObject.workflows = { name: !!real.workflows ? WorkFlowId.Enrich : undefined, id: null };
    filterObject.isActive = { name: real.isActive, id: null };
    filterObject.hasNotificationTriggers = { name: real.hasNotificationTriggers, id: null };
    filterObject.filters = { name: filters, id: null }; filters;
    return filterObject;
  }

  upsertTags(tags: Tag[]) {
    for (const tag of tags) {
      this.tagsMap.set(tag.id, tag);
    }
  }
}

function getValueDividedByOneMillion(value: number) {
  return Math.floor(value / 1_000_000).toString(10);
}

function diffInMonths(value: string) {
  return moment().diff(value, 'months');
}

function removeExtraSpaces(list: string[]): string[] {
  return list.map(data => data.trim());
}