
import { AfterViewInit, Component, ElementRef, inject, OnInit, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';
import { EMPTY, filter, from, map, mergeAll, mergeMap, Observable, scan, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { DestroyObservable } from '../../../rxjs/DestroyObservable';
import { fromIntersectionObserver } from '../../../rxjs/fromIntersectionObserver';
import { Notification } from '@models/notifications';
import { NotificationsService } from "@core/services/notifications.service";

@Component({
  selector: "bpc-notification-alerts",
  templateUrl: "./notification-alerts.component.html",
  styleUrls: ["./notification-alerts.component.scss"],
  providers: [DestroyObservable]
})
export class NotificationAlertsComponent implements OnInit, AfterViewInit {
  @ViewChild("content") content!: ElementRef;
  isVisible: boolean = false;
  private destroy$ = inject(DestroyObservable);
  private markAsReadSubject = new Subject<number>();
  subscriptions: Subscription[] = [];
  @Output() setUnreadNotificationCount = new EventEmitter<number>();


  unread = 0;

  notifications: Notification[] = [];
  notifications$: Observable<Notification[]> = this.notificationsService.notifications$.pipe(
    scan((acc, { page, notifications }) => page === 1 ? notifications : acc.concat(notifications), <Notification[]>[])
  );

  notificationsCount$ = this.notificationsService.notificationsCount$;

  @ViewChildren('n') notificationElements!: QueryList<ElementRef<HTMLDivElement>>;

  constructor(private notificationsService: NotificationsService) {
  }

  ngOnInit() {
    // this.getNotifications();
    this.notificationsCount$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.unread = res.data?.unreadCount ?? 0
        this.setUnreadNotificationCount.emit(this.unread);
      });
  }

  ngAfterViewInit(): void {
    (this.notificationElements.changes as Observable<QueryList<ElementRef<HTMLDivElement>>>).pipe(
      map(queryList => queryList.toArray().splice(-3).map(({ nativeElement }) => nativeElement)), // take the last 3 <div #n> tags
      switchMap(elements => from(elements.map(el => fromIntersectionObserver(el))).pipe(mergeAll())),
      filter(({ isIntersecting }) => isIntersecting)
    ).subscribe(this.notificationsService.loadMore$);
  }

  showAlerts() {
    this.isVisible = true;
    this.getNotifications();
    document.body.style.overflow = 'hidden';
  }

  getNotifications() {
    this.notificationsService.page = 1;
    this.notificationsService.hasNext = true;

    this.subscriptions.push(this.notificationsCount$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.unread = res.data?.unreadCount ?? 0
        this.setUnreadNotificationCount.emit(this.unread);
      }));


    this.subscriptions.push(this.notifications$
      .pipe(takeUntil(this.destroy$))
      .subscribe(notifications => this.notifications = notifications));


    this.subscriptions.push(this.markAsReadSubject
      .pipe(mergeMap(id => this.findNotificationById(id)?.isRead ? EMPTY : this.notificationsService.markAsRead(id)))
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res.data!.result && this.unread > 0) {
          const notification = this.findNotificationById(res.data!.data);
          if (notification) {
            notification.isRead = true;
          }
          // this.unread--;
          // console.log("unread",this.unread);
          // this.setUnreadNotificationCount.emit(this.unread);

        }
      }));
  }

  trackByNotificationId(index: number, { id }: Notification) {
    return id;
  }

  onHide() {
    this.isVisible = false;
    document.body.style.overflow = 'auto';
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  markAsRead({ id }: Notification) {
    //write code to check if the notification is already read or not
    if (this.findNotificationById(id)?.isRead) {
      this.isVisible = false;
      return;
    }
    this.markAsReadSubject.next(id);
    this.isVisible = false;
    this.unread--;
    this.setUnreadNotificationCount.emit(this.unread);
  }

  private findNotificationById(notificationId: number): Notification | undefined {
    return this.notifications.find(({ id }) => id === notificationId);
  }

  get contentElement(): HTMLElement {
    return this.content.nativeElement;
  }


}
