import { Component, Input, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { Company } from '@core/models/company';
import { Nullable } from '@core/models/nullable';
import { SocialLink } from '@core/models/social-link';
import { Prompt } from '@core/models/types';
import { BaseUser } from '@core/models/user';
import { AIPrepService, LoadConversationType } from '@core/services/ai-prep.service';
import { CompanyService } from '@core/services/company.service';
import { MixpanelService, SocialLinkType } from '@core/services/mixpanel.service';
import { MenuItem } from '@shared/components/menu-native/menu-native-item';
import 'deep-chat';
import { DeepChat } from 'deep-chat';
import { Signals } from 'deep-chat/dist/types/handler';
import { MessageContent } from 'deep-chat/dist/types/messages';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject, catchError, forkJoin, of, switchMap } from 'rxjs';
import { ApiResponse } from 'src/app/api/base';
import { ChromeExtensionService } from 'src/app/services/chrome-extension/chrome-extension.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { ModalUtilsService } from 'src/app/services/modal-utils.service';
import { UsersService } from 'src/app/services/users/users.service';

interface ConversationThread {
  userId?: number;
  userAssistantConversationId?: number;
  ThreadString?: string;
  ThreadId: string;
  resources?: string;
  createDate?: string;
  ThreadTitle?: string;
};


@Component({
  selector: 'bpc-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent {
  conversationThread: ConversationThread[] = [];

  loading: boolean = false;
  conversationLoading: boolean = false;
  conversationHistory: MessageContent[] = [];
  private threadClickSubject = new Subject<string>();
  selectedThread: Nullable<ConversationThread> = null;
  userDetails: Nullable<BaseUser> = null;
  threadId: string = '';
  avatarStyles: any = null;
  isMenuOpen: boolean = false;
  companyDetails: Company | null = null;
  @ViewChild("deepChatRef") deepChatComponent!: any;

  get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }
  
  constructor(
    private authService: AuthService,
    private aiPrepService: AIPrepService,
    private modalUtilsService: ModalUtilsService,
    private usersService: UsersService,
    private deviceDetectorService: DeviceDetectorService,
    private chromeExtensionService: ChromeExtensionService,
    private route: ActivatedRoute,
    private companiesService: CompaniesService,
    private mixPanelService: MixpanelService
  ) { 
    this.isMenuOpen = this.isMobile ? false : true;
  }

  ngAfterViewInit() {
    if (this.deepChatComponent != null) {
      if (this.isExtension && this.companyDetails){
        this.aiPrepService.getExtensionPrompt(Prompt.EXTENSION).subscribe((res: any) => {
          if (res) {
            var text = res.PromptTemplate;
            text = text.replace("{company_domain}", this.companyDetails?.domain)
            this.deepChatComponent.nativeElement.onComponentRender = (chatElementRef: DeepChat) => {
              chatElementRef.submitUserMessage({
                text: text
              });
            }
          }
          else {
            this.deepChatComponent.nativeElement.onComponentRender = (chatElementRef: DeepChat) => {
              chatElementRef.submitUserMessage({
                text: `Provide a summary report on ${this.companyDetails?.domain}`
              });
            }
            }
        });
      }
    }
  }

  ngOnInit(): void {
    if (this.isExtension) {
      const params = this.route.snapshot?.queryParams;
      const companyDetailsString = params ? params['companyDetails'] : null;
      
      if (companyDetailsString) {
        try {
          this.companyDetails = JSON.parse(companyDetailsString);
        } catch (error) {
          //console.error('Error parsing company details:', error);
          this.companyDetails = null;
        }
      } else {
        this.companyDetails = null;
      }
    } 

    this.getUserDetails();
    this.subscribeToConversationChanges();
    this.threadClickSubject.pipe(
      switchMap((id: string) => this.handleThreadClickSwitchMap(id))
    ).subscribe((res: any) => {
      if (res && res.messages && res.messages.length > 0) {
        this.conversationHistory = res.messages.reverse().map((item: any) => {
          return {
            role: item.role,
            text: item.content,
            files: item.attachments.map((attachment: any) => ({ name: attachment })),
          };
        });
      }
      this.conversationLoading = false;
    });
    this.aiPrepService.aiPrepConversationSubject.next(null);
  }

  ngOnDestroy(): void {
    this.threadClickSubject.complete();
  }

  // getCompanyDetails(companyId?: number) {
  //   if (companyId) {
  //     this.companyDetails = null;
  //     this.companiesService.getCompanyDetails(companyId).subscribe(({ data }) => {
  //       if (data) {
  //         this.companyDetails = data!;
  //         console.log(this.companyDetails);
  //       }
  //       else {
  //         this.companyDetails = null;
  //       }
  //     });
  //   }
  // }

  getUserDetails(): void {
    this.usersService.getCurrentUserDetails().subscribe((res: ApiResponse<BaseUser>) => {
      if (res.data) {
        this.userDetails = res.data;
        this.populateAvatarStyles();
      }
    });
  }

  populateAvatarStyles(): void {
    this.avatarStyles = {
      "default": {
        "styles": { "avatar": { "height": "32px", "width": "32px", "padding-top": "0" }, "container": { "marginTop": "10px" } }
      },
      "ai": {
        "src": "https://deepchat.dev/img/ai-brain.svg",
        "styles": { "avatar": { "marginLeft": "-4px 6px 0px -3px" }, "container": { "marginTop": "10px" } }
      },
      "user": {
        "src": this.userDetails?.profilePictureUrl ?? "assets/no-image.png",
        "styles": { "avatar": { "border-radius": "50%", "margin": "0 -3px" } }
      }
    };
  }

  subscribeToConversationChanges(): void {
    this.aiPrepService.aiPrepConversationSubject.subscribe((type: LoadConversationType) => this.getConversations(type));
  }

  getConversations(type: LoadConversationType): void {
    if (type === 'delete' && this.threadId === this.selectedThread?.ThreadId) {
      this.handleThreadClick('');
    }
    this.aiPrepService.getChatConversations().subscribe((res: any) => {
      if (res && res.length > 0) {
        this.conversationThread = [...res];
      }
    });
  }

  handleThreadClickSwitchMap(id: string): Observable<any> {
    if (this.threadId !== "") {
      return this.aiPrepService.getConversationsByThreadId(this.threadId);
    } else {
      this.conversationLoading = false;
      this.conversationHistory = [];
      return of(null);
    }
  }

  handleThreadClick(id: string): void {
    this.threadId = id;
    this.conversationHistory = [];
    this.conversationLoading = true;
    this.threadClickSubject.next(id);
  }

  requestParams = {
    handler: (body: any, signals: Signals) => {
      if (this.isExtension){
        if (this.deepChatComponent.nativeElement.getMessages().length == 1){
          this.deepChatComponent.nativeElement.clearMessages(false);
        }
      }
      this.loading = true;
      const fileIds: string[] = [];
      const { lastMessage, fileObservables } = this.processMessageContents(body);

      // an empty observable is pushed because fork join requires atleast one observable to run
      if (fileObservables.length === 0) {
        fileObservables.push(of(null));
      }

      const payload = {
        query: lastMessage?.text ?? "",
        thread_id: this.threadId ?? "",
        file_ids: fileIds
      };

      forkJoin(fileObservables).pipe(
        catchError((error: any) => {
          console.log("Error in file upload:", error);
          this.loading = false;
          signals.onResponse({ text: "File size exceeds 10MBs limit, please upload a smaller file" });
          signals.onClose();
          console.error('Error in file upload:', error);
          // Handle error here, for example, emit default response
          return of({ response: { error: true } }); // Return an empty array to continue with processing
        })
      ).subscribe(async (responses: any) => {
        if (responses && responses.length) {
          responses.forEach((res: any) => {
            if (res !== null) {
              fileIds.push(...res);
            }
          });
        }
        if (!responses?.response?.error) {
          try {
            const response = await this.aiPrepService.askQuery(payload);
            await this.handleStreamedResponse(response, signals, lastMessage);
          } catch (error) {
            signals.onResponse({ text: "Error occurred processing query" });
            this.loading = false;
          } finally {
            signals.onClose();
          }
        }
      });
    }
  };

  processMessageContents(body: any) {
    let messages: any[] = [];
    let lastMessage: MessageContent = {};
    const fileObservables: Observable<any>[] = [];

    if (body instanceof FormData) {
      body.forEach((value, key) => {
        if (key !== "files") {
          messages.push(value);
        }
      });
      if (messages.length !== 0) {
        lastMessage = JSON.parse(messages[messages.length - 1]);
      }
      const files = body.getAll("files");
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file instanceof File) {
          const fileFormData = new FormData();
          fileFormData.append("files", file);

          const fileUploadObservable = this.aiPrepService.getUploadedFileIds(fileFormData);
          fileObservables.push(fileUploadObservable);
        }
      }
    } else {
      lastMessage = body.messages[0];
    }
    return {
      lastMessage,
      fileObservables
    };
  }

  validateInput = (text?: string, files?: File[]) => {
    if (text?.length === 0) {
      return false;
    }
    return true;
  }

  async handleStreamedResponse(response: any, signals: Signals, lastMessage: MessageContent) {
    const reader = response?.body?.getReader();
    let lastResponseChunk;
    while (true) {
      // wait for next encoded chunk
      if (reader) {
        const { done, value } = await reader.read();
        // check if stream is done
        if (done) break;

        const regex = /^data:\s*({.*?})\s*$/gm;
        let match;
        while ((match = regex.exec(new TextDecoder().decode(value))) !== null) {
          const objectString = match[1]; // Extract the content within curly braces
          const parsedChunk = JSON.parse(objectString); // Parse the JSON object
          console.log(parsedChunk);
          if (parsedChunk.content=="##"&& this.isExtension){//To avoid large headings for extension
            parsedChunk.content="###"
          }
          signals.onResponse({ text: parsedChunk.content, overwrite: parsedChunk.last_message });
          lastResponseChunk = parsedChunk;
        }
      }
    }
    if (lastResponseChunk) {
      if (this.threadId === '') {
        this.aiPrepService.getConversationsByThreadId(lastResponseChunk.thread_id).pipe(
          catchError((error: any) => {
            this.loading = false;
            return of(null);
          })
        ).subscribe((res: any) => {
          if (res && res.entity) {
            this.conversationThread = [
              { ThreadId: res.entity?.ThreadId, ThreadTitle: res.entity?.ThreadTitle },
              ...this.conversationThread
            ];
          }
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
      this.threadId = lastResponseChunk.thread_id;
    }
  }

  // menu item temporarily removed, please don't remove the code
  menuItems: MenuItem[] = [
    {
      icon: "pi pi-pencil",
      label: "Rename",
      command: () => {
        if (this.selectedThread) {
          this.updateThread(this.selectedThread);
        }
      },
    },
    {
      icon: "pi pi-trash",
      label: "Delete",
      command: () => {
        if (this.selectedThread) {
          this.removeThread(this.selectedThread);
        }
      },
    },
  ];

  onMenuClick(event: any, thread: ConversationThread) {
    this.selectedThread = thread;
  }

  removeThread(thread: ConversationThread) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.chatThreadId = thread.ThreadId;
    modalParameters.currentModalAction = 'DeleteChatThread';
    this.modalUtilsService.toggalModal.next(modalParameters);
  }

  updateThread(thread: ConversationThread) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.chatThreadId = thread.ThreadId;
    modalParameters.title = thread.ThreadTitle;
    modalParameters.currentModalAction = 'UpdateChatThread';
    this.modalUtilsService.toggalModal.next(modalParameters);
  }

  deleteChatThread(event: any, thread: ConversationThread) {
    this.selectedThread = thread;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  socialUrlClick(event: SocialLink) {
    if (event) {
      const socialLinkType = SocialLinkType[event.type];
      this.mixPanelService.trackEvent("Company_Details_Social_Link_Clicked", {
        companyName: this.companyDetails?.name,
        socialLinkType: socialLinkType,
        isExtension: this.isExtension,
      });
    }
  };
}

