import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vote } from '../voting-stats/voting-stats.component';
import { Nullable } from 'primeng/ts-helpers';
import { VotingService } from '@core/services/voting.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { MessageService } from 'primeng/api';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'bpc-delete-vote-popup',
  templateUrl: './delete-vote-popup.component.html',
  styleUrls: ['./delete-vote-popup.component.scss']
})
export class DeleteVotePopupComponent {
  @Input() visible: boolean = false;
  @Input() vote: Nullable<Vote> = null;
  @Input() companyId: Nullable<number> = null;
  modalLoading: boolean = false;
  positiveButtonDisabled: boolean = false;
  @Output() toggleVisible = new EventEmitter<boolean>();
  @Output() refreshVotes = new EventEmitter<null>();

  constructor(
    private votingService: VotingService,
    private companiesService: CompaniesService,
    private messageService: MessageService
  ){

  }

  
  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  onNegativeClick(){
    this.toggleVisible.emit(false);
    this.positiveButtonDisabled = false;
  }

  onPositiveClick(){
    this.modalLoading = true;
    this.positiveButtonDisabled = true;


    if (this.vote != null && this.companyId != null){
      this.companiesService.deleteVote(this.companyId, this.vote.id).pipe(
          catchError((error) => {
            this.modalLoading = false;
            this.positiveButtonDisabled = false;
            this.showErrorToastMessage("Error deleting vote. Please try again.");
            this.toggleVisible.emit(false);
            return of(null);
          })
        ).subscribe(response => {
          if (response?.data){
            this.modalLoading = false;
            this.positiveButtonDisabled = false;
            this.refreshVotes.emit();
            this.showSuccessToastMessage("Vote deleted, click refresh to regenerate summary.");
            this.toggleVisible.emit(false);
          }
          if(response?.error){
            this.modalLoading = false;
            this.positiveButtonDisabled = false;
            this.showErrorToastMessage("Error deleting vote. Please try again.");
            this.toggleVisible.emit(false);
          }
        });
    }
  }
}
