import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Company } from '@core/models/company';
import { MixpanelService } from '@core/services/mixpanel.service';
import { DeviceDetectorService } from "ngx-device-detector";

type ProgressButtonStyle = {
  background?: string;
}

type IconContainerStyle = {
  height?: string;
  width?: string;
}

@Component({
  selector: 'bpc-circular-download-progress',
  templateUrl: './circular-download-progress.component.html',
})
export class CircularDownloadProgressComponent implements OnChanges {
  @Input() progress = 0;
  @Input() loading = false;
  @Input() selectedCompanies:Company[]=[]

  @Output() onDownloadClick = new EventEmitter<void>();
  @Output() onDownloadCancelClick = new EventEmitter<void>();

  progressButtonStyle: ProgressButtonStyle = {}
  iconContainerStyle: IconContainerStyle = {}

  constructor(
    public deviceDetectorService: DeviceDetectorService,
    private mixpanelService: MixpanelService,) {
    this.iconContainerStyle.height = 'calc(100% - 0.25rem)';
    this.iconContainerStyle.width = 'calc(100% - 0.25rem)';
  }

  ngOnInit(): void {
    this.calculateBackgroundGradient();
  }

  ngOnChanges() {
    this.calculateBackgroundGradient();
  }

  onButtonClick() {
    if (!this.progress) {
      this.mixpanelService.trackEvent('Download_CSV');
      this.onDownloadClick.emit();
    } else {
      this.onDownloadCancelClick.emit();
    }
  }

  private calculateBackgroundGradient() {
    const backgroundColor = this.progress === 0 ? '#ffffff' : '#f2f2f4';
    this.progressButtonStyle.background = `conic-gradient(#008fff ${this.progress}%, ${backgroundColor} ${this.progress}%)`;
  }

  get isTablet(): boolean {
    return this.deviceDetectorService.isTablet();
  }
}
