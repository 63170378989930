import { Directive } from '@angular/core';
import { DestroyObservable } from '../../rxjs/DestroyObservable';
import { takeUntil } from 'rxjs';
import { AutoComplete } from 'primeng/autocomplete';
import { IndustryService } from '@core/services/industry.service';
import { BaseMultipleAutoCompleteDirective } from '@core/directives/base-multiple-auto-complete.directive';
import { Industry } from '@models/auxiliary-company-models';

@Directive({
  selector: 'p-autoComplete[industries]',
  providers: [DestroyObservable]
})
export class IndustriesDirective extends BaseMultipleAutoCompleteDirective<Industry> {

  loadData = this.industryService.industries$.pipe(takeUntil(this.destroyObservable));

  constructor(autoComplete: AutoComplete, private industryService: IndustryService, private destroyObservable: DestroyObservable) {
    super(autoComplete);
  }
}
