import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BaseApiService } from '../../api/base';
import { AuthService } from '@core/auth/auth.service';

export interface Stats {
  companiesTotal: number;
  companiesPipeline: number;
  companiesPriority: number;
  companiesEnriched: number;
  companiesElevation: number;
  totalInteractions: number;
}

@Injectable({
  providedIn: 'root'
})
export class StatsService extends BaseApiService<Stats> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/dashboard', authService, router, messageService);
  }

  stats$ = this.get('/stats');
}
