import { Component, Input } from '@angular/core';

import { Nullable } from '@models/nullable';

import { CompanyScore, CompanyProductScore } from '@models/auxiliary-company-models';

@Component({
  selector: 'bpc-product-score',
  templateUrl: './g2-product-score.component.html'
})
export class ProductScoreComponent {
  @Input() productScore: Nullable<CompanyProductScore[]> = null;
  //@Input() disableTooltip = false;
}
