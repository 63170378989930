import { Pipe, PipeTransform } from '@angular/core';
import { Vote } from '@core/components/voting-stats/voting-stats.component';

@Pipe({
    name: 'voterDesignation'
})
export class voterDesignationPipe implements PipeTransform {
    transform(vote: Vote): string {
        switch (vote.group) {
            case 21:
                return 'Partner';
            case 22:
                return 'Principal';
            case 23:
                return 'Vice President';
            case 24:
                return 'Associate/Analyst';
            default:
                return vote.groupName;
        }
    }
}