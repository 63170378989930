import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlow } from '@models/auxiliary-company-models';
import { getActiveOrLastICVotingWorkflow } from '../../../utils/company-utils';
import { userDataKey } from '@core/localStorageKeys.ts';
import { AuthService } from '@core/auth/auth.service';

@Pipe({
  name: 'votingCurrentUserVoted'
})
export class VotingCurrentUserVotedPipe implements PipeTransform {

  constructor(private authService: AuthService) {

  }

  transform(userIds: number[]): boolean {
    let userId = null;
    let userData: any = this.authService.getUserData();
    if (userData) {
      userData = JSON.parse(userData);
      userId = userData?.id;
    }
    if (!userIds) {
      return false;
    }

    return userIds.indexOf(userId) > -1;
  }
}
