import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { MenuItem } from '../menu-native/menu-native-item';

@Component({
  selector: 'bpc-menu-native-primeng',
  templateUrl: './menu-native-primeng.component.html'
})
export class MenuNativePrimeNgComponent {
  @ViewChild('menuItems') menuItems!: ElementRef;
  @ViewChild('menu') menu!: any;

  @Input() items: MenuItem[] = [];
  @Output() hideTooltip = new EventEmitter();

  open = false;
  style: any = {};

  toggle($event: any) {
    this.menu.toggle($event);
  }
}
