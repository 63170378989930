import { Pipe, PipeTransform } from '@angular/core';
import { BasicCompany, Company } from '@models/company';
import { CompanyHelperService } from '@core/services/company-helper.service';

@Pipe({
  name: 'companyDetailsUrl'
})
export class CompanyDetailsUrlPipe implements PipeTransform {

  constructor(private companyHelperService: CompanyHelperService) {
  }

  transform(company: Company | BasicCompany): string[] {
    return this.companyHelperService.generateCompanyDetailsUrl(company);
  }
}