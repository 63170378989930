import { Directive, inject, Input } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { switchMap, takeUntil, tap } from 'rxjs';
import { DestroyObservable } from 'src/app/rxjs/DestroyObservable';
import { CompaniesSearchService } from 'src/app/services/companies-search/companies-search.service';

@Directive({
  selector: 'p-autoComplete[companies]',
  providers: [DestroyObservable]
})
export class CompaniesDirective {

  private destroy$ = inject(DestroyObservable);

  

  constructor(autoComplete: AutoComplete, companiesSearchService: CompaniesSearchService) {
    autoComplete.field = 'name';
    autoComplete.dataKey = 'id';
    autoComplete.appendTo = 'body';
    //re-enabled the multiple for companies
    autoComplete.multiple = true;
    autoComplete.completeMethod
      .pipe(tap(() => autoComplete.loading = true))
      .pipe(tap(() => autoComplete.show()))
      .pipe(switchMap(({ query }) => companiesSearchService.quickSearch(query)))
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }) => {
        autoComplete.loading = false;
        if (data) {
          autoComplete.suggestions = data?.companies ?? [];
        }
        autoComplete.cd.markForCheck();
      });
  }

}
