import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '@models/company';
import { NotificationTrigger } from '@models/notifications';
import { getTriggerDescription } from '../../../utils/notification-utils';

@Component({
  selector: 'bpc-notifications-icon',
  templateUrl: './notifications-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsIconComponent {
  @Input() company!: Company;
  @Output() onClick = new EventEmitter();

  get activeTriggers(): NotificationTrigger[] {
    return this.company.notificationTriggers.filter(({ isActive }) => isActive);
  }

  onIconClick() {
    this.onClick.emit();
  }

  getTriggerDescription(trigger: NotificationTrigger) {
    return getTriggerDescription(trigger);
  }
}