<span
  class="tags no-hover"
  [ngClass]="{ filled: tag.type === 4 }"
  (click)="emitTagClick()"
  (dblclick)="emitTagClick()"
  [overlayPanelTooltip]="tag.type !== 4 ? tooltip : null"
>
  <img class="mr-1" *ngIf="tag.type === 2" src="assets/svg/private-tag.svg" pTooltip="Global" tooltipPosition="top" />
  {{ tag.name }}
  <i class="pi pi-times ml-2" *ngIf="tag.type !== 4" (click)="onRemoveTagClick($event)"></i>
</span>

<p-overlayPanel styleClass="tool-tip-class" #tooltip>
  <ul>
    <li *ngIf="canEditTag" (click)="onEditTagClick()">Edit tag</li>
    <li (click)="onRemoveTagClick($event)">Remove tag</li>
  </ul>
</p-overlayPanel>
