<!-- <div *ngIf="!addNew && !isNonAnalystUser"
    [ngClass]="{'bg-teal-300' : connectionLevel === 1, 'bg-gray-200' : connectionLevel === 2 }"
    class="w-12 h-12 rounded-l flex items-center justify-center 'bg-gray-200'">
    <p-checkbox value="true" [(ngModel)]="localIsConnected" (click)="toggleConnection()"></p-checkbox>
  </div> -->

<!-- <div *ngIf="!addNew" class="flex justify-between items-center pt-1.5">
        <div class="flex flex-col">
          <span class="font-normal text-black text-sm">{{
            profile?.firstName || profile?.lastName
              ? (profile?.firstName || "") + " " + (profile?.lastName || "")
              : "N/A"
          }}</span>
          <span class="text-xs text-gray-700">{{ profile?.position }}</span>
        </div>
        <div class="flex items-center text-xl">
          <a
            [ngClass]="{ 'opacity-30 pointer-events-none': !profile?.workEmail }"
            href="mailto:{{ profile?.workEmail }}"
            target="_blank"
          >
            <i class="pi pi-envelope mr-2 cursor-pointer"></i>
          </a>
          <a
            [ngClass]="{ 'opacity-30 pointer-events-none': !(profile?.externalUrl | urlTransform) }"
            [href]="profile?.externalUrl | urlTransform"
            target="_blank"
          >
            <i class="pi pi-linkedin cursor-pointer"></i>
          </a>
          <div class="w-[1px] mx-4 bg-gray-500 h-10"></div>
          <i (click)="toggleDeleteModal()" class="cursor-pointer icon icon-user-delete mr-2"></i>
          <i
            (click)="toggleEdit()"
            [ngClass]="{ 'text-teal-300': editConnection }"
            class="pi pi-user-edit cursor-pointer"
          ></i>
        </div>
      </div> -->
<bpc-custom-modal [dismissableMask]="false" [visible]="visible" [contentStyleClass]="'scrollable'" (onHide)="onHide.emit()" [showFooter]="false">
  <ng-container modal-header>
    <span class="p-dialog-title flex">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="mr-2">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.18063 15.7979C4.76834 15.7979 1 16.4647 1 19.1369C1 21.8091 4.74539 22.5001 9.18063 22.5001C13.5941 22.5001 17.3613 21.832 17.3613 19.161C17.3613 16.49 13.617 15.7979 9.18063 15.7979Z"
          stroke="#002438"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.18063 15.7979C4.76834 15.7979 1 16.4647 1 19.1369C1 21.8091 4.74539 22.5001 9.18063 22.5001C13.5941 22.5001 17.3613 21.832 17.3613 19.161C17.3613 16.49 13.617 15.7979 9.18063 15.7979Z"
          stroke="#002438"
          stroke-opacity="0.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.18071 11.9866C12.0767 11.9866 14.424 9.6393 14.424 6.74331C14.424 3.84732 12.0767 1.5 9.18071 1.5C6.28587 1.5 3.93851 3.84732 3.93851 6.74331C3.92821 9.62897 6.25832 11.9763 9.14513 11.9866H9.18071Z"
          stroke="#002438"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.18071 11.9866C12.0767 11.9866 14.424 9.6393 14.424 6.74331C14.424 3.84732 12.0767 1.5 9.18071 1.5C6.28587 1.5 3.93851 3.84732 3.93851 6.74331C3.92821 9.62897 6.25832 11.9763 9.14513 11.9866H9.18071Z"
          stroke="#002438"
          stroke-opacity="0.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.8867 8.29419V12.897"
          stroke="#002438"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.8867 8.29419V12.897"
          stroke="#002438"
          stroke-opacity="0.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.2352 10.5957H17.5405"
          stroke="#002438"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.2352 10.5957H17.5405"
          stroke="#002438"
          stroke-opacity="0.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span *ngIf="addNew || !isConnected; else editTitle">
        {{ isNonAnalystUser ? "Add New Contact" : "Add new connection for " + selectedInternal?.name + "." }}
      </span>
      <ng-template #editTitle>
        {{ isNonAnalystUser ? "Edit Contact" : "Edit connection for " + selectedInternal?.name + "." }}
      </ng-template>
    </span>
  </ng-container>

  <ng-container modal-content>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="w-100" *ngIf="formLoaded">
      <fieldset [disabled]="formDisabled">
        <div class="grid grid-no-tb-gutter">
          <div class="col-12 mg-b-16" *ngIf="!isNonAnalystUser">
            <label class="block" for="select-user">Select BPC contact</label>
            <p-dropdown
              id="select-user"
              styleClass="w-100"
              (onChange)="onSelectedInternalChange()"
              [(ngModel)]="selectedInternal"
              [options]="allInternalUsers"
              [ngModelOptions]="{ standalone: true }"
              optionLabel="name"
            ></p-dropdown>
          </div>
          <div class="col-6 mg-b-16">
            <label for="linkedin" class="block">
              LinkedIn <sup class="text-red">*</sup>
            </label>
            <input
              [(ngModel)]="formData.externalUrl"
              #externalUrl="ngModel"
              (focusout)="onFocusOutLinkedIn(externalUrl)"
              name="externalUrl"
              required
              autocapitalize="none"
              pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})"
              class="w-100"
              id="linkedin"
              placeholder="LinkedIn profile"
              [ngClass]="{ 'input-error': !externalUrl.valid && externalUrl.touched }"
              pInputText
            />
            <small *ngIf="!externalUrl.valid && externalUrl.touched" class="p-error block error">
              {{ externalUrl.errors?.['pattern'] ? 'invalid url' : null }}
            </small>
          </div>

          <div class="col-6 mg-b-16">
            <label for="position" class="block">
              Position <sup class="text-red">{{ isNonAnalystUser ? "*" : "" }}</sup>
            </label>
            <input
              [(ngModel)]="formData.position"
              inputmode="url"
              name="position"
              [required]="isNonAnalystUser ? true : false"
              [value]="formData.position"
              class="w-100"
              id="position"
              placeholder="Position"
              pInputText
              #position="ngModel"
              [ngClass]="{ 'input-error': !position.valid && position.touched }"
            />
          </div>

          <div class="col-6 mg-b-16">
            <label for="first-name" class="block">
              First name <sup class="text-red">{{ isNonAnalystUser ? "" : "*" }}</sup>
            </label>
            <input
              [(ngModel)]="formData.firstName"
              name="firstName"
              [required]="isNonAnalystUser ? false : true"
              #fName="ngModel"
              class="w-100"
              id="first-name"
              placeholder="First name"
              pInputText
              [ngClass]="{ 'input-error': !fName.valid && fName.touched }"
            />
          </div>
          <div class="col-6 mg-b-16">
            <label for="last-name" class="block">
              Last name <sup class="text-red">{{ isNonAnalystUser ? "" : "*" }}</sup>
            </label>
            <input
              [(ngModel)]="formData.lastName"
              name="lastName"
              [required]="isNonAnalystUser ? false : true"
              #lName="ngModel"
              class="w-100"
              id="last-name"
              placeholder="Last name"
              pInputText
              [ngClass]="{ 'input-error': !lName.valid && lName.touched }"
            />
          </div>
          <div class="col-6 mg-b-16">
            <label for="phone" class="block">
              Phone
            </label>
            <input
              [(ngModel)]="formData.workPhone"
              inputmode="tel"
              name="workPhone"
              pattern="[- +()0-9]+"
              #wPhone="ngModel"
              class="w-100"
              id="phone"
              placeholder="Phone number"
              pInputText
            />
            <small *ngIf="!wPhone.valid && wPhone.touched" class="p-error block error">invalid phone</small>
          </div>
          <div class="col-6 mg-b-16">
            <label for="mail" class="block">
              Mail
            </label>
            <input
              [(ngModel)]="formData.workEmail"
              inputmode="email"
              (focusout)="onFocusOutEmail(wEmail)"
              (ngModelChange)="emailInUseByAnotherCompany = null"
              name="workEmail"
              #wEmail="ngModel"
              autocapitalize="none"
              class="w-100"
              id="mail"
              placeholder="Email address"
              pInputText
              email
            />
            <small *ngIf="emailInUseByAnotherCompany || (!wEmail.valid && wEmail.touched)" class="p-error block error">
              <span *ngIf="emailInUseByAnotherCompany"
                >Email already in use for another
                <a class="font-bold" target="_blank" [routerLink]="'company/' + emailInUseByAnotherCompany"
                  >company</a
                ></span
              >
              <span *ngIf="!wEmail.valid && wEmail.touched && !emailInUseByAnotherCompany">{{
              wEmail.errors?.['email'] ? 'invalid email' : null }}</span>
            </small>
          </div>
          <div class="col-6 mg-b-30" *ngIf="!isNonAnalystUser">
            <label for="level" class="block">Connection level</label>
            <!-- <p-dropdown [disabled]="formDisabled" [(ngModel)]="formData.level" name="level"
            (onChange)="onLevelChange(toNumber(formData.level))" optionLabel="label" optionValue="value" id="level"
            styleClass="w-100" [options]="connectionLevelOptions"></p-dropdown> -->

            <div class="flex flex-wrap gap-3">
              <div class="flex align-items-center">
                <p-radioButton
                  name="level"
                  value="1"
                  [(ngModel)]="formData.level"
                  inputId="ingredient1"
                  (onChange)="onLevelChange(toNumber(formData.level))"
                ></p-radioButton>
                <label for="ingredient1" class="ml-2 inline-label">1st</label>
              </div>

              <div class="flex align-items-center">
                <p-radioButton
                  name="level"
                  value="2"
                  [(ngModel)]="formData.level"
                  inputId="ingredient2"
                  (onChange)="onLevelChange(toNumber(formData.level))"
                ></p-radioButton>
                <label for="ingredient2" class="ml-2 inline-label">2nd</label>
              </div>
            </div>
          </div>
          <div
            class="col-6"
            *ngIf="
              !isNonAnalystUser &&
              toNumber(formData.level) === 2 &&
              secondLevelConnections.length > 0 &&
              !isNonAnalystUser
            "
          >
            <div class="w-full">
              <label for="count" class="block">Connections count ({{ secondLevelConnections.length }})</label>
              <!-- <p-avatarGroup styleClass="mb-3">
                <p-avatar
                  *ngFor="let user of secondLevelConnections"
                  styleClass="orange cursor-pointer"
                  (click)="editSecondConnection(user.externalUrl)"
                  [label]="user.firstName + ' ' + user.lastName | initials"
                  size="large"
                  shape="circle"
                ></p-avatar>
              </p-avatarGroup> -->
              <p-avatarGroup styleClass="mb-3">
                <p-avatar
                  *ngFor="let user of secondLevelConnections | slice: 0:6"
                  styleClass="orange cursor-pointer"
                  (click)="editSecondConnection(user.externalUrl)"
                  [overlayPanelTooltip]="userNameTooltip"
                  [label]="user.firstName + ' ' + user.lastName | initials"
                  size="large"
                  shape="circle"
                >
                  <p-overlayPanel #userNameTooltip>
                    <p>{{ user.firstName + ' ' + user.lastName }}</p>
                  </p-overlayPanel>
                </p-avatar>
                <p-avatar
                  styleClass="grey"
                  *ngIf="secondLevelConnections.length > 6"
                  size="large"
                  shape="circle"
                  [overlayPanelTooltip]="allUserNameTooltip"
                  >+{{(secondLevelConnections.length) - 6}}
                </p-avatar>
              </p-avatarGroup>

              <p-overlayPanel #allUserNameTooltip>
                <p 
                  class="second-level-connection-p-tag mb-2"
                  *ngFor="let user of secondLevelConnections"
                  (click)="editSecondConnection(user.externalUrl)"
                >
                  {{ user.firstName + ' ' + user.lastName }}
                </p>
              </p-overlayPanel>
            </div>
          </div>
        </div>
      </fieldset>

      <form
        *ngIf="toNumber(formData.level) === 2 && !isNonAnalystUser"
        (ngSubmit)="on2ndLevelSubmit(connectionForm)"
        #connectionForm="ngForm"
      >
        <fieldset [disabled]="formDisabled">
          <div class="grid grid-no-tb-gutter">
            <div class="col-12 mg-b-30">
              <hr class="hr" />
            </div>
            <div class="col-12 mg-b-30">
              <div class="grid grid-no-tb-gutter flex justify-content-between">
                <div class="col flex align-items-center">
                  <h4 class="sec-head">Add new 2<sup>nd</sup> level connection.</h4>
                </div>
                <div class="col flex align-items-center flex-w-auto">
                  <a
                    class="lbl-clear-filter mgr-0"
                    title="Delete Connection"
                    *ngIf="externalUrlSecondConnection?.value"
                    (click)="removeSecondConnection(externalUrlSecondConnection?.value); connectionForm.reset()"
                    >Delete Connection</a
                  >
                  <a class="lbl-clear-filter m-rl-2" *ngIf="externalUrlSecondConnection?.value">|</a>
                  <a class="lbl-clear-filter mgr-0" title="Clear All" (click)="clearData()">Clear All</a>
                </div>
              </div>
            </div>
            <div class="col-6 mg-b-16">
              <label for="linkedin" class="block">
                LinkedIn <sup class="text-red">*</sup>
              </label>
              <input
                [(ngModel)]="secondLevelFormData.externalUrl"
                #externalUrlSecondConnection="ngModel"
                (focusout)="onFocusOutSecondLevelLinkedIn(externalUrlSecondConnection)"
                name="externalUrl"
                required
                autocapitalize="none"
                [ngClass]="{ 'input-error': !externalUrlSecondConnection.valid && externalUrlSecondConnection.touched }"
                pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})"
                class="w-100"
                id="linkedin"
                placeholder="LinkedIn profile"
                pInputText
              />
              <small
                *ngIf="!externalUrlSecondConnection.valid && externalUrlSecondConnection.touched"
                class="p-error block error"
              >
                {{ externalUrlSecondConnection.errors?.['pattern'] ? 'invalid url' : null }}
              </small>
            </div>
            <div class="col-6 mg-b-16">
              <label for="mail" class="block"> Mail </label>
              <input
                [(ngModel)]="secondLevelFormData.workEmail"
                inputmode="email"
                (focusout)="onFocusOutSecondLevelEmail(wEmailSecondConnection)"
                name="workEmail"
                #wEmailSecondConnection="ngModel"
                autocapitalize="none"
                class="w-100"
                id="mail"
                placeholder="Email address"
                pInputText
                email
              />
            </div>
            <div class="col-6 mg-b-16">
              <label for="first-name" class="block"> First name <sup class="text-red">*</sup> </label>
              <input
                [(ngModel)]="secondLevelFormData.firstName"
                name="firstName"
                required
                #fNameSecondLevel="ngModel"
                class="w-100"
                [ngClass]="{ 'input-error': !fNameSecondLevel.valid && fNameSecondLevel.touched }"
                id="first-name"
                placeholder="First name"
                pInputText
              />
            </div>
            <div class="col-6 mg-b-16">
              <label for="last-name" class="block"> Last name <sup class="text-red">*</sup> </label>
              <input
                [(ngModel)]="secondLevelFormData.lastName"
                [ngClass]="{ 'input-error': !lNameSecondLevel.valid && lNameSecondLevel.touched }"
                name="lastName"
                required
                #lNameSecondLevel="ngModel"
                class="w-100"
                id="last-name"
                placeholder="Last name"
                pInputText
              />
            </div>
            <div class="col-6 mg-b-16">
              <label for="phone" class="block">
                Phone
              </label>
              <input
                [(ngModel)]="secondLevelFormData.workPhone"
                inputmode="tel"
                name="workPhone"
                pattern="[- +()0-9]+"
                #wPhone="ngModel"
                class="w-100"
                id="phone"
                placeholder="Phone number"
                pInputText
              />
              <small *ngIf="!wPhone.valid && wPhone.touched" class="p-error block error">invalid phone</small>
            </div>
            <div class="col-6 mg-b-16">
              <label for="position" class="block">Position</label>
              <input
                [(ngModel)]="secondLevelFormData.position"
                inputmode="url"
                name="position"
                [value]="secondLevelFormData.position"
                class="w-100"
                id="position"
                placeholder="Position"
                pInputText
              />
            </div>
            <div
              class="col-12 mg-b-16 flex justify-content-between"
              [ngClass]="{ 'extension-layout-fix': isExtension }"
            >
              <button
                pButton
                class="button-dark"
                [disabled]="!connectionForm.valid || emailInUseByAnotherCompany"
                type="submit"
                [ngClass]="{ 'opacity-30': !connectionForm.valid || emailInUseByAnotherCompany }"
              >
                {{ secondLevelEditMode ? "Update" : "Save" }} 2nd level connection
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      <div class="p-dialog-footer">
        <button pButton type="button" (click)="closeEditPanel(f, $event)">Cancel</button>
        <button
          pButton
          *ngIf="!addNew"
          class="button-dark"
          [disabled]="!f.valid || emailInUseByAnotherCompany"
          type="submit"
          [ngClass]="{ 'opacity-30': !f.valid || emailInUseByAnotherCompany }"
        >
          Save changes
        </button>
        <button
          pButton
          *ngIf="addNew"
          class="button-dark"
          [disabled]="!f.valid || emailInUseByAnotherCompany || connectionsCount < 1"
          type="submit"
          [ngClass]="{ 'opacity-30': !f.valid || emailInUseByAnotherCompany || connectionsCount < 1 }"
        >
          {{ isNonAnalystUser ? "Save Contact" : "Add new connection" }}
        </button>
      </div>
    </form>
  </ng-container>
  <!-- editConnection && -->
</bpc-custom-modal>

<p-dialog
  contentStyleClass="w-500 "
  [(visible)]="displayConfirmationModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  header="Warning!"
>
  <div class="grid">
    <div class="col-12">
      <p class="modal-content-msg">Your unsaved data will be lost.</p>
      <br />
      <p class="modal-content-msg">Are you sure you want to leave this page?</p>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button pButton (click)="closeConfirmationPanel()">Cancel</button>
    <button pButton (click)="closeConnectionPanel($event)" class="button-dark">Close</button>
  </ng-template>
</p-dialog>
