import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@models/nullable';

@Pipe({
  name: 'emailRecipients'
})
export class EmailRecipientsPipe implements PipeTransform {

  transform(value: string): string[] {
    if (value === null) {
      return []
    }
    const splittedRecipients = value.split(',');
    return splittedRecipients;
  }
}
