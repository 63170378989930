import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from "@angular/core";
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from "@angular/platform-browser";
import { DatePipe, DecimalPipe } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TopNavigationComponent } from "./components/top-navigation/top-navigation.component";
import { BpcNumberPipe } from "@shared/pipes/number/bpc-number.pipe";
import { CompanyDetailsComponent } from "@core/components/company-details/company-details.component";
import { SocialIconComponent } from "@shared/components/social-icon/social-icon.component";
import { InfoMessageComponent } from "@shared/components/info-message/info-message.component";
import { RevenueChangeComponent } from "@shared/components/revenue-change/revenue-change.component";
import { ProfileBadgeComponent } from "@shared/components/profile-badge/profile-badge.component";
import { PositionFilterPipe } from "@core/pipes/position-filter.pipe";
import { ActivityDataComponent } from "@core/components/activity-data/activity-data.component";
import { RelationshipDataComponent } from "@core/components/relationship-data/relationship-data.component";
import { PrimeNgModule } from "@core/modules/prime-ng/prime-ng.module";
import { SkeletonModule } from 'primeng/skeleton';
import { EditorModule } from 'primeng/editor';
import { AvatarComponent } from "@shared/components/avatar/avatar.component";
import { ProductScoreComponent } from "@shared/components/g2-product-score/g2-product-score.component";
import { InitialsPipe } from "@core/pipes/initials.pipe";
import { InteractionIconPipe } from "@core/pipes/interaction-icon.pipe";
import { MainFilterComponent } from "@core/components/main-filter/main-filter.component";
import { ZonesDirective } from "@core/directives/zones.directive";
import { InvestorsDirective } from "@core/directives/investors.directive";
import { FilterCountPipe } from "@core/pipes/filter-count.pipe";
import { ConnectionPanelComponent } from "@shared/components/connection-panel/connection-panel.component";
import { DialogDirective } from "@core/directives/dialog.directive";
import { RoundTypePipe } from "@core/pipes/round-type.pipe";
import { CheckboxDirective } from "@core/directives/checkbox/checkbox.directive";
import { ModalTitlePipe } from "@core/pipes/tabs/modal-title.pipe";
import { NegativeButtonLabelPipe } from "@core/pipes/tabs/negative-button-label.pipe";
import { PositiveButtonLabelPipe } from "@core/pipes/tabs/positive-button-label.pipe";
import { AuthComponent } from "@core/auth/auth.component";
import { HomeComponent } from "@core/components/home/home.component";
import { LatestInteractionComponent } from "@shared/components/latest-interaction/latest-interaction.component";
import { TimeAgoPipe } from "@core/pipes/time-ago.pipe";
import { MomentModule } from "ngx-moment";
import { CompanyStatusDirective } from "@core/directives/company-status.directive";
import { FilterLabelsComponent } from "@core/components/filter-labels/filter-labels.component";
import { FilterLabelsPipe } from "@core/directives/filter-labels.pipe";
import { NotFoundComponent } from "@core/components/not-found/not-found.component";
import { UrlTransformPipe } from "@core/pipes/url-transform.pipe";
import { IndustriesDirective } from "@core/directives/industries.directive";
import { StatusPipe } from "@core/pipes/tabs/status.pipe";
import { EnrichmentStatusPipe } from "@core/pipes/tabs/enrichment-status.pipe";
import { EnrichmentCreatorPipe } from "@core/pipes/tabs/enrichment-creator.pipe";
import { EnrichmentCreateDatePipe } from "@core/pipes/tabs/enrichment-createDate.pipe";
import { EnrichmentAssigneePipe } from "@core/pipes/tabs/enrichment-assignee.pipe";
import { EnrichmentClassesPipe } from "@core/pipes/tabs/enrichment-classes.pipe";
import { GridRowBackgroundPipePipe } from "@core/pipes/tabs/grid-row-background.pipe";
import { CompanyDetailsDirective } from "@core/directives/sidebar/company-details.directive";
import { DealCloudCompanyComponent } from "@shared/components/deal-cloud-company/deal-cloud-company.component";
import { InvestorsFilterPipe } from "@core/pipes/investors-filter.pipe";
import { InvestorRolePipe } from "@core/pipes/investor-role.pipe";
import { LongTouchDirective } from "@shared/directives/long-touch.directive";
import { CompanyDetailsUrlPipe } from "@core/pipes/company-details-url.pipe";
import { InteractionDetailsComponent } from "@core/components/interaction-details/interaction-details.component";
import { OverlayPanelTooltipDirective } from "@core/directives/overlay-panel-tooltip.directive";
import { CompanyDetailsEnrichmentClassPipe } from "@core/pipes/tabs/company-details-enrichment-class.pipe";
import { CompanySectorsPipe } from "@core/pipes/company-sectors.pipe";
import { CompanyLocationPipe } from "@core/pipes/company-location.pipe";
import { SocialLinksPipe } from "@core/pipes/social-links.pipe";
import { MessageService } from "primeng/api";
import { OwnersDirective } from "@core/directives/owners.directive";
import { WebsiteLinkPipe } from "@core/pipes/website-link.pipe";
import { HideColumnOnTabletPipe } from "@core/pipes/tabs/hide-column-on-tablet.pipe";
import { AutoSearchComponent } from "@core/components/auto-search/auto-search.component";
import { EmailRecipientsPipe } from "@core/pipes/email-recepients.pipe";

import * as Hammer from "hammerjs";
import { AuthConfigModule } from "@core/auth/auth-config.module";
import { VotingDatePipe } from "@core/pipes/tabs/voting-date.pipe";
import { VotingDescriptionPipe } from "@core/pipes/tabs/voting-description.pipe";
import { VotingSummaryPipe } from "@core/pipes/tabs/voting-summary.pipe";
import { VotingStatsComponent } from "@core/components/voting-stats/voting-stats.component";
import { VotingClassesWorkflowPipe } from "@core/pipes/tabs/voting-classes-workflow.pipe";
import { VotingClassesScorePipe } from "@core/pipes/tabs/voting-classes-score.pipe";
import { VotingDisabledPipe } from "@core/pipes/tabs/voting-disabled.pipe";
import { VotingSummaryEnablePipe } from "@core/pipes/tabs/voting-summary-enable.pipe";
import { MobileVoteComponent } from "@core/components/mobile-vote/mobile-vote.component";
import { SliderModule } from "primeng/slider";
import { InlineEditComponent } from "@shared/components/inline-edit/inline-edit.component";
import { AutoFocusDirective } from "@core/directives/auto-focus.directive";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TagsDirective } from "@core/directives/tags.directive";
import { TextareaMaxLengthDirective } from "@shared/directives/max-length/textarea-max-length.directive";
import { MaxLengthComponent } from "@shared/directives/max-length/max-length.component";
import { CoveragePersonsWithPodLeaderPipe } from "@core/pipes/coverage-persons-with-pod-leader.pipe";
import { ChipsDirective } from "@core/directives/chips.directive";
import { HistoryDataComponent } from "@core/components/history-data/history-data.component";
import { LinkedInLinkPipe } from "@core/pipes/linked-in-link.pipe";
import { CircularDownloadProgressComponent } from "@core/components/circular-download-progress/circular-download-progress.component";
import { ExtensionComponent } from "@core/components/extension/extension.component";
import { CompanyNameValidatorDirective } from "@core/directives/company-name-validator.directive";
import { TimeBasedTriggerOccurrencesDirective } from "@core/directives/dropdown/time-based-trigger-occurrences.directive";
import { DataBasedTriggerComparisonValuesDirective } from "@core/directives/dropdown/data-based-trigger-comparison-values.directive";
import { CompanyScoreComponent } from "@core/components/company-score/company-score.component";
import { WithoutEmailSubaddressPipe } from "@core/pipes/without-email-subaddress.pipe";
import { MobileTabsComponent } from "./components/mobile-tabs/mobile-tabs.component";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { SidebarModule } from "primeng/sidebar";
import { PodLeaderPipe } from "@core/pipes/pod-leader.pipe";
import { WebTabsComponent } from "./components/web-tabs/web-tabs.component";
import { CompanyTypeDirective } from "@core/directives/company-type.directive";
import { RecurringWeeklyDirective } from "@core/directives/dropdown/recurring-weekly.directive";
import { RecurringMonthlyDirective } from "@core/directives/dropdown/recurring-monthly.directive";
import { NotificationsBellComponent } from "./components/top-navigation/notifications-bell/notifications-bell.component";
import { NotificationDescriptionPipe } from "@core/pipes/notification-description.pipe";
import { NotificationsIconComponent } from "@core/components/notifications-icon/notifications-icon.component";
import { UpdateOnChangeInputNumberDirective } from "@core/directives/update-on-change-input-number.directive";
import { CustomTagComponent } from "@core/components/custom-tag/custom-tag.component";
import { EditCustomTagComponent } from "@core/components/edit-custom-tag/edit-custom-tag.component";
import { TagsAutocompleteDirective } from "@core/directives/tags-autocomplete.directive";
import { MetaTagsComponent } from "./components/top-navigation/meta-tags/meta-tags.component";
import { CompaniesDirective } from "@core/directives/companies.directive";
import { VotingCheckSizePipe } from "@core/pipes/tabs/voting-checkSize.pipe";
import { AvatarOutreachOwnerComponent } from "@shared/components/avatar-outreachowner/avatar-outreachowner.component";
import { MenuNativeComponent } from "@shared/components/menu-native/menu-native.component";
import { VotingCurrentUserVotedPipe } from "@core/pipes/tabs/voting-current-user-voted.pipe";
import { SelectButtonModule } from "primeng/selectbutton";
import { PasswordModule } from "primeng/password";
import { NgSelectModule } from "@ng-select/ng-select";
import { DropdownSearchComponent } from "@core/components/custom-dropdown-search/custom-dropdown-search.component";
import { InteractionHistoryComponent } from "@core/components/interaction-history/interaction-history.component";
import { CompanyPICDataComponent } from "@core/components/company-pic-data/company-pic-data.component";
import { SearchPannelItemComponent } from "@core/components/search-pannel-item/search-pannel-item.component";
import { MenuNativePrimeNgComponent } from "@shared/components/menu-native-primeng/menu-native-primeng.component";
import { ExtensionPreviewComponent } from "./components/extension-preview/extension-preview.component";
import { CustomModalComponent } from "@core/components/custom-modal/custom-modal.component";
import { GridComponent } from './components/grid/grid.component';
import { GridTabsComponent } from './components/grid-tabs/grid-tabs.component';
import { GridLoaderLineComponent } from "@shared/components/grid-loader-line/grid-loader-line.component";
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { NotificationAlertsComponent } from "./components/top-navigation/notification-alerts/notification-alerts.component";
import { AIPrepReportComponent } from './core/components/ai-prep-report/ai-prep-report.component';
import { aiPrepReportIconPipe } from "@core/pipes/ai-prep-report-icon.pipe";
import { voterDesignationPipe } from "@core/pipes/voter-designation.pipe";
import { PortfolioUpdateComponent } from "@core/components/portfolio-update/portfolio-update.component";
import { ProspectUpdateComponent } from './core/components/prospect-update/prospect-update.component';
import { IrUpdateComponent } from './core/components/ir-update/ir-update.component';
import { NewsletterUpdateComponent } from './core/components/newsletter-update/newsletter-update.component';
import { AdminAlertComponent } from './core/components/admin-alert/admin-alert.component';
import { ImageUploaderComponent } from './core/components/image-uploader/image-uploader.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ChatbotComponent } from './core/components/chatbot/chatbot.component';
import { HorizontalBarComponent } from './core/components/horizontal-bar/horizontal-bar.component';
import { DeleteVotePopupComponent } from './core/components/delete-vote-popup/delete-vote-popup.component';
import { HighchartsChartModule } from "highcharts-angular";
import { HeatMapComponent } from "./core/components/heatmap/heatmap.component";
import { HeatmapGridComponent } from './components/heatmap-grid/heatmap-grid.component';
import { InteractionsChartComponent } from "./core/components/interactions-chart/interactions-chart.component";
import { PieChartComponent } from './components/pie-chart/pie-chart.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopNavigationComponent,
    BpcNumberPipe,
    CompanyDetailsComponent,
    SocialIconComponent,
    InfoMessageComponent,
    RevenueChangeComponent,
    ProfileBadgeComponent,
    PositionFilterPipe,
    ActivityDataComponent,
    RelationshipDataComponent,
    AvatarComponent,
    GridLoaderLineComponent,
    AvatarOutreachOwnerComponent,
    ProductScoreComponent,
    InitialsPipe,
    InteractionIconPipe,
    MainFilterComponent,
    ZonesDirective,
    InvestorsDirective,
    ConnectionPanelComponent,
    FilterCountPipe,
    DialogDirective,
    RoundTypePipe,
    CheckboxDirective,
    TimeBasedTriggerOccurrencesDirective,
    RecurringWeeklyDirective,
    RecurringMonthlyDirective,
    DataBasedTriggerComparisonValuesDirective,
    ModalTitlePipe,
    NegativeButtonLabelPipe,
    PositiveButtonLabelPipe,
    AuthComponent,
    LatestInteractionComponent,
    TimeAgoPipe,
    CompanyStatusDirective,
    FilterLabelsComponent,
    HeatMapComponent,
    DropdownSearchComponent,
    FilterLabelsPipe,
    NotFoundComponent,
    UrlTransformPipe,
    IndustriesDirective,
    UpdateOnChangeInputNumberDirective,
    StatusPipe,
    EnrichmentStatusPipe,
    EnrichmentCreatorPipe,
    EnrichmentCreateDatePipe,
    EnrichmentAssigneePipe,
    EnrichmentClassesPipe,
    GridRowBackgroundPipePipe,
    CompanyDetailsEnrichmentClassPipe,
    CompanyDetailsDirective,
    DealCloudCompanyComponent,
    InvestorsFilterPipe,
    InvestorRolePipe,
    LongTouchDirective,
    CompanyDetailsUrlPipe,
    InteractionDetailsComponent,
    InteractionHistoryComponent,
    CompanyPICDataComponent,
    OverlayPanelTooltipDirective,
    CompanySectorsPipe,
    CompanyLocationPipe,
    SocialLinksPipe,
    OwnersDirective,
    WebsiteLinkPipe,
    HideColumnOnTabletPipe,
    AutoSearchComponent,
    VotingDatePipe,
    VotingDescriptionPipe,
    VotingCheckSizePipe,
    VotingSummaryPipe,
    VotingStatsComponent,
    VotingClassesWorkflowPipe,
    VotingClassesScorePipe,
    VotingDisabledPipe,
    VotingSummaryEnablePipe,
    VotingCurrentUserVotedPipe,
    MobileVoteComponent,
    InlineEditComponent,
    AutoFocusDirective,
    TagsDirective,
    TextareaMaxLengthDirective,
    MaxLengthComponent,
    CoveragePersonsWithPodLeaderPipe,
    ChipsDirective,
    HistoryDataComponent,
    LinkedInLinkPipe,
    CircularDownloadProgressComponent,
    ExtensionComponent,
    MenuNativeComponent,
    MenuNativePrimeNgComponent,
    CompanyNameValidatorDirective,
    CompanyScoreComponent,
    WithoutEmailSubaddressPipe,
    MobileTabsComponent,
    PodLeaderPipe,
    WebTabsComponent,
    CompanyTypeDirective,
    NotificationsBellComponent,
    ExtensionPreviewComponent,
    NotificationDescriptionPipe,
    NotificationsIconComponent,
    CustomTagComponent,
    SearchPannelItemComponent,
    EditCustomTagComponent,
    TagsAutocompleteDirective,
    MetaTagsComponent,
    NotificationAlertsComponent,
    CompaniesDirective,
    CustomModalComponent,
    GridComponent,
    GridTabsComponent,
    GenericModalComponent,
    AIPrepReportComponent,
    PortfolioUpdateComponent,
    aiPrepReportIconPipe,
    voterDesignationPipe,
    ProspectUpdateComponent,
    IrUpdateComponent,
    NewsletterUpdateComponent,
    AdminAlertComponent,
    ImageUploaderComponent,
    EmailRecipientsPipe,
    ChatbotComponent,
    HorizontalBarComponent,
    DeleteVotePopupComponent,
    HeatmapGridComponent,
    InteractionsChartComponent,
    PieChartComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    SkeletonModule,
    EditorModule,
    SelectButtonModule,
    PasswordModule,
    MomentModule,
    AuthConfigModule,
    SliderModule,
    InputTextareaModule,
    AvatarModule,
    AvatarGroupModule,
    SidebarModule,
    NgSelectModule,
    ImageCropperModule,
    HighchartsChartModule
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    UrlTransformPipe,
    InvestorRolePipe,
    MessageService,
    VotingDescriptionPipe,
    VotingCheckSizePipe,
    VotingDatePipe,
    BpcNumberPipe,
    VotingSummaryPipe,
    VotingClassesWorkflowPipe,
    StatusPipe,
    EnrichmentStatusPipe,
    aiPrepReportIconPipe,
    voterDesignationPipe,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
