import { Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from '@core/models/company';
import { UserRole } from '@core/models/user';
import { TabData } from '@core/static/tabs';
import { MessageService } from 'primeng/api';
import moment from 'moment';
import { CompanyType } from '@core/models/company-type';
import { Observable, Subject, catchError, concatMap, debounceTime, distinctUntilChanged, of, takeUntil } from 'rxjs';
import { DestroyObservable } from 'src/app/rxjs/DestroyObservable';
import { TabUtilsService } from 'src/app/services/tab-utils.service';
import { NgModel } from '@angular/forms';
import { Editor, EditorTextChangeEvent } from 'primeng/editor';
import { CompanyProspectService } from '@core/services/company-prospect.service';
import { GroupType } from '@core/models/auxiliary-company-models';
import { AuthService } from '@core/auth/auth.service';
import { Prospect } from '../prospect-update/prospect-update.component';


@Component({
  selector: 'bpc-newsletter-update',
  templateUrl: './newsletter-update.component.html',
  styleUrls: ['./newsletter-update.component.scss']
})
export class NewsletterUpdateComponent {

  isPublishLoading: boolean = false;
  loading: boolean = false;
  @Input() tabs!: Record<UserRole, TabData<Company>[]>;
  value: string | null = null;
  podGroups: Prospect[] = [];
  publishedDates: any = [];
  selectedPublishedDate: Date | null = null;
  companyTypes: CompanyType[] = [];
  @ViewChild('pdfDiv', { static: false }) pdfDiv!: ElementRef<HTMLDivElement>;
  @ViewChildren(Editor) editors!: QueryList<Editor>;
  visible: boolean = false;
  previewObj:any;
  modalLoading: boolean = false;
  private updateQueue: Subject<{ index: number; updates: string }> = new Subject();


  getIsSubmitDisabled(index: number, updates: NgModel) {
    if (this.selectedPublishedDate){
      const selectedDate = new Date(this.selectedPublishedDate);
      return selectedDate != new Date() && !this.podGroups[index].isSubmitLoading;
    }
    return !updates.dirty;
  }

  get isInvestmentCommitteeAdmin() {
    return this.authService.investmentcommitteeadmin;
  }

  get isPublishButtonVisible() {
    // const user = this.authService.user.getValue();
    // if(user){
    //   return user.email === 'jarvis@bpc.com'
    // }
    // return false;
    return true
  }

  get isInvestorRelation() {
    return this.authService.isInvestorRelation;
  }

  get isPublishDisabled() {
    // Return true if all the updates are empty
    return this.podGroups.every((pod) => !pod.updates) || this.selectedPublishedDate ? new Date(this.selectedPublishedDate!) != new Date() : false || this.isPublishLoading;
  }

  get isHistory() {
    return this.selectedPublishedDate ? new Date(this.selectedPublishedDate!) != new Date() : false;
  }

  constructor(
    protected router: Router,
    public prospectService: CompanyProspectService,
    private messageService: MessageService,
    private readonly destroy$: DestroyObservable,
    private tabUtilsService: TabUtilsService,
    private authService: AuthService,
  ) {
    
  }

  ngOnInit() {
    // this.getPublishedTimeStamps();
    this.getProspectUpdates();
    this.updateQueue
    .pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      concatMap(({ index, updates }) =>
        this.executeUpdateProspect(index, updates)
      ),
      takeUntil(this.destroy$)
    )
    .subscribe();
  }

  private executeUpdateProspect(index: number, updates: string) {
    return new Observable((observer) => {
      this.podGroups[index].isSubmitLoading = true;
      this.prospectService
        .updateProspect(
          this.podGroups[index].id,
          updates,
          this.podGroups[index].previousUpdates
        )
        .pipe(
          catchError((error) => {
            this.podGroups[index].isSubmitLoading = false;
            this.displayToastMessage(
              "error updating news. Please try again later",
              "error"
            );
            return of(null);
          })
        )
        .subscribe((response: any) => {
          if (response.data?.result) {
            this.podGroups[index].previousUpdates = updates;
            this.podGroups[index].isUpdateEmpty = false;
            this.podGroups[index].isSubmitLoading = false;
            this.displayToastMessage("News updated successfully", "success");
            observer.next();
            observer.complete();
          } else if (response.error) {
            this.podGroups[index].isSubmitLoading = false;
            this.displayToastMessage(
              response.error.response?.message ??
                "error updating News. Please try again later",
              "error"
            );
            observer.next();
            observer.complete();
          }
        });
    });
  }

  getPublishedTimeStamps() {
    this.prospectService.getPublishedDateTimes(GroupType.Newsletter).subscribe((response: any) => {
      this.publishedDates = [{ label: 'Current', value: null }];
      if (response.data && response.data.data.length > 0) {
        let dates = response.data.data.map((item: any) => {
          return (
            {
              label: moment(item.publishedTimestamp).format('MM/DD/YYYY'),
              value: item.publishedTimestamp
            }
          )
        });
        dates = dates.sort((a: any, b: any) => {
          return new Date(b.value).getTime() - new Date(a.value).getTime();
        });
        this.publishedDates = [...this.publishedDates, ...dates];
      }
    });
  }

  getProspectUpdates(date?: Date) {
    this.loading = true;
    this.prospectService.getUpdates(GroupType.Newsletter, date).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.data.length > 0) {
        this.podGroups = response.data.map((item: any) => {
          // const podMembers = item.group.name.split(',');
          // podMembers[0] = podMembers[0].replace('Pod: ', '');
          //write code to sort pod members based on isAdmin boolean
          const sortedPodMembers = item.group.members.sort((a: any, b: any) => {
            return b.isAdmin - a.isAdmin;
          });
          const isUpdateEmpty = item.updates === '' || item.updates === null;
          return ({...item, isSubmitLoading: false, isUpdateEmpty: isUpdateEmpty, previousUpdates: item.updates, sortedPodMembers});});
      }
      this.loading = false;

    });
  }

  onPublishDateChange(event: any) {
    this.getProspectUpdates(event.value);
  }

  publsihProspect() {
    if(this.podGroups.every((pod) => pod.isUpdateEmpty)) {
      this.displayToastMessage("There are no updates to publish. Please save updates first", "error");
      return;
    }
    this.isPublishLoading = true;
    this.prospectService.publishProspect(GroupType.Newsletter).pipe(
      catchError((error) => {
        this.isPublishLoading = false;
        this.displayToastMessage("error publishing updates. Please try again later", "error");
        return of(null);
      })).subscribe((response: any) => {
      if (response.data) {
        this.isPublishLoading = false;
        this.visible = false;
        this.displayToastMessage("News updated successfully", "success");
        this.getProspectUpdates();
        // this.getPublishedTimeStamps();
      }
    });

  }

  isImageSizeValid(imgTag: string) {
    const maxSizeInBytes = 500 * 1024; // 1MB
    const base64Match = imgTag.match(/src="data:image\/([a-zA-Z]*);base64,([^"]*)"/); // Extract base64 data
    if (base64Match) {
      const base64Data = base64Match[2];
      const binaryString = atob(base64Data);
      const fileSizeInBytes = binaryString.length;
      if (fileSizeInBytes > maxSizeInBytes) {
        return false;
      }
      return true;
    }
    return false;
  }

  limitText(event: EditorTextChangeEvent, index: number) {
    const limit = 10000;
    if (event.htmlValue) {
      let flag=0;
      const imgTags = event.htmlValue.match(/<img[^>]+>/g); // Find all img tags
      if (imgTags) {
        imgTags.forEach((imgTag: string) => {
          const isValid = this.isImageSizeValid(imgTag);
          if (!isValid) {
            this.displayToastMessage("Image size exceeds 400kB. Please try again with smaller image", "error");
            event.htmlValue = event.htmlValue.replace(imgTag, '');
            flag=1;
          }
        });
        if(flag==1){
          const editor = this.editors.toArray()[index];
          const quill = editor.getQuill();
          const delta = quill.clipboard.convert(event.htmlValue)
          quill.setContents(delta, 'silent');
          return;
        }
      }
    }
    if (event.htmlValue && event.htmlValue.length > limit) {
      this.podGroups[index].updates = `${event.htmlValue.substring(0, limit)}`;
      const editor = this.editors.toArray()[index];
      const quill = editor.getQuill();
      quill.deleteText(limit, quill.getLength());
    }
    this.updateQueue.next({ index, updates: event.htmlValue });
  }

  updateProspect(index: number, updates: string) {
    // if (this.companyPortfolios[index].updates) {
      
    // } else {
    //   this.companyPortfolios[index].isSubmitLoading = false;
    //   this.displayToastMessage("Updates are not valid", "error");
    // }

    if(this.podGroups[index].updates && !this.podGroups[index].updates.includes('<img src') && this.podGroups[index].updates.replace(/<[^>]*>/g, '') == ""){
      this.podGroups[index].updates = this.podGroups[index].updates.replace(/<[^>]*>/g, '');
    }

    this.podGroups[index].isSubmitLoading = true;
      this.prospectService.updateProspect(this.podGroups[index].id, this.podGroups[index].updates, this.podGroups[index].previousUpdates).pipe(
        catchError((error) => {
          this.podGroups[index].isSubmitLoading = false;
          this.displayToastMessage("error updating news. Please try again later", "error");
          return of(null);
        })).subscribe((response: any) => {
        if (response.data?.result) {
          this.podGroups[index].previousUpdates = this.podGroups[index].updates;
          // updates.control.markAsPristine();
          this.podGroups[index].isUpdateEmpty = false;
          this.podGroups[index].isSubmitLoading = false;
          this.displayToastMessage("News updated successfully", "success");
        }
        else if (response.error) {
          this.podGroups[index].isSubmitLoading = false;
          this.displayToastMessage(response.error.response?.message ?? "error updating news. Please try again later", "error");
        }
      });
  }

  private displayToastMessage(message: string, severity = "error", life = 3000) {
    this.messageService.add({
      key: "home-bc",
      severity,
      detail: message,
      life,
    });
  }

  onHide() {
    this.visible = false;
  }

  openModal() {
    this.visible = true;
    this.modalLoading = true;
    this.prospectService.getEmailPreview(GroupType.Newsletter).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.modalLoading = false;
      if (response.data?.result) {
        this.previewObj = response.data;
        let iframe: HTMLIFrameElement | null = document.getElementById("myIframe") as HTMLIFrameElement;
        if (iframe) {
          // TODO: this is a work around to fix the issue with iframe content styling and needs to be fixed
          let x: string = this.previewObj.data;
          // x = x.replace(/<h1>/g,"<h1 style='margin-bottom: 25px;'>");
          x = x.replace(/<h2>/g,"<h2 style='margin-bottom: 10px; margin-top: 12px;'>");
          x = x.replace(/<h4>/g,"<h4 style='margin-bottom: 6px; margin-top: 8px;'>");
          x = x.replace(/<body>/g,"<body style='line-height: 125%'>");
          iframe.srcdoc = x;
        }
      }
      else if (response.error) {
        this.displayToastMessage(response.error.response?.message ?? "error getting email preview. Please try again later", "error");
      }
    });
  }
}
