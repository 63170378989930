import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { EMPTY, fromEvent, mergeMap, takeUntil, tap } from 'rxjs';
import { DestroyObservable } from '../../rxjs/DestroyObservable';
import { Nullable } from '@models/nullable';

@Directive({
  selector: '[overlayPanelTooltip]',
  providers: [DestroyObservable]
})
export class OverlayPanelTooltipDirective implements OnInit, OnDestroy {
  private mouseOverPanel = false;
  @Input('overlayPanelTooltip') panel: Nullable<OverlayPanel> = null;
  @Input('overlayPanelTooltipDisabled') disabled = false;

  constructor(private readonly destroy$: DestroyObservable) {
  }

  ngOnInit() {
    this.panel?.onShow
      .pipe(
        takeUntil(this.destroy$),
        mergeMap(() => this.panel
          ? fromEvent(this.panel.container!, 'mouseenter')
            .pipe(
              takeUntil(fromEvent(this.panel.container!, 'mouseleave')
                .pipe(
                  tap(() => {
                    this.mouseOverPanel = false;
                    this.hidePanel();
                  })
                )
              )
            )
          : EMPTY
        )
      )
      .subscribe(() => {
        this.mouseOverPanel = true;
      });
  }

  ngOnDestroy() {
    this.panel?.hide();
  }

  @HostListener('mouseenter', ['$event'])
  @HostListener('touchstart', ['$event'])
  showPanel(event: Event) {
    if (!this.disabled) {
      this.panel?.show(event);
    }
  }

  @HostListener('mouseleave')
  hidePanel() {
    setTimeout(() => {
      if (!this.mouseOverPanel) {
        this.panel?.hide();
      }
    }, 300);
  }
}