import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { User } from '@models/user';
import { MessageService } from 'primeng/api';
import { ChromeExtensionService } from 'src/app/services/chrome-extension/chrome-extension.service';
import { CompanyHelperService } from '@core/services/company-helper.service';
import { takeUntil } from 'rxjs';
import { DestroyObservable } from '../../../rxjs/DestroyObservable';
import { MixpanelService } from '@core/services/mixpanel.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';

@Component({
  selector: 'bpc-extension',
  templateUrl: './extension.component.html',
  viewProviders: [DestroyObservable]
})
export class ExtensionComponent implements OnInit, OnDestroy {
  popStateListner: any;
  get user(): User {
    return this.authService.user.getValue()!;
  }

  get loading() {
    return this.chromeExtensionService.loading;
  }

  constructor(
    private chromeExtensionService: ChromeExtensionService,
    public authService: AuthService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private companyHelperService: CompanyHelperService,
    private readonly destroy$: DestroyObservable,
    private mixpanelService: MixpanelService,
    private companiesService: CompaniesService 
  ) {
  }

  ngOnInit(): void {

    this.chromeExtensionService.isExtension.next(true);
    this.chromeExtensionService.loading = true;
    this.getCompanyByDomain();
    this.popStateListner = window.addEventListener("popstate", () => {
      if (this.chromeExtensionService.isExtension)
        window.history.back();
    });
  }

  getCompanyByDomain() {
    this.mixpanelService.trackEvent('Extension_Viewed');
    const domain = this.route.snapshot.queryParams['domain'];
    const extensionVersion = this.route.snapshot.queryParams['extensionVersion'];
    localStorage.setItem('extensionVersion', extensionVersion);
    this.chromeExtensionService.getCompanyByDomain(domain)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if(response.error && response.statusCode === 401) {
          const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || this.router.url;
          this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
        }
        else if (response && response.data) {
          let companyId = response.data.id;
          if (companyId){
            this.companiesService.getCompanyDetails(companyId).subscribe(({ data }) => {
              if (data) {
                let companyDetails = data!;
                this.router.navigate(['/chatbot'], { queryParams: { companyDetails: JSON.stringify(companyDetails) } });
              }
              else {
                this.router.navigate(['/company']);
              }
              this.chromeExtensionService.loading = false;
            });
          }
        } else {
          this.router.navigate(['/company']);
          this.chromeExtensionService.loading = false;
        }
      });
  }

  ngOnDestroy() {
    window.removeEventListener("popstate", this.popStateListner);
  }
}
