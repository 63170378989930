import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { MessageService } from 'primeng/api';
import { BaseApiService } from 'src/app/api/base';


@Injectable({
  providedIn: 'root'
})
export class UserRolesService extends BaseApiService {
  userRoles: any[] = [];

  constructor(private httpClient: HttpClient, router: Router, authService: AuthService, messageService: MessageService) {
    super("/roles", authService, router, messageService);
    this.getAllRoles();
  }

  getUserRoles() {
    return this.get('');
  }

  getAllRoles(){ 
    this.getUserRoles().subscribe((res) => {
      this.userRoles = res.data;
    });
  }

}
