import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlow } from '@models/auxiliary-company-models';
import { Nullable } from '@models/nullable';
import { getActiveOrLastICVotingWorkflow } from '../../../utils/company-utils';

@Pipe({
  name: 'votingCheckSize'
})
export class VotingCheckSizePipe implements PipeTransform {

  transform(workflows: WorkFlow[]): Nullable<string> {
    let checkSize = getActiveOrLastICVotingWorkflow(workflows)?.checkSize;
    if (checkSize) {
      return `${Math.round(checkSize)}`;
    }
    return "";
  }
}
