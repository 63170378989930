import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, shareReplay } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ApiResponse, BaseApiService } from '../../api/base';
import { Zone } from '@models/zone';
import { AuthService } from '@core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends BaseApiService<Zone[]> {
  countries$ = this.get('/companies/countries').pipe(shareReplay(1));

  cities$: Observable<ApiResponse<Zone[]>> = this.get<string[]>('/companies/cities')
    .pipe(map(response => ({ ...response, data: response.data?.map(city => ({ name: city, code: city })) })))
    .pipe(shareReplay(1));

  regions$ = this.get('/regions/false').pipe(shareReplay(1));

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/locations', authService, router, messageService);
  }

  getCountries() {
    return this.get('/companies/countries');
  }

  getRegions() {
    return this.get('/regions/false');
  }

  getCities() {
    return this.get<string[]>('/companies/cities');
  }
}
