
<p-sidebar (onHide)="onHide()" [(visible)]="isVisible" #sidebar position="right" styleClass="w-25rem">
  <ng-template pTemplate="content">
    <section class="tags-sidebar">
      <div class="grid grid-no-tb-gutter flex justify-content-between flex-wrap mg-b-10">
        <div class="col flex-w-auto flex align-items-center">
          <i class="pi pi-bell alert-bell-icon mr-2"></i>
          <h3>Alerts</h3>
        </div>
      </div>

        <div class="grid grid-no-tb-gutter">
          <div class="col-12">
            <!-- class="max-w-[500px] max-h-[75vh] w-[82vw] md:w-[92vw] overflow-auto" -->
            <div class="alert-listing">
              <p *ngIf="notifications.length === 0" class="text-center select-none">You're all good...</p>
              <div *ngFor="let notification of notifications; trackBy: trackByNotificationId" #n class="hover:bg-gray-100 alert-company-list-item">
                <a
                  class="block p-2 rounded-sm"
                  (click)="markAsRead(notification)"
                  [routerLink]="notification.company | companyDetailsUrl"
                >
                  <strong class="alert-notification-read" [class.text-blue-600]="!notification.isRead" [class.font-bold]="!notification.isRead">
                    {{ notification.company.name }}
                  </strong>
                  <p class="alert-notification-read" [class.text-blue-600]="!notification.isRead">
                    {{ notification | notificationDescription }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
    </section>
  </ng-template>
</p-sidebar>
