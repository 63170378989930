import { Component, Input, OnChanges, Output, ViewChild, EventEmitter } from "@angular/core";
import { Nullable } from "@models/nullable";
import { ConnectedUser, Connection, Staff, User, UserRole } from "@models/user";
import { OverlayPanel } from "primeng/overlaypanel";
import { copyToClipboard } from "../../../utils/copy-to-clipboard";
import { DeviceDetectorService } from "ngx-device-detector";
import { AuthService } from "../../../core/auth/auth.service";
import { MessageService } from "primeng/api";
import { MenuItem } from "@shared/components/menu-native/menu-native-item";
import { DeleteUser } from "@core/models/forms";

@Component({
  selector: "bpc-profile-badge",
  templateUrl: "./profile-badge.component.html",
})
export class ProfileBadgeComponent implements OnChanges {
  @ViewChild("linkedInURLOverlayPanel") linkedInURLOverlayPanel!: OverlayPanel;

  @Input() badgeType: BadgeType = "extended";
  @Input() user: Nullable<User> = null;
  @Input() image!: string;
  @Input() width: number = 62;
  @Input() extendedUser!: Staff;
  @Input() internal?: ConnectedUser[];
  @Input() companyName!: string;
  @Output() onEditContact = new EventEmitter<Staff>();
  @Output() onRemoveContact = new EventEmitter<DeleteUser>();
  @Output() onRemoveConnection = new EventEmitter<Staff>();
  @Output() onMakeConnection = new EventEmitter<Staff>();
  @Output() toggleCompanyNavigation = new EventEmitter<boolean>();
  @Input() isNonAnalystUser?: boolean;

  @Input() isConnected!: boolean;

  user$ = this.authService.user;
  sortedInternal?: ConnectedUser[];
  selectedContact!: Staff;
  displayModal: boolean = false;
  deleteFromSystem: boolean = false;
  displayConnectionsModal: boolean = false;

  get userRole(): UserRole {
    return this.authService.user.getValue()!.role;
  }

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private deviceService: DeviceDetectorService
  ) {
    this.authService.user.subscribe((userData) => {
      this.isNonAnalystUser = (userData?.roles ? userData.roles.indexOf('analyst') < 0 : false) || this.userRole !== 'analyst';
    });
  }

  ngOnChanges() {
    this.sortProfiles();
    // this.sortedInternal?.reverse();
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get menuItems(): MenuItem[] {
    const removeTag: MenuItem = {
      label: "Remove",
      // icon: "icon-check-square",
      command: () => this.tagAction("remove"),
    };
    const editTag: MenuItem = {
      label: "Edit",
      // icon: "icon-enrich",
      command: () => this.tagAction("edit"),
    };
    const link: MenuItem = {
      label: "Link",
      //   icon: "icon-enrich",
      command: () => this.tagAction("link"),
    };

    const unlink: MenuItem = {
      label: "Unlink",
      //   icon: "icon-enrich",
      command: () => this.tagAction("unlink"),
    };
    let optionsArray = [editTag, removeTag];
    if (!this.isNonAnalystUser) {
      if (this.isConnected) {
        optionsArray.push(unlink);
      } else {
        optionsArray.push(link);
      }
    }
    return optionsArray;
  }

  toggleDeleteModal() {
    this.displayModal = !this.displayModal;
    if(this.displayModal) {
      this.toggleCompanyNavigation.emit(false);
    } else {
      this.toggleCompanyNavigation.emit(true);
    }
    this.deleteFromSystem = false;
  }

  removeUser() {
    this.onRemoveContact.emit({ id: this.selectedContact.id, deleteFromSystem: this.deleteFromSystem });
    this.toggleDeleteModal();
  }

  onMenuClick(contact: Staff) {
    this.selectedContact = contact;
  }

  isSecondLevelConnection(profile: ConnectedUser) {
    if (
      profile?.connections.length > 0 &&
      profile.connections[0].secondLevelConnections &&
      profile.connections[0].secondLevelConnections.length > 0
    ) {
      return true;
    }
    return false;
  }

  tagAction(action: string) {
    if (action === "remove") {
      this.toggleDeleteModal();
    } else if (action === "edit") {
      this.onEditContact.emit(this.selectedContact);
    } else if (action === "link") {
      this.onMakeConnection.emit(this.selectedContact);
    } else if (action === "unlink") {
      this.onRemoveConnection.emit(this.selectedContact);
    }
  }

  sortProfiles() {
    if (this.internal) {
      this.sortedInternal = [];

      for (let i = 0; i < this.internal.length; i++) {
        const connections: Connection[] = [];
        const primaryLevelConnections: Connection[] = [];

        this.internal[i].connections.forEach((connection) => {
          if (connection.companyPersonId === this.extendedUser.id) {
            if (connection.level === 1) {
              primaryLevelConnections.push(connection);
            } else {
              connections.push(connection);
            }
          }
        });

        if (connections.length) {
          this.sortedInternal?.push({
            ...this.internal![i],
            connections: connections,
          });
        }

        if (primaryLevelConnections.length) {
          this.sortedInternal = [
            {
              ...this.internal![i],
              connections: primaryLevelConnections,
            },
          ].concat(this.sortedInternal);
        }
      }

      if (this.sortedInternal) {
        this.sortedInternal?.sort(this.sortByEmptyConnectionsFirst);
        // for (let i = 0; i < this.sortedInternal?.length; i++) {
        //   this.sortedInternal.sort((a, b) => {
        //     if (
        //       (a.connections.length && a.connections[0].count == null) ||
        //       (b.connections.length && b.connections[0].count == null)
        //     ) {
        //       return 0;
        //     }
        //     return a.connections.length && a.connections.length
        //       ? a.connections[0].count! > b.connections[0].count!
        //         ? -1
        //         : 0
        //       : 0;
        //   });
        // }
      }
    }
  }

  copyEmail() {
    this.copyToClipboard(this.extendedUser.workEmail, "Email copied to clipboard.");
  }

  copyLinkedInProfileURL() {
    this.copyToClipboard(this.extendedUser.externalUrl, "LinkedIn profile URL copied to clipboard.");
  }

  private displayInfoToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "info", detail: message });
  }

  private displayErrorToastMessage() {
    this.messageService.add({
      key: "bc",
      severity: "error",
      detail: "Couldn't copy to clipboard. Please check the permissions.",
    });
  }

  private copyToClipboard(value: string, successMessage: string) {
    copyToClipboard(value)
      .then(() => {
        this.displayInfoToastMessage(successMessage);
      })
      .catch(() => this.displayErrorToastMessage());
  }

  sortByEmptyConnectionsFirst(a:ConnectedUser, b:ConnectedUser) {
    if(a.connections[0].secondLevelConnections && b.connections[0].secondLevelConnections ){
      if (a.connections[0].secondLevelConnections.length === 0 && b.connections[0].secondLevelConnections.length > 0) {
        return -1; // a comes first
      } else if (a.connections[0].secondLevelConnections.length > 0 && b.connections[0].secondLevelConnections.length === 0) {
        return 1; // b comes first
      } else {
        return 0; // no change in order
      }
    }
    else{
      return 0;
    }
  }
}

type BadgeType = "simple" | "extended";
