import { Directive, inject, Input } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { switchMap, takeUntil, tap } from 'rxjs';
import { DestroyObservable } from '../../rxjs/DestroyObservable';
import { CompaniesService } from '../../services/companies/companies.service';

@Directive({
  selector: 'p-autoComplete[tagsAutocomplete][companyId]',
  providers: [DestroyObservable]
})
export class TagsAutocompleteDirective {

  private destroy$ = inject(DestroyObservable);

  @Input() companyId!: number;

  constructor(autoComplete: AutoComplete, companiesService: CompaniesService) {
    autoComplete.field = 'name';
    autoComplete.dataKey = 'id';
    // autoComplete.appendTo = 'body';
    // autoComplete.multiple = true;
    autoComplete.completeMethod
      .pipe(tap(() => autoComplete.loading = true))
      .pipe(tap(() => autoComplete.show()))
      .pipe(switchMap(({ query }) => companiesService.getTags(this.companyId, query)))
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, error }) => {
        autoComplete.loading = false;
        if (data) {
          autoComplete.suggestions = data;
        }
        autoComplete.cd.markForCheck();
      });
  }
}