import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ApiResponse, BaseApiService } from '../../api/base';
import { BasicCompany, Company } from '@models/company';
import { MainFilterRaw } from '@models/main-filter-view';
import { AuthService } from '@core/auth/auth.service';
import { Tag } from '@models/tag';
import { Nullable } from '@core/models/nullable';
import { OperationResult } from '@core/models/operation-result';

export interface PagerData<T> {
  data: T[];
  count: number;
  totalCount: number;
  totalCountByAssignee: number;
}

export interface BasePagingFilter {
  pageNumber?: string;
  pageSize?: string;
}

export interface BaseSortingFilter {
  sortProperty?: string;
  sortDirection?: 'asc' | 'desc' | string;
}

export interface SavedSearch extends BasePagingFilter, BaseSortingFilter, MainFilterRaw {
}

interface Global {
  companies: BasicCompany[];
  lists: Tag[];
}
@Injectable({
  providedIn: 'root'
})
export class SavedSearchService extends BaseApiService<PagerData<Company>> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/companies/search', authService, router, messageService);
  }

  getSavedSearches(filter: SavedSearch): Observable<ApiResponse<PagerData<Company>>> {
    return this.get('/saved-filter', filter);
  }

  getSavedSearchById(id: Nullable<number> = null): Observable<ApiResponse<PagerData<Company>>> {
    return this.get(`/saved-filter/${id}`);
  }

  removeSavedSearch(id: Nullable<number> = null): Observable<ApiResponse<BasicCompany[]>> {
    return this.delete(`/saved-filter/${id}`);
  }

  upsertSavedSearch(filtersObject: any, savedFilterId?: number): Observable<ApiResponse<OperationResult>> {
    if (!savedFilterId) {
      return this.post(`/saved-filter`, null, filtersObject);
    } else {
      return this.put(`/saved-filter`, null, { ...filtersObject, savedFilterId });
    }
  }
}