<ng-container>
  <div class="grid grid-no-tb-gutter position-relative mb-20">
    <div class="col-12">
      <label class="w-100"> {{ label }} <sup *ngIf="required" class="text-red">*</sup></label>
      <!-- (scrollToEnd)="fetchMore()" -->
      <ng-select
        class="custom-select-drop-down"
        [placeholder]="placeholder || label || ''"
        [items]="options"
        bindLabel="name"
        [loading]="isLoading"
        bindValue="id"
        [(ngModel)]="selectedValue"
        appearance="outline"
        [closeOnSelect]="true"
        [clearOnBackspace]="true"
        [clearable]="true"
        (change)="onSelectChange($event)"
        [virtualScroll]="true"
        [typeahead]="input$"
        [hideSelected]="true"
        [appendTo]="body"
        (keydown)="onKeyDown($event)"
        #myNgSelect
      >
      </ng-select>
    </div>
    <div class="col-12">
      <div class="grid grid-no-tb-gutter" *ngIf="values && values?.length > 0">
        <div class="col-12 mg-t-10">
          <div class="tags-wrap">
            <span class="tags" *ngFor="let field of values">
              <span>{{ field.name }}</span>
              <i class="ml-1.5 pi pi-times" (click)="removeItem(field)"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
