import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'aiPrepReportIcon'
})
export class aiPrepReportIconPipe implements PipeTransform {
    transform(title: string): string {
        switch (title) {
            case "Description":
                return "assets/svg/Description.svg";
            case "Product":
                return "assets/svg/product.svg";
            case "Market":
                return "assets/svg/market.svg";
            case "Competitors":
                return "assets/svg/competitors.svg";
            case "Thesis":
                return "assets/svg/thesis.svg";
            case "Leadership":
                return "assets/svg/leadership.svg";
            case "Questions":
                return "assets/svg/questions.svg";
            case "Recent News":
                return "assets/svg/news.svg";
            default:
                return "";
        }
    }
}